import {createActions,using} from 'redux-box'

export default createActions({
    //MISC 
    setCriteriaType:using("data"),
    setEnableScroll:using("value"),
    setCreateSuccess:using("value"),
    setNotificationEditorType:using("value"),
    setNotificationType:using("type"),
    unsetNotificationData:using("value"),
    resetFilterData:using(),
    

    // DROPDOWN LIST FUNCTIONS
    requestAllChildrenListByOffset:using("offset"),
    requestConcatChildrenListByOffset:using("offset"),
    requestAllWowSchools:using(),
    requestAllSectionsByWowId:using("schoolId"),

    //FREADOM, WOW AND ALL USERS COMPONENT DROPDOWN FUNCTIONS
    requestGradesList:using(),
    requestAllChildrenByLevel:using("selectedLevels"),
    requestConcatChildrenListByLevel:using("selectedLevels"),
    requestAllChildrenBySchool:using("school"),
    unsetChildrenListResult:using(),
    setChildrenListSearch:using("search"),
    unsetChildrenList:using(),
    setSelectedLevelsList:using("data"),
    setSelectedGradeList:using("data"),
    setSelectedUsersList:using("data"),
    setSelectedSchoolsList:using("data"),
    setSelectedSectionsList:using("data"),
    setSelectedFirstChild:using("child"),
    setSelectedFilter:using("filter"),
    setSelectedWowData:using("data"),
    setSelectedFreadomData:using("data"),
    setSelectedAllData:using("data"),
    setEnableDoneBtn:using("value"),
    setMultipleSchoolList:using("data"),

    
    //NOTIFICATIONS CRUD ACTIONS
    requestAllNotifications:using("notification"),
    requestAllNotificationsByFilter:using("notification"),
    requestConcatAllNotifications:using("notification"),
    requestCreateNotification:using("notification"),
    requestNotificationById:using("notificationId"),
    setNotificationDescription:using("value"),
    setActiveNotification:using("result"),
    unsetDropdownData:using(),
    requestDeleteNotification:using("notification"),
    requestUpdateNotification:using("data"),


    //NOTIFICATION FILTER FUNCTIONS
    setSelectedNotType:using("value"),
    setSearchNotification:using("search"),
    setFilterUserType:using("userType"),
    setFilterMessageType:using("msgType"),
    setFilterDate:using("date"),
    
    })
import {createActions,using} from 'redux-box'

export default createActions({
  //....................UNIT CRUD..................
  setFetchSchoolGuidedPathList: using("value"),
  setSchoolGuidedPathList: using("List"),
  requestByGradeId: using("gradeId"),
  requestBySectionId: using("sectionId"),
  requestConcat: using("offset"),
  requestSchoolGuidedPath: using("data"),

  //CREATE UNIT
  createUnit: using("unitData"),
  createMultiUnit: using("unitData"),
  updateUnit: using("unitUpdateData"),
  setUnitEditInfo: using("value"),
  setUnitEdit: using("value"),
  // //.................Section............................
  requestActiveGrade: using(),

  //................................TASK CRUD............
  requestTaskList: using("unitId"),
  createTask: using("taskData"),
  updateTask: using("updateTaskData"),
  deleteTask: using("taskId"),
  setActiveTask: using("task"),

  // //...............MISC.................
  setSearch: using("search"),
  fetchSectionsForGpProduct: using("product_id", "pageName"),
  fetchSectionsForCustomGp: using("product_id"),
  setActiveGradeId: using("grade_id"),
});
import React, { Component } from "react";
// import { Empty } from "antd";
import ImageGallery from "react-image-gallery";
// import { ReactReader } from "react-reader";
import CloseBlackIcon from '../../../../static/img/close-black.svg'
import "../../../../../node_modules/react-image-gallery/styles/css/image-gallery.css";
// import "./MediaViewer.scss";
import Reader from 'react-epubjs'
const root = document.getElementById('root')

const TEXT = 'text';
const AUDIO = 'audio';
const HTML = 'html';
const IMAGE = 'image';
const PDF = 'pdf';
const EPUB = 'epub';
const VIDEO = 'video';
const YOUTUBE = 'youtube';
// @types/redux-logger
class MediaViewer extends Component {

  getYoutubeId = url => {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    const match = url.match(regExp);

    if (match && match[2].length === 11) {
        return match[2];
    } else {
        return 'error';
    }
  }
  componentDidMount() {
    const { data,content_type } = this.props;
    console.log('data',data)
    const firstMedia = data;
    if (content_type === PDF) {
      window.$("#pdf-view").pdf({
        source: firstMedia.media_url,
        loadingHeight: 500,
        loadingHTML: 'Loading... Please Wait.',
        title: '',
        bottom: 0,
        // height: 700,
        disableZoom: false,
        disableKeys: false,
        showToolbar: true,
        redrawOnWindowResize:true
      });
    }
  }

  render() {
    const { data,content_type } = this.props;
    const firstMedia = data;
    return (
      <div className="component-media-viewer">
        {/* <img src={CloseBlackIcon} className="close-icon" alt="close" onClick={this.props.closeMedia} /> */}
        {/* Text */}
        { content_type === TEXT && (
          <div dangerouslySetInnerHTML={{ __html: firstMedia.description }}></div>
        )}
        {/* AUDIO FILES */}
        {firstMedia.media_url && firstMedia.media_url.length !== 0 && (
          content_type === AUDIO && (
            <div className="audio-container">
              <audio controls>
                <source src={firstMedia.media_url} type="audio/mpeg" />
                Your browser does not support the audio tag.
              </audio>
            </div>
          )
        )}
        {/* HTML FILES */}
        {content_type === HTML && (
          <div dangerouslySetInnerHTML={{ __html: firstMedia.description }} />
        )}
        {/* IMAGE FILES */}
        {content_type === IMAGE && (
          <ImageGallery
            items={firstMedia.map(m => ({
              original: m.media_url,
              thumbnail: m.media_url
            }))}
            originalClass="img-class"
          />
        )}
        {/* PDF FILES */}
        {content_type === PDF && (
          // <iframe
          //   width="80%"
          //   height="100%"
          //   src={`https://docs.google.com/gview?url=${firstMedia.media[0].media_url}&embedded=true`}
          //   frameBorder="0"
          //   allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          //   title="pdf file view"
          // /> 
          // <div className="flip-book-container" src={firstMedia.media[0].media_url} />
          <div id="pdf-view"></div>
        )}
        {/* EPUB FILES */}
        {content_type === EPUB && (
          //  <ReactReader url={firstMedia.media_url} showToc />
          <Reader url={firstMedia.media_url}/>
        )}
        {/* VIDEO FILES */}
        {content_type === VIDEO && (
          <video controls>
            <source src={firstMedia.media_url} type="video/mp4" />
          </video>
        )}
        {/* YOUTUBE FILES */}
        {content_type === YOUTUBE && (
          <iframe
            src={`https://www.youtube.com/embed/${this.getYoutubeId(firstMedia.media_url)}`}
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope;"
            allowFullScreen
            title="video"
            style={{width:'50%'}}
          /> 
        )}
      </div>
    );
  }
}

export default MediaViewer;

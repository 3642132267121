import { createSagas } from "redux-box";
import PodcastApi from "@s/api/podcast";
import { call, put } from "redux-saga/effects";
import swal from "sweetalert2";

const errorHandler = (err) => {
  console.log("podcast list err", err);
  swal("Oops!", err.error_message, "error");
};

export default createSagas({
  FETCH_PODCAST_LIST: function*() {
    try {
      yield put({
        type: "SET_PODCAST_LIST_LOADING",
        value: true,
      });

      const res = yield call(PodcastApi.requestPodcastList);

      if(res){
        yield put({
          type: "SET_PODCAST_LIST",
          value: res,
        });

        yield put({
          type: "SET_PODCAST_LIST_LOADING",
          value: false,
        });
      }

    } catch (err) {
      errorHandler(err);
    }
  },

  FETCH_SCHOOL_GUIDED_PATH_PODCAST: function*() {
    try {
      const res = yield call(PodcastApi.requestPodcastList);
      if (res.length > 0) {
        const isDev =
          window.location.href.includes("admin-dev") ||
          window.location.href.includes("localhost");
        const podcast_id = isDev
          ? "a18f1361-f226-48cf-874b-410b78d502f7"
          : "222b5027-2431-4d6e-856c-5f70ef26351d";
        let school_guided_path_obj = res.filter(
          (ele) => ele.id === podcast_id
        )[0];
        yield put({
          type: "SET_SCHOOL_GUIDED_PATH_PODCAST",
          podcast: school_guided_path_obj,
        });
      }
    } catch (err) {
      errorHandler(err);
    }
  },

  UPDATE_PODCAST_LIST: function*({ updatePodcast }) {
    try {
      const res = yield call(
        PodcastApi.updatePodcast,
        updatePodcast.id,
        updatePodcast
      );
      if (res.id) {
        yield put({
          type: "FETCH_PODCAST_LIST",
        });
        swal("Done", "Podcast updated successfully", "success");
      }
    } catch (err) {
      errorHandler(err);
    }
  },

  CREATE_PODCAST: function*({ updatePodcast }) {
    try {
      const res = yield call(PodcastApi.createPodcast, updatePodcast);
      if (res.id) {
        yield put({
          type: "FETCH_PODCAST_LIST",
        });
        swal("Done", "Podcast created successfully", "success");
      }
    } catch (err) {
      errorHandler(err);
    }
  },

  FETCH_EPISODES_BY_PODCAST_ID: function*({ podcastId }) {
    console.log(podcastId);
    try {
      yield put({
        type: "SET_FETCH_PODCAST_EPISODES",
        value: true,
      });
      yield put({
        type: "SET_SELECTED_PODCAST",
        podcastId: podcastId,
      });
      const gState = require("store").default.getState();
      const search = gState.podcast.search_reading;
      const res = yield call(PodcastApi.getEpisodes, podcastId, 0, search);
      if (res && res.result) {
        yield put({
          type: "SET_EPISODES_RESULT",
          result: res,
        });
        yield put({
          type: "SET_EPISODES",
          episodes: res.result,
        });
        yield put({
          type: "SET_FETCH_PODCAST_EPISODES",
          value: false,
        });
      }
    } catch (err) {
      errorHandler(err);
    }
  },
  REQUEST_CONCAT_EPISODES: function*({ offset }) {
    try {
      const gState = require("store").default.getState();
      const podcastId = gState.podcast.podcast;
      const search = gState.schoolGuidedPath.search_reading;
      const res = yield call(PodcastApi.getEpisodes, podcastId, offset, search);

      yield put({
        type: "SET_EPISODES_RESULT",
        result: res,
      });
      if (res && res.result && res.result.length > 0)
        yield put({
          type: "SET_EPISODES_CONCAT_LIST",
          List: res.result,
        });
    } catch (err) {
      errorHandler(err);
    }
  },

  CREATE_EPISODE: function*({ episodeData }) {
    try {
      const res = yield call(PodcastApi.createEpisode, episodeData);
      if (res.id) {
        yield put({
          type: "FETCH_EPISODES_BY_PODCAST_ID",
          podcastId: episodeData.podcast,
        });
        swal("Done", "Episode created successfully", "success");
      }
    } catch (err) {
      errorHandler(err);
    }
  },

  UPDATE_EPISODE: function*({ updatedEpisodeData }) {
    try {
      const res = yield call(
        PodcastApi.updateEpisode,
        updatedEpisodeData.id,
        updatedEpisodeData
      );
      if (res.id) {
        yield put({
          type: "FETCH_EPISODES_BY_PODCAST_ID",
          podcastId: res.podcast,
        });
        swal("Done", "Episode updated successfully", "success");
      }
    } catch (err) {
      errorHandler(err);
    }
  },
});

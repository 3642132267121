import React, {Component} from 'react';
import {ImageURLContainer, LoadingContainer,LoadingImage} from "static/styled/custom.styled";
import {StorySectionContainer} from "@c/story-view/story-creator/StoryCreator.styled";
import YoutubeSection from "@c/activity-view/activity-creator/youtube-section/YoutubeSection";
import {connectStore} from "redux-box";
import {module as story} from 'store/story';


 class YoutubeStory extends Component
{
    constructor(props)
    {
        super();
        this.state=({
            media:[],
            enableLoad:false,
        })
    }
    componentDidMount()
    {
        let {section}=this.props;
        if(section!==null && section.content_type==='youtube')
        {
            this.setState({
                media:section.media,
            })
        }
    }



    onYoutubeLinkAdd = e => {
        if (e.key === "Enter" && e.target.value!=='') {
            this.setState({
                enableLoad:true,
                media: [{thumbnail_url:'',media_url:e.target.value}],
                youtube_url: ""
            });
            this.setYoutubeMedia(e.target.value)
            }

    };

    onYoutubeBtn = ()=> {
        console.log("hello");
        if(this.state.youtube_url && this.state.youtube_url.length>0)
        {this.setState({
            enableLoad:true,
            media: [{thumbnail_url:'',media_url:this.state.youtube_url}],
            youtube_url: ""
        });
        this.setYoutubeMedia(this.state.youtube_url)}

    };
    setYoutubeMedia=(targetFile)=> {
        //console.log(targetFile);
        let {story} = this.props;
        if (this.props.story.active_story) {
            let newSection = {
                content_type: 'youtube',
                media: [{thumbnail_url:'',media_url:targetFile}],
                name: 'youtube',
                description: '',
                story: story.active_story.id
            };
            if (this.props.section === null) {
                story.requestCreateStorySection(newSection);
            }
            else {
                story.requestUpdateStorySection(newSection);
            }
        }
        this.setState({
            enableLoad: false,
        })

    };
    render(){
        return(
            <StorySectionContainer>
            <div>
                <ImageURLContainer style={{width:'400px'}}>
                    <input
                        type="text"
                        placeholder="Enter Video URL"
                        value={this.state.youtube_url}
                        onChange={(e)=> this.setState({youtube_url: e.target.value})}
                        onKeyPress={this.onYoutubeLinkAdd}
                    />
                    <img
                        src={require("static/img/cloud-upload.png")}
                        alt="img-upload"
                        onClick={this.onYoutubeBtn}
                    />
                </ImageURLContainer>
            </div>
                {this.state.enableLoad?
                    <div style={{position:'relative',height:'120px'}}>
                        <LoadingContainer>
                            <LoadingImage alt="loading" width="50" height="50" src={require("static/img/Theme-loader.gif")} />
                        </LoadingContainer> </div>:
                    <div style={this.state.media.length>0?{marginTop:'20px'}:{}}>
                        <YoutubeSection media={this.state.media}/>
                    </div>}
            </StorySectionContainer>
        )
    }
}
export default connectStore({
    story
})(YoutubeStory)
import React, { useEffect, useState } from "react";
import { UserInputField } from "./UserCreaterFormStyled";
import Select from "react-select";
import { ThemeButton } from "@sc/custom.styled";
import {
  UserDetailsContainer,
  UserListLoadingContainer,
} from "@c/users-view/MainContent.styled";
import { AutoContainer } from "@sc/custom.styled";
import { module as user } from "@st/user";
import { connectStore } from "redux-box";
import { module as onboard } from "@st/onboard";
import { module as activity } from "@st/activity";
import useDebouncing from "../../../../utils/useDebouncing";
import swal from "sweetalert2";
import userApi from "@s/api/user";
import "../users.css";

const allStates = [
  {
    label: "Andaman and Nicobar Islands",
    value: "Andaman and Nicobar Islands",
  },
  { label: "Andhra Pradesh", value: "Andhra Pradesh" },
  { label: "Arunachal Pradesh", value: "Arunachal Pradesh" },
  { label: "Assam", value: "Assam" },
  { label: "Bihar", value: "Bihar" },
  { label: "Chandigarh", value: "Chandigarh" },
  { label: "Chhattisgarh", value: "Chhattisgarh" },
  {
    label: "Dadra and Nagar Haveli and Daman and Diu",
    value: "Dadra and Nagar Haveli and Daman and Diu",
  },
  { label: "Delhi", value: "Delhi" },
  { label: "Goa", value: "Goa" },
  { label: "Gujarat", value: "Gujarat" },
  { label: "Haryana", value: "Haryana" },
  { label: "Himachal Pradesh", value: "Himachal Pradesh" },
  { label: "Jammu and Kashmir", value: "Jammu and Kashmir" },
  { label: "Jharkhand", value: "Jharkhand" },
  { label: "Karnataka", value: "Karnataka" },
  { label: "Kerala", value: "Kerala" },
  { label: "Ladakh", value: "Ladakh" },
  { label: "Lakshadweep", value: "Lakshadweep" },
  { label: "Madhya Pradesh", value: "Madhya Pradesh" },
  { label: "Maharashtra", value: "Maharashtra" },
  { label: "Manipur", value: "Manipur" },
  { label: "Meghalaya", value: "Meghalaya" },
  { label: "Mizoram", value: "Mizoram" },
  { label: "Nagaland", value: "Nagaland" },
  { label: "Odisha", value: "Odisha" },
  { label: "Puducherry", value: "Puducherry" },
  { label: "Punjab", value: "Punjab" },
  { label: "Rajasthan", value: "Rajasthan" },
  { label: "Sikkim", value: "Sikkim" },
  { label: "Tamil Nadu", value: "Tamil Nadu" },
  { label: "Telangana", value: "Telangana" },
  { label: "Tripura", value: "Tripura" },
  { label: "Uttar Pradesh", value: "Uttar Pradesh" },
  { label: "Uttarakhand", value: "Uttarakhand" },
  { label: "West Bengal", value: "West Bengal" },
];

const UserCreaterForm = (props) => {
  const userTypes = [
    {
      label: "Student",
      value: false,
    },
    {
      label: "Teacher",
      value: true,
    },
  ];

  const [state, setState] = useState({
    userInfo: {
      name: "",
      contact_no: "",
      grade: "",
      schoolCode: "",
      school: "",
      section: "",
      state: "",
      is_teacher: false,
    },
  });

  const [allGrades, setAllGrades] = useState([]);
  const [allSections, setAllSections] = useState([]);
  const [showPassword, setShowPassword] = useState(false);

  const customStyles = {
    menu: (provided, state) => ({
      ...provided,
      position: "absolute",
    }),
    control: (provide) => ({
      ...provide,
      width: "95%",
      margin: "0 auto",
      border: "none",
      boxShadow: "none",
    }),
  };

  const debouncedSchoolCode = useDebouncing(state.userInfo.schoolCode, 1000);

  useEffect(() => {
    console.log(props.activity);
    if (props.activity.gradeList.length > 0) {
      setAllGrades(props.activity.gradeList);
    }
  }, [props.activity.gradeList]);

  useEffect(() => {
    if (props.user.sectionsList && props.user.sectionsList.length > 0) {
      setAllSections(props.user.sectionsList);
    }
  }, [props.user.sectionsList]);

  useEffect(() => {
    if (debouncedSchoolCode && debouncedSchoolCode.length == 6) {
      props.user.validateSchool(debouncedSchoolCode);
      setState({
        userInfo: {
          ...state.userInfo,
          grade: "",
          section: "",
        },
      });
    }
  }, [debouncedSchoolCode]);

  useEffect(() => {
    if (props.user.user_created) {
      setState({
        userInfo: {
          name: "",
          contact_no: "",
          grade: "",
          schoolCode: "",
          school: "",
          section: "",
          state: "",
          is_teacher: false,
        },
      });
      setAllSections([]);
    }
  }, [props.user.user_created]);

  const handleUserInfo = (type, evt, length) => {
    if (evt.target.value.length <= length) {
      setState({
        userInfo: { ...state.userInfo, [type]: evt.target.value },
      });
    }
  };

  const changeGrade = (selectedGrade) => {
    setState({
      userInfo: { ...state.userInfo, grade: selectedGrade, section: "" },
    });
    setAllSections([]);

    if (props.user.school) {
      let payload = {
        school: props.user.school.id,
        grade: selectedGrade.id,
      };
      props.user.getSections(payload);
    }
  };

  const validate = (name) => {
    let n = name.trim();
    const re = /^[a-zA-Z0-9_ .]*$/;
    if (re.test(n) == true && name.length > 2) return true;
    return false;
  };

  const handleSubmitBtn = async () => {
    const userInfo = state.userInfo;
    const validateName = validate(userInfo.name);
    if (!validateName) {
      swal("Error!", "Please enter a valid name!", "error");
      return;
    }

    if (userInfo.contact_no.length < 6 && userInfo.contact_no.length > 15) {
      swal("Error!", "Please enter a valid phone number!", "error");
      return;
    }

    if (!userInfo.grade && !userInfo.grade.id) {
      swal("Error!", "Please enter a valid grade!", "error");
      return;
    }

    if (!userInfo.section) {
      swal("Error!", "Please enter a valid section!", "error");
      return;
    }
    if (!userInfo.state) {
      swal("Error!", "Please enter a valid state!", "error");
      return;
    }

    const payload = {
      ...state.userInfo,
      grade: state.userInfo.grade.id,
      section:state.userInfo.is_teacher && state.userInfo.is_teacher.value ? state.userInfo.section.map((sc)=>sc.value) : [state.userInfo.section.value],
      school: props.user.school.id,
      is_teacher: state.userInfo.is_teacher.value,
      state: state.userInfo.state.value,
      device_type: "web",
    };

    props.user.registerUser(payload);
  };

  return (
    // <div className='add_user_form_container'>
    <UserDetailsContainer>
      {props.user.loading ? (
        <UserListLoadingContainer>
          <img
            alt="loading"
            width="40"
            height="40"
            src={require("static/img/Theme-loader.gif")}
          />
        </UserListLoadingContainer>
      ) : (
        <AutoContainer>
          <div className="add_user_form_main">
            <div className="add_user_form_body">
              <UserInputField
                type="text"
                placeholder="Name of the User"
                border={"2px solid #004ea3"}
                radius={"10px"}
                fontSize={"14px"}
                margin={"3px"}
                value={state.userInfo.name}
                onChange={(evt) => handleUserInfo("name", evt, 100)}
              />
              <UserInputField
                type="text"
                placeholder="Contact Number of the User"
                border={"2px solid #004ea3"}
                radius={"10px"}
                fontSize={"14px"}
                margin={"3px"}
                value={state.userInfo.contact_no}
                onChange={(evt) => handleUserInfo("contact_no", evt, 15)}
              />
              <UserInputField
                type="text"
                // disabled={debouncedSchoolCode.length === 6}
                placeholder="Enter School Code"
                border={"2px solid #004ea3"}
                radius={"10px"}
                fontSize={"14px"}
                margin={"3px"}
                value={state.userInfo.schoolCode}
                onChange={(evt) => handleUserInfo("schoolCode", evt, 6)}
              />
              {props.user.school && (
                <UserInputField
                  type="text"
                  disabled={true}
                  border={"2px solid #004ea3"}
                  radius={"10px"}
                  fontSize={"14px"}
                  margin={"3px"}
                  value={props.user.school && props.user.school.name}
                />
              )}
              <Select
                className="dropdown-container"
                value={state.userInfo.grade}
                onChange={changeGrade}
                options={allGrades && allGrades.length >= 1 && allGrades}
                placeholder="Select Grade"
                // styles={{ border: "2px solid #004ea3" }}
                styles={customStyles}
                menuPlacement="auto" // auto, top, or bottom
                menuPosition="fixed"
                // menuIsOpen={allGrades && allGrades.length >= 1 && true}
              />
              <Select
                className="dropdown-container"
                value={state.userInfo.is_teacher}
                onChange={(userType) =>
                  setState({
                    userInfo: { ...state.userInfo, is_teacher: userType },
                  })
                }
                options={userTypes}
                placeholder="Select User Type"
                styles={customStyles}
                menuPlacement="auto" // auto, top, or bottom
                menuPosition="fixed"
              />
              <Select
                className="dropdown-container"
                value={state.userInfo.section}
                onChange={(selectedSection) =>
                  setState({
                    userInfo: { ...state.userInfo, section: selectedSection },
                  })
                }
                options={allSections && allSections}
                placeholder="Select Section"
                styles={customStyles}
                menuPlacement="auto" // auto, top, or bottom
                menuPosition="fixed"
                isMulti={
                  state.userInfo.is_teacher && state.userInfo.is_teacher.value
                }
              />

              {/* <div className="toggle_switch_container">
              <label>Is Teacher?</label>
              <div className="toggle_switch">
                <p>No</p>
                <label class="switch">
                  <input
                    type="checkbox"
                    checked={state.userInfo.is_teacher}
                    onChange={(e) =>
                      setState({
                        userInfo: {
                          ...state.userInfo,
                          is_teacher: e.target.checked,
                        },
                      })
                    }
                  />
                  <span class="slider round"></span>
                </label>
                <p>Yes</p>
              </div>
            </div> */}

              <Select
                className="dropdown-container"
                value={state.userInfo.state}
                onChange={(userState) =>
                  setState({
                    userInfo: { ...state.userInfo, state: userState },
                  })
                }
                options={allStates}
                placeholder="Select User State"
                styles={customStyles}
                menuPlacement="auto" // auto, top, or bottom
                menuPosition="fixed"
              />

              <div
                style={{
                  minHeight: 100,
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <ThemeButton
                  onClick={() => handleSubmitBtn()}
                  primary
                  type="button"
                  style={{ width: "75%", margin: "0 auto" }}
                >
                  Create User
                </ThemeButton>
              </div>
            </div>
          </div>
        </AutoContainer>
      )}
    </UserDetailsContainer>

    // </div>
  );
};

export default connectStore({
  user,
  onboard,
  activity,
})(UserCreaterForm);

import styled from "styled-components";
import {MainBodyContentContainer,AlignContentHorizontal} from "@sc/custom.styled";

export const AssessmentCard=styled.div`
 margin:10px 0;
 height:150px;
 width:100%;
 padding:10px;
 border-radius:10px;
 //background: #341744;
background: linear-gradient(135deg, rgba(255,255,255,1) 0%, rgba(246,246,246,1) 4%, rgba(67,38,102,1) 100%);
 color:#333;
 transition: ease-in 0.3s;
 p{
 margin:0;
 font-size:16px;
 text-aligned:center;
 }
 &:hover{
 box-shadow: 0px 3px 10px -2px rgba(0,0,0,0.5);
 }
`;

export const AssessmentContainer=MainBodyContentContainer.extend`
 position:relative;
 min-height: calc(100vh - 110px);
 border-radius:10px;
 padding:0px;
`;

export const AssessmentContent=AlignContentHorizontal.extend`
width:100%;
top:0;
left:0;
padding:14px;
height:100%;`;
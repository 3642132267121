import React from 'react'
import {
    BrowseContainer,
    UploadLabel,
    UploadFileContainer,
    UploadFileLabelContainer
} from "../../../activity-view/activity-creator/media-editor/MediaEditor.styled";
import * as XLSX from "xlsx";
import { useState } from 'react';
import { connectStore } from 'redux-box';
import { module as user } from "@st/user";
import { module as ui } from "@st/ui";
import { ThemeButton } from '../../../../static/styled/custom.styled';

const BulkUpdateFileUploadModal = (props) => {
    const [uploadedFile,setUploadedFile] =useState(null)

    const handleUpload = (e) => {
        setUploadedFile(e.target.files[0])
    };

    const handleRegister =()=>{
        const file = uploadedFile;
        const reader = new FileReader();

        reader.onload = (e) => {
            const binaryStr = e.target.result;
            const workbook = XLSX.read(binaryStr, { type: "binary" });
            const sheetName = workbook.SheetNames[0];
            const sheet = workbook.Sheets[sheetName];
            const jsonData = XLSX.utils.sheet_to_json(sheet);
            props.user.updateBulkChild(jsonData);
        };

        reader.readAsBinaryString(file);

        props.ui.hideModal()
    }
  return (
    <div className='file_upload_container'>
        {/* {props.user.loading && <h1>Loading...</h1>} */}
        { uploadedFile && uploadedFile.name ? (
            uploadedFile.name
        ) : (
                  <UploadFileContainer>
                      <UploadFileLabelContainer>
                          <i className="fa fa-cloud-upload" />
                          <UploadLabel>
                              Drag Here or

                                  <BrowseContainer>
                                      <input
                                          type="file"
                                          accept={'xlsx'}
                                          onChange={handleUpload}
                                          multiple
                                      />
                                      <span> Browse</span>
                                  </BrowseContainer>


                              &nbsp; to Upload
                          </UploadLabel>
                      </UploadFileLabelContainer>
                  </UploadFileContainer>
        )}

        {
            uploadedFile && <ThemeButton primary onClick={()=>handleRegister()}>Register Now</ThemeButton>
        }

    </div>
  )
}

export default connectStore({
user,ui
})(BulkUpdateFileUploadModal)
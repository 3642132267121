import React, { Component } from 'react';
import { module as payment } from '@st/payment';
import { connectStore } from "redux-box";
import {SectionCard} from "../PaymentsLink.styled"



 class PromoCode extends Component {
    constructor(props) {
      super();
      this.state = {
        promoCode: ""
      };
    }

    componentDidMount() {
        
    }

    handlePromoInput=(e) => this.setState({promoCode: e.target.value});

    validatePromoCode=() => {
        let { payment } = this.props;
        payment.validatePromoCode(this.state.promoCode);
    }

    render() {
        return (
            <SectionCard >
                <h6 className="text-muted"><b>STEP 4: Apply Promo Code</b> </h6>
                <div className="input-group pb-2" style={{textAlign: 'center',justifyContent: 'center'}}>
                    <div className="input-group-prepend">
                        <span className="input-group-text bg-light" id="basic-addon1">
                            <b>Promo Code</b>
                        </span>
                    </div>
                    <input type="text" className="form-control" placeholder="If you have any" 
                    style={{width: '300px', flex: 'inherit'}}
                    value={this.state.promoCode} onChange={this.handlePromoInput} />
                    <div className="input-group-append">
                        <button className="btn btn-warning" type="button" onClick={this.validatePromoCode}>Apply Promo</button>
                    </div>
                </div>    
                {
                    (this.props.payment.validatedPromoCode!== null) &&
                    (<div className="text-center">
                        {this.props.payment.validatedPromoCode.percentage_value>0 &&
                        (<p className="text-danger m-0">Added {this.props.payment.validatedPromoCode.percentage_value} % discount.</p>)}
                        {this.props.payment.validatedPromoCode.days_value>0 && 
                        (<p className="text-danger m-0">Added {this.props.payment.validatedPromoCode.days_value} more days.</p>)}
                    </div>)
                }
                </SectionCard>
        )
    }
}
export default connectStore({
    payment
})(PromoCode)
import React, { Component } from 'react';
import { module as payment } from '@st/payment';
import { connectStore } from "redux-box";
import {SectionCard} from "../PaymentsLink.styled"



 class PlanCards extends Component {
    constructor(props) {
      super();
      this.state = {

      };
    }

    componentDidMount() {

    }

    selectPlan= (e, plan) => {
        e.stopPropagation();
        let { payment } = this.props;
        payment.setSelectedPlan(plan);
        
        this.props.showPromo(true);

    }
    unSelectPlan= (e) => {
        e.stopPropagation();
        this.props.payment.setSelectedPlan({});
        this.props.showPromo(true);
    }

    render() {
        let { payment } = this.props;
        let selectedPlan= payment.selectedPlan? payment.selectedPlan: {planId:-999};

        let planCard= (
            payment.webPlans.length>0 ?
            payment.webPlans.map((plan)=> 
            (
                <div key={plan.planId} className="col-sm-4">
                    <div className={"card m-2 "+ (selectedPlan.planId===plan.planId && "bg-warning text-light")} >
                        <div className="card-body">
                            <h6 className="card-subtitle text-muted">
                                Plan Name: <b>{plan.name}</b>
                            </h6>
                            <p className="card-text mb-2">For {plan.baseDays} Days</p>
                            <h4 className="card-title">&#8377; <b>{plan.baseAmount}</b></h4>
                            {  selectedPlan.planId === plan.planId?
                                (<button className="btn btn-sm btn-light" onClick={(e)=>this.unSelectPlan(e)}>
                                    Unselect Plan<i className="fa fa-minus ml-2"/>
                                </button>):
                                (<button className="btn btn-sm btn-warning" onClick={(e)=>this.selectPlan(e, plan)}>
                                    Select Plan<i className="fa fa-plus ml-2"/>
                                </button>)
                            }
                        </div>
                    </div>
                </div>
            )):
            (
                <div className="col-sm-8">
                    <div className="alert alert-danger p-3" role="alert">
                        <h6 className="alert-heading">Plans List empty!</h6>
                        <hr/>
                        <p className="mb-0"><b>Please contact at harshit@stones2milestones.com</b></p>
                    </div>
                </div>
            )
        );
        
        return (
            <SectionCard >
              <h6 className="text-muted"><b>STEP 3: Select Plan</b> </h6>
              <div className="row">
                {planCard}
              </div> 
            </SectionCard>
        )
    }
}
export default connectStore({
    payment
})(PlanCards)
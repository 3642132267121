import styled from "styled-components";
import { ActivityDescriptionInfoContainer } from "@sc/ActivityModal.styled";
import {ThemeButton} from "@sc/custom.styled"
import {ActivityInputField} from "@c/activity-view/activity-creation-form/ActivityCreationForm.styled";

export const StoryDescriptionInfoContainer = ActivityDescriptionInfoContainer.extend`
  width: 100%;
  z-index: 100;
 padding:18px;
 padding-bottom:0px;
  max-height:none;
  background-color: white;
`;

export const StorySectionLabelsContainer=styled.div`
 display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
`;


export const StoryThemeButton=ThemeButton.extend`
    width:${props=>props.width};
    margin-left:8px;
`;

export const StoryInputField=ActivityInputField.extend`
     height:${props=>props.height};
        margin:${props=>props.margin}
        font-size:${props=>props.fontSize}
        &::placeholder{
        font-size:${props=>props.fontSize};
        color:rgba(0,0,0,0.3);
        }
        `;

export const StoryCountItem=styled.div`
display:flex;
justify-content:flex-start;
align-items:center;
span{
color:#ff8a23;
font-size:20px;
}
//img
//{
//width:18px;
//height:18px;
//margin-left:8px;
//}
i {
font-size:16px;
margin-left:8px;
color:rgba(0,0,0,0.2);
`;

export const AddSectionContainer=styled.div`
position:absolute;
top: 120px;
 right: 22px;
`
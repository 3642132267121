import React, { Component } from "react";
import {
  ThemeButton,
  ErrorField,
  TagChipsContainer,
  TagChips,
  AudioThemeButton2,
  AudioThemeUploadIcon,
} from "@sc/custom.styled";
import {
  ActivityModalContainer,
  ActivityPopUpContainer,
} from "@sc/ActivityModal.styled";
import {
  CreateMCQQuestionContainer,
  MCQCheckBoxContainer,
  MCQCheckBoxInnerContainer,
  MCQDeleteButtonContainer,
  MCQOptionsAnswerContainer,
  QuestionHeader,
} from "@sc/McqEditor.styled";
import { FreadomPointsInputField } from "@sc/ActivityModal.styled";
import { connectStore } from "redux-box";
import { module as quiz2 } from "@st/quiz2";
import { module as activity } from "@st/activity";
import { module as ui } from "@st/ui";
import { module as tags } from "@st/tags";
import TextArea from "react-autosize-textarea";
import swal from "sweetalert2";
import { PanelHeader } from "@sc/custom.styled";
import { module as onboard } from "@st/onboard";
import Permission from "@s/permission";
import { uploadFile } from "@s/api/file";
import {
  ChangeImageButton,
  ImageContainer,
  SelectFileIn,
} from "../../../activity-view/activity-creation-form/ActivityCreationForm.styled";
import { UploadCollectionImageButton } from "../../../story-view/story-collection-creator/StoryCollectionCreator.styled";
import { UploadImageContainer } from "../../../story-view/story-creator/mcq-editor/McqEditor.styled";

import "./style_mcq.css";
const errorHandler = (err) => {
  swal({
    text: err,
    type: "warning",
  });
};
// let QuestionAnswerType =[
//   "text",'image','audio'
// ]
let QuestionAnswerType = ["text"];
const QASubCategoryType = ["normal"];

class QuizMcqEditor extends Component {
  constructor() {
    super();
    this.state = {
      question: "",
      label1: "",
      label2: "",
      valid_option: null,
      error: "",
      options: [],
      isMcqCreator: true,
      selectedDropdown: [],
      correctAnswer: "",
      fill_in_blank: "",
    };
    this.MAX_OPTIONS_COUNT = 4;
    this.MIN_OPTIONS_COUNT = 4;
  }
  componentWillReceiveProps(nextProps) {
    console.log(nextProps);
    let { quiz2 } = this.props;

    if (
      quiz2 &&
      quiz2.active_mcq &&
      nextProps.quiz &&
      nextProps.quiz2.active_mcq
    ) {
      if (quiz2.active_mcq.id !== nextProps.quiz2.active_mcq.id) {
        this.normalizeForState(nextProps.quiz2.active_mcq);
      }
    }
    if (nextProps.quiz2 && nextProps.quiz2.active_mcq) {
      if (this.state.question === "") {
        this.setState({ isMcqCreator: false });
      } else {
        this.setState({ isMcqCreator: true });
      }
      this.normalizeForState(nextProps.quiz2.active_mcq);
    } else {
      this.setState({
        question: "",
        options: [],
        valid_option: null,
        label1: "",
        label2: "",
        isMcqCreator: true,
        selectedDropdown: [],
        fill_in_blank: "",
        correctAnswer: "",
      });
    }
  }
  componentDidMount() {
    // hack to stabilize the textarea
    let { quiz2 } = this.props;
    this.setState({
      question: " ",
    });
    setTimeout(() => {
      if (quiz2 && quiz2.active_mcq) {
        this.normalizeForState(quiz2.active_mcq);
        //this.props.tags.setSelectedTagList(quiz2.active_mcq.tag);
      } else {
        this.setState({
          question: "",
        });
      }
    }, 200);
  }

  componentWillUnmount() {
    this.props.quiz2.setActiveQuizMcq(null);
  }

  normalizeForState = (mcq) => {
    if (this.props.quiz2.active_quiz.quiz_type == 0) {
      let option = mcq && mcq.options.options
      let bucket1values = mcq.options.bucket1.values.split(", ");
      let bucket2values = mcq.options.bucket2.values.split(", ");

      let valuesArr = option.map((eachitem) => {
        let returnitem = "";
        if (bucket1values.includes(eachitem)) {
          returnitem = mcq.options.bucket1.label;
        }
        if (bucket2values.includes(eachitem)) {
          returnitem = mcq.options.bucket2.label;
        }
        return returnitem;
      });

      this.setState({
        question: mcq.question,
        label1: mcq.options.bucket1.label,
        label2: mcq.options.bucket2.label,
        options: option,
        selectedDropdown: valuesArr,
      });
    }
    if (this.props.quiz2.active_quiz.quiz_type == 1) {
      let option = mcq && mcq.options.options
      let rightarr = parseInt(mcq.options.right_answer);
      let rightans = option[rightarr - 1];
      this.setState({
        question: mcq.question,
        fill_in_blank: mcq.options.blank,
        correctAnswer: rightans,
        options: option,
      });
    }

    if (this.props.quiz2.active_quiz.quiz_type == 2) {
      console.log("options2", mcq);

      let option = mcq && mcq.options.options
      let ritem = Array(option.length - 1).fill(" ");

      let Carr = this.state.selectedDropdown.map((eachitem) => {
        ritem.splice(
          eachitem - 1,
          1,
          this.state.options[this.state.selectedDropdown.indexOf(eachitem)]
        );
        // ritem.push(option[eachitem]);
        // ritem.insert(eachitem, option[eachitem]);
        console.log("ritem", ritem);
      });

      console.log("ritem", ritem);
      console.log("options1", mcq);

      let ansa = ritem.toString();

      const options = option.map((op) => ({
        id: op.id,
        name: op.name,
        imageChange: false,
        media: op.media ? op.media : [],
        order: op.order ? op.order : null,
      }));
      let valid_option_index = option.findIndex(
        (op) => op.id === mcq.valid_option
      );
      let valid_option = options[valid_option_index];
      console.log("11", options, valid_option);
      this.setState({
        question: mcq.question,
        options: option,
        selectedDropdown: mcq.options.selectedDropdown,
        ans: ansa,

        // options,
        // valid_option
      });
    }
  };

  addNewOption = () => {
    if (this.state.question == "") {
      this.setError("* Enter question");
      return;
    }
    if (
      this.props.quiz2.active_quiz.quiz_type == 0 &&
      (this.state.label1 == "" || this.state.label2 == "")
    ) {
      this.setError("* Enter Label");
      return;
    }
    if (
      this.props.quiz2.active_quiz.quiz_type == 2 &&
      (this.state.label1 == "" || this.state.label2 == "")
    )
      this.setState({
        options: [
          ...this.state.options,
          {
            id: Math.random(),
            name: "",
            imageChange: false,
            media: [],
            order: null,
          },
        ],
      });
    let add_options = this.state.options;
    add_options.push("");
    let selectdrop = this.state.selectedDropdown;
    selectdrop.push("Choose label");
    this.setState({ options: add_options, selectedDropdown: selectdrop });
  };

  setError = (value) => {
    this.setState({
      error: value,
    });
  };

  updateMcq = () => {
    if (this.state.options.length === 0 && this.state.question.length > 0)
      errorHandler("Please add options to add the mcq?");
    else if (this.state.question.length > 0 && this.state.options.length > 0) {
      if (this.props.quiz2.active_quiz.quiz_type == 1) {
        if (this.state.correctAnswer == "") {
          errorHandler("Please add correct answer");
          return;
        } else if (this.state.fill_in_blank == "") {
          errorHandler("Please enter fill in blank sentence");
          return;
        } else if (this.state.options.includes(this.state.correctAnswer)== "") {
          errorHandler("Please enter one of the options as the correct answer");
          return;
        }
        let passed = true;
        for (const key in this.state.options) {
          let cnt = Number(key) + 1;

          if (this.state.options[key] == "") {
            errorHandler(`Option ${cnt} is empty!`);
            passed = false;
          }
        }
        if (passed) {
          const mcq = this.normalizeForApi();
          this.props.quiz2.requestUpdateQuizQuestion(mcq);
          this.props.activity.closeEditor();
        }
      } else if (this.props.quiz2.active_quiz.quiz_type == 0) {
        let passed = true;
        for (const key in this.state.options) {
          let cnt = Number(key) + 1;

          if (
            this.state.selectedDropdown[key] == "Choose label" ||
            this.state.selectedDropdown[key] == ""
          ) {
            errorHandler(`Label ${cnt} is not selected!`);
            passed = false;
          }
          if (this.state.options[key] == "") {
            errorHandler(`Option ${cnt} is empty!`);
            passed = false;
          }
        }
        console.log(passed);
        if (passed) {
          const mcq = this.normalizeForApi();
          this.props.quiz2.requestUpdateQuizQuestion(mcq);
          this.props.activity.closeEditor();
        }
      } else if (this.props.quiz2.active_quiz.quiz_type == 2) {
        let passed = true;
        for (const key in this.state.options) {
          let cnt = Number(key) + 1;

          if (
            this.state.selectedDropdown[key] == "Choose..." ||
            this.state.selectedDropdown[key] == "Choose label" ||
            this.state.selectedDropdown[key] == ""
          ) {
            errorHandler(`Label ${cnt} is not selected!`);
            passed = false;
          }

          if (this.state.options[key] == "") {
            errorHandler(`Option ${cnt} is empty!`);
            passed = false;
          }
        }
        console.log(passed);
        if (passed) {
          const mcq = this.normalizeForApi();
          this.props.quiz2.requestUpdateQuizQuestion(mcq);
          this.props.activity.closeEditor();
        }
      }
    }

    // if (this.state.options.length === 0 && this.state.question.length > 0)
    //   errorHandler("Please add options to add the mcq?");

    // const mcq = this.normalizeForApi();

    // this.props.activity.closeEditor();
    // this.props.quiz2.setActiveQuizMcq(null);
    else if (
      this.state.question.length === 0 &&
      this.state.options.length > 0
    ) {
      errorHandler("Question Field is Empty! ");
    } else if (
      this.state.question.length === 0 &&
      this.state.options.length === 0
    ) {
      this.props.activity.closeEditor();
    }

    // if (this.props.quiz2.active_quiz.quiz_type == 0) {
    //   const mcq = this.normalizeForApi();
    //   console.log("done done done", mcq, this.state.selectedDropdown);
    // }
    // if (this.props.quiz2.active_quiz.quiz_type == 1) {
    //   const mcq = this.normalizeForApi();
    //   console.log(
    //     "done done done",
    //     mcq,
    //     this.state.correctAnswer,
    //     this.state.options
    //   );
    // }
  };
  createMcq = () => {
    if (this.state.options.length === 0 && this.state.question.length > 0)
      errorHandler("Please add options to add the mcq?");
    else if (this.state.question.length > 0 && this.state.options.length > 0) {
      if (this.props.quiz2.active_quiz.quiz_type == 1) {
        if (this.state.correctAnswer == "") {
          errorHandler("Please add correct answer");
          return;
        } else if (this.state.fill_in_blank == "") {
          errorHandler("Please enter fill in blank sentence");
          return;
        }else if (this.state.options.includes(this.state.correctAnswer)== "") {
          errorHandler("Please enter one of the options as the correct answer");
          return;
        }
        let passed = true;
        for (const key in this.state.options) {
          let cnt = Number(key) + 1;

          if (this.state.options[key] == "") {
            errorHandler(`Option ${cnt} is empty!`);
            passed = false;
          }
        }
        if (passed) {
          const mcq = this.normalizeForApi();
          this.props.quiz2.requestCreateQuizQuestion(mcq);
          this.props.activity.closeEditor();
        }
      } else if (this.props.quiz2.active_quiz.quiz_type == 0) {
        let passed = true;
        for (const key in this.state.options) {
          let cnt = Number(key) + 1;

          if (
            this.state.selectedDropdown[key] == "Choose label" ||
            this.state.selectedDropdown[key] == ""
          ) {
            errorHandler(`Label ${cnt} is not selected!`);
            passed = false;
          }
          if (this.state.options[key] == "") {
            errorHandler(`Option ${cnt} is empty!`);
            passed = false;
          }
        }
        console.log(passed);
        if (passed) {
          const mcq = this.normalizeForApi();
          this.props.quiz2.requestCreateQuizQuestion(mcq);
          this.props.activity.closeEditor();
        }
      } else if (this.props.quiz2.active_quiz.quiz_type == 2) {
        let passed = true;
        for (const key in this.state.options) {
          let cnt = Number(key) + 1;

          if (
            this.state.selectedDropdown[key] == "Choose label" ||
            this.state.selectedDropdown[key] == ""
          ) {
            errorHandler(`Label ${cnt} is not selected!`);
            passed = false;
          }

          if (
            this.state.optionType != "text" &&
            this.state.options[key].media &&
            this.state.options[key].media.length == 0
          ) {
            passed = false;
            errorHandler(`Option ${cnt} is empty!`);
          } else if (
            this.state.optionType == "text" &&
            this.state.options[key].name == ""
          ) {
            passed = false;
            errorHandler(`Option ${cnt} is empty!`);
          } else {
            if (!this.state.options[key]) {
              passed = false;
              errorHandler(`Order for option ${cnt} is required!`);
            }
          }
        }
        if (passed) {
          const mcq = this.normalizeForApi();
          this.props.quiz2.requestCreateQuizQuestion(mcq);
          this.props.activity.closeEditor();
        }
      }
    }
    // if (this.props.quiz2.active_quiz.quiz_type == 0) {
    //   const mcq = this.normalizeForApi();

    //   console.log("done done done", mcq, this.state.selectedDropdown);
    // }
    // if (this.props.quiz2.active_quiz.quiz_type == 1) {
    //   const mcq = this.normalizeForApi();
    //   console.log("done done done", mcq);
    // }

    //this.props.quiz2.requestCreateQuizQuestion(mcq);

    //this.props.activity.closeEditor();
    else if (
      this.state.question.length === 0 &&
      this.state.options.length > 0
    ) {
      errorHandler("Question Field is Empty! ");
    } else if (
      this.state.question.length === 0 &&
      this.state.options.length === 0
    ) {
      this.props.activity.closeEditor();
    }
  };

  normalizeForApi = () => {
    if (this.props.quiz2.active_quiz.quiz_type == 0) {
      let options_arr = this.state.options.join(", ");
      let bucket1_value = this.state.selectedDropdown
        .map((e, i) => (e === this.state.label1 ? i + 1 : ""))
        .filter(String);
      let bucket2_value = this.state.selectedDropdown
        .map((e, i) => (e === this.state.label2 ? i + 1 : ""))
        .filter(String);
      let value1 = bucket1_value.join(",");
      let value2 = bucket2_value.join(",");
      let quiz_body = {
        quiz: this.props.quiz2.active_quiz.id,
        question: this.state.question,
        options: {
          bucket1: {
            values: value1,
            label: this.state.label1,
          },
          bucket2: {
            values: value2,
            label: this.state.label2,
          },
          options: options_arr,
        },
      };
      return quiz_body;
    }
    if (this.props.quiz2.active_quiz.quiz_type == 2) {
      const res = { name: this.state.question };

      let options_arr = this.state.options.join(", ");
      console.log("sel", this.state.selectedDropdown);
      console.log("optna", this.state.options);
      let ritem = Array(this.state.options.length - 1).fill(" ");
      let Carr = this.state.selectedDropdown.map((eachitem) => {
        ritem.splice(
          eachitem - 1,
          1,
          this.state.options[this.state.selectedDropdown.indexOf(eachitem)]
        );

        // ritem.push(this.state.options[eachitem - 1]);
        // ritem.insert(eachitem, this.state.options[eachitem - 1]);

        console.log("ritem", ritem);
      });

      let ansa = ritem.toString();

      console.log("ritem", ritem);
      let bucket1_value = this.state.selectedDropdown
        .map((e, i) => (e === this.state.label1 ? i + 1 : ""))
        .filter(String);
      let bucket2_value = this.state.selectedDropdown
        .map((e, i) => (e === this.state.label2 ? i + 1 : ""))
        .filter(String);
      let value1 = bucket1_value.join(",");
      let value2 = bucket2_value.join(",");
      if (this.state.valid_option != null && this.state.options)
        res.valid_option =
          this.state.options.findIndex(
            (op) => op.id === this.state.valid_option.id
          ) + 1;
      let quiz_body = {
        quiz: this.props.quiz2.active_quiz.id,
        question: this.state.question,
        options: {
          options: options_arr,
          valid_option: this.state.valid_option,
          selectedDropdown: this.state.selectedDropdown,
          right_answer: ansa,
        },
      };
      return quiz_body;
    }
    if (this.props.quiz2.active_quiz.quiz_type == 1) {
      let options_arr = this.state.options.join(", ");

      let rightans = this.state.options.indexOf(this.state.correctAnswer);
      //console.log(this.state.correctAnswer, this.state.options, rightans);
      let quiz_body = {
        quiz: this.props.quiz2.active_quiz.id,
        question: this.state.question,
        options: {
          blank: this.state.fill_in_blank,
          options: options_arr,
          right_answer: (rightans + 1).toString(),
        },
      };
      return quiz_body;
    }
  };

  updateQuestion = (e) => {
    if (e.target.value !== "" && this.state.question.length === 1) {
      this.setError(null);
    }
    this.setState({
      question: e.target.value,
    });
  };

  setError = (value) => {
    this.setState({
      error: value,
    });
  };

  changeOptions = (e, index) => {
    let new_options = this.state.options;
    new_options[index] = e.target.value;
    this.setState({ options: new_options });
  };

  updateDropdown = (e, index) => {
    let dropdown = this.state.selectedDropdown;
    dropdown[index] = e.target.value;
    this.setState({ selectedDropdown: dropdown });
  };

  removeOption = (e, index) => {
    let option = this.state.options;
    let dropdown = this.state.selectedDropdown;
    dropdown.splice(index, 1);
    option.splice(index, 1);
    this.setState({ options: option, selectedDropdown: dropdown });
  };
  changeLabel1 = (e) => {
    console.log(e.target.value);
    this.state.label2 && e.target.value !== "" && this.setError(null);
    let ele = this.state.selectedDropdown.map((ele) => {
      if (ele == this.state.label1) return e.target.value;
      return ele;
    });
    this.setState({ label1: e.target.value, selectedDropdown: ele });
  };
  changeLabel2 = (e) => {
    console.log(e.target.value);
    this.state.label1 && e.target.value !== "" && this.setError(null);
    let ele = this.state.selectedDropdown.map((ele) => {
      if (ele == this.state.label2) return e.target.value;
      return ele;
    });
    this.setState({ label2: e.target.value, selectedDropdown: ele });
  };

  changeOrder = (e, index) => {
    let option = this.state.options;
    let a = option.map((v, i) => {
      if (v.order == e.target.value) {
        v.order = null;
      } else if (index == i) {
        v.order = Number(e.target.value);
      }
      return v;
    });
    this.setState({ options: a });
  };

  updateOption = (option, e) => {
    const index = this.state.options.findIndex((op) => op.id === option.id);
    let options1 = this.state.options.slice(0, index);
    let options2 =
      this.state.options.length > 1
        ? this.state.options.slice(index + 1, this.state.options.length)
        : [];
    this.setState({
      options: [
        ...options1,
        {
          id: option.id,
          name: e.target.value,
          order: option.order ? option.order : null,
        },
        ...options2,
      ],
    });
  };

  setAsValidOption = (option) => this.setState({ valid_option: option });

  render() {
    let { label1, label2 } = this.state;
    let { quiz2 } = this.props;
    console.log("hi", this.state.selectedDropdown);
    let orderOption =
      this.state.options &&
      this.state.options.map((v, i) => (
        <option value={i + 1} key={"key" + i}>
          {i + 1}
        </option>
      ));
    return (
      <ActivityPopUpContainer style={{ height: "100%" }}>
        <PanelHeader primary style={{ zIndex: "999" }}>
          <span>Create QuizQuestion</span>
          {quiz2.active_mcq ? (
            <ThemeButton primary onClick={this.updateMcq}>
              Update
            </ThemeButton>
          ) : (
            <ThemeButton primary onClick={this.createMcq}>
              Done
            </ThemeButton>
          )}
        </PanelHeader>
        <ActivityModalContainer className="mcq_body">
          <QuestionHeader>
            <ErrorField width={this.state.error ? "auto" : "0"}>
              <div style={{ marginTop: 15 }}>{this.state.error}</div>
            </ErrorField>
            <div style={{ display: "flex" }}>
              <CreateMCQQuestionContainer>
                <TextArea
                  placeholder="Enter question"
                  autoFocus={true}
                  value={this.state.question}
                  onChange={this.updateQuestion}
                />
              </CreateMCQQuestionContainer>
            </div>
          </QuestionHeader>
          {quiz2.active_quiz.quiz_type == 0 ? (
            <div>
              <div style={{ marginBottom: "5px" }}>
                <FreadomPointsInputField primary width={"20px"}>
                  <input
                    type="text"
                    placeholder="Bucket1 Label"
                    value={this.state.label1}
                    onChange={this.changeLabel1}
                  />
                </FreadomPointsInputField>
              </div>
              <div>
                <FreadomPointsInputField primary width={"20px"}>
                  <input
                    type="text"
                    placeholder="Bucket2 Label"
                    value={this.state.label2}
                    onChange={this.changeLabel2}
                  />
                </FreadomPointsInputField>
              </div>
              <hr />
              {this.state.options.map((each_op, index) => (
                <div style={{ marginBottom: "5px", display: "flex" }}>
                  <FreadomPointsInputField primary width={"10px"}>
                    <input
                      type="text"
                      placeholder="value"
                      value={each_op}
                      onChange={(e) => this.changeOptions(e, index)}
                    />
                  </FreadomPointsInputField>
                  <div style={{ marginLeft: "5px", marginRight: "5px" }}>
                    <select
                      id={`bucketlabel` + `${index}`}
                      onChange={(e) => this.updateDropdown(e, index)}
                      value={this.state.selectedDropdown[index]}
                    >
                      <option>Choose label</option>
                      <option value={label1}>{label1}</option>
                      <option value={label2}>{label2}</option>
                    </select>
                  </div>
                  <MCQDeleteButtonContainer
                    onClick={(e) => this.removeOption(e, index)}
                  >
                    <img
                      src={require("static/img/trash.png")}
                      className="image-fluid"
                      alt="Trash Icon"
                    />
                  </MCQDeleteButtonContainer>
                </div>
              ))}
              {this.state.options.length < 6 && (
                <ThemeButton onClick={this.addNewOption}>
                  + Add Answer
                </ThemeButton>
              )}
            </div>
          ) : null}
          {quiz2.active_quiz.quiz_type == 1 ? (
            <div>
              {this.state.options.map((each_op, index) => (
                <div style={{ marginBottom: "5px", display: "flex" }}>
                  <FreadomPointsInputField primary width={"10px"}>
                    <input
                      type="text"
                      placeholder="value"
                      value={each_op}
                      onChange={(e) => this.changeOptions(e, index)}
                    />
                  </FreadomPointsInputField>
                  <MCQDeleteButtonContainer
                    onClick={(e) => this.removeOption(e, index)}
                  >
                    <img
                      src={require("static/img/trash.png")}
                      className="image-fluid"
                      alt="Trash Icon"
                    />
                  </MCQDeleteButtonContainer>
                </div>
              ))}
              {this.state.options.length < 6 && (
                <ThemeButton onClick={this.addNewOption}>
                  + Add Answer
                </ThemeButton>
              )}
              <hr />
              <span>Fill in blanks sentence</span>
              <div style={{ marginLeft: "5px", marginRight: "5px" }}>
                <CreateMCQQuestionContainer>
                  <TextArea
                    placeholder="Enter fill in blank sentence"
                    autoFocus={true}
                    value={this.state.fill_in_blank}
                    onChange={(e) =>
                      this.setState({ fill_in_blank: e.target.value })
                    }
                  />
                </CreateMCQQuestionContainer>
              </div>
              <hr />
              <span>Correct Answer</span>
              <div style={{ marginTop: "10px" }}>
                <FreadomPointsInputField primary width={"10px"}>
                  <input
                    type="text"
                    placeholder="correct answer"
                    value={this.state.correctAnswer}
                    onChange={(e) =>
                      this.setState({ correctAnswer: e.target.value })
                    }
                  />
                </FreadomPointsInputField>
              </div>
            </div>
          ) : null}{" "}
          {quiz2.active_quiz.quiz_type == 2 ? (
            <div>
              {this.state.options.map((each_op, index) => (
                <div style={{ marginBottom: "5px", display: "flex" }}>
                  <TextArea
                    style={{
                      marginRight: "10%",
                      marginBottom: "3%",
                      padding: "5px",
                    }}
                    type="text"
                    autoFocus={true}
                    placeholder="Enter option"
                    value={each_op}
                    onChange={(e) => this.changeOptions(e, index)}
                  />
                  <select
                    style={{ width: "auto", marginRight: "3%" }}
                    className="custom-select"
                    id="inputGroupSelect01"
                    onChange={(e) => this.updateDropdown(e, index)}
                    value={this.state.selectedDropdown[index]}
                  >
                    <option
                      selected={this.state.selectedDropdown[index]}
                      defaultValue
                    >
                      Choose...
                    </option>
                    {orderOption}
                  </select>
                  <MCQDeleteButtonContainer
                    onClick={(e) => this.removeOption(e, index)}
                  >
                    <img
                      src={require("static/img/trash.png")}
                      className="image-fluid"
                      alt="Trash Icon"
                    />
                  </MCQDeleteButtonContainer>
                </div>
              ))}
              {this.state.options.length < 6 && (
                <ThemeButton onClick={this.addNewOption}>
                  + Add Answer
                </ThemeButton>
              )}
            </div>
          ) : null}
        </ActivityModalContainer>
      </ActivityPopUpContainer>
    );
  }
}

export default connectStore({
  quiz2,
  activity,
  ui,
  tags,
  onboard,
})(QuizMcqEditor);

import styled from "styled-components";

export const Container=styled.div`

`;

export const SectionDropDown=styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 1rem 0;
    width: 100%;
`;

// remove section border


export const SelectTag=styled.select`
    border: none;
    padding: 0.5rem;
    background-color: #ffffff;
    &:focus{
        outline: 0;
        border: none;

    }
`;

// create styles for section dropdown option
export const OptionTag=styled.option`
    border: none;
    padding: 0.5rem;

    // color: white !important;
    &:focus{
        outline: 0;
        border:none;
    }
`;


export const GradeButton = styled.button`
border: none;
outline: none;
background:transparent;
cursor: pointer;
`
import { createSagas } from "redux-box";
import GradeApi from "@s/api/grade";
import ActivityApi from "@s/api/activity";
import { call, put } from "redux-saga/effects";
import { push } from "react-router-redux";
import swal from "sweetalert2";

// import store from 'store'
const defaultSections = [
  { name: "Resources" },
  { name: "What will you learn" },
  { name: "Activity Steps" }
];

const errorHandler = err => {
  console.log(err);
  swal("Oops!", err.error_message, "error");
};

export default createSagas({
  REQUEST_GRADES: function*() {
    try {
      const res = yield call(GradeApi.getAllGrades);
      yield put({
        type: "SET_GRADES",
        grades: res
      });
      yield put({
        type: "REQUEST_ACTIVITIES_BY_GRADE",
        grade: res[0]
      });

      yield put({
        type: "SET_ACTIVE_GRADE",
        grade: res[0]
      });

      yield put({
        type: "SET_ACTIVE_PACK_GRADE",
        grade: res[0].id
      });
    } catch (err) {}
  },

  REQUEST_ACTIVITIES_BY_GRADE: function*({ grade }) {
    try {
      const gState = require("store").default.getState();
      let search = gState.activity.search_text;
      //const activities =gState.activity.activities;
      yield put({
        type: "SET_FETCH_ACTIVITY_BY_GRADE",
        val: true
      });
      yield put({
        type: "SET_ACTIVE_GRADE",
        grade: grade
      });

      const res = yield call(
        ActivityApi.getAllActivitiesByGrade,
        grade.id,
        0,
        search
      );
      //if(res!==null)
      yield put({
        type: "SET_ACTIVITIES_RESULT",
        result: res
      });
      if (res && res.result)
        yield put({
          type: "SET_ACTIVITIES",
          activities: res.result.sort(function(a, b) {
            return a.order - b.order;
          })
        });
    } catch (err) {
      errorHandler(err);
    } finally {
      yield put({
        type: "SET_FETCH_ACTIVITY_BY_GRADE",
        val: false
      });
    }
  },

  REQUEST_ACTIVITIES_CONCAT: function*({ offset }) {
    try {
      const gState = require("store").default.getState();
      const grade = gState.activity.active_grade;
      let search = gState.activity.search_text;
      let res = null;
      if (grade !== null)
        res = yield call(
          ActivityApi.getAllActivitiesByGrade,
          grade.id,
          offset,
          search
        );
      else res = yield call(ActivityApi.getAllActivities, offset, search);
      if (res !== null) {
        yield put({
          type: "SET_ACTIVITIES_RESULT",
          result: res
        });
        if (res && res.result && res.result.length > 0)
          yield put({
            type: "SET_CONCAT_ACTIVITIES",
            activities: res.result.sort(function(a, b) {
              return a.order - b.order;
            })
          });
      }
    } catch (err) {
      errorHandler(err);
    }
  },

  //  ...............................ACTIVITY CRUD.................
  REQUEST_ACTIVITIES: function*() {
    try {
      const gState = require("store").default.getState();
      const grade = gState.activity.active_grade;
      let search = gState.activity.search_text;
      let res = null;
      if (grade !== null)
        res = yield call(
          ActivityApi.getAllActivitiesByGrade,
          grade.id,
          0,
          search
        );
      else res = yield call(ActivityApi.getAllActivities, 0, search);
      yield put({
        type: "SET_ACTIVITIES_RESULT",
        result: res
      });
      if (res && res.result && res.result.length > 0)
        yield put({
          type: "SET_ACTIVITIES",
          activities: res.result.sort(function(a, b) {
            return a.order - b.order;
          })
        });
    } catch (err) {
      errorHandler(err);
    }
  },

  UPDATE_ACTIVITY: function*({ activity }) {
    try {
      // const gState = require('store').default.getState();
      // const id = activity.id ;
      const res = yield call(ActivityApi.updateActivity, activity.id, activity);
      yield put({
        type: "SET_ACTIVE_ACTIVITY",
        activity: res
      });
      yield put({
        type: "REQUEST_ACTIVITIES"
      });
      const globalState = require("store").default.getState();
      if (globalState.activity.active_activity_pack !== null) {
        const activity_pack_id = globalState.activity.active_activity_pack.id;
        yield put({
          type: "REQUEST_ACTIVITY_PACK_BY_ID",
          activityPackId: activity_pack_id
        });
      }
    } catch (err) {
      errorHandler(err);
    }
  },

  DELETE_ACTIVITY: function*({ activity }) {
    try {
      yield call(ActivityApi.deleteActivity, activity.id);
      yield put({
        type: "REQUEST_ACTIVITIES"
      });
    } catch (err) {
      errorHandler(err);
    }
  },

  CREATE_ACTIVITY: function*({ activity }) {
    try {
      const res = yield call(ActivityApi.createActivity, activity);
      // yield put({
      //   type: "SET_ACTIVE_ACTIVITY",
      //   activity: res
      // });
      yield put({
        type: "REQUEST_ACTIVITIES"
      });
      if (res && res.activity_pack !== null) {
        yield put({
          type: "REQUEST_ACTIVITY_PACKS"
        });
        yield put({
          type: "SET_ACTIVE_PACK_GRADE",
          grade: res.grade
        });
        const globalState = require("store").default.getState();
        const activity_pack = globalState.activity.active_activity_pack;
        if (activity_pack)
          swal(
            "Done!",
            "Created activity successfully in " + activity_pack.name + " !",
            "success"
          );
      } else swal("Done!", "Created activity successfully!", "success");
      yield put(push(`/activities/edit/${res.id}`));
    } catch (err) {
      errorHandler(err);
    }
  },

  REQUEST_ACTIVITY_BY_ID: function*({ activityId }) {
    try {
      yield put({
        type: "FETCH_ACTIVITY",
        value: true
      });
      const res = yield call(ActivityApi.getActivityById, activityId);
      yield put({
        type: "SET_ACTIVE_ACTIVITY",
        activity: res
      });
      if (res && res.activity_pack !== null) {
        yield put({
          type: "REQUEST_ACTIVITY_PACKS"
        });
      }
      if (res.section_data && res.section_data.length === 0) {
        defaultSections.forEach(function(section) {
          require("store").default.dispatch({
            type: "ADD_NEW_SECTION",
            section
          });
        });
      }
    } catch (err) {
      errorHandler(err);
    } finally {
      yield put({
        type: "FETCH_ACTIVITY",
        value: false
      });
    }
  },

  REORDER_ACTIVITY: function*({ activity }) {
    try {
      yield call(ActivityApi.reorderActivity, activity.id, {
        new_order: activity.new_order
      });
      yield put({
        type: "SET_ORDER_NO",
        order_no: activity.new_order
      });

      yield put({
        type: "REQUEST_ACTIVITIES"
      });
    } catch (err) {
      errorHandler(err);
    }
  },
  REQUEST_PUBLISH_ACTIVITY: function*({ activity }) {
    try {
      yield call(ActivityApi.updateActivity, activity.id, activity);

      yield put({
        type: "REQUEST_ACTIVITIES"
      });
      const globalState = require("store").default.getState();
      if (globalState.activity.active_activity_pack !== null) {
        const activity_pack_id = globalState.activity.active_activity_pack.id;
        yield put({
          type: "REQUEST_ACTIVITY_PACK_BY_ID",
          activityPackId: activity_pack_id
        });
      }
    } catch (err) {
      errorHandler(err);
    }
  },

  //  ...........................SECTION CRUD............
  REQUEST_SECTION: function*({ section }) {
    try {
      const res = yield call(ActivityApi.getSectionById, section.id);
      yield put({
        type: "SET_ACTIVE_SECTION",
        section: res.result
      });
    } catch (err) {
      errorHandler(err);
    }
  },

  //  ........................ACTIVITY PACK CRUD..............
  REQUEST_ACTIVITY_PACKS: function*() {
    try {
      yield put({
        type: "SET_FETCH_ACTIVITY_PACKS_BY_LEVEL",
        value: true
      });
      const globalState = require("store").default.getState();
      let gradeLevel =
        globalState.activity.active_grade_level +
        ":" +
        globalState.activity.active_level;
      let search = globalState.activity.search_pack_text;
      const res = yield call(
        ActivityApi.getAllActivityPacks,
        0,
        gradeLevel,
        search
      );
      yield put({
        type: "SET_ACTIVITY_PACKS_RESULT",
        result: res
      });
      if (res && res.result )
        if(res.result.length > 0){
          yield put({
            type: "SET_ACTIVITY_PACKS",
            activityPacks: res.result.sort(function(a, b) {
              return a.order - b.order;
            })
          });
        }else{
          yield put({
            type: "SET_ACTIVITY_PACKS",
            activityPacks: []
          });
        }
        
    } catch (err) {
      errorHandler(err);
    } finally {
      yield put({
        type: "SET_FETCH_ACTIVITY_PACKS_BY_LEVEL",
        value: false
      });
    }
  },

  REQUEST_CONCAT_ACTIVITY_PACKS: function*({ offset }) {
    try {
      const globalState = require("store").default.getState();
      let gradeLevel =
        globalState.activity.active_grade_level +
        ":" +
        globalState.activity.active_level;
      let search = globalState.activity.search_pack_text;
      const res = yield call(
        ActivityApi.getAllActivityPacks,
        offset,
        gradeLevel,
        search
      );
      yield put({
        type: "SET_ACTIVITY_PACKS_RESULT",
        result: res
      });
      if (res && res.result && res.result.length > 0)
        yield put({
          type: "SET_CONCAT_ACTIVITY_PACKS_LIST",
          activityPacks: res.result
        });
    } catch (err) {
      errorHandler(err);
    }
  },

  CREATE_ACTIVITY_PACK: function*({ activityPack }) {
    try {
      const res = yield call(ActivityApi.createActivityPack, activityPack);
      yield put({
        type: "SET_ACTIVE_ACTIVITY_PACK",
        activityPack: res
      });
      swal("Done", "Activity pack created successfully!", "success");
      if (res && res.grade_level !== "") {
        let level = res.grade_level.split(":");
        yield put({
          type: "SET_ACTIVE_LEVEL",
          activeLevel: level[1]
        });
        yield put({
          type: "SET_ACTIVE_GRADE_LEVEL",
          activeGradeLevel: level[0]
        });
      }
      yield put(push(`/activities/packs/edit/${res.id}`));
      yield put({
        type: "REQUEST_ACTIVITY_PACKS"
      });

      yield put(push(`/activities/packs/edit/${res.id}`));
    } catch (err) {
      errorHandler(err);
    }
  },

  REQUEST_ACTIVITY_PACK_BY_ID: function*({ activityPackId }) {
    try {
      yield put({
        type: "FETCH_ACTIVITY_PACK",
        value: true
      });

      const res = yield call(ActivityApi.getActivityPackById, activityPackId);
      let gradeLevels = res.grade_level.split(":");
      yield put({
        type: "SET_ACTIVE_LEVEL",
        activeLevel: gradeLevels[1]
      });
      yield put({
        type: "SET_ACTIVE_GRADE_LEVEL",
        activeGradeLevel: gradeLevels[0]
      });
      yield put({
        type: "SET_ACTIVE_ACTIVITY_PACK",
        activityPack: res
      });

      yield put({
        type: "SET_ACTIVE_PACK_GRADE",
        grade: res.grade
      });
    } catch (err) {
      errorHandler(err);
    } finally {
      yield put({
        type: "FETCH_ACTIVITY_PACK",
        value: false
      });
    }
  },

  PUBLISH_ACTIVITY_PACK: function*({ data }) {
    try {
      yield call(ActivityApi.updateActivityPack, data.id, data);

      yield put({
        type: "REQUEST_ACTIVITY_PACK_BY_ID",
        activityPackId: data.id
      });
      yield put({
        type: "REQUEST_ACTIVITY_PACKS"
      });
    } catch (err) {
      errorHandler(err);
    }
  },

  UPDATE_ACTIVITY_PACK: function*({ data }) {
    try {
      const globalState = require("store").default.getState();
      let activity_pack_id;
      activity_pack_id = globalState.activity.active_activity_pack.id;
      yield call(ActivityApi.updateActivityPack, activity_pack_id, data);

      yield put({
        type: "REQUEST_ACTIVITY_PACK_BY_ID",
        activityPackId: activity_pack_id
      });
      yield put({
        type: "REQUEST_ACTIVITY_PACKS"
      });
    } catch (err) {
      errorHandler(err);
    }
  },

  DELETE_ACTIVITY_PACK: function*({ activityPack }) {
    try {
      yield call(ActivityApi.deleteActivityPack, activityPack.id);
      yield put({
        type: "REQUEST_ACTIVITY_PACKS"
      });
    } catch (err) {
      errorHandler(err);
    }
  },

  //........................SECTION CRUD..............
  REQUEST_CREATE_SECTION: function*({ data }) {
    try {
      const gState = require("store").default.getState();
      const active_section = gState.activity.active_section;
      const activity_id = gState.activity.active_activity.id;
      yield call(ActivityApi.createSection, {
        content_type: "text",
        description: "",
        media: [],
        level: "easy",
        ...data,
        ...active_section,
        activity: activity_id
      });
      // flush the active_section from new_sections since it's been created
      yield put({
        type: "REMOVE_NEW_SECTION",
        section: gState.activity.active_section
      });
      yield put({
        type: "REQUEST_ACTIVITY_BY_ID",
        activityId: activity_id
      });
    } catch (err) {
      errorHandler(err);
    }
  },

  REQUEST_UPDATE_SECTION: function*({ data }) {
    try {
      const globalState = require("store").default.getState();
      const activity_id = globalState.activity.active_activity.id;
      const section = globalState.activity.active_section;

      yield call(ActivityApi.updateSection, activity_id, section.id, data);

      yield put({
        type: "REQUEST_ACTIVITY_BY_ID",
        activityId: activity_id
      });
    } catch (err) {
      errorHandler(err);
    }
  },
  DELETE_ACTIVITY_SECTION: function*({ section }) {
    try {
      const globalState = require("store").default.getState();
      const activity_id = globalState.activity.active_activity.id;
      yield call(ActivityApi.deleteSection, activity_id, section.id);

      yield put({
        type: "REQUEST_ACTIVITY_BY_ID",
        activityId: activity_id
      });
    } catch (err) {
      console.log(err);
      errorHandler(err);
    }
  },

  //.......................QUESTION CRUD...............
  REQUEST_CREATE_QUESTION: function*({ question }) {
    try {
      yield call(ActivityApi.createQuestion, question);
      swal("Done!", "Created question!", "success");
      yield put({
        type: "REQUEST_ALL_QUESTIONS",
        activityId: question.activity
      });
    } catch (err) {
      errorHandler(err);
    }
  },
  REQUEST_UPDATE_QUESTION: function*({ question }) {
    try {
      const gState = require("store").default.getState();
      const questionId = gState.activity.active_mcq.id;

      for (let i = 0; i < question.options.length; i++) {
        let option = question.options[i];
        yield ActivityApi.updateOption(option.id, option);
      }
      delete question.options;
      yield call(ActivityApi.updateQuestion, questionId, question);
      swal("Done!", "Updated question!", "success");
      yield put({
        type: "REQUEST_ALL_QUESTIONS",
        activityId: question.activity
      });
    } catch (err) {
      errorHandler(err);
    }
  },
  REQUEST_DELETE_QUESTION: function*({ question }) {
    try {
      yield call(ActivityApi.deleteQuestion, question.id);
      swal("Done!", "The selected question was deleted!", "success");
      yield put({
        type: "REQUEST_ALL_QUESTIONS",
        activityId: question.activity
      });
    } catch (err) {
      errorHandler(err);
    }
  },

  REQUEST_ALL_QUESTIONS: function*({ activityId }) {
    try {
      const res = yield call(ActivityApi.getAllQuestion, activityId);
      yield put({
        type: "SET_ALL_QUESTION",
        question: res
      });
    } catch (err) {
      errorHandler(err);
    }
  },

  //.......................TAG CRUD..................
  REQUEST_CREATE_TAG: function*({ tag }) {
    try {
      const globalState = require("store").default.getState();
      let tags = globalState.activity.active_activity.tag;
      if (!tags.some(data => data.toLowerCase() === tag.toLowerCase())) {
        const data = {
          ...globalState.activity.active_activity,
          tag: [...tags, tag]
        };

        yield put({
          type: "UPDATE_ACTIVITY",
          activity: data
        });
      }
    } catch (err) {
      errorHandler(err);
    }
  },

  DELETE_TAG: function*({ tag }) {
    try {
      const globalState = require("store").default.getState();
      const activity = globalState.activity.active_activity;
      const tags = globalState.activity.active_activity.tag.filter(
        data => data !== tag
      );
      const data = { ...activity, tag: [...tags] };

      yield put({
        type: "UPDATE_ACTIVITY",
        activity: data
      });
    } catch (err) {
      errorHandler(err);
    }
  },
  REQUEST_CREATE_PACK_TAG: function*({ tag }) {
    try {
      const globalState = require("store").default.getState();
      let tags = globalState.activity.active_activity_pack.tag;
      if (!tags.some(data => data.toLowerCase() === tag.toLowerCase())) {
        const data = {
          ...globalState.activity.active_activity_pack,
          tag: [...tags, tag]
        };

        yield put({
          type: "UPDATE_ACTIVITY_PACK",
          data: data
        });
      }
    } catch (err) {
      errorHandler(err);
    }
  },
  DELETE_PACK_TAG: function*({ tag }) {
    try {
      const globalState = require("store").default.getState();
      const activity = globalState.activity.active_activity_pack;
      const tags = globalState.activity.active_activity_pack.tag.filter(
        data => data !== tag
      );
      const data = { ...activity, tag: [...tags] };

      yield put({
        type: "UPDATE_ACTIVITY_PACK",
        data: data
      });
    } catch (err) {
      errorHandler(err);
    }
  },

  //..........................publish experience...................
  REQUEST_PUBLISH_ACTIVITY_EXPERIENCE: function*({ experience }) {
    try {
      let data = { is_published: !experience.is_published };

      const res = yield call(
        ActivityApi.updateActivityExperience,
        experience.id,
        data
      );
      if (experience.show)
        yield put({
          type: "SET_ACTIVE_EXPERIENCE",
          experience: res
        });
      const globalState = require("store").default.getState();
      const activity_id = globalState.activity.active_activity.id;

      yield put({
        type: "REQUEST_ACTIVITY_BY_ID",
        activityId: activity_id
      });
    } catch (err) {
      errorHandler(err);
    }
  },

  REQUEST_DELETE_ACTIVITY_EXPERIENCE: function*({ experienceId }) {
    try {
      yield call(ActivityApi.deleteActivityExperience, experienceId);
      const globalState = require("store").default.getState();
      const activity_id = globalState.activity.active_activity.id;

      yield put({
        type: "REQUEST_ACTIVITY_BY_ID",
        activityId: activity_id
      });
      yield put({
        type: "SET_ACTIVE_EXPERIENCE",
        experience: null
      });
    } catch (err) {
      errorHandler(err);
    }
  }
});

import { createSagas } from "redux-box";
import CaseStudyApi from "@s/api/caseStudy";
import { call, put } from "redux-saga/effects";
//import {push} from "react-router-redux";
import swal from "sweetalert2";

const errorHandler = err => {
    //console.log(err);
    swal("Oops!", err.error_message, "error");

};


export default createSagas({
    REQUEST_CASE_STUDIES: function*() {
        try {
            yield put({
                type: "SET_FETCH_CASE_STUDIES_LIST",
                value:true
            });
          const res = yield call(CaseStudyApi.getAllCaseStudies);
          yield put({
            type: "SET_CASE_STUDIES",
            caseStudies: res
          });
        } catch (err) {
            errorHandler(err);
        }
        finally {
            yield put({
                type: "SET_FETCH_CASE_STUDIES_LIST",
                value:false
            });
        }
      },

    CREATE_CASE_STUDY:function*({ caseStudy }) {
        try {
            yield call(CaseStudyApi.createCaseStudy, caseStudy);
            yield put({
                type: "REQUEST_CASE_STUDIES"
            });

        } catch (err) {
            errorHandler(err);
        }
    },
   GET_CASE_STUDY_BY_ID:function*({ caseStudyId }) {
       try {
           yield put({
               type: "FETCH_CASE_STUDY",
               value: true
           });
           const res = yield call(CaseStudyApi.getCaseStudyById, caseStudyId);

           yield put({
               type: "SET_ACTIVE_CASE_STUDY",
               caseStudy: res
           });
       } catch (err) {
           errorHandler(err);
       } finally {
           yield put({
               type: "FETCH_CASE_STUDY",
               value: false
           });
       }
   },

    UPDATE_CASE_STUDY:function*({ data }) {
        try {
            const globalState = require("store").default.getState();
            let case_study_id;
            case_study_id = globalState.caseStudy.active_case_study.id;
            yield call(
                CaseStudyApi.updateCaseStudy,
                case_study_id,
                data
            );
            yield put({
                type: "ENABLE_CASE_STUDY_EDIT",
                value:false
            });
            yield put({
                type: "GET_CASE_STUDY_BY_ID",
                caseStudyId:case_study_id
,            });

            yield put({
                type: "REQUEST_CASE_STUDIES"
            });
        } catch (err) {
            errorHandler(err);
        }
    },

    DELETE_CASE_STUDY:function*({ caseStudyId }) {
        try {

            yield call(CaseStudyApi.deleteCaseStudy, caseStudyId);
            swal('Done!', 'Deleted case study successfully!', 'success');
            yield put({
                type: "REQUEST_CASE_STUDIES"
            });

        } catch (err) {
            errorHandler(err);
        }
    },
     
    

})
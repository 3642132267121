import React, { Component } from 'react';
import { AudioList } from "static/styled/custom.styled";


export default class AudioSection extends Component {

    static defaultProps = {
        media: []
    }

    render() {
        return (
            <AudioList>
                {
                    this.props.media.map((audio, i) => (
                        <audio controls key={i} style={{margin:'10px'}}>
                             <source src={audio.media_url} type="audio/ogg" />
                             <source src={audio.media_url} type="audio/mp4" />
                            <source src={audio.media_url} type="audio/mpeg" />
                        </audio>
                    ))
                }


            </AudioList>
        )

    }
}

import { createActions, using } from "redux-box";

export default createActions({
  //...........Collections CRUD..........
  setActiveCollection: using("collection"),
  requestCollections: using(),
  requestCreateCollection: using("collection"),
  requestCollectionById: using("collectionId"),
  requestUpdateCollection: using("collection"),
  requestDeleteCollection: using("collectionId"),
  requestCollectionsListConcat:using('offset'),

  //..........Story CRUD..........
  requestCreateStory: using("story"),
  requestStories: using(),
  requestStoriesListConcat: using("offset"),
  requestDeleteStory: using("story"),
  requestUpdateStory: using("story"),
  requestStoryById: using("storyId"),
  requestAddStoryCollection: using("collectionId"),
  requestRemoveStoryCollection: using("collectionId"),
  setActiveStory: using("story"),
  unsetActiveStory: using(),
  setStoryType: using("storyType"),
  setActiveStoryGradeLevel: using("gradeLevel"),
  setActiveStoryLevel: using("level"),
  setActiveStoryCollection: using("collections"),
  setStoryCollectionList: using("storyCollections"),
  setActiveFilter: using("value"),
  unsetActiveFilter: using("value"),

  //............questions CRUD..........
  requestStoryQuestionsById: using("storyId"),
  requestCreateStoryQuestion: using("question"),
  requestUpdateStoryQuestion: using("question"),
  requestDeleteStoryQuestion: using("question"),
  // updateStoryNewQuestion: using("question"),
  // createStoryQuestion: using("storyId", "question"),
  // StoryNewQuestion: using("question"),
  setActiveStoryQuestion: using("question"),
  unsetActiveStoryQuestion: using(),

  //..............options CRUD.........
  addNewStoryOption: using("option"),
  removeStoryNewOption: using("optionId"),

  //..........Story Sections CRUD..........
  addNewStorySection: using(),
  setActiveStorySection: using("section"),
  removeStorySection: using(),
  startCreatingStorySection: using("sectionType, data"),
  startUpdatingStorySection: using("sectionType, data"),
  removeNewStorySection: using("section"),
  updateNewStorySection: using("section"),
  resetNewStorySections: using(),
  requestCreateStorySection: using("section"),
  requestDeleteStorySection: using("sectionId"),
  requestUpdateStorySection: using("section"),
  deleteStorySection: using("section"),

  //............MISC..............
  setStoryEditorType: using("editorType"),
  requestSetStoryFileType: using("fileType"),
  requestSetStoryType: using("storyType"),
  setStoryActionType: using("action"),
  setStoryMainType: using("storyMainType"),
  fetchLoadSetTopStory: using("val"),
  setFetchMediaLoading: using("loading"),
  setFetchImagesLoading: using("loading"),
  setSearchStory: using("search"),
  setSelectedCollections: using("collection"),
  setSelectedSources: using("source"),

  //...........EXPERIENCES
  setActiveStoryExperience: using("experience"),
  setStoryExperiencesList: using("result"),
  requestDeleteStoryExperience: using("expId"),
  requestPublishStoryExperience: using("experience"),
  setFetchActiveStoryExperience: using("value"),
  setFetchStoryExperinceList: using("value"),
  setFilterLevelSearch: using("data"),
  setGradeLevelSort: using("value"),
  setActiveStoriesDate: using("date"),
  setActiveStoryType: using("value"),
});

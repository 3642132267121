const getData = res => {
  let result = res.map(el => {
    el.value = el.name;
    el.label = el.name;
    return el;
  });
  return result;
};
export default {
  SET_GRADES: (state, { grades }) => {
    state.grades = grades;
    state.gradeList = getData(grades);
  },
  SET_ACTIVITIES: (state, { activities }) => (state.activities = activities),
  SET_ACTIVE_ACTIVITY: (state, { activity }) => {
    state.active_activity = activity;
  },
  SET_CONCAT_ACTIVITIES: (state, { activities }) =>
    (state.activities = state.activities.concat(activities)),
  SET_SECTIONS: (state, { sections }) => (state.sections = sections),
  SET_ACTIVITY_PACKS: (state, { activityPacks }) =>
    (state.activityPacks = activityPacks),
  FETCH_ACTIVITY: (state, { value }) => (state.fetchingActivity = value),
  // ADD_NEW_SECTION: (state, action) => {
  //   state.new_section = {
  //     name :"",
  //     content_type:"",
  //     description:"",
  //     media:[]
  //   }
  // },
  REMOVE_SECTION: (state, action) => (state.new_section = null),
  SET_ACTIVE_SECTION: (state, { section }) => (state.active_section = section),
  UPDATE_ACTIVE_SECTION: (state, { section }) =>
    (state.active_section = { ...state.active_section, ...section }),

  CLOSE_EDITOR: (state, action) => (state.current_editor_type = null),
  START_CREATING_SECTION: (state, { sectionType, data }) => {
    state.current_editor_type = sectionType;
    state.current_section_action = "create";
    state.active_section = data;
  },
  START_UPDATING_SECTION: (state, { sectionType, data }) => {
    state.current_editor_type = sectionType;
    state.current_section_action = "update";
    state.active_section = data;
  },

  ADD_NEW_SECTION: (state, { section }) =>
    state.new_sections.push({ __id: Math.random(), ...section }),
  REMOVE_NEW_SECTION: (state, { section }) => {
    state.new_sections = state.new_sections.filter(
      sec => sec.__id !== section.__id
    );
  },
  SET_ACTIVITY_SEARCH: (state, { search }) => (state.search_text = search),
  SET_ACTIVE_GRADE: (state, { grade }) => (state.active_grade = grade),
  SET_ACTIVE_PACK_GRADE: (state, { grade }) =>
    (state.active_pack_grade = grade),
  RESET_NEW_SECTIONS: (state, { action }) => (state.new_sections = []),
  UPDATE_NEW_SECTION: (state, { section }) => {
    state.new_sections = state.new_sections.map(sec => {
      if (sec.__id === section.__id) return section;
      return sec;
    });
  },
  SET_ACTIVE_ACTIVITY_PACK: (state, { activityPack }) => {
    state.active_activity_pack = activityPack;
  },
  SET_QUESTION: (state, { question }) => (state.question_set = question),
  NEW_QUESTION: (state, { question }) => (state.new_question = question),
  FETCH_ACTIVITY_PACK: (state, { value }) =>
    (state.fetchingActivityPack = value),
  SET_ALL_QUESTION: (state, { question }) => (state.questions = question),
  SET_ACTIVE_MCQ: (state, { question }) => (state.active_mcq = question),
  UPDATE_NEW_QUESTION: (state, { question }) => (state.new_question = question),
  SET_EDITOR_TYPE: (state, { editorType }) =>
    (state.current_editor_type = editorType),
  ADD_NEW_OPTION: (state, { option }) =>
    state.new_options.push({ name: option, __id: Math.random() }),
  REMOVE_NEW_OPTION: (state, { optionId }) => {
    state.new_options = state.new_options.filter(
      option => option.__id !== optionId
    );
  },
  UNSET_ACTIVE_ACTIVITY: state => {
    state.active_activity = null;
  },
  UNSET_ACTIVE_ACTIVITY_PACK: state => {
    state.active_activity_pack = null;
  },
  SET_CONCAT_ACTIVITY_PACKS_LIST: (state, { activityPacks }) =>
    (state.activityPacks = state.activityPacks.concat(activityPacks)),
  UNSET_SHOW_ACTIVITY_PACK: state => (state.show_activity_pack = false),
  SET_SEARCH_PACK_TEXT: (state, { searchText }) =>
    (state.search_pack_text = searchText),
  ENABLE_REORDER: (state, { value }) => {
    state.enableOrder = value;
  },
  UNSET_ACTIVE_QUESTION: state => {
    state.questions = [];
    state.active_mcq = [];
  },
  SET_ORDER_NO: (state, { order_no }) => (state.order_no = order_no),
  SET_ACTIVE_EXPERIENCE: (state, { experience }) =>
    (state.active_experience = experience),
  SET_ACTIVE_GRADE_LEVEL: (state, { activeGradeLevel }) =>
    (state.active_grade_level = activeGradeLevel),
  SET_ACTIVE_LEVEL: (state, { activeLevel }) =>
    (state.active_level = activeLevel),
  SET_FETCH_ACTIVITY_BY_GRADE: (state, { val }) =>
    (state.fetch_activity_by_grade = val),
  SET_FETCH_ACTIVITY_PACKS_BY_LEVEL: (state, { value }) =>
    (state.fetch_activity_packs_by_level = value),
  SET_ACTIVITIES_RESULT: (state, { result }) =>
    (state.activities_result = result),
  SET_ACTIVITY_PACKS_RESULT: (state, { result }) =>
    (state.activity_packs_result = result),
  SET_ACTIVITY_PACKS_SEARCH: (state, { packSearch }) =>
    (state.search_pack_text = packSearch)
};

import React, { Component } from "react";
import { connectStore } from "redux-box";
import { module as inviteCode } from "store/inviteCode";
import { module as activity } from "store/activity";
import {
  generateRenewalOldSchoolFile,
  validateRenewalCsvFile,
} from "@s/api/file";
import swal from "sweetalert2";
import {
  InputLabel,
  DateSelector,
  DateSelectorIcon,
  UploadButton,
} from "./RenewalOldSchool.styled";
import DatePicker from "react-datepicker";
import moment from "moment";


class RenewalOldSchool extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedSchool: null,
      selectedClass: null,
      selectedFreadomGrade: null,
      selectedDate: null,
      selectedDateFormat:null,
      fileValidated: false,
      fileData: "",
      rejectedList: [],
      generatedInviteCodes: false,
    };
  }

  componentDidMount() {
    let { inviteCode } = this.props;
    inviteCode.requestSchoolList();
    // console.log(inviteCode, 'schoolList');
  }

  reInitializeState = (stateNames) => {
    stateNames.forEach((s) => {
      this.setState({ [s]: null });
    });
  };

  clearAllInput = () => {
    this.reInitializeState([
      "selectedSchool",
      "selectedDate",
      "selectedDateFormat",
      "selectedClass",
      "selectedFreadomGrade",
    ]);
    this.setState({
      fileValidated: false,
      fileData: "",
      rejectedList: [],
      generatedInviteCodes: false,
    });
  };

  handleSchoolChange = (e) => {
    this.reInitializeState([
      "selectedSchool",
      "selectedDate",
      "selectedClass",
      "selectedDateFormat",
      "selectedFreadomGrade",
    ]);
    this.setState({
      fileValidated: false,
      fileData: "",
      generatedInviteCodes: false,
    });

    let { inviteCode } = this.props;
    const schoolId = e.target.value;
    this.setState({
      selectedSchool:
        inviteCode.schoolList &&
        inviteCode.schoolList.find((s) => s.id === schoolId),
    });
    inviteCode.requestClassList(schoolId);
  };
  handleClassChange = (e) => {
    this.reInitializeState([
      "selectedClass",
      "selectedFreadomGrade",
      "selectedDate",
      "selectedDateFormat"
    ]);
    this.setState({
      fileValidated: false,
      fileData: "",
      generatedInviteCodes: false,
    });

    let { inviteCode } = this.props;
    const classId = e.target.value;
    const selectedClass =
      inviteCode.classList &&
      inviteCode.classList.find((c) => c.id === classId);

    this.setState({
      selectedClass,
      selectedFreadomGrade: null,
    });
    inviteCode.requestSectionList({
      schoolId: selectedClass.school.id,
      classId: classId,
    });
  };
  handleFreadomGradeChange = (e) => {
    this.reInitializeState(["selectedDate","selectedDateFormat", "selectedFreadomGrade"]);
    this.setState({
      fileValidated: false,
      fileData: "",
      generatedInviteCodes: false,
    });
    const freadomGrade = e.target.value;
    const gradeList = this.props.activity.gradeList;
    const selectedFreadomGrade =
      gradeList && gradeList.find((grade) => grade.id === freadomGrade);
    this.setState({ selectedFreadomGrade });
  };

  handleUploadClick = () => {
    this.inputElement.click();
  };

  handleClearBtn = () => {
    this.reInitializeState(["selectedDate","selectedDateFormat"]);
    this.setState({
      fileValidated: false,
      fileData: "",
      generatedInviteCodes: false,
    });
  };

  onDatepickerRef(el) {
    if (el && el.input) {
      el.input.readOnly = true;
    }
  }

  handleDateChange = (date) => {
    this.setState({
      fileValidated: false,
      fileData: "",
      generatedInviteCodes: false,
    });
    this.setState({
      selectedDate: date,
      selectedDateFormat:moment(date).format("YYYY-MM-DD"),
    });
  };

  handleFileChange = (file) => {
    let fileData = file.files[0];
    const { selectedSchool, selectedClass } = this.state;
    const curr = new Date();
    var diff = new Date(this.state.selectedDate).getTime() - curr.getTime();

    swal
      .fire({
        title: "Are you sure?",
        text:
          "Child will be registered with " +
          Math.ceil(diff / (1000 * 3600 * 24)) +
          " days.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, create it!",
      })
      .then((result) => {
        if (result.value) {
          validateRenewalCsvFile(fileData, selectedSchool.id, selectedClass.id)
            .then((res) => {
              this.setState({
                err: res,
              });
              if (res.success) {
                this.setState({
                  fileValidated: true,
                  fileData: file.files[0],
                  rejectedList: [],
                });

                fileData = "";
                swal("Done", "Verified successfully", "success");
              } else {
                if (res.length > 0) {
                  let rejectedList = res.map((c) => {
                    let obj = {};
                    obj.childName = c[0];
                    obj.contactNo = c[1];
                    obj.reason = c[2];
                    return obj;
                  });
                  this.setState({ rejectedList });
                }
                this.setState({ fileValidated: false, fileData: "" });
                // unValidatedData: some_array
                fileData = "";
                file.value = "";
                swal.fire({
                  icon: "error",
                  title: "Not Validated!",
                  html: res.error.error_message,
                });
              }
            })
            .catch((err) => {
              this.setState({ fileValidated: false, fileData: "" });
              file.value = "";
              // swal.fire({
              //   icon: "error",
              //   title: "Not Validated!",
              //   html: "<b> Please check the CSV Headers. </b>",
              // });
            });
        }
      });
  };
  generateBulkInviteCode = () => {
    const fileData = this.state.fileData;
    const reqBody = {
      grade: this.state.selectedFreadomGrade.id,
      wow_school: this.state.selectedSchool.id,
      wow_class: this.state.selectedClass.id,
      expiry_date: this.state.selectedDateFormat,
    };

    generateRenewalOldSchoolFile(fileData, reqBody).then((res) => {
      if (res.success) {
        swal("Done", "Uploaded Successfully", "success");
        this.setState({ generatedInviteCodes: true });
      } else {
        swal("Oops!", res.error.error_message);
      }
    });
  };
  render() {
    let { inviteCode } = this.props;
    let {
      selectedSchool,
      selectedClass,
      selectedFreadomGrade,
      selectedDate,
      rejectedList,
      fileValidated,
    } = this.state;
    let schoolListOptions =
      inviteCode.schoolList.length &&
      inviteCode.schoolList.map((school, i) => (
        <option value={school.id} key={school.id}>
          {school.name}
        </option>
      ));
    let classListOptions =
      inviteCode.classList.length &&
      inviteCode.classList.map((classObj, i) => (
        <option value={classObj.id} key={classObj.id}>
          {classObj.name}
        </option>
      ));
    let freadomGradeListOptions =
      this.props.activity.gradeList.length &&
      this.props.activity.gradeList.map((grade, i) => (
        <option value={grade.id} key={grade.id}>
          {grade.name}
        </option>
      ));

    return (
      <div className="p-4">
        <div className="alert alert-warning" role="alert">
          <h5>Bulk Generate Invite Codes</h5>
        </div>
        <div className="row">
          <div className="col-sm-4">
            <div className="card" style={{ height: "420px" }}>
              <div className="card-body">
                <div className="mb-4 text-right">
                  <button
                    className="btn btn-warning btn-sm"
                    onClick={this.clearAllInput}
                  >
                    Clear all
                    <i className="fa fa-times ml-2" />
                  </button>
                </div>
                <div className="input-group mb-3">
                  <div className="input-group-prepend">
                    <InputLabel
                      className="input-group-text"
                      htmlFor="inputGroupSelect01"
                    >
                      School
                    </InputLabel>
                  </div>
                  <select
                    className="custom-select"
                    id="inputGroupSelect01"
                    onChange={(e) => this.handleSchoolChange(e)}
                  >
                    <option defaultValue>Choose...</option>
                    {schoolListOptions}
                  </select>
                </div>

                {selectedSchool !== null && (
                  <div>
                    <div className="input-group mb-3">
                      <div className="input-group-prepend">
                        <InputLabel
                          className="input-group-text"
                          htmlFor="inputGroupSelect02"
                        >
                          Class
                        </InputLabel>
                      </div>
                      <select
                        className="custom-select"
                        id="inputGroupSelect02"
                        onChange={(e) => this.handleClassChange(e)}
                      >
                        <option defaultValue>Choose...</option>
                        {classListOptions}
                      </select>
                    </div>
                    {selectedClass !== null && (
                      <div>
                        <div className="input-group mb-3">
                          <div className="input-group-prepend">
                            <InputLabel
                              className="input-group-text"
                              htmlFor="inputGroupSelect04"
                            >
                              Freadom Grade
                            </InputLabel>
                          </div>
                          <select
                            className="custom-select"
                            id="inputGroupSelect04"
                            onChange={(e) => this.handleFreadomGradeChange(e)}
                          >
                            <option defaultValue>Choose...</option>
                            {freadomGradeListOptions}
                          </select>
                        </div>

                        <div>
                          <DateSelector style={{ overflow: "hidden" }}>
                            <DateSelectorIcon className="fa fa-calendar" />
                            <DatePicker
                              selected={this.state.selectedDate}
                              selectsStart
                              startDate={this.state.selectedDate}
                              onChange={this.handleDateChange}
                              minDate={moment().toDate()}
                              placeholderText="Select Expiry date"
                              ref={(el) => this.onDatepickerRef(el)}
                            />
                            {this.state.date ? (
                              <i
                                className="fa fa-times"
                                onClick={() => this.handleClearBtn()}
                                style={{ padding: "8px", cursor: "pointer" }}
                              />
                            ) : null}
                          </DateSelector>
                        </div>

                        {selectedDate != null && selectedFreadomGrade != null && (
                          <div className="mb-4">
                            <input
                              style={{ display: "none" }}
                              ref={(input) => (this.inputElement = input)}
                              type="file"
                              accept=".csv"
                              onChange={(e) => this.handleFileChange(e.target)}
                            />
                            <UploadButton
                              className="btn btn-simple mt-4"
                              primary
                              onClick={this.handleUploadClick}
                              disabled={fileValidated}
                            >
                              Upload And Validate
                            </UploadButton>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="col-sm-8">
            <div className="card">
              <div className="card-body">
                Generating Invite Codes
                <h5>
                  <b>School:</b> {selectedSchool != null && selectedSchool.name}
                </h5>
                <h5>
                  <b>Class:</b> {selectedClass != null && selectedClass.name}
                </h5>
                <h5>
                  <b>Grade:</b>
                  {selectedFreadomGrade != null && selectedFreadomGrade.name}
                </h5>
                {rejectedList.length > 0 && (
                  <div className="alert alert-danger" role="alert">
                    <table className="table table-bordered">
                      <thead className="thead-dark">
                        <tr>
                          <th scope="col">#</th>
                          <th scope="col">Error Message</th>
                          {/* <th scope="col">Contact No.</th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {rejectedList.map((k, index) => {
                          return (
                            <tr>
                              <th scope="row">{index + 1}</th>
                              <td>{this.state.err[index]}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                    <p>
                      <b> Please fix these changes and upload again.</b>
                    </p>
                  </div>
                )}
                <div>
                  {this.state.fileValidated &&
                    !this.state.generatedInviteCodes && (
                      <UploadButton
                        className="btn btn-warning"
                        onClick={this.generateBulkInviteCode}
                      >
                        Renew Old Schools
                      </UploadButton>
                    )}
                </div>
                {this.state.generatedInviteCodes && (
                  <div className="text-center">
                    <a
                      className="btn btn-outline-primary"
                      href={
                        "https://internals.freadom.in/#/school-report/" +
                        this.state.selectedSchool.id
                      }
                      target="_blank"
                    >
                      Check Adopted Users List
                    </a>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connectStore({
  inviteCode,
  activity,
})(RenewalOldSchool)
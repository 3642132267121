import React, { Component } from "react";
// import {
//   SearchBarContainer,
//   ToolbarContainer
// } from "@c/users-view/MainContent.styled";
import {
  NavigationContainer,
  NavigationHeaderContainer,
  NavigationToolbar
} from "@sc/NavigationBar.styled";
// import { Link } from "react-router-dom";
import { connectStore } from "redux-box";
import { module as tags } from "store/tags";


 class ActivityViewHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchBarEnable: false,
      navigationSelector: false
    };
  }

  componentDidMount() {
    let currentPath = window.location.pathname;
    if (currentPath.includes("/tags/")) {
      this.setState({
        navigationSelector: true
      });
    } else {
      this.setState({
        navigationSelector: false
      });
    }
  }

  handleActivitiesSearch = e => {
    // let { tags } = this.props;
    // if (tag.enableOrder) activity.enableReorder(false);
    // activity.setActivitySearch(e.target.value);
    // setTimeout(() => {
    //   if (this.props.tags.active_grade !== null)
    //     this.props.activity.requestActivitiesByGrade(activity.active_grade);
    // }, 1500);
  };

  render() {
    // let currentPath = window.location.pathname;
    return (
      <NavigationHeaderContainer>
        <NavigationContainer>Skills</NavigationContainer>
        <NavigationToolbar>
          {/* <SearchBarContainer
            width={this.state.searchBarEnable ? "300px" : "200px"}
          >
            <i className="fa fa-search" />
            {currentPath.includes("/tags/") && (
              <input
                type="text"
                onChange={this.handleActivityPacksSearch}
                placeholder="Search"
                value={this.props.tags}
                onFocus={() => this.enableSearchBar("focus")}
                onBlur={() => this.enableSearchBar("blur")}
              />
            )}
          </SearchBarContainer> */}
        </NavigationToolbar>
      </NavigationHeaderContainer>
    );
  }
}
export default connectStore({
  tags
})(ActivityViewHeader)
import styled from "styled-components";
import {
    DropDown,
    DropDownMenu
} from "@sc/custom.styled";


export const Container=styled.div`

`;

export const FreadomPointsInputField=styled.div`
    margin-left: 0.5rem
`;

export const RCHeaderDropDown= styled(DropDown)`
display: block;
width: 70%;
padding: .375rem .75rem;
font-size: 1rem;
line-height: 1.5;
color: #495057;
background-color: #fff;
background-clip: padding-box;
border: 1px solid #ced4da;
border-radius: .25rem;
border-top-left-radius: 0;
border-bottom-left-radius: 0;
transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
`;

export const RCHeaderDropDownMenu= styled(DropDownMenu)`
border-radius: 0;
`;

export const CreateRCButton= styled.button`
color: white;
width: 100%;
background: #ea872b;
`;

export const RCNameInput= styled.input`
margin: 0.5rem 0; 
`;



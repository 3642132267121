import React, { Component } from "react";
import CreateQuestionEditor from "./create-question-editor/CreateQuestionEditor";
import CreateAssessment from "./create-assessment/CreateAssessment";
import { connectStore } from "redux-box";
import { module as activity } from "@st/activity";
import { module as ui } from "@st/ui";
import { module as assessment } from "@st/assessment";
import {
  ComponentContainer,
  SubContainer,
  CreatorComponent
} from "@sc/Creator.styled";

// export default connectStore({
//   activity,
//   assessment,
//   ui
// })
class CreateAssessmentContainer extends Component {
  constructor(props) {
    super();
    this.state = {
      opacity: 1,
      translate: "100%"
    };
  }
  componentWillMount() {
    const assessment = this.props.assessment;
    assessment.requestGetLevelTestById(this.props.match.params.id);
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({
        opacity: 1,
        translate: 0
      });
    }, 30);
    let history = this.props.history;
    document.addEventListener("keyup", function(e) {
      if (
        e.keyCode === 27 &&
        window.location.pathname.includes("/assessments")
      ) {
        history.push("/assessments");
      }
    });
  }

  componentWillUnmount() {
    this.props.activity.closeEditor();
    this.props.assessment.unsetAllActiveAssessmentQuestion();
  }

  render() {
    const { activity } = this.props;
    return (
      <CreatorComponent
        key={this.props.location.key}
        style={{ opacity: this.state.opacity }}
      >
        <ComponentContainer
          style={{ transform: `translateX(${this.state.translate})` }}
        >
          <CreateAssessment />
        </ComponentContainer>
        {activity.current_editor_type && (
          <SubContainer active={activity.current_editor_type}>
            {activity.current_editor_type === "createQA" && (
              <CreateQuestionEditor />
            )}
          </SubContainer>
        )}
      </CreatorComponent>
    );
  }
}

// export default CreateAssessmentContainer;
export default connectStore({
  activity,
 ui,
 assessment
 })(CreateAssessmentContainer)
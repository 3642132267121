import React, { Component } from "react";
import { Container } from "./NotificationTextEditor.styled";
import { PanelHeader, ThemeButton } from "static/styled/custom.styled";
import { module as notification } from "@st/notification";
import { connectStore } from "redux-box";
import { uploadFile } from "@s/api/file";
import {
  EditorState,
  convertToRaw,
  convertFromHTML,
  ContentState
} from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import { stateToHTML } from "draft-js-export-html";
import swal from "sweetalert2";

class NotificationTextEditor extends Component {
  constructor() {
    super();
    this.state = {
      editorState: EditorState.createEmpty(),
      editEnabled: false
    };
  }

  componentDidMount() {
    let { notification } = this.props;
    let description = notification.email_description;

    if (description.length > 0) {
      const blocksFromHTML = convertFromHTML(description);
      if (blocksFromHTML !== null && blocksFromHTML.contentBlocks !== null) {
        const state = ContentState.createFromBlockArray(
          blocksFromHTML.contentBlocks,
          blocksFromHTML.entityMap
        );
        this.setState({
          editorState: EditorState.createWithContent(state)
        });
      }
    }
  }

  componentWillUnmount() {
    let { notification } = this.props;
    notification.setNotificationEditorType("");
  }

  onEditorStateChange = editorState => {
    this.setState({
      editorState: editorState,
      editEnabled: true
    });
  };

  uploadImageCallBack = file => {
    return new Promise((resolve, reject) => {
      uploadFile(file)
        .then(res => {
          resolve({ data: { link: res.file } });
        })
        .catch(err => {
          reject(err);
        });
    });
  };
  setEmailData = () => {
    let { notification } = this.props;
    let editorBlock = convertToRaw(this.state.editorState.getCurrentContent());
    if (
      (editorBlock.blocks &&
        editorBlock.blocks.some(block => {
          return block.text !== "";
        })) ||
      editorBlock.entityMap.length > 0
    ) {
      let description = stateToHTML(this.state.editorState.getCurrentContent());
      notification.setNotificationDescription(description);
      this.props.notification.setNotificationEditorType("");
    } else {
      swal("Oops!", "Please add text to the HTML Editor", "warning");
      this.setState({
        editEnabled: false
      });
    }
  };

  handleDoneBtn = () => {
    this.setEmailData();
  };

  render() {
    const EditorImage = (
      <Editor
        wrapperClassName="demo-wrapper"
        editorClassName="demo-editor"
        editorState={this.state.editorState}
        onEditorStateChange={this.onEditorStateChange}
        toolbar={{
          options: [
            "inline",
            "blockType",
            "fontSize",
            "fontFamily",
            "list",
            "textAlign",
            "image"
          ],
          inline: { inDropdown: true },
          list: { inDropdown: true },
          textAlign: { inDropdown: true },
          image: {
            uploadCallback: this.uploadImageCallBack,
            popupClassName: "image-popup",
            alt: { present: false },
            defaultSize: {
              height: "auto",
              width: "100%"
            },
            previewImage: true
          }
        }}
      />
    );
    return (
      <Container>
        <PanelHeader
          primary
          style={{ borderRadius: "10px 10px 0 0", zIndex: "20" }}
        >
          <span> Email Template Editor</span>
          {this.state.editEnabled ? (
            <ThemeButton primary onClick={this.handleDoneBtn}>
              Done
            </ThemeButton>
          ) : (
            <ThemeButton
              primary
              onClick={() =>
                this.props.notification.setNotificationEditorType("")
              }
            >
              Close
            </ThemeButton>
          )}
        </PanelHeader>
        <div style={{ padding: "65px 5px 20px 5px", overflow: "auto" }}>
          {EditorImage}
        </div>
      </Container>
    );
  }
}

export default connectStore({
  notification
})(NotificationTextEditor)
import { Api } from "./base";

const _headers = () => {
  return {
    Authorization:
      "JWT " + require("store").default.getState().onboard.user.auth_token,
    "Content-Type": "application/json",
  };
};

export default {
  //..................................UNIT CRUD.................

  requestProductList() {
    return Api.get(`/guided-path/v1/admin-guided-path/`, _headers());
  },

  updateProduct(id, data) {
    return Api.put(
      `/guided-path/v1/admin-guided-path/${id}/`,
      data,
      _headers()
    );
  },

  createProduct(data) {
    return Api.post(`/guided-path/v1/admin-guided-path/`, data, _headers());
  },
};

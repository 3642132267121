import React, {Component} from 'react';
import {
    CardContainer, StyledDiv,
    StyledDivImage,Title
} from "@c/activity-view/activities-list/ActivityCard/ActivityCard.styled";
import {CountOverlayContainer} from "static/styled/custom.styled"
import {FreadCountItem} from "./NewsFreedsCard.styled"


export default class NewsFreedsCard extends Component
{
    render(){
        let {newsInfo}=this.props;
        return(
            <CardContainer>

                <StyledDiv secondary>
                    <Title>{newsInfo.name}</Title>
                    <Title primary>{this.props.newsInfo.description.length<36 ?
                        newsInfo.description: newsInfo.description.slice(0,40) }</Title>
                </StyledDiv>
                <StyledDivImage primary  image={newsInfo.image} alt="read">
                <CountOverlayContainer style={{width:'56px'}}>
                        <FreadCountItem style={{justifyContent:'flex-start'}}>
                            <i className="fa fa-sticky-note-o"/>
                            <span> {newsInfo.reading_count}</span>
                        </FreadCountItem>
                        <FreadCountItem style={{justifyContent:'flex-start'}}>
                            <i className="fa fa-bullseye"/>
                            <span>{newsInfo.completed_count} </span>
                        </FreadCountItem>
                        <FreadCountItem style={{justifyContent:'flex-start'}}>
                            <i className="fa fa-tachometer"/>
                            {/*<img src={require("static/img/page-check.png")} alt="readcount"/>*/}
                            <span>{newsInfo.accuracy>=100?'100':newsInfo.accuracy} </span>
                        </FreadCountItem>
                    </CountOverlayContainer>
                </StyledDivImage>
            </CardContainer>
        )
    }
}

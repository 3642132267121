import styled from "styled-components";
import { ActivityDescriptionInfoContainer } from "@sc/ActivityModal.styled";
import { FlexBetween, ThemeButton } from "@sc/custom.styled";
export const ActivityPackDescriptionInfoContainer = ActivityDescriptionInfoContainer.extend`
  width: 100%;

  z-index: 100;
  position:absolute;
  top:0;
  left:0;
 
  padding-bottom:0px;
  max-height:none;
  background-color: white;
`;

export const ActivityUploadImageContainer = styled.div`
  width: ${props=>props.width?props.width:'150px'};
  margin-left:${props=>props.marginLeft?props.marginLeft:'0px'};
  height: 150px;
  border-radius: 10px;
  border: dashed 1px rgba(151, 151, 151, 0.44);
  background-color: #ffffff;
  cursor: pointer;
  outline: none;
  display: flex;
  justify-content: space-around;
  position: relative;
`;
export const GradeDescriptionLabel = FlexBetween.extend`
  font-size: var(--sm-font-size);
  width: ${props => (props.primary ? "30%" : "60%")}
  align-items: center;
  display:flex;
  flex-flow:row wrap;
  justify-content: ${props => (props.primary ? "flex-end" : "flex-start")};
  border: none;
  img {
    width: 18px;
    height: 18px;
    cursor:pointer;
    transition:all 0.3s;
    text-align: center;
    padding:2px;
    margin-left: 12px;
  }
`;
export const ActivitySectionLabelsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  align-items: center;
`;

export const ActivityDropDownListContainer = styled.div`
  display: flex;
  padding-top: 200px;

  flex-flow: column nowrap;
`;

export const ActivityDropDownListItem = styled.div``;

export const ActivityPackThemeButton = ThemeButton.extend`
  padding: 6px 18px;
  margin-left: 6px;
`;

export const InputFieldsContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 75%;
`;
export const PackThemeButton=ThemeButton.extend`
    margin-top:3px;
    height:33px;`;

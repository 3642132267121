import React, { Component } from "react";
import ChildTagSelector from "./child-tag-selector";
import ParentTagSelector from "./parent-tag-selector";
import { FlexBox, ChildBox, ContentBox } from "./TagSelector.styled";
export default class TagSelector extends Component {
  render() {
    return (
      <ContentBox>
        <FlexBox>
          <ChildBox>
            <ParentTagSelector />
          </ChildBox>
          <ChildBox>
            <ChildTagSelector />
          </ChildBox>
        </FlexBox>
      </ContentBox>
    );
  }
}

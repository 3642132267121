import React, { Component } from "react";
import {
  AutoContainer,
  EmptyContainer,
  AlignContentHorizontal,
  LoadingContainer,
  InfiniteScrollParentContainer,
  InfiniteScrollLoader
} from "static/styled/custom.styled";
import ActivityPackCard from "./ActivityPackCard/ActivityPackCard";
import { connectStore } from "redux-box";
import { module as tags } from "@st/tags";
import { Link } from "react-router-dom";
// import {
//   NavigationHeaderContainer,
//   NavigationContainer
// } from "static/styled/NavigationBar.styled";
import InfiniteScroll from "react-infinite-scroller";
import { module as activity } from "@st/activity";


class ActivityPackList extends Component {
  constructor(props) {
    super();
    this.state={
      showloader:false
    }
  }
  componentDidMount() {
    this.props.tags.requestTagList();
  }

  componentWillReceiveProps(nextProps){
    console.log(nextProps)
    if(this.state.showloader != nextProps.tags.fetch_activity_packs_by_level){
      this.setState({showloader:!this.state.showloader})
    }
  }
  fetchMoreActivityPacks = () => {
    if (
      this.props.tags.tagResult &&
      this.props.tags.tagResult.has_next
    ) {
      setTimeout(() => {
        if (
          this.props.tags.tagResult !== null &&
          this.props.tags.tagResult.next_page > 0
        )
          this.props.tags.requestConcatTagList(
            this.props.tags.tagResult.next_page
          );
      }, 300);
    }
  };

  render() {
    const { tags } = this.props;
    let tagList = this.props.tags.tagList;
    return (
      <AutoContainer>
        {this.props.tags.fetch_activity_packs_by_level? 
          <LoadingContainer style={{ zIndex: "100" }}>
            <img
              alt="loading"
              width="60"
              height="60"
              src={require("static/img/Theme-loader.gif")}
            />
          </LoadingContainer>
        : this.props.tags.tagList &&
          this.props.tags.tagList.length > 0 ? 
          <InfiniteScrollParentContainer full>
            <InfiniteScroll
              pageStart={0}
              loadMore={this.fetchMoreActivityPacks}
              style={{ position: "relative" }}
              hasMore={
                this.props.tags.tagResult && this.props.tags.tagResult.has_next
              }
              loader={
                <InfiniteScrollLoader className="loader" key={0}>
                  <img
                    alt="loading"
                    width="60"
                    height="60"
                    src={require("static/img/Theme-loader.gif")}
                  />
                </InfiniteScrollLoader>
              }
              useWindow={false}
            >
              <AlignContentHorizontal marginBottom="70px">
                {tags.tagList && tagList.length > 0 ? (
                  tagList.map((tag, index) => {
                    return (
                      <div className="col-sm-6 col-md-4 col-lg-3" key={index}>
                        <Link
                          to={`/tags/edit/${tag.id}`}
                          onClick={() => this.props.tags.setActiveTag(tag)}
                        >
                          <ActivityPackCard tag={tag} />
                        </Link>
                      </div>
                    );
                  })
                ) : (
                  <EmptyContainer>
                    <h4>NO TAGS CREATED</h4>
                  </EmptyContainer>
                )}
              </AlignContentHorizontal>
            </InfiniteScroll>
          </InfiniteScrollParentContainer>:null
        }
      </AutoContainer>
    );
  }
}

export default connectStore({
  tags,activity
})(ActivityPackList);

import React, { Component } from "react";
import "video-react/dist/video-react.css";
import { VideoList} from "./VideoSection.styled";

import {Player, BigPlayButton, ControlBar, VolumeMenuButton, TimeDivider, CurrentTimeDisplay} from "video-react";
import "../../../../global.css";
export default class VideoSection extends Component {
  render() {
    return (
      <VideoList>

        {this.props.media.map((media, index) => (
          <Player
            key={index}
            style={{borderRadius:'10px',width:'100%'}}
            height={100}
            fluid={false}

            playsInline
            src={media.media_url}
          >
            <BigPlayButton position="center" />
            <ControlBar>
              <TimeDivider disabled />
              <CurrentTimeDisplay disabled />
              <VolumeMenuButton disabled />
            </ControlBar>
          </Player>
         
        ))}
      </VideoList>
    );
  }
}

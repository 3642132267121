import React, { Fragment } from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import ActivityPackCreator from "../components/tag-view/activity-pack-creator/ActivityPackCreator";
import { ModalBackDrop } from "static/styled/ModalBackDrop.styled";
export const Routes = function({ location }) {
  return (
    <Switch location={location}>
      <Route
        path={"/tags/edit/:id"}
        render={props => (
          <Fragment>
            <ActivityPackCreator {...props} />
            <ModalBackDrop active second />
          </Fragment>
        )}
      />
    </Switch>
  );
};

export default withRouter(Routes);

import React, { Component } from "react";
import FreadomFutureRouter from "@r/freadomFutureRouter";
import { FreadomFutureContainer } from "./FreadomFutureView.styled";
import FreadomFutureList from "./freadom-future-list/FreadomFutureList";
import { PlusButton, PlusIcon } from "@sc/custom.styled";
import { connectStore } from "redux-box";
import { module as ui } from "@st/ui";
import { module as activity } from "@st/activity";
import { module as freadomFuture } from "@st/freadomFuture";
import { module as onboard } from "@st/onboard";
import Permission from "@s/permission";
import swal from "sweetalert2";


class FreadomFutureView extends Component {
  componentWillMount() {
    this.props.freadomFuture.getLevelList();
    this.props.freadomFuture.setFetchFutureFreadomList(true);
    this.props.freadomFuture.requestFreadomFutureActiveGrade();
  }

  componentWillUnmount() {
    this.props.freadomFuture.setFreadomFutureList([]);
  }

  handleAddClickButton = e => {
    let loc = this.props.location;
    const { permission } = this.props.onboard;
    if (loc.pathname.includes("/freadom-future/edit")) {
      this.props.history.push("/freadom-future");
    } else {
      if(Permission.getPermission(permission, "add_reading")){
        this.props.ui.showModal("FREADOMFUTURE");
      }else{
        swal({
          text: "You do not have permission.",
          type: "warning"
        });
      }
      
    }
  };
  render() {
    const isCreatorOpen = this.props.location.pathname.includes(
      "/freadom-future/edit"
    );
    
    return (
      <FreadomFutureContainer primary>
        <FreadomFutureRouter />
        <FreadomFutureList />
        
          <PlusButton
            hide={this.props.ui.show_modal}
            onClick={this.handleAddClickButton}
          >
            <PlusIcon
              primary
              alt="Plus Icon"
              cross={isCreatorOpen}
              src={require("static/img/plusicon.png")}
            />
          </PlusButton>
      </FreadomFutureContainer>
    );
  }
}
export default connectStore({
  ui,
  activity,
  freadomFuture,
  onboard
})(FreadomFutureView)
import styled from "styled-components";

export const Container = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const InfoBox = styled.div`
  width: 400px;
  // flex: 400px 1;
  font-size: 14px;
  line-height: 2;
  flex-basis: 400px;
  background: #e7e7e7;
  border-radius: 10px;
  color: #000;
  padding: 10px;
  margin-top: 30px;
  i {
    color: #999;
    width: 40px;
  }
`;

export const Pill = styled.button`
  background: #fff;
  border: 1px solid #f5f5f5;
  border-radius: 100px;
  outline: 0;
  font-size: 12px;
  padding: 5px 20px;
  margin-right: 10px;
  &:focus {
    outline: 0;
  }
`;


import {PhotoAlbumItem} from "@c/activity-view/activity-creator/media-editor/MediaEditor.styled";

export const PhotoAlbumItemContainer=PhotoAlbumItem.extend`
   margin: 14px 14px 0px 0px;
   border:1px solid rgba(0,0,0,0.1);
   background-image: url("http://photokonkurs.russia.travel/images/icons/preloader.gif");
   background-size:20px;
   background-position:center;
   background-repeat:no-repeat;
`;

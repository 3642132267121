import { ThemeButton } from "static/styled/custom.styled";
import styled from "styled-components";

export const UploadContainer = styled.div`
    padding: 14px;
    width:100%;
    background-color: #fafafa;
    border-radius: 10px;
    margin:14px 0px;
   
`;

export const UploadButton = styled.button`
    background: none;
    outline: none;
    border: 0;
    cursor:pointer;
    font-size:var(--sm-font-size);
    color: rgba(38,54,81,0.7);
    margin-right: 20px;
    img {
        height: 15px;
        margin-right: 5px;
    }
`;

export const SaveChangesButton = ThemeButton.extend`
margin-left:10px;
margin-right:${props => props.marginRight ? props.marginRight : '0px'}
margin-bottom:8px;`;

export const CreateMCQHeader = styled.div`
    
    display:flex;
    justify-content:space-between;
    font-size: var(--lg-font-size);
   
    font-weight: 600;
    
`;

export const ActivityBodyItemContainer = styled.div`
    margin-top:14px;
    padding:14px;
    display:flex;
    justify-content:space-between;
    flex-flow:row wrap;
    border-radius: 6px;
    position:relative
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
     background-color: #ffffff;
    border: solid 1px rgba(0, 0, 0, 0.05);`;

export const MCQDescription = styled.div`
    font-size: 12px;
  line-height: 1.25;
  letter-spacing: 0.1px;
  text-align: justify;
  color: #000000;
  width:85%;
    `;
export const MCQToolbar = styled.div`
    position:absolute;
    top:0px;
    right:18px;
    
    img
    {  height:12px;
       margin-left:6px; 
       background-repeat:no-repeat;
       cursor:pointer;
       }
    `;
export const FlexBetween = styled.div`
        display:flex;
        justify-content: space-between;
        align-items: center;
        border-bottom:${props => props.primary ? '1px solid rgba(71,73,162,0.1)' : 0};
    `;


export const Heading = styled.p`
    font-size:var(--lg-font-size);
  font-weight:600;`;

export const AlignContentHorizontal = styled.div`
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;`;

export const MainBodyContentContainer = styled.div`
         background-color: #ffffff;
                margin-top:${props => props.primary ? '0px' : '20px'};
                padding:14px;
                padding-bottom: 0;
                border-radius:10px;
                min-height:${props => props.primary ? 'calc(100vh - 110px)' : 'calc(100vh - 200px)'};`;


export const SectionToolbar = styled.div`
    width:15%;
    margin-right:15px;
    display:flex;
    flex-flow:row wrap;
    justify-content:space-around;
    `;

export const ToolbarItem = styled.div`
    cursor:pointer;
    display:flex;
    align-items:center;
    width:40%;
    font-size:var(--sm-font-size);
    span
    {
        color:${props => props.primary ? '#0acade' : '#ff7373'};
    }

    img
    {
        width:12px;
        height:12px;
        margin-right:6px;

    }
    `;
import styled from "styled-components";

export const DropDownTitle = styled.div`
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 10px;
    text-transform: uppercase;
    color: #979797;
    margin-bottom : 10px;
`
export const DropDownContainer = styled.div`
    margin-top : 10px;
`
export const DatePickerContainer = styled.div`
    margin-top : 10px;
`

// add border to table,tr,th,td

export const Table = styled.table`
    border-collapse: collapse;
    width: 100%;
`

export const Tr = styled.tr`
    border-collapse: collapse;
    border: 1px solid #a349c9;
`

export const Th = styled.th`
    border-collapse: collapse;
    border: 1px solid #a349c9;
`

export const Td = styled.td`
    border-collapse: collapse;
    width: 50%;
    border: 1px solid #a349c9;
`
export const Grade = styled.span`
    padding: 5px;
    margin: 5px;
`

import React, { Fragment } from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import { ModalBackDrop } from "static/styled/ModalBackDrop.styled";
import StoryCreator from "@c/story-view/story-creator/StoryCreator";

export const Routes = function({ match, location }) {
  return (
    <Switch location={location}>
      <Route
        path={"/stories/:gradeLevel/:level/edit/:storyId/:storyIndex"}
        render={props => (
          <Fragment>
            <StoryCreator {...props} />
            <ModalBackDrop active second />
          </Fragment>
        )}
      />
    </Switch>
  );
};

export default withRouter(Routes);

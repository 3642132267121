import styled from "styled-components";

export const DropDownTitle = styled.div`
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 10px;
    text-transform: uppercase;
    color: #979797;
    margin-bottom : 10px;
`
export const DropDownContainer = styled.div`
    margin-top : 10px;
`
export const DatePickerContainer = styled.div`
    margin-top : 10px;
`
import React, { Component } from "react";
import {
  ActivityContinueButtonContainer,
  ActivityMainFormContainer,
  CreateActivityContainer,
  ActivityUploadImageButton,
  SelectFileIn,
  ImageContainer,
  ChangeImageButton,
  UploadActivityImageContainer,
} from "@c/activity-view/activity-creation-form/ActivityCreationForm.styled";
import { FileListContainer } from "@c/activity-view/activity-creator/file-section/FileSection.styled";
import {
  RCNameInput,
  CreateRCButton,
  PRShortTextArea,
} from "./PodcastEpisodeCreationForm.styled";
import {
  ThemeButton,
  UploadImagesParentContainer,
  ImageLoader,
  SubHeading

} from "@sc/custom.styled";

import { module as ui } from "@st/ui";
import { module as activity } from "@st/activity";
import { module as podcast } from "@st/podcast";
import { connectStore } from "redux-box";
import swal from "sweetalert2";
import { uploadFile } from "@s/api/file";


class PodcastEpisodeCreationForm extends Component {
  constructor(props) {
    super();
    this.state = {
      podcastFormData: {
        name: "",
        description: "",
        thumbnail: "",
        content_url: "",
        episode_number: "",
        duration: "",
        id: "",
        podcast: "",
      },
    };
  }

  componentDidMount() {
    let { episodeEditInfo } = this.props.podcast;
    if (episodeEditInfo && episodeEditInfo.id) {
      this.setEpisodeData();
    } else {
      this.props.podcast.setEpisodeEdit(true);
    }
  }

  componentWillUnmount() {
    this.props.podcast.setEpisodeEditInfo(null);
    this.props.podcast.setEpisodeEdit(false);
  }

  setEpisodeData = () => {
    let { episodeEditInfo } = this.props.podcast;

    this.setState({
      podcastFormData: {
        ...this.state.podcastFormData,
        name: episodeEditInfo.name,
        description: episodeEditInfo.description,
        thumbnail: episodeEditInfo.thumbnail,
        content_url: episodeEditInfo.content_url,
        duration: episodeEditInfo.duration,
        id: episodeEditInfo.id,
      },
    });
  };

  handleName = (evt) => {
    this.setState({
      podcastFormData: {
        ...this.state.podcastFormData,
        name: evt.target.value,
      },
    });
  };

  handleDescription = (evt) => {
    this.setState({
      podcastFormData: {
        ...this.state.podcastFormData,
        description: evt.target.value,
      },
    });
  };

  handleContentURL = (evt) => {
    this.setState({
      podcastFormData: {
        ...this.state.podcastFormData,
        content_url: evt.target.value,
      },
    });
  };

  handleDuration = (evt) => {
    this.setState({
      podcastFormData: {
        ...this.state.podcastFormData,
        duration: evt.target.value,
      },
    });
  };

  handleImageFocus = (actionType) => {
    if (actionType === "over" && this.state.imageChange === false)
      this.setState({
        imageChange: true,
      });
    else if (actionType === "out" && this.state.imageChange === true)
      this.setState({
        imageChange: false,
      });
  };

  _handleImageChange = (e) => {
    // e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];

    let fileSize = e.target.files[0].size / 1024 / 1024;

    if (fileSize > 2) {
      swal("Oops!", "File size greater than 2Mb ", "warning");
    } else {
      reader.onloadend = () => {
        this.setState({
          imageChange: false,
          enableLoad: true,
          podcastFormData: {
            ...this.state.podcastFormData,
            thumbnail: reader.result,
          },
        });
      };
      reader.readAsDataURL(file);
      uploadFile(file).then((res) => {
        this.setState({
          podcastFormData: {
            ...this.state.podcastFormData,
            thumbnail: res.file,
          },
          enableLoad: false,
        });
      });
    }
  };

  handleAudioFocus = (actionType) => {
    if (actionType === "over" && this.state.audioChange === false)
      this.setState({
        audioChange: true,
      });
    else if (actionType === "out" && this.state.audioChange === true)
      this.setState({
        audioChange: false,
      });
  };

  _handleAudioChange = (e) => {
    // e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];

    let fileSize = e.target.files[0].size / 1024 / 1024;

    if (fileSize > 5) {
      swal("Oops!", "File size greater than 5Mb ", "warning");
    } else {
      reader.onloadend = () => {
        this.setState({
          audioChange: false,
          enableAudioLoad: true,
          podcastFormData: {
            ...this.state.podcastFormData,
            file: reader.result,
          },
        });
      };
      reader.readAsDataURL(file);
      uploadFile(file).then((res) => {
        this.setState({
          podcastFormData: {
            ...this.state.podcastFormData,
            content_url: res.file,
          },
          enableAudioLoad: false,
        });
      });
    }
  };

  handleEditBtn = () => {
    this.props.podcast.setEpisodeEdit(true);
  };

  handleUpdateBtn = () => {
    const { podcastFormData } = this.state;

    if (
      podcastFormData.name &&
      podcastFormData.description &&
      podcastFormData.content_url &&
      podcastFormData.duration &&
      (podcastFormData.thumbnail !== "undefined" ||
        podcastFormData.thumbnail !== "" ||
        podcastFormData.thumbnail !== null)
    ) {
      let updateEpisodeObject = {
        name: podcastFormData.name,
        description: podcastFormData.description,
        content_url: podcastFormData.content_url,
        duration: podcastFormData.duration,
        thumbnail: podcastFormData.thumbnail,
        id:podcastFormData.id
      };

      this.props.podcast.updateEpisode(updateEpisodeObject);
      this.props.ui.hideModal();
      this.props.podcast.setEpisodeEdit(false);
    } else {
      swal(
        "Oops!",
        "You must enter title, description and long description",
        "error"
      );
    }
  };

  handleContinueBtn = () => {
    const { podcastFormData } = this.state;

    const url = window.location.pathname;
    const podcast_id = url.split("/")[2];
    // const podcast_id = this.props.match.params.id;
    const episodes_length =
      this.props.podcast.episodesResult &&
      this.props.podcast.episodesResult.count;
    if (
      podcastFormData.name &&
      podcastFormData.description &&
      podcastFormData.content_url &&
      podcastFormData.duration &&
      (podcastFormData.thumbnail !== "undefined" ||
        podcastFormData.thumbnail !== "" ||
        podcastFormData.thumbnail !== null)
    ) {
      let episodeObject = {
        name: podcastFormData.name,
        description: podcastFormData.description,
        content_url: podcastFormData.content_url,
        duration: podcastFormData.duration,
        thumbnail: podcastFormData.thumbnail,
      };
      episodeObject["podcast"] = podcast_id;
      episodeObject["episode_number"] = parseInt(episodes_length + 1);

      this.props.podcast.createEpisode(episodeObject);
      this.props.ui.hideModal();
    } else {
      swal(
        "Oops!",
        "You must enter title, description and long description",
        "error"
      );
    }
  };

  render() {
    const { podcastFormData } = this.state;

    return (
      <CreateActivityContainer>
        <div>
          <UploadImagesParentContainer>
            {!this.state.podcastFormData.thumbnail ? (
              <UploadActivityImageContainer height="120px" marginBottom="14px">
                <ActivityUploadImageButton type="button">
                  <div>
                    <img
                      src={require("static/img/cloud-upload-icon.png")}
                      alt="cloud-upload"
                    />
                  </div>
                  <span>Upload Image </span>
                </ActivityUploadImageButton>

                <SelectFileIn
                  type="file"
                  onChange={(e) => this._handleImageChange(e)}
                />
              </UploadActivityImageContainer>
            ) : this.state.enableLoad ? (
              <UploadActivityImageContainer height="120px" marginBottom="14px">
                <ImageLoader>
                  {" "}
                  <img
                    alt="loading"
                    width="30"
                    height="30"
                    src="http://photokonkurs.russia.travel/images/icons/preloader.gif"
                  />
                </ImageLoader>
              </UploadActivityImageContainer>
            ) : (
              <UploadActivityImageContainer
                height="120px"
                marginBottom="14px"
                onMouseOver={() => this.handleImageFocus("over")}
                onMouseOut={() => this.handleImageFocus("out")}
              >
                <ImageContainer src={this.state.podcastFormData.thumbnail} />
                <ChangeImageButton
                  top={this.state.imageChange ? "83%" : "100%"}
                  opacity={this.state.imageChange ? 1 : 0}
                  disabled={
                    this.props.podcast.episodeEdit
                      ? !this.props.podcast.episodeEdit
                      : true
                  }
                >
                  Change Image
                </ChangeImageButton>
                <SelectFileIn
                  type="file"
                  onChange={(e) => this._handleImageChange(e)}
                  disabled={
                    this.props.podcast.episodeEdit
                      ? !this.props.podcast.episodeEdit
                      : true
                  }
                />
              </UploadActivityImageContainer>
            )}
          </UploadImagesParentContainer>
          <UploadImagesParentContainer>
            {!this.state.podcastFormData.content_url ? (
              <UploadActivityImageContainer height="120px" marginBottom="14px">
                <ActivityUploadImageButton type="button">
                  <div>
                    <img
                      src={require("static/img/cloud-upload-icon.png")}
                      alt="cloud-upload"
                    />
                  </div>
                  <span>Upload Audio </span>
                </ActivityUploadImageButton>

                <SelectFileIn
                  type="file"
                  onChange={(e) => this._handleAudioChange(e)}
                />
              </UploadActivityImageContainer>
            ) : this.state.enableAudioLoad ? (
              <UploadActivityImageContainer height="120px" marginBottom="14px">
                <ImageLoader>
                  {" "}
                  <img
                    alt="loading"
                    width="30"
                    height="30"
                    src="http://photokonkurs.russia.travel/images/icons/preloader.gif"
                  />
                </ImageLoader>
              </UploadActivityImageContainer>
            ) : (
              <React.Fragment>
                <UploadActivityImageContainer
                  height="120px"
                  marginBottom="14px"
                  onMouseOver={() => this.handleAudioFocus("over")}
                  onMouseOut={() => this.handleAudioFocus("out")}
                >
                  {/* <ImageContainer src={this.state.podcastFormData.content_url} /> */}
                  <FileListContainer style={{ marginTop: "20px" }}>
                    <i
                      className="fa fa-file-o"
                      style={{
                        fontSize: "48px",
                        color: "red",
                        width: "100%",
                        height: "100%",
                      }}
                    />
                  </FileListContainer>
                  <ChangeImageButton
                    top={this.state.imageChange ? "83%" : "100%"}
                    opacity={this.state.audioChange ? 1 : 0}
                    disabled={
                      this.props.podcast.episodeEdit
                        ? !this.props.podcast.episodeEdit
                        : true
                    }
                  >
                    Change Audio
                  </ChangeImageButton>
                  <SelectFileIn
                    type="file"
                    onChange={(e) => this._handleAudioChange(e)}
                    disabled={
                      this.props.podcast.episodeEdit
                        ? !this.props.podcast.episodeEdit
                        : true
                    }
                  />
                </UploadActivityImageContainer>
                <SubHeading
                  style={{
                    margin: "0px",
                    marginLeft: "12px",
                    wordBreak: "break-all",
                  }}
                >
                  {this.state.podcastFormData.content_url &&
                    this.state.podcastFormData.content_url
                      .split("-")
                      .reverse()[0]}{" "}
                </SubHeading>
              </React.Fragment>
            )}
          </UploadImagesParentContainer>
        </div>
        <ActivityMainFormContainer>
          <form>
            <RCNameInput
              type="text"
              className="form-control"
              autoFocus
              maxLength="50"
              placeholder="Add Episode Title"
              value={podcastFormData.name}
              disabled={
                this.props.podcast.episodeEdit
                  ? !this.props.podcast.episodeEdit
                  : true
              }
              onChange={this.handleName}
            />
            <div>
              <PRShortTextArea
                className="form-control"
                placeholder="Add Episode description"
                maxLength="200"
                value={podcastFormData.description}
                disabled={
                  this.props.podcast.episodeEdit
                    ? !this.props.podcast.episodeEdit
                    : true
                }
                onChange={this.handleDescription}
              />
            </div>

            {/* <RCNameInput
              type="text"
              className="form-control"
              autoFocus
              maxLength="50"
              placeholder="Add Content URL"
              value={podcastFormData.content_url}
              disabled={
                this.props.podcast.episodeEdit
                  ? !this.props.podcast.episodeEdit
                  : true
              }
              onChange={this.handleContentURL}
            /> */}

            <RCNameInput
              type="text"
              className="form-control"
              autoFocus
              maxLength="50"
              placeholder="Add Episode Duration"
              value={podcastFormData.duration}
              disabled={
                this.props.podcast.episodeEdit
                  ? !this.props.podcast.episodeEdit
                  : true
              }
              onChange={this.handleDuration}
            />

            <ActivityContinueButtonContainer>
              {this.props.podcast.episodeEditInfo &&
              this.props.podcast.episodeEditInfo.id ? (
                this.props.podcast.episodeEdit ? (
                  <ThemeButton
                    primary
                    type="button"
                    onClick={this.handleUpdateBtn}
                  >
                    {" "}
                    Update Episode{" "}
                  </ThemeButton>
                ) : (
                  <ThemeButton
                    primary
                    type="button"
                    onClick={this.handleEditBtn}
                  >
                    {" "}
                    Edit Episode{" "}
                  </ThemeButton>
                )
              ) : (
                <CreateRCButton
                  primary
                  className="btn btn-warning"
                  type="button"
                  onClick={() => this.handleContinueBtn()}
                >
                  {" "}
                  Create Episode{" "}
                </CreateRCButton>
              )}
            </ActivityContinueButtonContainer>
          </form>
        </ActivityMainFormContainer>
      </CreateActivityContainer>
    );
  }
}
export default connectStore({
  ui,
  activity,
  podcast,
  podcast,
})(PodcastEpisodeCreationForm);

import styled from "styled-components";

export const ActivityPackCardContainer = styled.div`
  position: relative;
  min-height: 80px;
  border-radius: 20px;
  overflow: hidden;
  max-width: 100%;
  cursor: pointer;
  margin-bottom: 20px;
  background: #f1f1f1;
  border: 1px solid #e7e7e7;
  text-transform:capitalized;
  transition: all 0.3s;
  &:hover {
    box-shadow: 0px 3px 10px -2px rgba(0, 0, 0, 0.5);
  }
  &:after {
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 10px;
    box-shadow: inset 0 0 2000px rgba(255, 255, 255, 0.5);
    filter: blur(50px);
    width: 100%;
    height: 100%;
    z-index: 1;
    content: "";
  }
  &:before {
    position: absolute;
    right: 0;
    bottom: 0;
    border-radius: 100px;
    border:1.5px solid #e7e7e7;
    width: 35px;
    height: 35px;
    color: #341744;
    display:flex;
    justify-content:center;
    align-items:center;
    z-index: 1;
    content: '${props => props.before}';
  }
`;

export const ActivityPackContainer = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  padding: 10px;
  padding-right: 35px;
  width: 100%;
  height: 100%;
  z-index: 2;
`;

export const ActivityPackTitle = styled.div`
  padding: 2px 0;
  font-size: ${props => (props.primary ? "var(--lg-font-size)" : "12px")};
  color: ${props => (props.primary ? "#341744" : "#263651")};
  font-weight: ${props => (props.primary ? "600" : "400")};
`;

import styled from "styled-components";

export const CreatorComponent = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1000;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  //transition: all 5s;
`;

export const ComponentContainer = styled.div`
  position: relative;
  padding-bottom: ${props => (props.primary ? "30px" : "0")};
  width: 70%;
  background: white;
  min-height: 20px;
  border-radius: 10px;
  overflow: hidden;
  height: 100%;
  transition: all 0.5s;
  transform: translateX(${props => (props.active ? "0" : "100%")};);
`;

export const SubContainer = styled.div`
  border-radius: 10px;
  margin-left: 1%;
  min-width: 29%;
  max-height: 100%;
  position: relative;
  //overflow-y:auto;
  background: white;
  transform: translateX(${props => props.translateX});
  min-height: 20px;
  opacity: ${props => (props.active ? 1 : 0)};
  width: ${props => (props.active ? "30%" : 0)};
  transition: all 0.5s;
`;


import { createActions, using } from "redux-box";

export default createActions({
  requestCaseStudies:using(),
  createCaseStudy:using("caseStudy"),
    getCaseStudyById:using("caseStudyId"),
    deleteCaseStudy:using("caseStudyId"),
    updateCaseStudy:using("data"),
    setActiveCaseStudy:using("caseStudy"),
    setCaseStudyId:using("caseStudyId"),
    enableCaseStudyEdit:using("value"),

});

import { createSagas } from "redux-box";
import GradeApi from "@s/api/grade";
import ReadingApi from "@s/api/readingChallenge";
import { call, put } from "redux-saga/effects";
//import { push } from "react-router-redux";
import swal from "sweetalert2";

// import store from 'store'
const defaultSections = [
  { name: "Challenge Rules" },
  { name: "Challenge Steps" },
  { name: "Resources" },
];

const errorHandler = (err) => {
  console.log("reading Challenge store err",err);
  swal("Oops!", err.error_message, "error");
};

export default createSagas({
  REQUEST_ACTIVE_GRADE: function*() {
    try {
      const res = yield call(GradeApi.getAllGrades);

      yield put({
        type: "REQUEST_READING_CHALLENGE_BY_GRADE_ID",
        gradeId: res[0].id,
      });
      yield put({
        type: "SET_ACTIVE_READING_GRADE",
        grade: res[0].id,
      });
    } catch (err) {}
  },

  REQUEST_CONCAT_READING_CHALLENGE: function*({ offset }) {
    try {
      const gState = require("store").default.getState();
      const gradeId = gState.readingChallenge.active_reading_grade;
      const search = gState.readingChallenge.search_reading;
      const res = yield call(
        ReadingApi.getReadingChallenge,
        gradeId,
        offset,
        search
      );
      yield put({
        type: "SET_READING_CHALLENGES_RESULT",
        result: res,
      });
      if (res && res.result && res.result.length > 0)
        yield put({
          type: "SET_CONCAT_READING_CHALLENGE_LIST",
          readingList: res.result,
        });
    } catch (err) {
      errorHandler(err);
    }
  },
  REQUEST_READING_CHALLENGE: function*() {
    try {
      yield put({
        type: "SET_FETCH_READING_CHALLENGE_LIST",
        value: true,
      });

      const gState = require("store").default.getState();
      const gradeId = gState.readingChallenge.active_reading_grade;
      const search = gState.readingChallenge.search_reading;
      const res = yield call(
        ReadingApi.getReadingChallenge,
        gradeId,
        0,
        search
      );
      yield put({
        type: "SET_READING_CHALLENGES_RESULT",
        result: res,
      });
      if (res && res.result && res.result.length > 0)
        yield put({
          type: "SET_READING_CHALLENGE_LIST",
          readingList: res.result,
        });
    } catch (err) {
      errorHandler(err);
    } finally {
      yield put({
        type: "SET_FETCH_READING_CHALLENGE_LIST",
        value: false,
      });
    }
  },

  REQUEST_CREATE_READING_CHALLENGE: function*({ reading }) {
    try {
      yield call(ReadingApi.createReadingChallenge, reading);
      yield put({
        type: "REQUEST_READING_CHALLENGE",
      });
    } catch (err) {
      errorHandler(err);
    }
  },
  REQUEST_UPDATE_READING_CHALLENGE: function*({ reading }) {
    try {
      const res = yield call(
        ReadingApi.updateReadingChallenge,
        reading.id,
        reading
      );
      yield put({
        type: "SET_ACTIVE_READING_CHALLENGE",
        activeReading: res,
      });
      yield put({
        type: "REQUEST_READING_CHALLENGE",
      });
    } catch (err) {
      errorHandler(err);
    }
  },
  REQUEST_READING_CHALLENGE_BY_GRADE_ID: function*({ gradeId }) {
    try {
      yield put({
        type: "SET_FETCH_READING_CHALLENGE_LIST",
        value: true,
      });
      yield put({
        type: "SET_ACTIVE_READING_GRADE",
        grade: gradeId,
      });
      const gState = require("store").default.getState();
      const search = gState.readingChallenge.search_reading;
      const res = yield call(
        ReadingApi.getReadingChallenge,
        gradeId,
        0,
        search
      );
      yield put({
        type: "SET_READING_CHALLENGES_RESULT",
        result: res,
      });
      if (res && res.result && res.result.length > 0)
        yield put({
          type: "SET_READING_CHALLENGE_LIST",
          readingList: res.result,
        });
      yield put({
        type: "SET_FETCH_READING_CHALLENGE_LIST",
        value: false,
      });
    } catch (err) {
      errorHandler(err);
    }
  },

  REQUEST_READING_CHALLENGE_BY_ID: function*({ readingId }) {
    try {
      yield put({
        type: "FETCH_READING_CHALLENGE",
        value: true,
      });

      const res = yield call(ReadingApi.getReadingChallengeById, readingId);
      yield put({
        type: "SET_ACTIVE_READING_CHALLENGE",
        activeReading: res,
      });

      yield put({
        type: "SET_ACTIVE_READING_GRADE",
        grade: res.grade,
      });
      yield put({
        type: "FETCH_READING_CHALLENGE",
        value: false,
      });
      if (res.section_data && res.section_data.length === 0) {
        defaultSections.forEach(function(section) {
          require("store").default.dispatch({
            type: "ADD_NEW_READING_SECTION",
            section,
          });
        });
      }
    } catch (err) {
      errorHandler(err);
    }
  },

  //...........................Section.......................
  REQUEST_READING_SECTION_BY_ID: function*({ readingId }) {
    try {
      const res = yield call(ReadingApi.getSectionById, readingId);
      yield put({
        type: "SET_READING_SECTIONS",
        sections: res,
      });
    } catch (err) {
      errorHandler(err);
    }
  },
  REQUEST_CREATE_READING_SECTION: function*({ section }) {
    try {
      const gState = require("store").default.getState();
      const active_section = gState.readingChallenge.active_section;
      const reading_id = gState.readingChallenge.active_reading_challenge.id;
      yield call(ReadingApi.createSection, {
        content_type: "text",
        description: "",
        media: [],
        level: "easy",
        ...section,
        ...active_section,
        reading: reading_id,
      });
      // flush the active_section from new_sections since it's been created
      yield put({
        type: "REMOVE_NEW_READING_SECTION",
        section: gState.readingChallenge.active_section,
      });
      yield put({
        type: "REQUEST_READING_CHALLENGE_BY_ID",
        readingId: reading_id,
      });
    } catch (err) {
      errorHandler(err);
    }
  },
  REQUEST_DELETE_READING_SECTION: function*() {
    try {
      const res = yield call(ReadingApi.get);
      yield put({
        type: "SET_GRADES",
        grades: res,
      });
    } catch (err) {
      errorHandler(err);
    }
  },
  REQUEST_UPDATE_READING_SECTION: function*({ section }) {
    try {
      const globalState = require("store").default.getState();
      const reading_id =
        globalState.readingChallenge.active_reading_challenge.id;
      const active_section = globalState.readingChallenge.active_section;

      yield call(ReadingApi.updateSection, active_section.id, ...section);

      yield put({
        type: "REQUEST_READING_CHALLENGE_BY_ID",
        readingId: reading_id,
      });
    } catch (err) {
      errorHandler(err);
    }
  },
  DELETE_READING_SECTION: function*({ section }) {
    try {
      const globalState = require("store").default.getState();
      const reading_id =
        globalState.readingChallenge.active_reading_challenge.id;
      yield call(ReadingApi.deleteSectionById, section.id);
      yield put({
        type: "REQUEST_READING_CHALLENGE_BY_ID",
        readingId: reading_id,
      });
    } catch (err) {
      console.log(err);
      errorHandler(err);
    }
  },

  //.............................Tags..................
  REQUEST_CREATE_READING_TAG: function*({ tag }) {
    try {
      const globalState = require("store").default.getState();
      let tags = globalState.readingChallenge.active_reading_challenge.tag;
      if (!tags.some((data) => data.toLowerCase() === tag.toLowerCase())) {
        const data = {
          ...globalState.readingChallenge.active_reading_challenge,
          tag: [...tags, tag],
        };
        yield put({
          type: "REQUEST_UPDATE_READING_CHALLENGE",
          reading: data,
        });
      }
    } catch (err) {
      errorHandler(err);
    }
  },
  DELETE_READING_TAG: function*({ tag }) {
    try {
      const globalState = require("store").default.getState();
      const reading = globalState.readingChallenge.active_reading_challenge;
      const tags = globalState.readingChallenge.active_reading_challenge.tag.filter(
        (data) => data !== tag
      );
      const data = { ...reading, tag: [...tags] };
      yield put({
        type: "REQUEST_UPDATE_READING_CHALLENGE",
        reading: data,
      });
    } catch (err) {
      errorHandler(err);
    }
  },

  //....................................Questions...................
  REQUEST_CREATE_READING_QUESTION: function*({ question }) {
    try {
      yield call(ReadingApi.createQuestion, question);
      swal("Done!", "Created question!", "success");
      yield put({
        type: "REQUEST_ALL_READING_QUESTIONS",
        readingId: question.reading,
      });
    } catch (err) {
      errorHandler(err);
    }
  },
  REQUEST_UPDATE_READING_QUESTION: function*({ question }) {
    try {
      const gState = require("store").default.getState();
      const questionId = gState.readingChallenge.active_mcq.id;

      for (let i = 0; i < question.options.length; i++) {
        let option = question.options[i];
        yield ReadingApi.updateOption(option.id, { name: option.name });
      }
      delete question.options;
      yield call(ReadingApi.updateQuestion, questionId, question);

      swal("Done!", "Updated question!", "success");
      yield put({
        type: "REQUEST_ALL_READING_QUESTIONS",
        readingId: question.reading,
      });
    } catch (err) {
      errorHandler(err);
    }
  },
  REQUEST_DELETE_READING_QUESTION: function*({ question }) {
    try {
      yield call(ReadingApi.deleteQuestion, question.id);
      swal("Done!", "The selected question was deleted!", "success");
      yield put({
        type: "REQUEST_ALL_READING_QUESTIONS",
        readingId: question.reading,
      });
    } catch (err) {
      errorHandler(err);
    }
  },
  REQUEST_ALL_READING_QUESTIONS: function*({ readingId }) {
    try {
      const res = yield call(ReadingApi.getAllQuestions, readingId);
      yield put({
        type: "SET_ALL_READING_QUESTIONS",
        questions: res,
      });
    } catch (err) {
      errorHandler(err);
    }
  },

  //..........................publish experience...................
  REQUEST_PUBLISH_READING_EXPERIENCE: function*({ experience }) {
    try {
      let data = { is_published: !experience.is_published };
      const res = yield call(
        ReadingApi.updateReadingExperience,
        experience.id,
        data
      );
      if (experience.show)
        yield put({
          type: "SET_ACTIVE_READING_EXPERIENCE",
          experience: res,
        });
      const globalState = require("store").default.getState();
      const reading_id =
        globalState.readingChallenge.active_reading_challenge.id;
      yield put({
        type: "REQUEST_READING_CHALLENGE_BY_ID",
        readingId: reading_id,
      });
    } catch (err) {
      errorHandler(err);
    }
  },

  REQUEST_DELETE_READING_EXPERIENCE: function*({ experienceId }) {
    try {
      yield call(ReadingApi.deleteReadingExperience, experienceId);
      const globalState = require("store").default.getState();
      const reading_id =
        globalState.readingChallenge.active_reading_challenge.id;
      yield put({
        type: "REQUEST_READING_CHALLENGE_BY_ID",
        readingId: reading_id,
      });
      yield put({
        type: "SET_ACTIVE_READING_EXPERIENCE",
        experience: null,
      });
    } catch (err) {
      errorHandler(err);
    }
  },
});

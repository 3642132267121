import React, { Component } from "react";
import { uploadFile } from "@s/api/file";
import {
  CheckBall,
  CheckBallInner,
  DropdownItem,
  FlexStart,
  SubLevelItem,
  UploadImagesParentContainer,
} from "@sc/custom.styled";
import {
  FreadomPointsInputField,
  RCHeaderDropDown,
  CategoryDropDown,
  RCHeaderDropDownMenu,
  RCNameInput,
  CreateRCButton,
  CategorySection,
  CategoryButton,
  Box,
  Closeicon,
  PopupBox,
  CategoryName,
  SubmitButton,
} from "./QuizInfo.styled";
import {
  ActivityGradeSelectOption,
  ActivityDescription,
  ActivityDescriptionInfoContainer,
  ActivityDescriptionTextArea,
} from "@sc/ActivityModal.styled";
import { ActivitySectionAdder } from "@c/activity-view/activity-creator/mcq-editor/McqEditor.styled";
import {
  SelectFileIn,
  ChangeImageButton,
  ActivityInputField,
  ActivityUploadImageButton,
  ImageContainer,
} from "@c/activity-view/activity-creation-form/ActivityCreationForm.styled";
import { EditIcon } from "@c/activity-view/activity-creator/activity-info/ActivityInfo.styled";
import {
  ThemeButton,
  ImageLoader,
  ModalCountItem,
  ModalCountOverlayContainer,
} from "@sc/custom.styled";
import { connectStore } from "redux-box";
import { module as activity } from "@st/activity";
import { module as quiz2 } from "@st/quiz2";
import { module as category } from "@st/category";

import { ActivityUploadImageContainer } from "@c/activity-view/activity-pack-creator/activity-pack-info/ActivityPackInfo.styled";
//import {ActivityUploadImageButton} from "activity-view/activity-creation-form/ActivityCreationForm.styled";
import swal from "sweetalert2";
import { module as onboard } from "@st/onboard";
import Permission from "@s/permission";
import { Chevron } from "@sc/custom.styled";

class QuizInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addedCategory: "",
      readingEdit: true,
      categoryEdit: false,
      gradeSelected: [],
      gradeSelector: false,
      categorySelector: false,
      gradeData: [],
      selectedGrades: [],
      grade_selected: null,
      enableLoad: false,
      reading_count: 0,
      completed_count: 0,
      backgroundImageChange: false,
      enableBackgroundImageChange: false,
      imageChange: false,
      displayMenu: false,
      quiz_info: {
        id: "",
        grade: "",
        points: "",
        name: "",
        description: "",

        image: "",
        quiz_type: "",
        theme: "",
        quiz_category_name: "",
        quiz_category: "",
        is_background_image: true,
        status: true,
        quiz_url: null,
      },
      quizbody: { grade: "" },
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.quiz2 && nextProps.quiz2.active_quiz) {
      this.normalizeForState(nextProps.quiz2.active_quiz);
    } else {
      this.setState({
        gradeSelected: "Select Grade",
      });
    }
  }
  normalizeForState = (activequiz) => {

    let grade_arr = activequiz.grade;

    this.setState({ selectedGrades: grade_arr });

    let gradeName = this.props.activity.grades.filter((grade) => {
      return grade_arr.includes(grade.id);
    });

    this.setState({
      gradeSelected: gradeName,
      quiz_info: {
        ...this.state.quiz_info,
        grade: activequiz.grade,
      },
    });
  };

  componentDidMount() {
    let data = this.props.active_quiz;

    this.setState({
      imageChange: false,
      reading_count: data.reading_count,
      completed_count: data.completed_count,
      quiz_info: {
        ...this.state.quiz_info,
        id: data.id,
        grade: data.grade,
        points: data.points,
        name: data.name,
        description: data.description,
        image: data.image || null,
        quiz_type: data.quiz_type,
        theme: data.theme,
        quiz_category_name: data.quiz_category_name,
        quiz_category: data.quiz_category,
        is_background_image: data.is_background_image,
        status: data.status,
        quiz_url: data.quiz_url,
      },
    });

    const grade =
      this.props.activity.active_grade || this.props.activity.grades[0];

    this.setState({
      grade_selected: grade,
      quizbody: { grade: grade.id },
    });

    const { activity } = this.props;

    let gradeList = activity.grades.map((grade) => {
      let gradeData = {
        ...grade,
        selected: false,
        clicked: false,
      };
      return gradeData;
    });

    //console.log(gradeList)

    this.setState({
      gradeData: [
        {
          name: "Select All",
          selected: false,
          clicked: false,
        },
        ...gradeList,
      ],
    });
  }

  handleGradeChange = (data) => {
    this.handleGradeSelector(true);
    let selectedGrades = this.state.selectedGrades;
    if (data.name === "Select All" || data.name === "Unselect All") {
      // change the grade data with new grade data
      let newGradeData = this.state.gradeData.map((grade) => {
        if (grade.name === "Select All" || grade.name === "Unselect All") {
          let name =
            grade.name === "Select All" ? "Unselect All" : "Select All";
          return { ...grade, name: name, selected: !grade.selected };
        }
        return {
          ...grade,
          selected: !this.state.gradeData[0].selected,
        };
      });

      // change selected grades
      if (data.name === "Unselect All") selectedGrades = [];
      else if (data.name === "Select All") {
        // storing the id's and filtering for undefined
        selectedGrades = this.state.gradeData
          .map((g) => g.id)
          .filter((g) => g !== undefined);
      }

      this.setState({
        gradeData: newGradeData,
        selectedGrades: selectedGrades,
      });
    } else {
      let newGradeData = this.state.gradeData.map((grade) => {
        if (grade.name === data.name)
          return { ...grade, selected: !grade.selected };
        return { ...grade };
      });

      const data_idx = selectedGrades.indexOf(data.id);

      // removing grade if it already exists
      data_idx > -1
        ? selectedGrades.splice(data_idx, 1)
        : selectedGrades.push(data.id);

      this.setState({
        gradeData: newGradeData,
        selectedGrades: selectedGrades,
      });
    }
  };

  handleImageChange = (e) => {
    // e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    let fileSize = e.target.files[0].size / 1024 / 1024;
    if (fileSize > 2) {
      swal("Oops!", "File size greater than 2MB ", "warning");
    } else {
      reader.onloadend = () => {
        this.setState({
          enableLoad: true,
          imageChange: false,
          quiz_info: {
            ...this.state.quiz_info,
            image: reader.result,
          },
        });
      };
      reader.readAsDataURL(file);
      uploadFile(file).then((res) => {
        this.setState({
          quiz_info: {
            ...this.state.quiz_info,
            image: res.file,
          },
          enableLoad: false,
        });
      });
    }
  };

  handleImageFocus = (actionType) => {
    if (actionType === "over" && this.state.imageChange === false)
      this.setState({
        imageChange: true,
      });
    else if (actionType === "out" && this.state.imageChange === true)
      this.setState({
        imageChange: false,
      });
  };

  handleGradeSelector = (val) => {
    //console.log("grade selector is here");

    let newGradeData = this.state.gradeData.map((grade) => {
      if (this.state.selectedGrades.includes(grade.id))
        return { ...grade, selected: !grade.selected };
      return { ...grade };
    });
    this.setState({
      gradeData: newGradeData,
      gradeSelector: val,
    });

  };

  handleReadingNameChange = (e) => {
    this.setState({
      quiz_info: { ...this.state.quiz_info, name: e.target.value },
    });
  };

  handleCategoryChange = (e) => {
    this.setState({
      addedCategory: e.target.value,
    });
  };

  handleDescriptionValue = (e) => {
    this.setState({
      quiz_info: {
        ...this.state.quiz_info,
        description: e.target.value,
      },
    });
  };

  handleReadingEditClick = (e) => {
    let setgrade = document.getElementById("set-grade").click();
    this.setState({
      readingEdit: false,
    });
  };

  handleCategoryEdit = (e) => {
    this.setState({
      categoryEdit: true,
    });
  };

  handleCategoryBack = (e) => {
    this.setState({
      categoryEdit: false,
    });
  };

  handleDoneBtn = (e) => {
    this.setState({
      readingEdit: true,
      categoryEdit: false,
    });
    //console.log("states", this.state.quiz_info);
    document.getElementById("activityName").removeAttribute("disabled");
    ////console.log(this.state.reading_info);
    this.props.quiz2.requestUpdateQuiz(this.state.quiz_info);
  };

  handleSubmitBtn = (e) => {
    this.setState({
      readingEdit: true,
      categoryEdit: false,
    });
    //console.log(this.state.quiz_info.grade);
    document.getElementById("activityName").removeAttribute("disabled");
    ////console.log(this.state.reading_info);
    if (this.state.addedCategory != "") {
      this.props.category.requestCreateCategory(this.state.addedCategory);
      // this.props.quiz2.requestUpdateQuiz(this.state.quiz_info);
      this.setState({
        addedCategory: "",
      });
    } else {
      swal("Oops!", "Category Name is Empty", "warning");
    }
  };

  displayGradeMenu = (e) => {
    this.setState({
      displayMenu: !this.state.displayMenu,
    });
  };

  handleGradeSelected = (newgrade) => (e) => {
    this.setState({
      displayMenu: !this.state.displayMenu,
      gradeSelected: newgrade.name,
      quiz_info: { ...this.state.quiz_info, grade: newgrade.id },
    });
  };
  handleFreadomPoints = (evt) => {
    if (evt.target.value.length === 0 && this.state.quiz_info.points !== "") {
      this.setState({
        quiz_info: {
          ...this.state.quiz_info,
          points: "",
        },
      });
    } else
      this.setState({
        quiz_info: {
          ...this.state.quiz_info,
          points: evt.target.value,
        },
      });
  };
  setGradeData = () => {
    let newGradeData = this.state.gradeData.map((grade) => {
      if (this.state.selectedGrades.includes(grade.id))
        return { ...grade, selected: !grade.selected };
      return { ...grade };
    });
  };

  handleCategorySelector = (val) => {
    let newGradeData = this.state.gradeData.map((grade) => {
      return { ...grade, clicked: false };
    });
    this.setState({
      gradeData: newGradeData,
      categorySelector: val,
    });
  };

  handleQuizCategory = (value) => {
    //console.log("Value>>", value);
    this.setState({
      quiz_info: {
        ...this.state.quiz_info,
        quiz_category_name: value.name,
        quiz_category: value.id,
      },
    });
    this.handleCategorySelector(false);
  };

  render() {
    //console.log(
    //   "reached the quiz info section",
    //   this.props,
    //   this.state.gradeData,
    //   this.state.selectedGrades
    // );
    //console.log(this.state.quiz_info.grade);

    const { state } = this;
    const { gradeData, selectedGrades } = state;
    const { activity } = this.props;
    const { permission } = this.props.onboard;
    const gradeOptions = activity.grades.map((grade, i) => (
      <ActivityGradeSelectOption
        onClick={this.handleGradeSelected(grade)}
        key={i}
      >
        {grade.name}
      </ActivityGradeSelectOption>
    ));

    return (
      <ActivityDescriptionInfoContainer style={{ position: "relative" }}>
        {this.state.quiz_info.image === "" ? (
          <ActivityUploadImageContainer>
            <ActivityUploadImageButton type="button">
              <img
                src={require("static/img/cloud-upload-icon.png")}
                alt="Upload"
              />
              <span>Upload Image </span>
            </ActivityUploadImageButton>

            <SelectFileIn
              type="file"
              onChange={(e) => this.handleImageChange(e)}
            />
          </ActivityUploadImageContainer>
        ) : state.enableLoad ? (
          <ActivityUploadImageContainer>
            <ImageLoader>
              {" "}
              <img
                alt="loading"
                width="30"
                height="30"
                src="http://photokonkurs.russia.travel/images/icons/preloader.gif"
              />
            </ImageLoader>
          </ActivityUploadImageContainer>
        ) : (
          <ActivityUploadImageContainer
            onMouseOver={() => this.handleImageFocus("over")}
            onMouseOut={() => this.handleImageFocus("out")}
          >
            <ImageContainer src={this.state.quiz_info.image} />
            {!state.readingEdit ? (
              <ChangeImageButton
                top={this.state.imageChange ? "82%" : "100%"}
                opacity={this.state.imageChange ? 1 : 0}
              >
                Change Image
              </ChangeImageButton>
            ) : null}
            {!state.readingEdit ? (
              <SelectFileIn
                type="file"
                onChange={(e) => this.handleImageChange(e)}
              />
            ) : null}
          </ActivityUploadImageContainer>
        )}
        <div id="set-grade" onClick={this.setGradeData}></div>
        <ActivityDescription>
          <ActivitySectionAdder>
            {this.state.readingEdit ? (
              <div>
                <div>
                  Grade :{" "}
                  {state.gradeSelected &&
                    state.gradeSelected.length>0 &&
                    state.gradeSelected.map((grade) => grade.name).join(", ")}
                </div>
              </div>
            ) : (
              //   <ActivityCreatorNavContainer>
              //     <ActivityGradeDropdownContainer>
              //       <ActivityGradeDropdown onClick={this.displayGradeMenu}>
              //         <ActivityDropdownLabelContainer>
              //           <ActivityDropdownLabel>
              //             {this.state.gradeSelected}
              //           </ActivityDropdownLabel>
              //           <img
              //             src={require("static/img/downarrowicon.png")}
              //             alt="down-arrow"
              //           />
              //         </ActivityDropdownLabelContainer>
              //       </ActivityGradeDropdown>
              //       {this.state.displayMenu ? (
              //         <ActivityGradeDropdownMenu>
              //           {gradeOptions}
              //         </ActivityGradeDropdownMenu>
              //       ) : null}
              //     </ActivityGradeDropdownContainer>
              //     <FreadomPointsInputField primary width={"10px"}>
              //       <input
              //         type="number"
              //         placeholder="Points"
              //         value={this.state.quiz_info.points}
              //         onChange={this.handleFreadomPoints}
              //       />
              //     </FreadomPointsInputField>
              //   </ActivityCreatorNavContainer>
              // )

              <RCHeaderDropDown
                active={this.state.gradeSelector}
                onBlur={() => this.handleGradeSelector(false)}
                tabIndex="1"
              >
                <div
                  style={{ fontSize: "1rem" }}
                  // onMouseDown={(e) => e.preventDefault()}
                  onClick={() =>
                    this.handleGradeSelector(!this.state.gradeSelector)
                  }
                >
                  {this.state.selectedGrades.length >= 11
                    ? "All "
                    : this.state.selectedGrades.length + " "}{" "}
                  Grades Selected
                  <Chevron>
                    <img
                      src={require("static/img/downarrowicon.png")}
                      alt="down-arrow"
                    />
                  </Chevron>
                </div>
                <RCHeaderDropDownMenu
                  maxHeight="150px"
                  active={this.state.gradeSelector}
                  color="#fff"
                  style={{ padding: "0px" }}
                >
                  {this.state.gradeData.map((gradeData, index) => (
                    <DropdownItem key={index}>
                      <FlexStart
                        style={{
                          width: "100%",
                          padding: "6px 8px",
                          position: "relative",
                          borderBottom: "solid 1px #e6e6e6",
                        }}
                        onClick={() => this.handleGradeChange(gradeData)}
                      >
                        <CheckBall
                          style={{ marginRight: "6px" }}
                          active={gradeData.selected || gradeData.clicked}
                        >
                          <CheckBallInner
                            active={gradeData.selected || gradeData.clicked}
                          />
                        </CheckBall>
                        {gradeData.name}
                      </FlexStart>
                    </DropdownItem>
                  ))}
                </RCHeaderDropDownMenu>
              </RCHeaderDropDown>
            )}

            {state.readingEdit ? (
              <span>
                {Permission.getPermission(permission, "change_quiz") ? (
                  <EditIcon
                    src={require("static/img/editicon.png")}
                    alt="edit icon"
                    className="image-fluid"
                    onClick={this.handleReadingEditClick}
                  />
                ) : null}
              </span>
            ) : (
              <ThemeButton primary onClick={this.handleDoneBtn}>
                Done
              </ThemeButton>
            )}
          </ActivitySectionAdder>
          <ActivityInputField
            primary
            placeholder="New Activity Here"
            border={
              state.readingEdit ? "none" : "solid 1px rgba(40,50,112,0.1)"
            }
            bb
            id="activityName"
            value={state.quiz_info.name}
            left={!state.readingEdit ? "6px" : "0px"}
            width={!state.readingEdit ? "75%" : "100%"}
            onChange={this.handleReadingNameChange}
            disabled={this.state.readingEdit}
          />

          <ActivityDescriptionTextArea
            primary={!state.readingEdit}
            id="activityDescription"
            disabled={state.readingEdit}
            onChange={this.handleDescriptionValue}
            value={state.quiz_info.description}
          />

          <CategorySection>
            {this.state.readingEdit ? (
              <CategoryName>
                <div>Category : {this.state.quiz_info.quiz_category_name}</div>
              </CategoryName>
            ) : (
              <CategoryDropDown
                active={this.state.categorySelector}
                onBlur={() => this.handleCategorySelector(false)}
                tabIndex="1"
              >
                <div
                  style={{ fontSize: "1rem" }}
                  // onMouseDown={(e) => e.preventDefault()}
                  onClick={() =>
                    this.handleCategorySelector(!this.state.categorySelector)
                  }
                >
                  {this.state.quiz_info.quiz_category_name == null
                    ? "Category"
                    : this.state.quiz_info.quiz_category_name}

                  <Chevron>
                    <img
                      src={require("static/img/downarrowicon.png")}
                      alt="down-arrow"
                    />
                  </Chevron>
                </div>
                <RCHeaderDropDownMenu
                  maxHeight="150px"
                  active={this.state.categorySelector}
                  color="#fff"
                  style={{ padding: "0px" }}
                >
                  {this.props.category.category_list.map(
                    (categoryData, index) => (
                      <DropdownItem key={index}>
                        <FlexStart
                          style={{
                            width: "100%",
                            padding: "6px 8px",
                            position: "relative",
                            borderBottom: "solid 1px #e6e6e6",
                          }}
                          onClick={() => this.handleQuizCategory(categoryData)}
                        >
                          {categoryData.name}
                        </FlexStart>
                      </DropdownItem>
                    )
                  )}
                </RCHeaderDropDownMenu>
              </CategoryDropDown>
            )}

            {Permission.getPermission(permission, "change_quiz") ? (
              <CategoryButton
                onClick={() => {
                  this.handleCategoryEdit();
                }}
              >
                + Create Category
              </CategoryButton>
            ) : null}
          </CategorySection>
        </ActivityDescription>

        {this.state.categoryEdit && (
          <div>
            <PopupBox>
              <Box>
                <Closeicon
                  className="close-icon"
                  onClick={() => this.handleCategoryBack()}
                >
                  x
                </Closeicon>
                <ActivityInputField
                  style={{ marginTop: "23px" }}
                  primary={!state.categoryEdit}
                  placeholder="Category name"
                  border={
                    !state.categoryEdit
                      ? "none"
                      : "solid 1px rgba(40,50,112,0.1)"
                  }
                  id="CategoryName"
                  value={this.state.addedCategory}
                  left={state.categoryEdit ? "6px" : "0px"}
                  width={!state.categoryEdit ? "75%" : "100%"}
                  onChange={this.handleCategoryChange}
                  disabled={!this.state.categoryEdit}
                />

                <SubmitButton primary onClick={this.handleSubmitBtn}>
                  Submit
                </SubmitButton>
              </Box>
            </PopupBox>
          </div>
        )}
      </ActivityDescriptionInfoContainer>
    );
  }
}

export default connectStore({
  activity,
  quiz2,
  onboard,
  category,
})(QuizInfo);

export default {
  fetch_quiz_list: false,
  active_quiz_grade: {},
  active_quiz: null,
  quiz_result: null,
  fetchingQuiz: false,
  quizList: [],
  active_quiz_grade: {},

  //MCQ CRUD
  questions: [],
  active_mcq: null,
};

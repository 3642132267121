import React, { Fragment } from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import { ModalBackDrop } from "static/styled/ModalBackDrop.styled";
import CreateAssessmentContainer from "@c/assessments-view/create-assessment-container/CreateAssessmentContainer";

export const Routes = function({ location }) {
  return (
    <Switch location={location}>
      <Route
        path="/assessments/create/:id"
        render={props => (
          <Fragment>
            <CreateAssessmentContainer {...props} />
            <ModalBackDrop active second />
          </Fragment>
        )}
      />
    </Switch>
  );
};

export default withRouter(Routes);

import React, { Component } from 'react'
import UsersOverlayRouter from "@r/usersOverlayRouter";


export default class MainContent extends Component {
    render() {
        return (
            <div>
                <UsersOverlayRouter />
                {/*<PlusButton second={this.state.showActivityCreator ? '10000' : '100'} onClick={this.handleAddClick}>*/}
                {/*{this.state.addModal || this.state.showActivityCreator ?*/}
                {/*<PlusIcon primary alt="Plus Icon" src={require("static/img/plusicon.png")}/> :*/}
                {/*<PlusIcon alt="PlusIcon" src={require("static/img/plusicon.png")}/>}*/}
                {/*</PlusButton>*/}
            </div>
        )
    }
}
import React, { Component } from "react";
import {
  MainBodyContainer,
  MainBodyContentContainer,
  PlusButton,
  PlusIcon
} from "static/styled/custom.styled";
import NotificationsHeader from "./notifications-header/NotificationsHeader";
import NotificationsRouter from "@r/notificationsRouter";
import { NavigationBodyContainer } from "./NotificationsView.styled";
import NotificationSideRouter from "@r/notificationSideRouter";
import NotificationSelectorModal from "./notification-selector-modal/NotificationSelectorModal";
import { connectStore } from "redux-box";
import { module as notification } from "@st/notification";
import { module as ui } from "@st/ui";
import { SubContainer } from "@sc/Creator.styled";
import { module as onboard } from "@st/onboard";
import Permission from "@s/permission";
import swal from "sweetalert2";


 class NotificationsView extends Component {
  componentDidMount() {
    let currentPath = window.location.pathname;
    let { notification } = this.props;
    notification.setSelectedNotType(this.props.match.params.type);

    if (currentPath.includes("/sent")) {
      notification.requestAllNotifications({ offset: 0, type: "sent" });
    } else if (currentPath.includes("/scheduled")) {
      notification.requestAllNotifications({ offset: 0, type: "scheduled" });
    } else if (currentPath.includes("/failed")) {
      notification.requestAllNotifications({ offset: 0, type: "failed" });
    } else {
      notification.requestAllNotifications({ offset: 0, type: "in-progress" });
    }
  }

  handleAddClickButton = () => {
    let { notification, history } = this.props;
    let type = this.props.match.params.type;
    let currentPath = window.location.pathname;
    const {permission} = this.props.onboard;
    if (currentPath.includes("create")) history.push(`/notifications/${type}`);
    else if (currentPath.includes("edit") && notification.active_notification) {
      notification.unsetNotificationData(true);
      notification.unsetDropdownData();
      history.push(`/notifications/${type}`);
    } else {
      if(Permission.getPermission(permission, "add_notification")){
        history.push("/notifications/scheduled/create");
      }else{
        swal({
          text: "You do not have permission.",
          type: "warning"
        });
      }
    }
  };

  render() {
    let currentPath = window.location.pathname;
    let isCreatorOpen =
      currentPath.includes("create") || currentPath.includes("edit");
   
    return (
      <NavigationBodyContainer>
        <MainBodyContainer style={{ width: "70%" }}>
          <NotificationsHeader ref={e => (this.nh = e)} />
          <MainBodyContentContainer
            style={{
              backgroundColor: "#f5f8fa",
              paddingRight: 0,
              paddingBottom: 0
            }}
          >
            <NotificationsRouter />
          </MainBodyContentContainer>
        </MainBodyContainer>
        <SubContainer active>
          <NotificationSideRouter />
        </SubContainer>
        <NotificationSelectorModal />
        
        <PlusButton
          onClick={this.handleAddClickButton}
          hideBtn={
            this.props.ui.show_modal === "PLAN" ||
            this.props.ui.show_notification_modal
          }
        >
          <PlusIcon
            primary
            alt="Plus Icon"
            cross={isCreatorOpen}
            src={require("static/img/plusicon.png")}
          />
        </PlusButton>
      </NavigationBodyContainer>
    );
  }
}
export default connectStore({
  notification,
  ui,
  onboard
})(NotificationsView)
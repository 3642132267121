export default {
    SET_ASSESSMENT_LIST: (state, {assessmentList}) => (state.assessmentList = assessmentList),
    SET_ACTIVE_ASSESSMENT:(state, {activeAssessment}) => (state.active_assessment = activeAssessment),
    SET_ACTIVE_ASSESSMENT_MCQ: (state, {mcq}) => (state.active_mcq = mcq),
    UPDATE_NEW_ASSESSMENT_QUESTION: (state, {question}) => (state.new_question = question),
    ADD_NEW_ASSESSMENT_OPTION:(state,{option}) => (state.new_options.push({name:option,__id:Math.random()}) ),
    REMOVE_NEW_ASSESSMENT_OPTION: (state, {optionId}) => {
        state.new_options = state.new_options.filter(option => option.__id !== optionId)
    },
    SET_ASSESSMENT_QUESTION: (state, { question }) => (state.question_set = question),
    NEW_ASSESSMENT_QUESTION: (state, { question }) => (state.new_question = question),
    SET_ALL_ASSESSMENT_QUESTION:(state, {question}) => (state.questions = question),
    UNSET_ACTIVE_ASSESSMENT:(state, {activeAssessment}) => (state.active_assessment = activeAssessment),
    UNSET_ALL_ACTIVE_ASSESSMENT_QUESTION:(state, {question}) => (state.questions = question),
    SET_FETCH_ASSESSMENT_LIST:(state,{value})=>(state.fetch_assessment_list=value),
}

import { Api } from "./base";

const _headers = () => {
  return {
    Authorization:
      "JWT " + require("store").default.getState().onboard.user.auth_token,
    "Content-Type": "application/json"
  };
};

export default {
  createLevelTest(data) {
    return Api.post(`/assessment/v1/admin-level-test/`, data, _headers());
  },
  getLevelTests() {
    return Api.get(`/assessment/v1/admin-level-test/`, _headers());
  },
  getLevelTestById(assessmentId) {
    return Api.get(
      `/assessment/v1/admin-level-test/${assessmentId}/`,
      _headers()
    );
  },
  createQuestion(data) {
    return Api.post(`/assessment/v1/admin-question/`, data, _headers());
  },
  deleteQuestion(questionId) {
    return Api.deleteCall(
      `/assessment/v1/admin-question/${questionId}/`,
      _headers()
    );
  },
  updateQuestion(questionId, data) {
    return Api.put(
      `/assessment/v1/admin-question/${questionId}/`,
      data,
      _headers()
    );
  },
  updateOption(optionId, data) {
    return Api.put(
      `/assessment/v1/admin-option/${optionId}/`,
      data,
      _headers()
    );
  },
  getAllQuestion(levelId) {
    return Api.get(
      `/assessment/v1/admin-question/?level_test=${levelId}`,
      _headers()
    );
  },
  updateAssessment(assessmentId, data) {
    return Api.put(
      `/assessment/v1/admin-level-test/${assessmentId}/`,
      data,
      _headers()
    );
  }
};

import styled from "styled-components";

export const NavigationBodyContainer=styled.div`
 display:flex;
 width:100%;
 justify-content:space-between;
 flex-wrap:nowrap;
`;

export const CreateNotificationMainContainer=styled.div`
height:100%;
  border-radius: 10px;
  background: white;
 min-height: 20px;
 position:relative;
 max-height: calc(100vh - 110px);
    
    
   
  
`;


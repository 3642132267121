import React, { Component } from "react";
import { connectStore } from "redux-box";
import { module as inviteCode } from "store/inviteCode";
import { module as activity } from "store/activity";
import { module as ui } from "store/ui";
import Table from "@c/common/table";
import Select from "react-dropdown-select";
import {
  CheckBox,
  PublishContainer
} from "static/styled/custom.styled";

import {CardContainer, CardTitle,CardBody,Item} from "./SchoolCode.styled";
import SchoolView from "../school-view";
import { module as onboard } from "@st/onboard";
import Permission from "@s/permission";

const schoolCodeCol= [
  {
    name: "School Name",
    value: "name",
    type: "TEXT"
  },
  {
    name: "School Code",
    value: "school_code",
    type: "TEXT"
  },
  {
    name: "Used Count",
    value: "used_count",
    type: "TEXT"
  },
  {
    name: "Trial Expiry",
    value: "trial_expiry_date",
    type: "DATE"
  },
  {
    name: "Update",
    value: "edit",
    type: "ACTION"
  }
];



class SchoolCode extends Component {
  constructor(props) {
    super(props);
    this.state = {
      schoolName: "",
      schoolList:[],
      searchOption:"name",
      selectedSchool:null,
      edit:false,
      editSchool:null
    };
  }

  componentDidMount() {
    let { inviteCode } = this.props;
    inviteCode.requestSchoolCodeList();
  }

  componentWillReceiveProps(nextProps){

    if(nextProps.inviteCode.searchSchoolResult.length > 0){
      this.setState({schoolList:nextProps.inviteCode.searchSchoolResult})
    }
  }

  handleSchoolChange = (e) => {
    this.setState({
      schoolName: e.target.value
    })
  }

  generateCode = () => {
    let { inviteCode } = this.props;
    inviteCode.createSchoolCode({name: this.state.schoolName});
  }

  handleNext = () => {
    const {permission} = this.props.onboard;
    //if(Permission.getPermission(permission, "change_schoolcode")){
      let { inviteCode } = this.props;
      if (inviteCode.schoolCodesLength> inviteCode.schoolCodes.length) {
        inviteCode.requestSchoolCodesByOffset(
          inviteCode.schoolCodesOffset+1
        );
      }
    //}
  }

  searchSchool=(evt)=>{
    let { inviteCode } = this.props;
    if(this.state.searchOption == 'name'){
      let data = {name:evt.state.search}
      inviteCode.requestSchoolSearch(data)
    }else{
      let data = {code:evt.state.search}
      inviteCode.requestSchoolSearch(data)
    }
   
  }
  seletecSchool=(value)=>{

    if(value){
      this.setState({selectedSchool:value[0]})
    }
   
  }
  
  handleToggle = (value) => {
  
   this.setState({searchOption:value})
  };
  schoolView= (value) => {
    console.log('edit',value)
    console.log(value,this.props)
    this.props.inviteCode.setSelectedSchool(value)
    setTimeout(() => {
      this.props.ui.showModal("SCHOOLEDIT");
    }, 10);
  }
  customItemRenderer = ({ item, itemIndex, props, state, methods }) => (
    <Item>
      <div onClick={() => methods.addItem(item)}>
        {item.label} ({item.value})
      </div>
    </Item>
  );

  render() {
    const {permission} = this.props.onboard;
    const demoOptions = this.state.schoolList.map((option) => ({
      ...option,
      label: option.name,
      value: option.school_code
    }));
    return (
      <div >
        <div className="container">
          <div className="card m-4">
            <div className="card-body">
            
            <PublishContainer>
              <CheckBox active={this.state.searchOption == 'name'} onClick={() =>this.handleToggle('name')}>
                  <i className="fa fa-check" />
                </CheckBox>
                <span style={{color:"black"}}>
                  {" "}School Name{" "}
                </span>
                &nbsp; &nbsp;
                <CheckBox active={this.state.searchOption == 'code'} onClick={() =>this.handleToggle("code")}>
                  <i className="fa fa-check" />
                </CheckBox>
                <span style={{color:"black"}}>
                  {" "}School Code{" "}
                </span>
            </PublishContainer> 
            <Select options={demoOptions} 
              searchFn={(values) =>this.searchSchool(values)}
              onChange={(values) =>this.seletecSchool(values)} 
              itemRenderer={this.customItemRenderer}
              />
              <br/>
              {this.state.selectedSchool ? 
                <CardContainer>
                    <CardTitle primary>
                      <div style={{display:"flex",justifyContent:"space-between"}}> {this.state.selectedSchool.school_code !== null
                        ? <span>
                            School Name: {this.state.selectedSchool.name}
                          </span>
                        :<span>
                            School Name: {" "}
                          </span>}
                        <i onClick={()=>{this.schoolView(this.state.selectedSchool)}} className={"mx-1 p-1 bg-light rounded fa fa-edit"}></i>
                      </div>
                    </CardTitle>
                    <CardBody>
                      <div>School Code: {this.state.selectedSchool.school_code !== null ?
                          <span>
                          {this.state.selectedSchool.school_code}
                          </span>:"-"
                        } </div>
                      <div>Used Count: {this.state.selectedSchool.used_count !== null ?
                          <span>
                          {this.state.selectedSchool.used_count}
                          </span>:"-"
                        }</div>
                      <div>Trial Expiry Date: {this.state.selectedSchool.trial_expiry_date !== null ?
                          <span>
                          {this.state.selectedSchool.trial_expiry_date}
                          </span>:"-"
                        }</div>
                        
                      </CardBody>
                </CardContainer>:null}

              <br/>
              { Permission.getPermission(permission, "add_schoolcode")?
              <div>
                <div className="input-group mb-3">
                  <div className="input-group-prepend">
                    <span className="input-group-text" id="basic-addon1">Enter School Name</span>
                  </div>
                  <input type="text" className="form-control" 
                  placeholder="School Name" aria-label="schoolName" 
                  aria-describedby="basic-addon1"
                  value={this.state.schoolName}
                  onChange={this.handleSchoolChange}/>
                </div>
                <div className="text-center">
                  <button type="button" 
                  className="btn btn-primary"
                  onClick={this.generateCode}>
                    Generate Code
                  </button>
                </div>
              </div>:null}
            </div>
          </div>
          { Permission.getPermission(permission, "view_schoolcode")?
          <div>
            <div className="mx-4 mt-4 py-2">
              <Table colData={schoolCodeCol} schoolView={this.schoolView} tableData={this.props.inviteCode.schoolCodes} />
            </div>
            <div>
            { (this.props.inviteCode.schoolCodesLength> this.props.inviteCode.schoolCodes.length) 
              &&
              (<div className="text-center pb-4" onClick={this.handleNext} style={{cursor: 'pointer'}}>
                Load More <i className="fa fa-angle-down" />
              </div>)
            }
            </div> 
          </div>:null}
          {this.state.edit?
          <div>
            <SchoolView/>
          </div>:null}
        </div>
       
      </div>
    );
  }
}
export default connectStore({
  inviteCode,
  activity,
  ui,
  onboard
})(SchoolCode)
import React from "react";
import { Route, Switch } from "react-router-dom";
import Login from "@c/login/Login";

export default function(props) {
  return (
    <Switch>
      <Route path="/" component={Login} />
    </Switch>
  );
}

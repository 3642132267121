import React, {Component} from 'react';
import {
    NotificationCardMainContainer, NotificationContent, NotificationDetailsContainer, NotificationImageContainer,
    NotificationItemCard,
    NotificationType, ScheduleDetailsContainer, ItemContainer, NotificationToolbar, NotificationBorder
} from './NotificationCard.styled';
import {FlexBetween} from "static/styled/custom.styled";
import swal from "sweetalert2"
import {connectStore} from "redux-box";
import {module as notification} from "store/notification";
import { module as onboard } from "@st/onboard";
import Permission from "@s/permission";



class NotificationCard extends Component
{

    constructor(props)
    {
        super(props);
        this.state=({
            date:'',
            time:'',
        })
    }
    getDate=()=>{
        let {notificationInfo}=this.props;
        let date = new Date(notificationInfo.scheduled_time);
        
            return date;   
        }
    


    componentDidMount()
    {
        let {notificationInfo}=this.props;
        
        let dateIST=new Date(notificationInfo.scheduled_time);
        let months=['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
        let date=dateIST.getDate()+' '+months[dateIST.getMonth()]+' '+dateIST.getFullYear();
        let hours=dateIST.getHours()<=12?dateIST.getHours():dateIST.getHours()===0?12:dateIST.getHours()-12;
        let format=dateIST.getHours()<12?'AM':'PM';
        let min=dateIST.getMinutes()<10?'0'+dateIST.getMinutes():dateIST.getMinutes();
        let time=hours +":"+min+' '+format;
        this.setState({
            date:date,
            time:time,
        })
    }

    handleDeleteClick=(evt,notificationData)=>{
        
        evt.preventDefault();
        evt.stopPropagation();
        //this.props.activity.closeEditor();
        swal({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
          }).then((result) => {
            if (result.value) {
                let {notification}=this.props;
                if(notification.active_notification && notification.active_notification.id===notificationData.id)
                notification.setActiveNotification(null);
                notification.requestDeleteNotification(notificationData.id)
            }
          });
        
        

    };

    render(){
        let {notificationInfo}=this.props;
        const {permission}= this.props.onboard;
        return(
          <NotificationCardMainContainer>
                   <NotificationItemCard width="52%" flexFlow={'row nowrap'} padding="0px">
                       <NotificationImageContainer>
                           <img src={notificationInfo.image===''?require("static/img/activity-bg.png"):notificationInfo.image} alt="notification-card"/>
                           <NotificationType>
                               {notificationInfo.notification_type.toUpperCase()}
                           </NotificationType>
                       </NotificationImageContainer>
                       <NotificationDetailsContainer>
                           <NotificationContent
                               fontSize={'var(--md-font-size)'}
                               opacity="1.0"
                               fontWeight="500"> {notificationInfo.subject}</NotificationContent>
                           <NotificationContent
                               fontSize={'var(--xsm-font-size)'}
                               opacity="0.4"
                               fontWeight="normal">
                           {notificationInfo.notification_type!=='email'?notificationInfo.body:''}
                           </NotificationContent>
                       </NotificationDetailsContainer>
                       </NotificationItemCard >
                       <NotificationBorder/>
                 <NotificationItemCard width="46%" flexFlow={'column nowrap'} padding="12px">
                     <ScheduleDetailsContainer fontSize={'var(--sm-font-size)'}> {this.state.date +'  |  ' + this.state.time} </ScheduleDetailsContainer>
                     {notificationInfo.filter_type==='all' && 
                     <FlexBetween style={{marginTop:'12px',justifyContent:'flex-end'}}>
                         <ItemContainer>
                            <NotificationContent color="#ff8a23" opacity="1" fontSize="16px"> 
                                { this.props.notificationInfo.custom_user_ids && this.props.notificationInfo.custom_user_ids.length===0?
                                    'All':notificationInfo.custom_user_ids.length}
                            </NotificationContent>
                            <NotificationContent  opacity="0.4" fontSize={'var(--xsm-font-size)'} style={{marginLeft:'6px'}}> Children </NotificationContent>
                            </ItemContainer>
                            </FlexBetween>}

                            {notificationInfo.filter_type.includes('wow') && 
                     <FlexBetween style={{marginTop:'12px',justifyContent:'flex-end'}}>
                     {this.props.notificationInfo.school_name && this.props.notificationInfo.school_name.length===1 && <ItemContainer>
                         <NotificationContent color="#ff8a23" opacity="1" fontSize="16px"> 
                                {notificationInfo.school_name[0].name}
                            </NotificationContent>
                            <NotificationContent  opacity="0.4" fontSize={'var(--xsm-font-size)'} style={{marginLeft:'6px'}}>School</NotificationContent>
                            </ItemContainer>}
                         <ItemContainer>
                         <NotificationContent color="#ff8a23" opacity="1" fontSize="16px"> 
                                { (this.props.notificationInfo.filter_type_ids && this.props.notificationInfo.filter_type_ids.length===0) || this.props.notificationInfo.filter_type==='wow-school'?
                                    'All':notificationInfo.filter_type_ids.length}
                            </NotificationContent>
                            <NotificationContent  opacity="0.4" fontSize={'var(--xsm-font-size)'} style={{marginLeft:'6px'}}>Sections</NotificationContent>
                            </ItemContainer>
                            <ItemContainer>
                            <NotificationContent color="#ff8a23" opacity="1" fontSize="16px"> 
                                { this.props.notificationInfo.custom_user_ids && this.props.notificationInfo.custom_user_ids.length===0?
                                    'All':notificationInfo.custom_user_ids.length}
                            </NotificationContent>
                            <NotificationContent  opacity="0.4" fontSize={'var(--xsm-font-size)'} style={{marginLeft:'6px'}}> Children </NotificationContent>
                            </ItemContainer>
                            </FlexBetween>
                        }
                            {notificationInfo.filter_type.includes('freadom') && 
                     <FlexBetween style={{marginTop:'12px',justifyContent:'flex-end'}}>
                         <ItemContainer>
                            <NotificationContent color="#ff8a23" opacity="1" fontSize="16px"> 
                                { this.props.notificationInfo.filter_type_ids && this.props.notificationInfo.filter_type_ids.length===0?
                                    'All':notificationInfo.filter_type_ids.length}
                            </NotificationContent>
                            <NotificationContent  opacity="0.4" fontSize={'var(--xsm-font-size)'} style={{marginLeft:'6px'}}>{notificationInfo.filter_type==='freadom-grade'?'Grades':'Levels'} </NotificationContent>
                            </ItemContainer>
                         <ItemContainer>
                            <NotificationContent color="#ff8a23" opacity="1" fontSize="16px"> 
                                { this.props.notificationInfo.custom_user_ids && this.props.notificationInfo.custom_user_ids.length===0?
                                    'All':notificationInfo.custom_user_ids.length}
                            </NotificationContent>
                            <NotificationContent  opacity="0.4" fontSize={'var(--xsm-font-size)'} style={{marginLeft:'6px'}}> Children </NotificationContent>
                            </ItemContainer>
                           </FlexBetween>}

    
                     { notificationInfo.status==='pending' && <NotificationToolbar>
                         {Permission.getPermission(permission, "change_notification")?
                         <img src={require("static/img/edit-button-blue.png")}
                              className="image-fluid"
                              alt="Edit icon"/>:null}
                         {Permission.getPermission(permission, "delete_notification")?
                         <img src={require("static/img/trash.png")}
                              className="image-fluid" alt="Delete icon"
                              onClick={evt=>{this.handleDeleteClick(evt,notificationInfo)}}/>
                        :null}
                     </NotificationToolbar> }
                </NotificationItemCard>
          </NotificationCardMainContainer>
        )
    }
}
export default connectStore({
    notification,onboard
})(NotificationCard)
import React, { Component } from 'react';
import { connectStore } from "redux-box";
import { module as payment } from "@st/payment";
import { PaymentsContainer, PaymentListContainer, PaymentItem, PaymentsHeader, PaymentDataContainer, PaymentHeading} from './ChildPaymentPopUp.styled';
import { PlanLeftContainer, PlanHeading, PlanRightContainer } from "@c/users-view/child-profile/child-subscription/ChildSubscription.styled"
import InfiniteScroll from 'react-infinite-scroller';
import {
    InfiniteScrollLoader
} from "@sc/custom.styled";

 class ChildPaymentPopUp extends Component {



    componentDidMount() {

    }


    componentWillUnmount() {
        let { payment } = this.props;
        payment.setSubPaymentList([]);
    }


    convertDateFormat(inputDate) {
        let months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        let date = new Date(inputDate);
        let newDate = date.getDate() + " " + months[date.getMonth()] + " " + date.getFullYear();
        let hours = date.getHours() <= 12 ? date.getHours() : date.getHours() === 0 ? 12 : date.getHours() - 12;
        let format = date.getHours() < 12 ? 'AM' : 'PM';
        let min = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
        let time = hours + ":" + min + ' ' + format;
        newDate += (', ' + time);
        return newDate;
    }

    fetchMorePayments = () => {
        let { payment } = this.props;
        if(payment.sub_payment_list_result!==null && payment.sub_payment_list_result.has_next)
        { //// console.log("hello");
            setTimeout(() => {
                if(this.props.payment.sub_payment_list_result!==null && this.props.payment.sub_payment_list_result.next_page>0)
                    this.props.payment.requestSubPaymentList({offset:this.props.payment.sub_payment_list_result.next_page,concat:true});
            }, 300);}

    }



    render() {
        let { payment } = this.props;
        let paymentList = payment.sub_payment_list && payment.sub_payment_list.length > 0 &&
            payment.sub_payment_list.map((data, index) =>
                <PaymentItem key={index}>
                    <PlanLeftContainer>
                        <PaymentHeading fontSize="16px" style={{ fontWeight: '500' }} > Trans Id :  {data.pg_type === 'razorpay' ? data.razorpay_payment_id : data.txn_id}</PaymentHeading>
                        <PaymentDataContainer>
                            <PaymentHeading fontSize="14px" style={{ margin: 0 }}>Mode: {data.pg_type.slice(0, 1).toUpperCase().concat(data.pg_type.slice(1))} </PaymentHeading>
                            <PaymentHeading fontSize="14px"> | Date : {this.convertDateFormat(data.created_at)} </PaymentHeading>

                        </PaymentDataContainer>
                    </PlanLeftContainer >
                    <PlanRightContainer style={{ padding: 0 }}>

                        <PaymentHeading> <span>  {`${data.currency} ${data.amount}`} </span> Amount  </PaymentHeading>
                        <PaymentDataContainer>

                            <PaymentHeading color={data.status === 'success' ? '#20bf6b' : '#fc5c65'}> Status   : <span>{data.status.toUpperCase()} </span> </PaymentHeading>
                        </PaymentDataContainer>
                    </PlanRightContainer>

                </PaymentItem>)
        return (
            <PaymentsContainer>
                <PaymentsHeader onClick={this.handleActiveList}>
                    <PlanHeading color="#000" fontSize="20px"> <span style={{ color: '#ee8a23', fontSize: '20px' }}>{payment.active_subscription && payment.active_subscription.plan && payment.active_subscription.plan.name} </span> | Payments </PlanHeading>

                </PaymentsHeader>
                <PaymentListContainer >
                    <InfiniteScroll
                        pageStart={0}
                        style={{ position: 'relative' }}
                        loadMore={this.fetchMorePayments}
                        hasMore={this.props.payment.sub_payment_list_result && this.props.payment.sub_payment_list_result.has_next}
                        loader={<InfiniteScrollLoader className="loader" key={0} > <img alt="loading" width="50" height="50" src={require("static/img/Theme-loader.gif")} /> </InfiniteScrollLoader>}
                        useWindow={false}

                    >
                        {paymentList}
                    </InfiniteScroll>
                </PaymentListContainer>
            </PaymentsContainer>
        )
    }
}
export default connectStore({
    payment
})(ChildPaymentPopUp)
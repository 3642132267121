import styled from "styled-components";

export const ActivityPackCardContainer=styled.div`    
    background:${props=>`url('${props.image}')`};
    position:relative;
    min-height: 190px;
    border-radius: 10px;
    background-repeat: no-repeat;   
    background-size:cover;
    background-position: center;
    max-width:100%;
    cursor:pointer;
    color: white;
    margin-bottom:20px;
    transition:all 0.3s;
    &:hover{
    box-shadow: 0px 3px 10px -2px rgba(0,0,0,0.5);
    }
    &:after{
    position:absolute;
    left:0;
    top:0;
    border-radius:10px;
    background:rgba(0,0,0,0.5);
    width:100%;
    height:100%;
    z-index: 1;
    content:'';
 }
`;

export const ActivityPackContainer=styled.div`
    position:absolute;
    left:0;
    top:0;
    padding: 10px;
    width:100%;
    height:100%;
    z-index: 2;
`;

export const ActivityPackTitle=styled.div`
    padding:5px 0;
    font-size: var(--lg-font-size);
    font-weight: 600;
    margin-top:30px;
`;

export const ActivitySubHeading=styled.div`
    padding:0;
    font-weight:400;
    font-size:14px;
`;



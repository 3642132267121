import styled from "styled-components";
import {FlexCenter} from "@sc/custom.styled";
export const NavigationHeaderContainer = styled.div`
         display: flex;
         justify-content: space-between;
         align-items: center;
         flex-flow: row nowrap;
         border-radius: ${(props) =>
           props.shadow ? "6px 6px 0px 0px" : "6px"};
         background-color: #ffffff;
         padding: 10px;
         box-shadow: ${(props) =>
           props.shadow ? "0px 2px 3px 0px rgba(0,0,0,0.3)" : ""};
         position: relative;
         z-index: ${(props) => (props.zIndex ? props.zIndex : "")};
       `;


export const NavigationContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
a {
    color: black;
    cursor:pointer;
    text-align: center;
    text-decoration: none;
    opacity: 0.4;
    font-size: ${props=>props.primary?"var(--md-font-size)":"var(--lg-font-size)"};
    font-weight: 500;
    border-bottom:2px solid  #fff;
    padding:5px 10px;
    margin-right: 10px;
    transition:0.3s;
    &:hover{
    color: #281045;
    opacity: 1;
    }
}
.active{
    color: #281045;
    border-bottom:2px solid  #ff9e31;
    opacity: 1;
}
`;

export const FixedNav = styled.div`
position:absolute;
top:0;
background:white;
left:0;
width:100%;
z-index: 10;
box-shadow: ${props => props.shadow ? '0px 1px 5px 0px rgba(0,0,0,0.3)': ''};
`;


export const NavigationToolbar = styled.div`
    display:flex;
    flex-flow:row wrap;
    justify-content: flex-end;
    align-items:center;
    position:relative;
    `;


export const NavigationBar=styled.div`
    margin-bottom:${props=>props.primary? '0': '8px'};
    ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
}
li {
    float: left;
    margin-left: 10px;
}

.navbar-inactive{
     color: rgba(40, 16, 69,0.4);
}
.navbar-active {
border-bottom:2.4px solid  #ff9e31;
    &:hover{
    border:0px 0px 2.4px 0px solid #ff9e31;
    }
}
li span {
    display: block;
    text-align: left;
    text-decoration: none;
    cursor:pointer;
    font-size: var(--lg-font-size);
    font-weight: 500;
    padding: 10px;
}
li a span{
color:rgba(0,0,0,0.4);
}
li a:hover span{
 color:rgba(40,16,69,1.0);
 }

li span:hover {
    border-bottom:${props=>props.border};
    opacity: 1;
    color:rgba(40, 16, 69,1.0);
}
    `;

export const RefreshBtn=FlexCenter.extend`
 border-radius:100px;
 padding:0px;
 width:28px;
 height:28px;
cursor:pointer;

 background-color:${props=>props.active?"#ee8a23":"rgba(0,0,0,0.2)"};

 i {
     color:white;
     transition:${props=>props.active?"all 0.5s ease-in":"all 0s ease-in"} ;
     transform:${props=>props.active?"rotate(180deg)":"rotate(-180deg)"};

 }

 span{
     width:100%;
     text-align:center;
     height:100%;

}
 `


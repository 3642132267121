import React, {Component} from 'react';
import {NotificationListContainer} from './ScheduledNotificationsList.styled';
import NotificationCard from "./notification-card/NotificationCard";
import { connectStore } from 'redux-box';
import {module as notification} from "store/notification";
import {Link} from "react-router-dom";
import InfiniteScroll from 'react-infinite-scroller';
import {NotificationScrollParentContainer, InfiniteScrollLoader, LoadingContainer, EmptyContainer} from "static/styled/custom.styled"



class ScheduledNotificationsList extends Component
{

    handleLoadNotification=(notificationData)=>{
        let {notification}=this.props;
        if(notification.enableDoneBtn)
        notification.setEnableDoneBtn(false);
        if(notification.active_notification!==null)
        notification.unsetDropdownData();
        notification.requestNotificationById(notificationData.id);
    };

    fetchMoreNotifications=()=>{
       // let {notification}=this.props;
        if(this.props.notification.notification_result!==null && this.props.notification.notification_result.has_next)
        { //// console.log("hello");
            setTimeout(() => {
                if(this.props.notification.notification_result!==null && this.props.notification.notification_result.next_page>0)
                    this.props.notification.requestConcatAllNotifications(
                        {offset:this.props.notification.notification_result.next_page,
                            type:"scheduled"
                        });

            }, 300);}
    }
    

  
    render(){
        let {notification}=this.props;
        let notificationList=notification.notification_list && notification.notification_list.length>0 &&
        notification.notification_list.map((notificationInfo,index)=>
        <Link to={"/notifications/scheduled/edit/"+ notificationInfo.id}  key={index} onClick={()=>this.handleLoadNotification(notificationInfo)}>
        <NotificationCard notificationInfo={notificationInfo} 
        key={index} />
        </Link>);

        return(<div>
            {this.props.notification.fetch_notification_list?<LoadingContainer style={{zIndex:'100'}}>
                    <img alt="loading" width="60" height="60" src={require("static/img/Theme-loader.gif")} />
                    </LoadingContainer>: (notification.notification_list && notification.notification_list.length>0 ?
            <NotificationScrollParentContainer>
                    <InfiniteScroll
                    pageStart={0}
                    loadMore={this.fetchMoreNotifications}
                    style={{position:'relative'}}
                    hasMore={this.props.notification.notification_result && this.props.notification.notification_result.has_next}
                    loader={<InfiniteScrollLoader className="loader" 
                    style={{bottom:0}}
                    key={0} > <img alt="loading" width="60" height="60" src={require("static/img/Theme-loader.gif")}/> </InfiniteScrollLoader>}
                    useWindow={false}>
          <NotificationListContainer>
              {notificationList}
          </NotificationListContainer>
          
          </InfiniteScroll>
          </NotificationScrollParentContainer>:<EmptyContainer>
                       <h5 style={{opacity:0.5}}> 
                       <img src={require("static/img/notification_icon.png")}  alt="bell" width="24" height="24"/> NO NOTIFICATIONS ADDED</h5>
                   </EmptyContainer>)}
          </div>
        )
    }
}
export default connectStore({
    notification
})(ScheduledNotificationsList)
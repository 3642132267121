export default {
  // FETCH_QUIZ2_LIST: (state, { quizList }) => {
  //   state.quizList = quizList;
  // },
  SET_ACTIVE_QUIZ_GRADE: (state, { grade }) => {
    state.active_quiz_grade = grade;
  },
  SET_ACTIVE_QUIZ: (state, { activeQuiz }) => (state.active_quiz = activeQuiz),

  SET_QUIZ_LIST: (state, { quizList }) => (state.quizList = quizList),
  SET_FETCH_QUIZ_LIST: (state, { value }) => (state.fetch_quiz_list = value),
  SET_QUIZ_RESULT: (state, { result }) => (state.quiz_result = result),

  SET_CONCAT_QUIZ_LIST: (state, { quizList }) =>
    (state.quizList = state.quizList.concat(quizList)),

  SET_ACTIVE_QUIZ_MCQ: (state, { mcq }) => (state.active_mcq = mcq),
  FETCH_QUIZ: (state, { value }) => (state.fetchingQuiz = value),
  SET_ALL_QUIZ_QUESTIONS: (state, { questions }) =>
    (state.questions = questions),
  UNSET_ACTIVE_QUIZ_QUESTION: (state) => {
    state.questions = [];
    state.active_mcq = [];
  },
};

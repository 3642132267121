import React, { Component } from "react";
import {
  Container,
  CreateMcqContainer,
  AssessmentsHeader,
  GradeSelectionContainer,
  AssessmentLabels,
  McqListContainer,
  McqContainer
} from "./CreateAssessment.styled";
import {
  ThemeButton,
  McqSectionHeader,
  LoadingContainer
} from "static/styled/custom.styled";

import store from "store";
import action from "@st/activity/actions";
import { connectStore } from "redux-box";
import McqCard from "./mcq-card/McqCard";
import { module as activity } from "@st/activity";
import { module as assessment } from "@st/assessment";

import {
  SectionHeading,
  SectionNameEditable
} from "@c/activity-view/activity-creator/activity-info/ActivityInfo.styled";
import { SubHeading } from "@sc/custom.styled";
import { FixedNav } from "static/styled/NavigationBar.styled";
import { module as onboard } from "@st/onboard";
import Permission from "@s/permission";
// export default connectStore({
//   activity,
//   assessment
// })
class CreateAssessment extends Component {
  constructor(props) {
    super();
    this.state = {
      gradeSelected: "Select Grade",
      editEnabled: false,
      displayMenu: false,
      level: {
        name: ""
      }
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.assessment && nextProps.assessment.active_assessment) {
      this.normalizeForState(nextProps.assessment.active_assessment);
    } else {
      this.setState({
        level: {
          ...this.state.level,
          name: "hello"
        }
      });
    }
  }

  normalizeForState = assessment => {
    this.setState({
      level: {
        ...this.state.level,
        name: assessment.name
      }
    });
  };

  componentWillUnmount() {
    this.props.assessment.setActiveAssessment(null);
  }

  handleGradeSelected = newgrade => e => {
    this.setState({
      displayMenu: !this.state.displayMenu,
      gradeSelected: newgrade.name
    });
  };
  displayGradeMenu = e => {
    this.setState({
      displayMenu: !this.state.displayMenu
    });
  };

  editEnabled = () => {
    this.setState({
      editEnabled: !this.state.editEnabled
    });
  };

  handleLevelName = evt => {
    this.setState({
      level: {
        ...this.state.level,
        name: evt.target.value
      }
    });
  };

  handleLevelUpdate = evt => {
    if (evt.key === "Enter") {
      this.props.assessment.requestUpdateAssessment(this.state.level);
      this.setState({
        editEnabled: false
      });
    }
  };

  handleSaveChangesBtn = () => {
    this.props.assessment.requestUpdateAssessment(this.state.level);
    this.setState({
      editEnabled: false
    });
  };

  render() {
    const { assessment } = this.props;
    const {permission} = this.props.onboard;
    return (
      <Container>
        {!assessment.active_assessment ? (
          <LoadingContainer>
            <div>
              <img
                width="60"
                height="60"
                alt="loader"
                src={require("static/img/Theme-loader.gif")}
              />
            </div>
          </LoadingContainer>
        ) : (
          <FixedNav shadow style={{ padding: "10px 20px" }}>
            <AssessmentsHeader>
              {this.state.editEnabled ? (
                <SectionHeading
                  width={"45%"}
                  placeholder="Enter Level Name"
                  type="text"
                  value={this.state.level.name}
                  autoFocus
                  onChange={this.handleLevelName}
                  onKeyDown={this.handleLevelUpdate}
                />
              ) : (
                <SectionNameEditable>
                  <SubHeading
                    primary
                    style={{ minWidth: "160px" }}
                    width={(this.state.level.name.length + 1) * 10 + "px"}
                  >
                    {this.state.level.name}
                  </SubHeading>
                  { Permission.getPermission(permission, "change_activity")?
                  <img
                    src={require("static/img/editicon.png")}
                    alt="edit icon"
                    className="image-fluid"
                    onClick={this.editEnabled}
                  />:null}
                </SectionNameEditable>
              )}
              <ThemeButton primary onClick={this.handleSaveChangesBtn}>
                Save Changes
              </ThemeButton>
            </AssessmentsHeader>
            <GradeSelectionContainer>
              <AssessmentLabels> Level </AssessmentLabels>
              {assessment.active_assessment.grade_level
                ? assessment.active_assessment.grade_level
                : ""}
            </GradeSelectionContainer>
            <McqSectionHeader style={{ marginTop: "10px" }}>
              Create MCQ
              { Permission.getPermission(permission, "change_activity")?
              <ThemeButton
                onClick={() => {
                  this.props.assessment.setActiveAssessmentMcq(null);
                  store.dispatch(action.setEditorType("createQA"));
                }}
              >
                + New question
              </ThemeButton>:null}
            </McqSectionHeader>
          </FixedNav>
        )}

        <CreateMcqContainer>
          <McqListContainer style={{ padding: "0 20px" }}>
            {this.props.assessment.questions &&
              this.props.assessment.questions.map((data, index) => (
                <McqContainer key={index}>
                  <AssessmentLabels> {index + 1} </AssessmentLabels>
                  <McqCard question_data={data} />
                </McqContainer>
              ))}
          </McqListContainer>
        </CreateMcqContainer>
      </Container>
    );
  }
}

// export default CreateAssessment;
export default connectStore({
  activity,
 assessment,
 onboard
 })(CreateAssessment)
import React, { Component } from 'react';
import {
    UserDetailsContainer, UserDetailValueLabels, UserField2, UserFieldName, UserListLoadingContainer, Pagination, PaginationButton
} from "@c/users-view/MainContent.styled";
import { AutoContainer, ListContainer, EmptyDataContainer } from "@sc/custom.styled";
import { module as payment } from '@st/payment';
import { connectStore } from "redux-box";
import PaymentCard from "./payments-card/PaymentsCard"


 class PaymentsList extends Component {

    handleNext = () => {
        let { payment } = this.props;
        if (payment.payment_list_result && payment.payment_list_result.has_next) {
            payment.requestPaymentList(payment.payment_list_result.next_page);
        }

    };
    handlePrevious = () => {
        let { payment } = this.props;
        if (payment.payment_list_result.prev_page >= 0 && payment.payment_offset !== 0) {
            payment.requestPaymentList(payment.payment_list_result.prev_page);
        }
    };
    render() {
        let { payment } = this.props;
        // let children_list = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13.14];
        let children = (payment.payment_list_result && payment.payment_list_result.result
            && payment.payment_list_result.result.length > 0 ?
            payment.payment_list_result.result.map((data, index) =>
                <PaymentCard paymentInfo={data} key={index} />
            ) : <EmptyDataContainer>
                <i className="fa fa-credit-card" />
                <h3 style={{ color: "rgba(0,0,0,0.6)" }}>No Payment Records Added</h3>
            </EmptyDataContainer>);
        return (
            <UserDetailsContainer>
                <AutoContainer style={{ padding: "0" }}>
                    <UserDetailValueLabels>
                        <UserFieldName primary style={{ width: '14%' }}>Child Id
                            </UserFieldName>
                        <UserFieldName primary>Child Name
                            </UserFieldName>
                        <UserField2 primary> Phone number
                            </UserField2>
                        <UserField2 primary>
                            Transaction Id
                            </UserField2>
                        <UserField2 primary>
                            Transaction Date
                            </UserField2>

                        <UserField2 primary>
                            Amount
                            </UserField2>

                        <UserField2 primary>
                            Mode
                            </UserField2>
                        <UserField2 primary  >
                            Status
                            </UserField2>

                    </UserDetailValueLabels>
                    {this.props.payment && this.props.payment.fetch_payment_list ?
                        <UserListLoadingContainer>
                            <img alt="loading" width="40" height="40" src={require("static/img/Theme-loader.gif")} />
                        </UserListLoadingContainer> :
                        <ListContainer>
                            {children}
                        </ListContainer>}
                </AutoContainer>

                <Pagination>
                    <PaginationButton color={this.props.payment.payment_offset !== 0 ? '#ff8a23' : '#d5d5d5'} onClick={this.handlePrevious}>
                        <i className="fa fa-chevron-left" />
                    </PaginationButton>
                    <PaginationButton color={this.props.payment.payment_list_result && this.props.payment.payment_list_result.has_next ? '#ff8a23' : '#d5d5d5'} onClick={this.handleNext}>
                        <i className="fa fa-chevron-right" />
                    </PaginationButton>
                </Pagination>
            </UserDetailsContainer>
        )
    }
}

export default connectStore({
    payment
})(PaymentsList)
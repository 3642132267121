import styled from "styled-components";
import {McqItemContainer,McqToolbar} from "static/styled/custom.styled"
import {ActivityInputField} from "@c/activity-view/activity-creation-form/ActivityCreationForm.styled";
export const Container=styled.div`

`;

export const CollectionPopUpContainer=styled.div`
display:flex;
flex-flow:row wrap;
justify-content:space-between;
width:700px;
overflow:hidden;
height:100%;
`;

export const CollectionListMainContainer=styled.div`
 width:50%;
 //padding:0px 18px;
 position:relative;
  border-right:solid 1px rgba(151,151,151,0.2);`;

export const CollectionCreatorContainer=styled.div`
  width:50%;
 `;

export const CollectionListContainer=styled.div`
 overflow-y:auto;
 min-height:330px;
 /* max-height:calc( 100vh - 340px); */
 overflow-x: hidden;
 `;

export const CollectionItem=McqItemContainer.extend`
   width:100%;
   position:relative;
   display:flex;


   align-items:center;
   cursor:pointer;
    border-radius:10px;
   justify-content:center;
   height:64px;
   padding:6px 8px;
    background:url(${props=>props.bg?props.bg:''});
    background-repeat:no-repeat;
    background-size:cover;
   `;
export const CollectionToolbar=McqToolbar.extend`
    top:auto;
    bottom:10px;
    right:10px;`;

export const TitleInputField=ActivityInputField.extend`
margin:0px;
padding:10px 0px;
height:80px;`;

export const UploadCollectionImageButton=styled.button`
outline:none;
border:none;
 display-flex;
 justify-content:center;
 width:100%;
 flex-flow:column no-wrap;
 background-color:transparent;
 align-items:center;
 position:relative;
 i
 {
 font-size:36px;
 color:rgba(67, 38, 102,0.2);
 }

 div
 {
bottom:8px;
left:0;
text-align:center;
display:inline-block;
position:absolute;
width:100%;
 font-size:var(--sm-font-size);
  color: #432666;
 }
 `;

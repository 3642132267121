import { Api } from "./base";

const _headers = () => {
  return {
    Authorization:
      "JWT " + require("store").default.getState().onboard.user.auth_token,
    "Content-Type": "application/json"
  };
};

export default {
  getTagList(offset) {
    return Api.getCsv(`/assessment/v1/admin-tag/?parent=True&offset=${offset}`, _headers());
  },
  getChildTagListByParentId(id) {
    return Api.getCsv(`/assessment/v1/admin-tag/?parent_tag=${id}`, _headers());
  },
  getTagById(id) {
    return Api.get(`/assessment/v1/admin-tag/${id}`, _headers());
  },
  createChildTag(payload) {
    return Api.post(`/assessment/v1/admin-tag/`, payload, _headers());
  },
  createParentTag(payload) {
    return Api.post(`/assessment/v1/admin-tag/`, payload, _headers());
  },
  updateTagById(id, payload) {
    return Api.put(`/assessment/v1/admin-tag/${id}/`, payload, _headers());
  }
};

import React, { Component } from "react";
import { Container } from "./FreadomPointLeaderBoardList.styled";
import {
  UserDetailValues,
  UserDetailValueLabels,
  UserField2,
  UserFieldEmail,
  UserFieldName,
  Pagination,
  PaginationButton
} from "@c/users-view/MainContent.styled";
import {
  ScrollContainer,
  ScrollParentContainer
} from "../LeaderBoardView.styled";
import Pravatar from "pravatar";
import {
  LoadingContainer
} from "@/static/styled/custom.styled";
import { connectStore } from "redux-box";
import { module as leaderBoard } from "store/leaderBoard";


class FreadomPointLeaderBoardList extends Component {
  // constructor(props) {
  //   super(props);
  // }
  componentDidMount() {
    this.props.leaderBoard.requestFreadomPointLeaderBoardList(0);
  }

  handleNext = () => {
    let { leaderBoard } = this.props;
    if (leaderBoard.freadomPointLeaderBoardList.has_next) {
      leaderBoard.requestFreadomPointLeaderBoardList(
        leaderBoard.freadomPointLeaderBoardList.next_page
      );
    }
  };
  handlePrevious = () => {
    let { leaderBoard } = this.props;
    if (
      leaderBoard.freadomPointLeaderBoardList.prev_page >= 0 &&
      leaderBoard.freadomPointLeaderBoardList.offset !== 0
    ) {
      leaderBoard.requestFreadomPointLeaderBoardList(leaderBoard.freadomPointLeaderBoardList.prev_page);
    }
  };
  render() {
    return (
      <Container>
        {this.props.leaderBoard.loader ? (
          <LoadingContainer style={{ zIndex: "100" }}>
            <img
              alt="loading"
              width="60"
              height="60"
              src={require("static/img/Theme-loader.gif")}
            />
          </LoadingContainer>) :
          (<ScrollContainer>
            <UserDetailValueLabels  >
              <UserField2 primary> Rank</UserField2>
              <UserField2 primary> </UserField2>
              <UserFieldName primary>Name</UserFieldName>
              <UserFieldEmail primary>School</UserFieldEmail>
              <UserField2 primary>Freadom Points</UserField2>
              <UserField2 primary>Grade</UserField2>
              <UserField2 primary>Wow class name</UserField2>
              <UserField2 primary>Invite code</UserField2>
            </UserDetailValueLabels>
            <ScrollParentContainer>
              {this.props.leaderBoard.freadomPointLeaderBoardList.result &&
                this.props.leaderBoard.freadomPointLeaderBoardList.result.all.map(
                  (data, index) => (
                    <Container key={data.id}>
                      <UserDetailValues>
                        <UserField2>
                          {this.props.leaderBoard.freadomPointLeaderBoardList
                            .offset *
                            20 +
                            (index + 1)}
                        </UserField2>
                        <UserField2 primary>
                          <Pravatar
                            text={data.image !== "" ? "" : data.name.slice(0, 1)}
                            image={data.image}
                            height="30px"
                            width="30px"
                            bg="#e7e7e7"
                          />
                        </UserField2>
                        <UserFieldName>{data.name}</UserFieldName>
                        <UserFieldEmail>{data.school ? data.school : '-'}</UserFieldEmail>
                        <UserField2>{data.freadom_point}</UserField2>
                        <UserField2>{data.grade.name}</UserField2>
                        <UserField2>{data.wow_class_name ? data.wow_class_name : '-'}</UserField2>
                        <UserField2>{data.invite_code ? data.invite_code : '-'}</UserField2>
                      </UserDetailValues>
                    </Container>
                  )
                )}
            </ScrollParentContainer>
          </ScrollContainer>)}
        <Pagination>
          <PaginationButton
            color={
              this.props.leaderBoard.leaderBoardList.offset !== 0
                ? "#ff8a23"
                : "#d5d5d5"
            }
            onClick={this.handlePrevious}
          >
            <i className="fa fa-chevron-left" />
          </PaginationButton>
          <PaginationButton
            color={
              this.props.leaderBoard.leaderBoardList &&
                this.props.leaderBoard.leaderBoardList.has_next
                ? "#ff8a23"
                : "#d5d5d5"
            }
            onClick={this.handleNext}
          >
            <i className="fa fa-chevron-right" />
          </PaginationButton>
        </Pagination>
      </Container>
    );
  }
}

export default connectStore({
  leaderBoard
})(FreadomPointLeaderBoardList)
import styled from "styled-components";
const Modal = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color:white;
  z-index: 2000;
  width:100vw;
  height:105vh;
  margin: auto;
`;
export default Modal;
import { createSagas } from "redux-box";
import ProductApi from "@s/api/product";
import { call, put } from "redux-saga/effects";
import swal from "sweetalert2";

const errorHandler = (err) => {
  console.log("product list err", err);
  swal("Oops!", err.error_message, "error");
};

export default createSagas({
  FETCH_PRODUCT_LIST: function*() {
    try {
      yield put({
        type: "SET_PRODUCT_LIST_LOADING",
        value: true,
      });

      const res = yield call(ProductApi.requestProductList);

      yield put({
        type: "SET_PRODUCT_LIST",
        value: res,
      });

      yield put({
        type: "SET_PRODUCT_LIST_LOADING",
        value: false,
      });
    } catch (err) {
      errorHandler(err);
    }
  },

  FETCH_SCHOOL_GUIDED_PATH_PRODUCT: function*() {
    try {
      const res = yield call(ProductApi.requestProductList);
      if (res.length > 0) {
        const isDev =
          window.location.href.includes("admin-dev") ||
          window.location.href.includes("localhost");
        const product_id = isDev
          ? "a18f1361-f226-48cf-874b-410b78d502f7"
          : "222b5027-2431-4d6e-856c-5f70ef26351d";
        let school_guided_path_obj = res.filter(
          (ele) => ele.id === product_id
        )[0];
        yield put({
          type: "SET_SCHOOL_GUIDED_PATH_PRODUCT",
          product: school_guided_path_obj,
        });
      }
    } catch (err) {
      errorHandler(err);
    }
  },

  UPDATE_PRODUCT_LIST: function*({ updateProduct }) {
    try {
      const res = yield call(
        ProductApi.updateProduct,
        updateProduct.id,
        updateProduct
      );
      if (res.id) {
        yield put({
          type: "FETCH_PRODUCT_LIST",
        });
        swal("Done", "Product updated successfully", "success");
      }
    } catch (err) {
      errorHandler(err);
    }
  },

  CREATE_PRODUCT: function*({ updateProduct }) {
    try {
      const res = yield call(ProductApi.createProduct, updateProduct);
      if (res.id) {
        yield put({
          type: "FETCH_PRODUCT_LIST",
        });
        swal("Done", "Product created successfully", "success");
      }
    } catch (err) {
      errorHandler(err);
    }
  },
});

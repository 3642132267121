import styled from "styled-components";
import {
  NavigationHeaderContainer,
  NavigationToolbar
} from "@sc/NavigationBar.styled";
import {
  CheckBall,
  CheckBallInner,
  Chevron,
  DropDown,
  DropdownItem,
  DropdownFlexStart,
  DropDownMenu,
  ThemeButton
} from "@sc/custom.styled";

export const OptionContainer = styled.div`
 width:100%;
 display:flex;
 flex-flow:row wrap;
 justify-content:space-between;
 padding: ${props => (props.active ? "0px 5px" : "0px 10px")}
 align-items:center;
 color:black;
   `;
export const ToggleButton = styled.div`
  width: 45px;
  height: ${props => (props.height ? props.height : "26px")};
  position: relative;
  border-radius: 100px;
  background-color: ${props => (props.color ? props.color : "#ff8f26")};
`;

export const ToggleStatus = styled.div`
  font-size: ${props => (props.fontSize ? props.fontSize : "14px")};
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  padding: 5px;
  color: #000000;
  cursor: pointer;
  opacity: ${props => (props.primary ? 0.3 : 1)};
`;

export const ToggleHandler = styled.div`
  position: absolute;
  cursor: pointer;
  border-radius: 100%;
  background-color: white;
  left: ${props => (props.left ? props.left : props.primary ? "2px" : "21px")};
  width: ${props => (props.width ? props.width : "22px")};
  transition: 0.3s;
  height: ${props => (props.height ? props.height : "22px")};
  top: 2px;
  padding: 2px;
`;

export const ActiveWrapper = styled.div`
  background: ${props => (props.active ? '#26de81;' : "")};
`;

export const SVHeaderNavContainer= styled(NavigationHeaderContainer)`
  display: block;
`;

export const SVHeaderNavigationToolbar= styled(NavigationToolbar)`
  justify-content: flex-start
`;

export const SVHeaderDropDown= styled(DropDown)`
display: block;
width: 100%;
padding: .375rem .75rem;
font-size: 1rem;
line-height: 1.5;
color: #495057;
background-color: #fff;
background-clip: padding-box;
border: 1px solid #ced4da;
border-radius: .25rem;
border-top-left-radius: 0;
border-bottom-left-radius: 0;
transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
`;

export const SVHeaderDropDownMenu= styled(DropDownMenu)`
border-radius: 0;
`;






import React, { Component } from "react";
import {
  ActivityInputField,
  CreateActivityContainer
} from "@c/activity-view/activity-creation-form/ActivityCreationForm.styled";
import { AssessmentThemeButton } from "./CreateAssessmentForm.styled";
import { GradeSelectionContainer } from "@sc/ActivityModal.styled";
import { connectStore } from "redux-box";
import { module as ui } from "@st/ui";
import { module as assessment } from "@st/assessment";
import { module as level } from "@st/level";
import {
  CheckBall,
  CheckBallInner,
  Chevron,
  DropDown,
  DropdownItem,
  DropDownMenu,
  FlexStart,
  SubLevelItem
} from "static/styled/custom.styled";


class CreateAssessmentForm extends Component {
  constructor(props) {
    super();
    this.state = {
      grades: [],
      gradeSelected: "Select Grade",
      levelTypeSelector: false,
      subLevelSelector: false,
      displayMenu: false,
      activeLevel: "",
      selectedLevel: "",
      activeSublevel: "",
      assessment_info: {
        name: "",
        grade_level: ""
      }
    };
  }

  componentDidMount() {
    this.setState({
      grades: this.props.level.levels.map(() => {
        return { levels: ["Easy", "Hard"] };
      })
    });
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.assessment && nextProps.assessment.assessmentList) {
      this.updateLevelList(false);
    }
  }

  updateLevelList = val => {
    if (this.props.assessment && this.props.assessment.assessmentList && val) {
      let newGradeList = this.state.grades;
      newGradeList = newGradeList.map((level, index) => {
        let subLevels = level.levels;
        this.props.assessment.assessmentList.forEach(assessment => {
          let assessmentLevel = assessment.grade_level.split(":");
          //console.log(assessmentLevel);
          if (assessmentLevel[0] === "Level " + (index + 1)) {
            if (level.levels.includes(assessmentLevel[1])) {
              let index = assessmentLevel[1] === "Easy" ? 0 : 1;
              delete subLevels[index];
            }
          }
        });
        console.log(subLevels);
        return { ...level, levels: subLevels };
      });

      this.setState({
        grades: newGradeList
      });
    }
    this.setState({
      levelTypeSelector: val
    });
  };

  normalizeForState = assessment => {};

  handleLevelSelector = val => {
    this.setState({
      levelTypeSelector: val
    });
  };

  displayGradeMenu = e => {
    this.setState({
      displayMenu: !this.state.displayMenu
    });
  };

  handleGradeSelected(newGrade) {
    this.setState({
      displayMenu: !this.state.displayMenu
    });
  }
  handleCreateAssessmentBtn = () => {
    if (
      this.state.assessment_info.grade_level.length > 0 &&
      this.state.assessment_info.name.trim().length
    ) {
      this.props.assessment.requestCreateGradeLevelTest(
        this.state.assessment_info
      );
    }
    this.props.ui.hideModal();
  };
  handleAssessmentName = e => {
    this.setState({
      assessment_info: {
        ...this.state.assessment_info,
        name: e.target.value
      }
    });
  };
  setActiveLevel = (index, subLevelSelector) => {
    let { assessment_info } = this.state;
    if (
      assessment_info.grade_level !==
      "Level " + (index + 1) + ":" + this.state.activeSublevel
    )
      this.setState({
        activeLevel: "Level " + index,
        subLevelSelector: subLevelSelector
      });
    else {
      this.setState({
        assessment_info: { ...this.state.assessment_info, grade_level: "" },
        selectedLevel: "",
        activeSublevel: ""
      });
      if (this.state.subLevelSelector)
        this.setState({ subLevelSelector: false });
    }
  };
  handleSubLevelSelector = (index, subLevel) => {
    let { assessment_info } = this.state;
    if (assessment_info.grade_level !== "Level " + (index + 1) + ":" + subLevel)
      this.setState({
        assessment_info: {
          ...this.state.assessment_info,
          grade_level: "Level " + (index + 1) + ":" + subLevel
        },
        selectedLevel: "Level " + index,
        activeSublevel: subLevel
      });
    else {
      this.setState({
        assessment_info: { ...this.state.assessment_info, grade_level: "" },
        selectedLevel: "",
        activeSublevel: ""
      });
    }
  };

  render() {
    return (
      <CreateActivityContainer primary>
        <ActivityInputField
          type="text"
          placeholder="Enter Level Name"
          value={this.state.assessment_info.name}
          onChange={this.handleAssessmentName}
        />
        <GradeSelectionContainer>
          Level
          <DropDown
            minWidth="160px"
            active={this.state.levelSelector}
            onBlur={() => this.handleLevelSelector(false)}
            tabIndex="1"
          >
            <div
              style={{ fontSize: "14px", height: "24px" }}
              onClick={() =>
                this.updateLevelList(!this.state.levelTypeSelector)
              }
            >
              {this.state.assessment_info.grade_level.length > 0
                ? this.state.assessment_info.grade_level
                : "Select Level"}

              <Chevron>
                <img
                  src={require("static/img/downarrowicon.png")}
                  alt="down-arrow"
                />
              </Chevron>
            </div>
            <DropDownMenu
              maxHeight="100px"
              active={this.state.levelTypeSelector}
              color="#fff"
              style={{ padding: "0px" }}
            >
              {this.state.grades.map((level, index) =>
                level.levels.includes("Easy") ||
                level.levels.includes("Hard") ? (
                  <DropdownItem>
                    <FlexStart
                      style={{
                        width: "100%",
                        padding: "6px 8px",
                        position: "relative",
                        borderBottom: "solid 1px #e6e6e6",
                      }}
                      onMouseDown={(e) => e.preventDefault()}
                      onClick={() => {
                        this.setActiveLevel(
                          index,
                          !this.state.subLevelSelector
                        );
                      }}
                    >
                      <CheckBall
                        style={{ marginRight: "6px" }}
                        active={this.state.selectedLevel === "Level " + index}
                      >
                        <CheckBallInner
                          active={this.state.selectedLevel === "Level " + index}
                        />
                      </CheckBall>
                      <Chevron
                        cross={
                          this.state.activeLevel === "Level " + index &&
                          this.state.subLevelSelector
                            ? "rotate(0deg)"
                            : "rotate(-90deg)"
                        }
                      >
                        <img
                          src={require("static/img/downarrowicon.png")}
                          alt="down-arrow"
                        />
                      </Chevron>
                      Level {index + 1}
                    </FlexStart>
                    {this.state.activeLevel === "Level " + index &&
                    this.state.subLevelSelector ? (
                      <div>
                        {level.levels.includes("Easy") ? (
                          <SubLevelItem
                            style={{
                              backgroundColor: "#faf7ff",
                              width: "100%",
                              padding: "6px 8px 6px 36px",
                              justifyContent: "flex-start",
                              borderBottom: "solid 1px #e6e6e6",
                            }}
                            onMouseDown={(e) => e.preventDefault()}
                            onClick={() =>
                              this.handleSubLevelSelector(index, "Easy")
                            }
                          >
                            <CheckBall
                              style={{ marginRight: "6px" }}
                              active={
                                this.state.assessment_info.grade_level ===
                                "Level " + (index + 1) + ":Easy"
                              }
                            >
                              <CheckBallInner
                                active={
                                  this.state.assessment_info.grade_level ===
                                  "Level " + (index + 1) + ":Easy"
                                }
                              />
                            </CheckBall>
                            Easy
                          </SubLevelItem>
                        ) : null}
                        {level.levels.includes("Hard") ? (
                          <SubLevelItem
                            style={{
                              backgroundColor: "#faf7ff",
                              width: "100%",
                              padding: "6px 8px 6px 36px",
                              justifyContent: "flex-start",
                              borderBottom: "solid 1px #e6e6e6",
                            }}
                            onMouseDown={(e) => e.preventDefault()}
                            onClick={() =>
                              this.handleSubLevelSelector(index, "Hard")
                            }
                          >
                            <CheckBall
                              style={{ marginRight: "6px" }}
                              active={
                                this.state.assessment_info.grade_level ===
                                "Level " + (index + 1) + ":Hard"
                              }
                            >
                              <CheckBallInner
                                active={
                                  this.state.assessment_info.grade_level ===
                                  "Level " + (index + 1) + ":Hard"
                                }
                              />
                            </CheckBall>
                            Hard
                          </SubLevelItem>
                        ) : null}
                      </div>
                    ) : null}
                  </DropdownItem>
                ) : null
              )}
            </DropDownMenu>
          </DropDown>
        </GradeSelectionContainer>
        <AssessmentThemeButton onClick={this.handleCreateAssessmentBtn}>
          Create Assessment
        </AssessmentThemeButton>
      </CreateActivityContainer>
    );
  }
}
export default connectStore({
  ui,
  assessment,
  level
})(CreateAssessmentForm);

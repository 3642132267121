import styled from "styled-components";

export const McqFreadContainer = styled.div`
padding: 14px;
    
    background-color: #fafafa;
    border-radius: 10px;
    margin:14px;
    
`;

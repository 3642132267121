import React, { useState, useEffect } from "react";
import FreadomFutureApi from "@s/api/freadomFuture";
import { withRouter } from "react-router";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import { ThemeButton } from "@sc/custom.styled";
import {
  ActivityModalContainer,
  ActivityPopUpContainer,
} from "@sc/ActivityModal.styled";
import { DropDownTitle, DropDownContainer } from "./TaskEditor.styled";
import { connectStore } from "redux-box";
import { module as freadomFuture } from "@st/freadomFuture";
import swal from "sweetalert2";
import { PanelHeader } from "@sc/custom.styled";
import "./style_task.css";

const taskOrderOptions = [
  { value: "pre-liveclass", label: "Pre-Live class" },
  { value: "post-liveclass", label: "Post-Live class" },
];

const taskTypeOptions = [
  { value: "news_fread", label: "News" },
  { value: "activity", label: "Activity" },
  { value: "story", label: "story" },
  { value: "quiz", label: "Quiz" },
  { value: "word_game", label: "Word Game" },
];

const taskTagOptions = [
  { value: "GRAMMAR", label: "GRAMMAR" },
  { value: "READING", label: "READING" },
  { value: "LISTENING", label: "LISTENING" },
  { value: "SPELLING", label: "SPELLING" },
];

const TaskEditor = (props) => {
  const [selectedTaskOrder, setSelectedTaskOrder] = useState(null);
  const [selectedTaskType, setSelectedTaskType] = useState(null);
  const [selectedTaskTag, setSelectedTaskTag] = useState(null);

  const [selectedTaskContent, setSelectedTaskContent] = useState(null);

  const setField = () => {
    let active_task = props.freadomFuture.active_task;
    if (active_task) {
      setSelectedTaskOrder(
        taskOrderOptions.filter(
          (ele) => ele.value === active_task.position_type
        )[0]
      );
      setSelectedTaskType(
        taskTypeOptions.filter(
          (ele) => ele.value === active_task.content_type
        )[0]
      );
      let tagOption = [];
      active_task.tag.forEach((tagValue) => {
        tagOption = [
          ...tagOption,
          ...taskTagOptions.filter((ele) => ele.value === tagValue),
        ];
      });
      setSelectedTaskTag(tagOption);
      setSelectedTaskContent({
        _source: {
          name: active_task.name,
          image: active_task.thumbnail_url,
          description: active_task.description,
        },
        _id: active_task.content_id,
      });
      console.log("active_task=======", active_task);
    }
  };

  const setDefaultValue = () => {
    setSelectedTaskOrder(null);
    setSelectedTaskType(null);
    setSelectedTaskTag(null);
    setSelectedTaskContent(null);
  };

  useEffect(() => {
    if (props.freadomFuture.active_task) {
      setField();
    } else {
      setDefaultValue();
    }
  }, [props.freadomFuture.active_task]);

  const checkData = () => {
    if (
      selectedTaskOrder &&
      selectedTaskType &&
      selectedTaskTag &&
      selectedTaskTag.length > 0 &&
      selectedTaskContent
    ) {
      return true;
    } else {
      return false;
    }
  };

  const createTask = () => {
    let res = checkData();
    if (res) {
      let taskObj = {
        name: selectedTaskContent._source.name,
        description: selectedTaskContent._source.description,
        content_id: selectedTaskContent._id,
        thumbnail_url: selectedTaskContent._source.image,
        content_type: selectedTaskType.value,
        position_type: selectedTaskOrder.value,
        tag: selectedTaskTag.map((tagEle) => tagEle.value),
        unit: props.match.params.id,
      };
      props.freadomFuture.createFreadomFutureTask(taskObj);
    } else {
      swal(
        "Oops!",
        `You must select 'TASK ORDER', 'TASK TYPE', 'TASK TAG' & 'CONTENT' to create Task `,
        "error"
      );
    }
  };

  const updateTask = () => {
    alert("update");
  };

  const handleInputChange = (value) => {
    console.log("handleInputChange", value);
  };

  const handleChange = (value) => {
    setSelectedTaskContent(value);
  };

  // load options using API call
  const loadOptions = async (searchValue) => {
    if (selectedTaskType.value) {
      let data = await FreadomFutureApi.getContentSearchList(
        searchValue,
        selectedTaskType.value
      );
      return data.documents;
      // return fetch(`https://api.getfreadom.com/api/global-search/v1/?query=${searchValue}&index_type=${selectedTaskType.value}`)
      //   .then((res) => res.json())
      //   .then((data) => data.result.documents);
    }
  };

  const handleTaskTypeChange = (value) => {
    setSelectedTaskContent(null);
    setSelectedTaskType(value);
  };

  return (
    <ActivityPopUpContainer style={{ height: "100%" }}>
      <PanelHeader primary style={{ zIndex: "999" }}>
        <span>Create TASK</span>
        {props.freadomFuture.active_task ? (
          <ThemeButton primary onClick={() => setField()}>
            Reset
          </ThemeButton>
        ) : null}

        {props.freadomFuture.active_task ? (
          <ThemeButton primary onClick={() => updateTask()}>
            Done
          </ThemeButton>
        ) : (
          <ThemeButton primary onClick={() => createTask()}>
            Done
          </ThemeButton>
        )}
      </PanelHeader>
      <ActivityModalContainer className="mcq_body">
        <DropDownContainer>
          <DropDownTitle>TASK ORDER</DropDownTitle>
          <Select
            placeholder={"Select Task Order"}
            value={selectedTaskOrder}
            onChange={setSelectedTaskOrder}
            options={taskOrderOptions}
          />
        </DropDownContainer>

        <DropDownContainer>
          <DropDownTitle>TASK TYPE</DropDownTitle>
          <Select
            placeholder={"Select Task Type"}
            value={selectedTaskType}
            onChange={handleTaskTypeChange}
            options={taskTypeOptions}
          />
        </DropDownContainer>

        <DropDownContainer>
          <DropDownTitle>TASK TAG</DropDownTitle>
          <Select
            placeholder={"Select Task Tag"}
            value={selectedTaskTag}
            onChange={setSelectedTaskTag}
            options={taskTagOptions}
            isMulti={true}
          />
        </DropDownContainer>
        {selectedTaskType !== "word_game" ? (
          <DropDownContainer>
            <DropDownTitle>CONTENT</DropDownTitle>
            <AsyncSelect
              cacheOptions
              defaultOptions
              isDisabled={
                selectedTaskType && selectedTaskType.value ? false : true
              }
              value={selectedTaskContent}
              getOptionLabel={(e) => e._source.name}
              getOptionValue={(e) => e._id}
              loadOptions={loadOptions}
              onInputChange={handleInputChange}
              onChange={handleChange}
            />
          </DropDownContainer>
        ) : null}
      </ActivityModalContainer>
    </ActivityPopUpContainer>
  );
};

// export default McqEditor;
export default withRouter(
  connectStore({
    freadomFuture,
  })(TaskEditor)
);

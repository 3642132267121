import styled from "styled-components";

export const Container = styled.div``;

export const VideoList = styled.div`
  flex-flow: colum nowrap;
  width: 100%;
  margin-top: -10px;
  padding: 4px 0px;
`;

export const VideoItem = styled.div`
  border-radius: 10px;
  margin-right: 14px;
  position: relative;
  .video-react {
    display: inline-block;
    margin-right: 35px;
  }
`;

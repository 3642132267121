import React, { Component } from "react";
import {
  PlusButton,
  PlusIcon,
  EmptyContainer,
  LoadingContainer,
  ThemeButton
} from "@sc/custom.styled";
import AssessmentRouter from "@r/assessmentsRouter";
import {
  AssessmentCard,
  AssessmentContainer,
  AssessmentContent
} from "./AssessmentsView.styled";
import { Link } from "react-router-dom";
import { connectStore } from "redux-box";
import { module as ui } from "@st/ui";
import { module as activity } from "@st/activity";
import { module as assessment } from "@st/assessment";
import { module as onboard } from "@st/onboard";
import Permission from "@s/permission";
import swal from "sweetalert2";

// export default connectStore({
//   ui,
//   activity,
//   assessment
// })

class AssessmentsView extends Component {
  componentWillMount() {
    this.props.activity.requestGrades();
    this.props.assessment.requestGetLevelTests();
  }

  handleAddClickButton = e => {
    const {permission} = this.props.onboard;
    let loc = this.props.location;
    if (loc.pathname.includes("/assessments/edit")) {
      this.props.history.push("/assessments/create");
    } else if (loc.pathname.includes("/assessments/create")) {
      this.props.history.push("/assessments");
    } else {
      if(Permission.getPermission(permission, "add_activity")){
        this.props.ui.showModal("ASSESSMENTSFORM");
      }else{
        swal({
          text: "You do not have permission.",
          type: "warning"
        });
      }
      
    }
  };

  render() {
    const isCreatorOpen = this.props.location.pathname.includes(
      "/assessments/create"
    );
    let { assessment } = this.props;
      
    return (
      <AssessmentContainer primary>
        <AssessmentRouter />
        <AssessmentContent>
          {this.props.assessment.fetch_assessment_list ? (
            <LoadingContainer style={{ zIndex: "100" }}>
              <img
                alt="loading"
                width="60"
                height="60"
                src={require("static/img/Theme-loader.gif")}
              />
            </LoadingContainer>
          ) : this.props.assessment.assessmentList &&
            this.props.assessment.assessmentList.length > 0 ? (
                assessment.assessmentList.map((assessment, index) => (
                  <div key={index} className="col-sm-6 col-md-4 col-lg-3">
                    <Link to={"/assessments/create/" + assessment.id}>
                      <AssessmentCard>
                        {assessment.name ? <p style={{ marginTop: '10px', fontWeight: 'bold', color: '#a349c9', }}>{assessment.name}</p>
                          :
                          <p style={{ marginTop: '10px', fontWeight: 'bold', color: '#a349c9' }}>No level </p>
                        }

                        <ThemeButton style={{ backgroundColor: '#e7e7e7', padding: '2px 10px', fontSize: '12px', border: '0', color: 'grey', }}> {assessment.grade_level}</ThemeButton>
                      </AssessmentCard>
                    </Link>
                  </div>
                ))
              ) : (
                <EmptyContainer>
                  <h4>NO ASSESSMENT RECORDS ADDED</h4>
                </EmptyContainer>
              )}
        </AssessmentContent>
        
        <PlusButton
          hide={this.props.ui.show_modal}
          onClick={this.handleAddClickButton}
        >
          <PlusIcon
            primary
            alt="Plus Icon"
            cross={isCreatorOpen}
            src={require("static/img/plusicon.png")}
          />
        </PlusButton>
      </AssessmentContainer>
    );
  }
}

// export default AssessmentsView;
export default connectStore({
  activity,
 ui,
 assessment,
 onboard
 })(AssessmentsView)

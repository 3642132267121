import React, { Component } from "react";
import {
  ThemeButton,
  McqOptionsToolbar,
  CheckBox,
  McqOptionItem,
  PublishContainer
} from "static/styled/custom.styled";
import {
  ActivityModalContainer,
  ActivityPopUpContainer,
  AssessmentFooter
} from "@sc/ActivityModal.styled";
import TextArea from "react-autosize-textarea";
import {
  CreateMCQQuestionContainer,
  MCQOptionsAnswerContainer
} from "@sc/McqEditor.styled";
import { connectStore } from "redux-box";
import { module as activity } from "@st/activity";
import { module as assessment } from "@st/assessment";
import swal from "sweetalert2";
import { PanelHeader } from "@sc/custom.styled";

const errorHandler = err => {
  //console.log(err);
  swal({
    text: err,
    type: "warning"
  });
};

// export default connectStore({
//   activity,
//   assessment
// })
class CreateQuestionEditor extends Component {
  constructor() {
    super();
    this.state = {
      question: "",
      options: [],
      valid_option: null,
      level_up_dependent: false
    };
    this.MAX_OPTIONS_COUNT = 4;
    this.MIN_OPTIONS_COUNT = 4;
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.assessment && nextProps.assessment.active_mcq) {
      this.normalizeForState(nextProps.assessment.active_mcq);
    } else {
      this.setState({
        question: "",
        options: [],
        valid_option: null,
        level_up_dependent: false
      });
    }
  }
  componentDidMount() {
    // hack to stabilize the textarea
    this.setState({
      question: " "
    });
    setTimeout(() => {
      if (this.props.assessment && this.props.assessment.active_mcq) {
        this.normalizeForState(this.props.assessment.active_mcq);
      } else {
        this.setState({
          question: ""
        });
      }
    }, 200);
  }

  componentWillUnmount() {
    this.props.assessment.setActiveAssessmentMcq(null);
  }

  updateQuestion = e => {
    this.setState({
      question: e.target.value
    });
  };

  updateOption = (option, e) => {
    // debugger
    const index = this.state.options.findIndex(op => op.id === option.id);
    let options1 = this.state.options.slice(0, index);
    let options2 =
      this.state.options.length > 1
        ? this.state.options.slice(index + 1, this.state.options.length)
        : [];
    this.setState({
      options: [
        ...options1,
        { id: option.id, text: e.target.value },
        ...options2
      ]
    });
  };

  setAsValidOption = option => this.setState({ valid_option: option });

  removeOption = option => {
    if (this.state.valid_option && this.state.valid_option.id === option.id)
      this.setState({
        valid_option: null
      });
    const index = this.state.options.findIndex(op => op.id === option.id);
    let options1 = this.state.options.slice(0, index);
    let options2 =
      this.state.options.length > 1
        ? this.state.options.slice(index + 1, this.state.options.length)
        : [];
    this.setState({
      options: [...options1, ...options2]
    });
  };

  addNewOption = () => {
    this.setState({
      options: [...this.state.options, { id: Math.random(), text: "" }]
    });
  };

  createMcq = () => {
    const mcq = this.normalizeForApi();
    if (this.state.question.length > 0) {
      if (this.state.options.length === 0)
        errorHandler("Please add options to add the mcq?");
      else if (
        (this.state.options[0] && this.state.options[0].text === "") ||
        (this.state.options[1] && this.state.options[1].text === "")
      )
        errorHandler("Option field is empty!");
      else if (this.state.options.length < 2)
        errorHandler("Please select at least two options to add the mcq?");
      else if (this.state.valid_option == null)
        errorHandler("Please mark the correct answer to add the mcq?");
      else {
        this.props.assessment.requestCreateAssessmentQuestion(mcq);
        this.props.activity.closeEditor();
      }
    } else if (
      this.state.question.length === 0 &&
      this.state.options.length > 0
    )
      errorHandler("Question field is empty!");
    else if (
      this.state.question.length === 0 &&
      this.state.options.length === 0
    ) {
      this.props.activity.closeEditor();
    }
  };

  updateMcq = () => {
    const mcq = this.normalizeForApi();
    if (this.state.options.length === 0 && this.state.question.length > 0)
      errorHandler("Please add options to add the mcq?");
    else if (this.state.question.length > 0 && this.state.options.length > 0) {
      if (this.state.valid_option === null)
        errorHandler("Please mark the correct answer to add the mcq?");
      else {
        this.props.assessment.requestUpdateAssessmentQuestion(mcq);
        this.props.activity.closeEditor();
        this.props.assessment.setActiveAssessmentMcq();
      }
    } else if (
      this.state.question.length === 0 &&
      this.state.options.length > 0
    ) {
      errorHandler("Question Field is Empty! ");
    } else if (
      this.state.question.length === 0 &&
      this.state.options.length === 0
    ) {
      this.props.activity.closeEditor();
    }
  };

  handleLevelUpdate = () => {
    this.setState({
      level_up_dependent: !this.state.level_up_dependent
    });
  };

  normalizeForApi = () => {
    const res = { name: this.state.question };
    res.level_test = this.props.assessment.active_assessment.id;
    res.level_up_dependent = this.state.level_up_dependent;
    if (this.props.assessment.active_mcq) {
      res.option = this.state.options.map(op => ({ id: op.id, name: op.text }));
      // res.valid_option = this.state.valid_option.id;
    } else {
      res.option = this.state.options.map(op => op.text);
      if (this.state.valid_option != null && this.state.options)
        res.valid_option =
          this.state.options.findIndex(
            op => op.id === this.state.valid_option.id
          ) + 1;
    }
    return res;
  };

  normalizeForState = mcq => {
    const options = mcq.option.map(op => ({ id: op.id, text: op.name }));
    // debugger
    let valid_option_index = mcq.option.findIndex(
      op => op.id === mcq.valid_option
    );
    let valid_option = options[valid_option_index];
    // debugger;
    this.setState({
      question: mcq.name,
      options,
      valid_option,
      level_up_dependent: mcq.level_up_dependent
    });
  };

  render() {
    let { assessment } = this.props;
    return (
      //FREAD-1205
      <ActivityPopUpContainer style={{height:'100%'}}>
        <PanelHeader primary style={{zIndex:'999'}}>
          <span>Create QA</span>
          {assessment.active_mcq !== null ? (
            <ThemeButton primary onClick={this.updateMcq}>
              Update
            </ThemeButton>
          ) : (
            <ThemeButton primary onClick={this.createMcq}>
              Done
            </ThemeButton>
          )}
        </PanelHeader>
      {/* //FREAD-1205 */}
        <ActivityModalContainer className="mcq_body">
          <CreateMCQQuestionContainer>
            <TextArea
              placeholder="Enter question"
              autoFocus={true}
              value={this.state.question}
              onChange={this.updateQuestion}
            />
          </CreateMCQQuestionContainer>
          {/*FREAD-1205 */}
          <div //className="ans_div_mcq"
          >
          {this.state.options.map((option, index) => (
            <MCQOptionsAnswerContainer key={option.id}>
              <TextArea
                type="text"
                style={{ padding: "10px", width: "75%" }}
                placeholder="Enter option"
                autoFocus={true}
                value={option.text}
                onChange={e => this.updateOption(option, e)}
              />
              <McqOptionsToolbar>
                <McqOptionItem
                  color={
                    this.state.valid_option &&
                    this.state.valid_option.id === option.id
                      ? "green"
                      : "#c8c8c8"
                  }
                  onClick={() => this.setAsValidOption(option)}
                >
                  <i className="fa fa-check-circle" /> Correct
                </McqOptionItem>
                {assessment.active_mcq === null && (
                  <McqOptionItem
                    color="#ff7373"
                    onClick={() => this.removeOption(option)}
                  >
                    <img src={require("static/img/trash.png")} alt="trash" />{" "}
                    Delete{" "}
                  </McqOptionItem>
                )}
              </McqOptionsToolbar>
            </MCQOptionsAnswerContainer>
          ))}
          {assessment.active_mcq === null &&
            this.state.options.length < 4 && (
              <ThemeButton onClick={this.addNewOption}>
                + Add Answer
              </ThemeButton>
            )}
            </div>
        </ActivityModalContainer>
        <AssessmentFooter>
          <PublishContainer onClick={this.handleLevelUpdate}>
            <CheckBox active={this.state.level_up_dependent}>
              <i className="fa fa-check" />
            </CheckBox>
            <span>
              {" "}
              {this.state.level_up_dependent ? "Unmark " : "Mark "} Question for
              Level update{" "}
            </span>
          </PublishContainer>
        </AssessmentFooter>
      </ActivityPopUpContainer>
    );
  }
}

// export default CreateQuestionEditor;

export default connectStore({
  activity,
 assessment
 })(CreateQuestionEditor)
import styled from "styled-components";
import { FlexCenter, FlexBetween } from "@sc/custom.styled"

export const ChildSubContainer = styled.div`
 height:calc(100vh - 250px);

`;

export const SubscriptionContainer = FlexCenter.extend`
padding: 10px 30px;
padding-bottom:${props => props.padBottom ? '0' : '30px'}
height:100%;
flex-flow:column nowrap;
justify-content:${props => props.content ? props.content : 'center'};


 i {
     font-size:70px;
     color:rgba(0,0,0,0.4);

 }`;
export const SubscriptionLabel = styled.div`
 font-size:24px;
 color:${props => props.color ? props.color : '#ff7373'};

`;
export const SubscriptionSubLabel = styled.div`
 font-size:18px;
 color:${props => props.color ? props.color : '#ee8a23'};
`;

export const ActivePlanContainer = styled.div`
display:flex;
position:relative;
 padding: 14px 18px;
  cursor:pointer;

margin:14px 30px;
 border-radius:10px;
 background:${props=>props.activeColor?"linear-gradient(to right, #fabc7e -1%,#e77c2b 0%,#fabc7e 0%,#fabc7e 26%,#fabc7e 26%,#fabc7e 27%,#fabc7e 27%,#fabc7e 27%,#fabc7e 29%,#fabc7e 29%,#fabc7e 29%,#fabc7e 29%,#e77c2b 92%)":"rgba(0,0,0,0.3)"};
 &:hover{
    box-shadow: 2px 5px 6px 2px rgba(0,0,0,0.2);
    }`;

export const PlanLeftContainer = FlexCenter.extend`
 width:65%;
 justify-content:flex-start;
 align-items:flex-start;
 flex-flow:column nowrap;
 `;
export const PlanHeading = styled.div`
 
 color:${props => props.color ? props.color : 'white'};
 font-size:${props => props.fontSize ? props.fontSize : '14px'};
 span {
     font-size:24px;
     color:white;
 }
 
 `;

export const PlanDateContainer = styled.div`
 position:absolute;
 bottom:10px;
 right:18px;
 display:flex;
 div {
     margin-left:20px;
 }
 `
export const PlanDetails = FlexBetween.extend`
 `;

export const PlanDescription = styled.div`
  color:rgba(0,0,0,0.5);
  font-size:var(--md-font-size)`;
export const PlanRightContainer = FlexCenter.extend`
 justify-content:flex-start;
 align-items:flex-end;
 padding-top:12px;
 text-align:left;
 width:35%;

 flex-flow:column nowrap;
 `;






import React, { Component } from "react";
import { MainBodyContainer } from "static/styled/custom.styled";
import ParentProfileHeader from "./parent-profile-header/ParentProfileHeader";
import ParentProfileInfo from "./parent-profile-info/ParentProfileInfo";
import { connectStore } from "redux-box";
import { module as user } from "@st/user";

class ParentProfile extends Component {
  componentWillMount() {
    this.props.user.requestParentById(this.props.match.params.id);
  }

  render() {
    return (
      <MainBodyContainer>
        <ParentProfileHeader />
        <ParentProfileInfo />
      </MainBodyContainer>
    );
  }
}

export default connectStore({
  user
})(ParentProfile);

import { Api } from "./base";

const _headers = () => {
  return {
    Authorization:
      "JWT " + require("store").default.getState().onboard.user.auth_token,
    "Content-Type": "application/json"
  };
};

export default {
  createActivity(data) {
    return Api.post(`/activity/v1/admin-activity/`, data, _headers());
  },
  getAllActivities(offset, search) {
    if (search !== "")
      return Api.getCsv(
        `/activity/v1/admin-activity/?offset=${offset}&key=${search}`,
        _headers()
      );
    return Api.getCsv(
      `/activity/v1/admin-activity/?offset=${offset}`,
      _headers()
    );
  },
  getActivityById(activityId) {
    return Api.get(`/activity/v1/admin-activity/${activityId}/`, _headers());
  },
  getAllActivitiesByGrade(gradeId, offset, search) {
    if (search !== "")
      return Api.getCsv(
        `/activity/v1/admin-activity/?grade=${gradeId}&offset=${offset}&key=${search}`,
        _headers()
      );

    return Api.getCsv(
      `/activity/v1/admin-activity/?grade=${gradeId}&offset=${offset}`,
      _headers()
    );
  },
  updateActivity(activityId, data) {
    return Api.put(
      `/activity/v1/admin-activity/${activityId}/`,
      data,
      _headers()
    );
  },
  deleteActivity(activityId) {
    return Api.deleteCall(`/activity/v1/${activityId}`);
  },
  reorderActivity(activityId, data) {
    return Api.post(
      `/activity/v1/admin-activity/${activityId}/reorder/`,
      data,
      _headers()
    );
  },

  //................................SECTION.................................
  createSection(data) {
    // return Api.post(`/activity/v1/${activityId}/sectiondata/`, data);
    return Api.post(`/activity/v1/admin-section-data/`, data, _headers());
  },
  getSectionById(activityId, sectionId) {
    return Api.get(`/activity/v1/admin-section-data/?activity=${activityId}/`);
  },
  updateSection(activityId, sectionId, data) {
    return Api.put(
      `/activity/v1/admin-section-data/${sectionId}/`,
      data,
      _headers()
    );
  },
  deleteSection(activityId, sectionId) {
    return Api.deleteCall(
      `/activity/v1/admin-section-data/${sectionId}/`,
      _headers()
    );
  },

  //........................................MCQ..................................
  createQuestion(data) {
    return Api.post(`/assessment/v1/admin-question/`, data, _headers());
  },
  updateQuestion(questionId, data) {
    return Api.put(
      `/assessment/v1/admin-question/${questionId}/`,
      data,
      _headers()
    );
  },
  updateOption(optionId, data) {
    return Api.put(
      `/assessment/v1/admin-option/${optionId}/`,
      data,
      _headers()
    );
  },
  deleteQuestion(questionId) {
    return Api.deleteCall(
      `/assessment/v1/admin-question/${questionId}`,
      _headers()
    );
  },
  getAllQuestion(activityId) {
    return Api.get(
      `/assessment/v1/admin-question/?activity=${activityId}`,
      _headers()
    );
  },

  //.........................................TAG...................................
  createTag(data) {
    return Api.post(`misc/v1/admin-tag/`, data);
  },

  //..............................EXPERIENCE.......................................
  updateActivityExperience(experienceId, data) {
    return Api.put(
      `/experience/v1/admin-activity/${experienceId}/`,
      data,
      _headers()
    );
  },
  deleteActivityExperience(experienceId) {
    return Api.deleteCall(
      `/experience/v1/admin-activity/${experienceId}/`,
      _headers()
    );
  },
  //..............................ACTIVITY PACK..................................
  getAllActivityPacks(offset, gradeLevel, search) {
    if (search !== "")
      return Api.getCsv(
        `/activity/v1/admin-activity-pack/?offset=${offset}&grade_level=${gradeLevel}&key=${search}`,
        _headers()
      );
    return Api.getCsv(
      `/activity/v1/admin-activity-pack/?offset=${offset}&grade_level=${gradeLevel}`,
      _headers()
    );
  },
  createActivityPack(data) {
    return Api.post(`/activity/v1/admin-activity-pack/`, data, _headers());
  },
  getActivityPackById(activityPackId) {
    return Api.get(
      `/activity/v1/admin-activity-pack/${activityPackId}/`,
      _headers()
    );
  },
  updateActivityPack(activityPackId, data) {
    return Api.put(
      `/activity/v1/admin-activity-pack/${activityPackId}/`,
      data,
      _headers()
    );
  },
  deleteActivityPack(activityPackId) {
    return Api.deleteCall(`/activity-pack/v1/${activityPackId}/`);
  }
};

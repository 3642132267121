import React, {Component} from 'react';
import "video-react/dist/video-react.css";
import {Player, BigPlayButton, ControlBar, VolumeMenuButton, TimeDivider, CurrentTimeDisplay} from "video-react";
import {module as story} from "@st/story";
import {connectStore} from "redux-box";
import swal from "sweetalert2";
import {ExperienceDescription,
    ExperienceDetailsMainContainer, ExperienceHeaderContainer, ExperienceImageContainer, ExperienceMediaContainer,
    ExperienceToolbarContainer,
    UserDetailsContainer}
    from "@c/activity-view/activity-creator/experiences-editor/experience-card/ExperienceCard.styled";


 class StoryExperienceCard extends Component
{
    constructor(props) {
        super(props);
        this.state = ({

            publish:false,
            mediaWidth:'280',
        })
    }

    componentDidMount()
    {
        if(this.myInput)
            this.setState({
                mediaWidth:this.myInput.offsetWidth,
            })
    }

    

    handleDownload=(evt)=>{
        evt.stopPropagation();
    };

    handleDelete=(e,experienceId)=>{
        swal({
            text: "Are you sure that you want to delete this experience?",
            type: "warning",
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No'
        }).then((result) => {
            if (result.value) {
                this.props.story.requestDeleteStoryExperience(experienceId);
            }
        });
        e.stopPropagation();
    };

    handlePublish(e,experience){
        let value=null;
        if(experience.is_published)
            value="Unpublish";
        else
            value="Publish";

        swal({
            text: "Are you sure that you want to "+ value + "  this experience?",
            type: "warning",
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No'

        }).then((result) => {
            if (result.value) {
                this.props.story.requestPublishStoryExperience({...experience,show:true});
            }
        });
        e.stopPropagation();
    };

    getFileName=(experience)=> {
        let fileName=experience.media[0].media_url.toString();
        let fileExt=fileName.split(".");
        //console.log(fileExt[fileExt.length-1]);
        return "exp."+ fileExt[fileExt.length-1];
    };

    handleDownLoadError=(evt)=> {
        evt.stopPropagation();
        swal("Sorry!", "No media attached to this experience", "warning");
    };

    


    render(){
        let experience_info=this.props.story.active_experience;
        return(
            <ExperienceDetailsMainContainer>
            <ExperienceHeaderContainer>
                <UserDetailsContainer>
                    <div>
                        <img alt="child_profile" src={experience_info.child_image?experience_info.child_image:require("static/img/default-image.png")}/>
                    </div>
                    <span> {experience_info.child_name} </span>
                </UserDetailsContainer>
                <ExperienceToolbarContainer>
                    {experience_info.media.length>0 ?
                        <a href={experience_info.media[0].media_url} download onClick={(evt)=>this.handleDownload(evt,experience_info)} target="_blank">
                            <i className="fa fa-cloud-download" style={{color:'#ff7373'}}/>
                        </a>:
                        <i className="fa fa-cloud-download" onClick={this.handleDownLoadError} style={{color:'#ff7373'}}/>}

                    <i className="fa fa-check-circle" onClick={(e)=>this.handlePublish(e,experience_info)} style={{color:experience_info.is_published?"#61bd4f":"#ff7373"}}/>
                    <i className="fa fa-trash" onClick={(e)=>this.handleDelete(e,experience_info.id)} style={{color:"#ff7373"}}/>

                </ExperienceToolbarContainer>
            </ExperienceHeaderContainer>
            {experience_info && experience_info.content_type.length>0 && experience_info.media.length>0 ?(<ExperienceMediaContainer innerRef={input => { this.myInput = input }} height={experience_info.content_type==='audio'?'50px':'150px'}>
                {experience_info && experience_info.content_type==='audio' &&   experience_info.media.map((audio, i) => (
                    <audio controls key={i}>

                        <source src={audio.media_url}  type="audio/ogg; codecs=opus"/>
                        <source src={audio.media_url}  type="audio/ogg; codecs=vorbis"/>
                        <source src={audio.media_url} type="audio/mpeg" />

                    </audio>
                ))}
                {experience_info && experience_info.content_type==='image' &&  <ExperienceImageContainer src={experience_info.media.length>=1?experience_info.media[0].media_url:require("static/img/default-image.png")}/>}
                {experience_info && experience_info.content_type==='video' &&
                <Player

                    height={140}
                    width={this.state.mediaWidth}
                    style={{borderRadius:'10px',width:"100%"}}
                    fluid={false}
                    playsInline
                    src={experience_info.media[0].media_url}>
                    <BigPlayButton position="center" style={{left:"55%"}}/>
                    <ControlBar>
                        <TimeDivider disabled />
                        <CurrentTimeDisplay disabled />
                        <VolumeMenuButton disabled />
                    </ControlBar>
                </Player>}
            </ExperienceMediaContainer>):null}

            <ExperienceDescription>
                {experience_info.text}
            </ExperienceDescription>
        </ExperienceDetailsMainContainer>

        )
    }
}
export default connectStore({
    story
})(StoryExperienceCard)
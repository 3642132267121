import React, { Component } from "react";
import {
  UploadButton,
  UploadContainer,
} from "@c/activity-view/activity-creator/new-section/NewSection.styled";
import {
  RemoveSection,
  SectionHeading,
  SectionNameEditable,
  UploadHeaderContainer
} from "@c/activity-view/activity-creator/activity-info/ActivityInfo.styled";
import { SubHeading } from "static/styled/custom.styled";
import store from "store";
import actions from "store/readingChallenge/actions";
import {connectStore} from 'redux-box'
import {module as activity} from 'store/activity'
import {module as readingChallenge} from 'store/readingChallenge'
import { module as onboard } from "@st/onboard";
import Permission from "@s/permission";
import swal from "sweetalert2";

 class Section extends Component {
  constructor(props) {
    super();
    this.state = {
      editable: true,
      name: "",
      removed: false
    };
  }

  componentDidMount(){
    const props = this.props;
    props.section && props.section.name &&  this.setState({
            editable : (  props.section.name.length===0)
        })
    }



  onNameChange = (e) => {
      //this.setState({ name: e.target.value });
      store.dispatch(actions.updateNewReadingSection({__id: this.props.section.__id, name: e.target.value}))
  };

  disableEdit = (e) =>{
      if(e.key==="Enter"){
        this.setState({ editable: false })
      }
  };

  enableEdit =()=> {
    const {permission} = this.props.onboard;
    if(Permission.getPermission(permission, "change_reading")){
      this.setState({ editable: true })
    }else{
        swal({
          text:
            "You do not have permission.",
          type: "warning",
        });
      }
  };

  removeSection= () =>{
    const {permission} = this.props.onboard;
    if(Permission.getPermission(permission, "change_reading")){
      this.setState({
          removed: true
      })
      store.dispatch(actions.removeNewReadingSection(this.props.section))
      this.props.activity.closeEditor();
    }else{
      swal({
        text:
          "You do not have permission.",
        type: "warning",
      });
    }
  }

  startNewTextSection =() =>{
    const {permission} = this.props.onboard;
    if(Permission.getPermission(permission, "change_reading")){
      this.props.activity.setEditorType("text");
      store.dispatch(actions.startCreatingReadingSection("text", this.props.section ) )
    }else{
      swal({
        text:
          "You do not have permission.",
        type: "warning",
      });
    }

  };
  startNewMediaSection =() =>{
    const {permission} = this.props.onboard;
    if(Permission.getPermission(permission, "change_reading")){
      this.props.activity.setEditorType("media");
      store.dispatch(actions.startCreatingReadingSection("media", this.props.section ))
    }else{
    swal({
      text:
        "You do not have permission.",
      type: "warning",
    });
  }

  };

  render() {
    const {section} = this.props;

    return (
        // this.state.removed ? <div/>:
        <UploadContainer>
        <UploadHeaderContainer>
          <UploadHeaderContainer>
            {this.state.editable ? (
              <SectionHeading
                width={"45%"}
                placeholder="Enter Section Name"
                type="text"
                autoFocus
                value={section.name}
                onChange={this.onNameChange}
                onKeyDown={(e) =>this.disableEdit(e)}
              />
            ) : (
              <SectionNameEditable>
                <SubHeading primary width={(this.state.name.length + 1) * 10 + "px"} style={{minWidth:'160px'}}>
                  {this.props.section.name}
                </SubHeading>
                <img src={require("static/img/editicon.png")} alt="" className="image-fluid" onClick={this.enableEdit} />
              </SectionNameEditable>
            )}

            <RemoveSection onClick={this.removeSection}>
              Remove Section
            </RemoveSection>
          </UploadHeaderContainer>
        </UploadHeaderContainer>
        <div>
          <UploadButton onClick={this.startNewTextSection}>
            <img className="img-fluid" src={require("static/img/edit.png")} alt="add notes"/> Add notes
          </UploadButton>
          <UploadButton onClick={this.startNewMediaSection}>
            <img className="img-fluid" src={require("static/img/link.png")} alt="add media"/> Add Media
          </UploadButton>
        </div>
      </UploadContainer>
    );
  }
}
export default connectStore({
  activity, readingChallenge,onboard
})(Section)
import styled from "styled-components";



export const NotificationListContainer=styled.div`
  width:100%;
  padding:6px 14px 6px 14px;
 height:100%;
  
`;

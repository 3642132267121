import styled from "styled-components";
import {FlexBetween} from "static/styled/custom.styled"
export const ExperienceDetailsMainContainer=styled.div`
    margin-top:70px;
    padding:14px;
    
    width:100%;
    overflow:hidden;`;

export const ExperienceHeaderContainer=styled.div`
    display:flex;
    justify-content:space-between;
    flex-flow:row wrap;
    align-items:center;`;

export const UserDetailsContainer=FlexBetween.extend`
    justify-content:flex-start;
    font-size:var(--xlg-font-size);
    width:70%;
    position:relative;
    color:#000;
    font-weight:500;
    img
    {height: 48px;
    width: 100%;
    max-width: 100%;
    object-fit: cover;
    object-position: center;
    cursor: pointer;
    border-radius: 100px;
    }
    
    div
    {
     width:48px;
     height:48px;
     border-radius:100px;
     display:flex;
     position:absolute;
     align-items:center;
     
    }
    span
    {
    width:100%;
    padding-left:56px;}
    `;

export const ExperienceToolbarContainer=styled.div`
    align-items:center;
    width:80px;
    display:flex;
    justify-content:space-between;
    flex-flow:row wrap;
    img
    {
        width:16px;
        height:16px;
        cursor:pointer;
        display:flex;
        align-items:center;
        text-align:center;
    }
    
    i
    {
        font-size:var(--lg-font-size);
        color:${props=>props.color};
        cursor:pointer;
       
      
    }
    a
    {
        text-decoration:none;
        color:transparent;
        width:18px;
        height:18px;
    }`;

export const ExperienceMediaContainer=styled.div`

margin-top:26px;
margin-bottom:14px;
height:${props=>props.height?props.height:'150px'};
display:flex;
    justify-content:center;
    align-items:center;
width:100%;
`;

export const PublishIcon=styled.div`
width:18px;
  height:18px;
  display:inline-flex;
  justify-content: center;
  align-items:center;
  cursor:pointer;
 
  background: ${props=>props.color?props.color:'white'};
 border: solid 1px #979797;
 border-radius:100px;
  transition:all 0.3s;
  color:white;
  
  i
  {
  padding:0px;
  margin:0px;
  font-size:var(--sm-font-size);
  }`;

export const ExperienceDescription=styled.div`
 width:100%;
 margin-top:20px;
 font-size:var(--sm-font-size);
 color:#000;
 `;

export const ExperienceImageContainer=styled.img`
height: 100%;
    width: 100%;
    max-width: 100%;
    border:1px solid rgba(0,0,0,0.2);
    object-fit: cover;
    object-position: center;
    cursor: pointer;
    border-radius: 10px;`;

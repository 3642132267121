import { createActions, using } from "redux-box";

export default createActions({
  //....................PRODUCT ..................
  fetchPodcastList: using(""),
  setSelectedPodcast: using("podcast"),
  setEditPodcast: using("podcast"),
  updatePodcastList: using("updatePodcast"),
  createPodcast: using("updatePodcast"),

  fetchEpisodesByPodcastId: using("podcastId"),
  setFetchPodcastEpisodes: using(),
  createEpisode: using("episodeData"),
  updateEpisode: using("updatedEpisodeData"),
  requestConcatEpisodes: using("offset"),
  setEpisodeEditInfo: using("value"),
  setEpisodeEdit: using("value"),
});

import React, { Component } from 'react';
import { NotificationListContainer } from '@c/notifications-view/scheduled-notifications-list/ScheduledNotificationsList.styled';
import NotificationCard from "@c/notifications-view/scheduled-notifications-list/notification-card/NotificationCard";
import { connectStore } from 'redux-box';
import { module as notification } from "store/notification";
import InfiniteScroll from 'react-infinite-scroller';
import { NotificationScrollParentContainer, InfiniteScrollLoader, LoadingContainer, EmptyContainer } from "static/styled/custom.styled"



class SentNotificationsList extends Component {
    fetchMoreNotifications = () => {


        if (this.props.notification.notification_result !== null && this.props.notification.notification_result.has_next) { //// console.log("hello");
            setTimeout(() => {
                if (this.props.notification.notification_result !== null && this.props.notification.notification_result.next_page > 0) {
                    let currentPath = window.location.pathname;
                    let type = currentPath.includes('sent') ? 'sent' : 'in-progress'
                    type=currentPath.includes('failed')?'failed':type;
                    this.props.notification.requestConcatAllNotifications(
                        {
                            offset: this.props.notification.notification_result.next_page,
                            type: type
                        });
                }

            }, 300);
        }
    }

    render() {
        let { notification } = this.props;
        let currentPath = window.location.pathname;
        let notificationList = notification.notification_list && notification.notification_list.length > 0 &&
            notification.notification_list.map((notificationInfo, index) =>
                <NotificationCard notificationInfo={notificationInfo}
                    key={index} />);
        return (
            <div>
                {this.props.notification.fetch_notification_list ? <LoadingContainer style={{ zIndex: '100' }}>
                    <img alt="loading" width="60" height="60" src={require("static/img/Theme-loader.gif")} />
                </LoadingContainer> : (notification.notification_list && notification.notification_list.length > 0 ?
                    <NotificationScrollParentContainer>
                        <InfiniteScroll
                            pageStart={0}
                            loadMore={this.fetchMoreNotifications}
                            style={{ position: 'relative' }}
                            hasMore={this.props.notification.notification_result && this.props.notification.notification_result.has_next}
                            loader={<InfiniteScrollLoader className="loader"
                                style={{ bottom: 0 }} key={0} >
                                <img alt="loading" width="60" height="60" src={require("static/img/Theme-loader.gif")} /> </InfiniteScrollLoader>}
                            useWindow={false}>
                            <NotificationListContainer>
                                {notificationList}
                            </NotificationListContainer>
                        </InfiniteScroll>
                    </NotificationScrollParentContainer> : <EmptyContainer>
                        <h5 style={{ opacity: 0.5 }}>
                            <img src={require("static/img/notification_icon.png")}
                                alt="loading"
                                width="24" height="24" /> NO NOTIFICATIONS {currentPath.includes('sent') ? ' SENT' : ' IN PROGRESS'}</h5>
                    </EmptyContainer>)}
            </div>
        )

    }
}
export default connectStore({
    notification
})(SentNotificationsList)
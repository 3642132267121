import React, { Component } from "react";
import { SchoolListContainer } from "./SchoolCodeView.styled";
import SchoolViewCard from "./school-view-card/SchoolViewCard";
import {
  MainBodyContentContainer,
  PlusButton,
  PlusIcon,
    LoadingContainer
} from "@sc/custom.styled";
import { module as inviteCode } from "@st/inviteCode";
import { module as ui } from "@st/ui";
import { connectStore } from "redux-box";
import { module as onboard } from "@st/onboard";
import { module as product } from "@st/product";
import Permission from "@s/permission";
import swal from "sweetalert2";
import { withRouter } from "react-router";
import { InfiniteScrollLoader, InfiniteScrollParentContainer,AlignContentHorizontal } from "static/styled/custom.styled";
import InfiniteScroll from "react-infinite-scroller";
import { AutoContainer, MainBodyContainer, NotificationScrollParentContainer } from "../../static/styled/custom.styled";
import SchoolListHeader from "./school-list-header";
import { ThemeButton } from "static/styled/custom.styled";
import Report from "./Report.js"


class SchoolCodeView extends Component {
  constructor(props) {
    super();
  }

  componentDidMount() {
    const { inviteCode ,product } = this.props;
    product.fetchSchoolGuidedPathProduct()
    product.fetchProductList();
    inviteCode.getSchoolList();
    // inviteCode.getReport()
    console.log("inviteCode",this.props)
  }

  fetchMoreSchool = () => {
    if (
      this.props.inviteCode.schoolListResult &&
      this.props.inviteCode.schoolListResult.has_next
    ) {
      setTimeout(() => {
        if (
          this.props.inviteCode.schoolListResult !== null &&
          this.props.inviteCode.schoolListResult.next_page > 0
        )
          this.props.inviteCode.getSchoolList(
            this.props.inviteCode.schoolListResult.next_page
          );
      }, 300);
    }
  };
  
  handleAddClickButton = e => {
    const {permission} = this.props.onboard;
    if(Permission.getPermission(permission, "add_school")){
      this.props.ui.showModal("ADDSCHOOLFORM");
    }else{
      swal({
        text: "You do not have permission.",
        type: "warning"
      });
    }
  };


  render() {
    let { inviteCode } = this.props;
    const search = this.props.location.search;
    const report_id = new URLSearchParams(search).get("report");
    return (
      <MainBodyContainer>
       <SchoolListHeader showDownloadReport = {report_id ? true : false}/><br></br>
       
      <MainBodyContentContainer primary style={{padding:'0px 0'}}>
        { !report_id &&
         <AutoContainer>
          {this.props.inviteCode.fetch_school_list?
              <LoadingContainer>
                  <img alt="loading" width="60" height="60"  src={require("static/img/Theme-loader.gif")}/>
              </LoadingContainer>:
               this.props.inviteCode.adminSchoolList &&
               this.props.inviteCode.adminSchoolList.length > 0 ?
              <InfiniteScrollParentContainer full>
                <InfiniteScroll
                  pageStart={0}
                  loadMore={this.fetchMoreSchool}
                  style={{ position: "relative" }}
                  hasMore={
                    this.props.inviteCode.schoolListResult && this.props.inviteCode.schoolListResult.has_next
                  }
                  loader={
                    <InfiniteScrollLoader className="loader" key={0}>
                      <img
                        alt="loading"
                        width="60"
                        height="60"
                        src={require("static/img/Theme-loader.gif")}
                      />
                    </InfiniteScrollLoader>
                  }
                  useWindow={false}
                >
                  <AlignContentHorizontal marginBottom="70px">
                    {inviteCode.adminSchoolList && inviteCode.adminSchoolList.length !== 0 &&
                      inviteCode.adminSchoolList.map((school, id) => (
                      <SchoolViewCard key={school.id} schoolInfo={school} />
                      ))}
                  </AlignContentHorizontal>
                </InfiniteScroll>
              </InfiniteScrollParentContainer>
              :null
              }
            
            <PlusButton
              hide={this.props.ui.show_modal}
              onClick={this.handleAddClickButton}>
              <PlusIcon
                primary
                alt="Plus Icon"
                src={require("static/img/plusicon.png")}
              />
            </PlusButton>
          </AutoContainer>
        } 
        {
         
          report_id &&
          
          <div id = "divDownload">
          
          <Report/>
        </div>
        }
      </MainBodyContentContainer>
      </MainBodyContainer>
    );
  }
}

export default withRouter(connectStore({
  inviteCode,ui,onboard,product
 })(SchoolCodeView))

import { Api } from "./base";

const _headers = () => {
  return {
    Authorization:
      "JWT " + require("store").default.getState().onboard.user.auth_token,
    "Content-Type": "application/json"
  };
};

export default {
  getAllGrades() {
    return Api.get("/misc/v1/admin-grade/", _headers());
  },
  createGrades() {
    return Api.post("/grade/v1/");
  },
  getAllLevels() {
    return Api.get("/misc/v1/level/", _headers());
  }
};

import React, { Component } from "react";
import {
  ActivitiesListContainer,
  ActivitiesListHeader,
  ActivitiesListMainContainer,
} from "./PackActivitiesList.styled";
import { ThemeButton } from "@sc/custom.styled";
import { Link } from "react-router-dom";
import ActivityCard from "../../activities-list/ActivityCard/ActivityCard";
import { module as activity } from "@st/activity";
import { module as ui } from "@st/ui";
import { connectStore } from "redux-box";
import swal from "sweetalert2";
import { module as onboard } from "@st/onboard";
import Permission from "@s/permission";
// export default connectStore({
//   activity,
//   ui,
// })
// export default 
class PackActivitiesList extends Component {
  constructor(props) {
    super();
    this.state = {};
  }

  handleActivityClick = (activity) => {
    this.props.activity.setActiveActivity(activity);
  };
  handleDeleteClick = (activity, e) => {
    swal({
      text: "Are you sure that you want to delete this activity?",
      type: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then((result) => {
      if (result.value) {
        this.props.activity.updateActivity({ ...activity, is_deleted: true });
      }
    });
    e.preventDefault();
  };

  handlePublishClick = (activity, e) => {
    let value = null;
    if (activity.is_published) value = "unpublish";
    else value = "publish";
    swal({
      text: "Are you sure that you want to " + value + " this activity?",
      type: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then((result) => {
      if (result.value) {
        this.props.activity.requestPublishActivity({
          ...activity,
          is_published: !activity.is_published,
        });
      }
    });
    e.preventDefault();
  };

  addActivity = () => {
    // this.props.activity.unsetShowActivityPack();
    this.props.ui.showModal("ACTIVITYFORM");
  };
  render() {
    let activitiesList = this.props.activitiesList;
    const {permission} = this.props.onboard;
    return (
      <ActivitiesListContainer>
        <ActivitiesListHeader>
          <span>
            {" "}
            {activitiesList ? activitiesList.length : null} &nbsp;Activities in
            the Pack{" "}
          </span>
          {Permission.getPermission(permission, "change_activity")? 
            <Link
              to={
                "/activities/packs/" +
                this.props.activity.active_grade_level +
                "/" +
                this.props.activity.active_level +
                "/" +
                this.props.activity.active_activity_pack.id +
                "/activity/new"
              }
            >
              <ThemeButton primary onClick={this.addActivity}>
                {" "}
                Add Activity{" "}
              </ThemeButton>{" "}
            </Link>:null}
        </ActivitiesListHeader>
        {activitiesList && activitiesList.length !== 0 && (
          <ActivitiesListMainContainer>
            {activitiesList.map((activityInfo, i) => {
              return (
                <div className="col-sm-6 col-md-4 col-lg-4" key={i}>
                  <Link
                    to={
                      "/activities/packs/" +
                      this.props.activity.active_grade_level +
                      "/" +
                      this.props.activity.active_level +
                      "/" +
                      activityInfo.activity_pack +
                      "/activity/edit/" +
                      activityInfo.id
                    }
                  >
                    <ActivityCard
                      activityId={i}
                      activityInfo={activityInfo}
                      handleDeleteClick={(e) =>
                        this.handleDeleteClick(activityInfo, e)
                      }
                      handlePublishClick={(e) =>
                        this.handlePublishClick(activityInfo, e)
                      }
                    />
                  </Link>
                </div>
              );
            })}
          </ActivitiesListMainContainer>
        )}
      </ActivitiesListContainer>
    );
  }
}

export default connectStore({
  activity,
 ui,onboard
 })(PackActivitiesList)
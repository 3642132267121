import React, { Fragment } from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import { ModalBackDrop } from "static/styled/ModalBackDrop.styled";
import QuizChallengeCreator from "../components/quiz2-view/quiz2-creator";

export const Routes = function({ location }) {
  return (
    <Switch location={location}>
      <Route
        path="/quiz2/edit/:id"
        render={(props) => (
          <Fragment>
            <QuizChallengeCreator {...props} />
            <ModalBackDrop active second />
          </Fragment>
        )}
      />
    </Switch>
  );
};

export default withRouter(Routes);

import React from "react";
import { CardContainer, Title } from "./SchoolGuidedPathCard.styled";
import { connectStore } from "redux-box";
import { CardIcon, CardIconContainer } from "static/styled/custom.styled";
import { ToolbarItem } from "@sc/Section.styled";
import { module as ui } from "@st/ui";
import { module as schoolGuidedPath } from "@st/schoolGuidedPath";
import { module as onboard } from "@st/onboard";

function SchoolGuidedPathCard(props) {
  const schoolGuidedPathInfo = props.schoolGuidedPathInfo;
  const { user } = props.onboard;
  const user_group = user.groups[0].name;

  const handleEditClick = (evt) => {
    evt.preventDefault();
    props.schoolGuidedPath.setUnitEditInfo(schoolGuidedPathInfo);
    props.ui.showModal("SCHOOLGUIDEDPATH");
    //   this.props.caseStudy.enableCaseStudyEdit(true);
    //   this.props.caseStudy.setCaseStudyId(this.props.caseStudyInfo.id);
  };
  const hadleUnlock = (evt) => {
    evt.preventDefault();
    console.log(schoolGuidedPathInfo)
    // let unit_data = { ...schoolGuidedPathInfo,unitId:schoolGuidedPathInfo.id};
    // unit_data.is_unlocked = !unit_data.is_unlocked;
    let unit_data ={
      is_unlocked:!schoolGuidedPathInfo.is_unlocked,
      unitId:schoolGuidedPathInfo.id
    }

    props.schoolGuidedPath.updateUnit(unit_data);
    props.ui.hideModal();
    props.schoolGuidedPath.setUnitEdit(false);
  };

  return (
      <CardContainer>
        <CardIconContainer>
          <CardIcon onClick={hadleUnlock}>
          {schoolGuidedPathInfo.is_unlocked ? (
            <i className="fa fa-unlock" />
          ) : (
            <i className="fa fa-lock" />
          )}
          </CardIcon>
          {user_group !== "spectator" ?
            <ToolbarItem primary onClick={handleEditClick}>
              <div className="icon-container">
                <i className="fa fa-pencil" />
              </div>
            </ToolbarItem>:null}
          <CardIcon>
            <i className="fa fa-trash" />
          </CardIcon>
        </CardIconContainer>

        <Title>{schoolGuidedPathInfo.name}</Title>
      </CardContainer>
  );
}

export default connectStore({
  ui,
  schoolGuidedPath,
  onboard
})(SchoolGuidedPathCard);

import React, { Component } from "react";
import { ThemeButton, PanelHeader } from "@sc/custom.styled";
import {
  ActivityModalContainer,
  ActivityPopUpContainer
} from "@sc/ActivityModal.styled";
import { InputBox, InputArea } from "@sc/InputField.styled";
import { connectStore } from "redux-box";
import { module as tags } from "@st/tags";
import { module as ui } from "@st/ui";
import Permission from "@s/permission";
import { module as onboard } from "@st/tags";


 class ActivityAssignTags extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      parent_tag: "",
      ideal_value: "",
      description: ""
    };
  }
  handleTagChange = e => {
    this.setState({
      name: e.target.value
    });
  };
  handleValueChange = e => {
    this.setState({
      ideal_value: e.target.value
    });
  };
  handleDescriptionChange = e => {
    this.setState({
      description: e.target.value
    });
  };
  handleTagNameChange = e => {
    this.setState({
      name: e.target.value
    });
  };
  handleEnterClick = e => {
    if (e.key === "Enter" && e.target.value.trim().length) {
      this.handleAddClick(e);
    }
  };
  handleAddClick = e => {
    let { tags } = this.props;
    let tag = {
      name: this.state.name,
      ideal_value: this.state.ideal_value,
      parent_tag: this.props.id,
      description: this.state.description
    };
    console.log(tag);
    if (this.state.name.trim().length) {
      if (tags.activeChildTag.id !== undefined) {
        tags
          .updateTagById(
            {
              id: tags.activeChildTag.id
            },
            {
              name: this.state.name,
              ideal_value: this.state.ideal_value,
              parent_tag: tags.activeChildTag.parent_tag,
              description: this.state.description
            }
          )
          
      } else {
        tags.createChildTag(tag)
      }
    }
    this.setState({
      name: "",
      ideal_value: "",
      description: ""
    });
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.tags && nextProps.tags.activeChildTag) {
      this.normalizeForState(nextProps.tags.activeChildTag);
    } else {
      this.setState({
        name: "",
        ideal_value: "",
        description: ""
      });
    }
  }
  normalizeForState = tags => {
    this.setState({
      name: tags.name,
      ideal_value: tags.ideal_value,
      description: tags.description
    });
  };
  handleTagClick(tag) {
    let { tags } = this.props;
    tags.requestCreateTag(tag);
  }

  onDoneClick = e => {
    this.props.activity.closeEditor();
  };

  render() {
    const {permission} = this.props.onboard
    return (
      <ActivityPopUpContainer>
        <PanelHeader primary>
          <span>Assign Tags</span>
          {Permission.getPermission(permission, "change_tag")?
          <ThemeButton primary onClick={this.handleAddClick}>
            Done
          </ThemeButton>:null}
        </PanelHeader>
        <ActivityModalContainer>
          <InputBox
            type="text"
            autoFocus={true}
            placeholder="Enter Skill Name"
            value={this.state.name}
            onChange={this.handleTagNameChange}
            style={{ marginTop: "10px" }}
          />
          <InputBox
            type="number"
            placeholder="Enter Accuracy Value"
            value={this.state.ideal_value}
            onChange={this.handleValueChange}
          />
          <InputArea
            placeholder="Enter description"
            value={this.state.description}
            onChange={this.handleDescriptionChange}
          />
        </ActivityModalContainer>
      </ActivityPopUpContainer>
    );
  }
}
export default connectStore({
  tags,
  ui,
  onboard
})(ActivityAssignTags)
import styled from "styled-components";
import { CreateMCQQuestionContainer } from "../../../static/styled/McqEditor.styled";

export const NewsFreedsSectionContainer = styled.div`
  padding-top: 220px;
  overflow-y: auto;
  height: 100%;
  width: 100%;
  padding-bottom: 50px;
  margin-bottom: 20px;
`;

export const NewsFreedsDescriptionContainer = CreateMCQQuestionContainer.extend`
  min-height: 180px;
  max-height: 380px;
  margin: 0px 14px;
  textarea {
    font-size: var(--md-font-size);
    ::placeholder {
      font-size: var(--lg-font-size);
    }
  }
`;

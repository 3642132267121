import styled from "styled-components";
import stars from "@i/stars.png";
import { FlexStart } from "static/styled/custom.styled";
import { DropDownMenu } from "static/styled/custom.styled";

export const NotificationParentContainer = styled.div`
  padding-top: 15px;
`;

export const DropdownFlexStart = FlexStart.extend`
     width:100%;
     padding:6px 8px;
     position:relative;
     font-size:${props => (props.second ? "14px" : "12px")};
     border-bottom: solid 1px #e6e6e6}
`;

export const ModalViewParentContainer = styled.div`
  width: 100%;
  height: 540px;
  position: relative;
`;
export const NotificationDropdownParentContainer = styled.div`
  display: flex;
  position: relative;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  margin: 14px 0px;
  flex-flow: row nowrap;
`;
export const ModalBackDrop = styled.div`
  position: fixed;
  background-color: var(--primary-color);
  background-image: url(${stars});
  top: 0;
  transition: all 0.3s;
  opacity: ${props => (props.active ? 0.8 : 0)};
  pointer-events: ${props => (props.active ? "unset" : "none")};
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 900;
  transition: 0.3s;
  background-size: cover;
  max-width: 100%;
`;

export const ModalView = styled.div`
  position: absolute;
  bottom: 0;
  right: 10px;
  min-height: 500px;
  min-width: 350px;
  transition: 0.3s;
  max-width: 700px;
  background: white;
  border-radius: 10px;
`;

export const ModalViewContainer = styled.div`
  height: 100%;
  border-radius: 10px;
  padding: 20px;
`;

export const CloseIconBar = styled.span`
  // padding: 10px;
  position: absolute;
  right: 5px;
  top: 15px;
  cursor: pointer;
  border: 1px solid #ff8a23;
  border-radius: 100px;
  height: 26px;
  width: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s;
  &:hover {
    border: 1px solid #000;
    background: #000;
  }
  > img {
    height: 13px;
    width: 13px;
  }
`;

export const ModalContainer = styled.div`
  position: relative;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  z-index: 1000;
  transition: all 0.3s;
  transform: ${props => (props.active ? "translateY(0)" : "translateY(200px)")};
  opacity: ${props => (props.active ? 1 : 0)};
  pointer-events: ${props => (props.active ? "unset" : "none")};
  transition: all 0.2s;
`;

//  NOTIFICATION DROPDOWN COMMMON CSS

export const NotificationSearchInputContainer = styled.div`
 width: 175px;
  border-bottom:1px solid rgba(0,0,0,0.4);
  height:32px;
  z-index:1000;
  background-color:white;
   position:fixed;
    i {
    font-size: 14px;
    position: absolute;
   display:flex;
    padding:0px 10px 4px;
   top:0;
   left:0;
   height:100%;
   justify-content:center;
    align-items:center;
    
    }
    
    input {
    font-size: var(--sm-font-size);
    
    padding:5px 5px 5px 30px;
    border:none;
    width: 170px;
    outline: none;
    color:var(--primary-color);
    ::placeholder{
    color: rgba(0,0,0,0.6);
  }`;

export const DropdownError = styled.div`
  font-size: 12px;
  color: red;
  width: 100%;
`;

export const NotificationScrollParentContainer = DropDownMenu.extend`
  margin-top: 5px;
`;

export const ChildDataDescription = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-flow: column nowrap;
  font-size: 14px;
  div {
    width: 100%;
    font-size: 12px;
  }

  i {
    font-size: 10px;
    color: #ff8a23;
  }
`;

export const DoneButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: absolute;
  bottom: 40px;
  right: 20px;
`;

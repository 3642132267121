import React, { Component } from "react";
import { connectStore } from "redux-box";

import {
  CardContainer,
  StyledDiv,
  StyledDivImage,
  Title,
} from "@c/activity-view/activities-list/ActivityCard/ActivityCard.styled";
import {
  CardIcon,
  CardIconContainer,
} from "static/styled/custom.styled";
import { module as onboard } from "@st/onboard";
import { module as podcast } from "@st/podcast";
import swal from "sweetalert2";

class PodcastCard extends Component {

  handlePublishClick = (e) => {
    e.stopPropagation()
    let { podcastDetails } = this.props;

    let value = null;
    if (podcastDetails.is_published) value = "unpublish";
    else value = "publish";

    swal({
      text: "Are you sure that you want to " + value + " this podcast",
      type: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then((result) => {
      if (result.value) {
        this.props.podcast.updatePodcastList({
          ...podcastDetails,
          is_published: !podcastDetails.is_published,
        });
      }
    });
    // e.preventDefault();
  };

  render() {
    const { podcastDetails } = this.props;

    return (
      <div onClick={this.props.handlePodcastSelect}>
        <CardContainer>
          <StyledDiv secondary>
            <Title>{podcastDetails.name}</Title>
            <Title primary>
              {this.props.podcastDetails &&
              this.props.podcastDetails.description &&
              this.props.podcastDetails.description.length < 36
                ? podcastDetails.description
                : podcastDetails.description.slice(0, 40) + "..."}
            </Title>
          </StyledDiv>
          <StyledDivImage primary image={podcastDetails.thumbnail}>
            <CardIconContainer>
              <CardIcon onClick={this.props.handleEditClick}>
                <i className="fa fa-pencil" />
              </CardIcon>
              <CardIcon
                primary
                onClick={this.handlePublishClick}
                bg={podcastDetails.is_published}
              >
                <i className="fa fa-check" />
              </CardIcon>
            </CardIconContainer>
          </StyledDivImage>
        </CardContainer>
      </div>
    );
  }
}
export default connectStore({  onboard,podcast })(PodcastCard);

import styled from "styled-components";

export const Container = styled.div`


`;

export const DateTimeContainer = styled.div`

`;
export const NameContainer = styled.div`
img {
    height: 35px;
    width: 35px;
    object-fit: cover;
    object-poisition: top;
    margin: 0 8px;
    border-radius: 100px;
    border: 1px solid white;
    align-self: flex-start;
  }
  div {
    color: white;
    p {
      margin: 0;
      font-weight: 600;
      height: ${props => (props.active ? "100%" : "")};
      align-items: center;
      display: flex;
    }
    div {
    }
  }
`;

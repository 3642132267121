import React, { Component } from "react";
import { SearchBarContainer } from "@c/users-view/MainContent.styled";
import {
  NavigationContainer,
  NavigationHeaderContainer,
  NavigationToolbar
} from "@sc/NavigationBar.styled";
import { Link } from "react-router-dom";
import { connectStore } from "redux-box";
import DatePicker from "react-datepicker";
import { DateSelectionField } from "@sc/custom.styled";
import { module as experience } from "store/experience";
import moment from 'moment';
import { module as onboard } from "@st/onboard";
import Permission from "@s/permission";

class ExperienceViewHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchBarEnable: false,
      navigationSelector: false,
      // date: moment(),
      date: '',
      // date: new Date(),

    };
  }
  componentDidMount = () => {
    if (this.props.experience.experience_date) {
      // let newDate = moment.unix(this.props.experience.experience_date).format('YYYY-MM-DD');
      this.setState({
        date: this.props.experience.experience_date
      })
    }
  }
  enableSearchBar = actionType => {
    if (actionType === "focus" && this.state.searchBarEnable === false)
      this.setState({
        searchBarEnable: true
      });
    else if (actionType === "blur" && this.state.searchBarEnable === true)
      this.setState({
        searchBarEnable: false
      });
  };
  //function for date selector (function starts)
  handleDateChange = date => {
    let { experience } = this.props;
    this.setState({
      date: date,

    })
    if (date) {
      let selectedDate = new Date(date);
      let month =
        selectedDate.getMonth() >= 9
          ? selectedDate.getMonth() + 1
          : "0" + (selectedDate.getMonth() + 1);
      let day =
        selectedDate.getDate() < 10
          ? "0" + selectedDate.getDate()
          : selectedDate.getDate();
      let dateData = selectedDate.getFullYear() + "-" + month + "-" + day;
      // experience.setActiveExperienceDate(new Date(dateData).getTime() / 1000);
      experience.unsetAllExperiencesList();
      experience.setActiveExperienceDate(dateData);
      experience.requestGetAllExperiencesList(0);
    }
  };
  //function for date selector in stories(function ends)

  handleClearBtn = () => {
    let { experience } = this.props;
    this.setState({
      date: ""
    });
    experience.setActiveExperienceDate("");
    experience.requestGetAllExperiencesList(0);
  };

  handleSearchChange = evt => { };
  handleNavigationChange = data => {
    let { experience } = this.props;
    if (data !== experience.experience_type) {
      experience.setExperienceType(data.link);
      experience.unsetAllExperiencesList();
      experience.requestGetAllExperiencesList(0);
    }
  };
  onDatepickerRef(el) {
    if (el && el.input) {
      el.input.readOnly = true;
    }
  }
  render() {
    const {permission} = this.props.onboard;
    const data = [
      { title: "Activities", link: "activity",permission:['activity experience'] },
      { title: "Reading Challenges", link: "reading",permission:['reading experience'] },
      { title: "Stories", link: "story" ,permission:['story experience']}
    ];
    let levelList = data.map((type, i) => (
      Permission.getPermissionForMenu(permission, type.permission,null)?
      <Link
        to={"/experiences/" + type.link}
        key={i}
        className={
          this.props.experience.experience_type === type.link ? "active" : ""
        }
        onClick={() => this.handleNavigationChange(type)}
      >
        {type.title}
      </Link>:null
    ));
    return (
      <NavigationHeaderContainer>
        <NavigationContainer>{levelList}</NavigationContainer>
        <NavigationToolbar>
          <div id="payments-date">
            <DateSelectionField width="220px" style={{ overflow: "hidden" }}>
              <img src={require("static/img/date-icon.png")} alt="startDate" />
              <DatePicker
                selected={this.state.date}
                selectsStart
                startDate={this.state.date}
                onChange={this.handleDateChange}
                placeholderText='Select date'
                ref={el => this.onDatepickerRef(el)}
              />
              {this.state.date ? (
                <i
                  className="fa fa-times"
                  onClick={() => this.handleClearBtn()}
                  style={{ padding: "8px", cursor: "pointer" }}
                />
              ) : null}
            </DateSelectionField>
          </div>
          {/* <SearchBarContainer
            width={this.state.searchBarEnable ? "300px" : "200px"}
          >
            <i className="fa fa-search" />
            <input
              type="text"
              onChange={this.handleSearchChange}
              placeholder="Search"
              value={this.props.experience.search_experience_text}
              onFocus={() => this.enableSearchBar("focus")}
              onBlur={() => this.enableSearchBar("blur")}
            />
          </SearchBarContainer> */}
        </NavigationToolbar>
      </NavigationHeaderContainer>
    );
  }
}
export default connectStore({
  experience,onboard
})(ExperienceViewHeader)
import React, { useEffect } from 'react'
import { useState } from 'react'
import { module as user } from "@st/user";
import { connectStore } from "redux-box";
import { module as onboard } from "@st/onboard";
import { module as ui } from "@st/ui";

import './AssignFreadomTeacher.css'
import { ThemeButton } from '../../../../static/styled/custom.styled';
import Select from 'react-select'


const AssignFreadomTeacher = (props) => {

  const [section,setSection] = useState([])
  const [sectionsList,setSectionsList] = useState([])


  const customStyles = {
    menu: (provided, state) => ({
      ...provided,
      position: "relative"
    }),
  };

  useEffect(()=>{
    let schoolclassList=[]
    if(props.user.active_child){
      let difference = props.user.active_child.school_class.filter(
        (sc) => sc.teacher !== props.user.active_child.teacher
      );
        if(difference.length>0){
          difference.forEach((sc)=>{
            schoolclassList.push({
              label:sc.name,
              value:sc.id
            })
      })
    }else{
      props.user.active_child.school_class.forEach((sc)=>{
        schoolclassList.push({
          label: sc.name,
          value: sc.id
        })
      })
    }
      setSectionsList(schoolclassList)
    }
  },[])


  const handleAssignFreadomTeacher = async() => {
    const data = {
      is_freadom_teacher: true,
      child: props.user.active_child.id,
      school_class:section.map((sec)=>sec.value)
    }
    await props.user.updateFreadomTeacher(data)
    props.ui.hideModal()
  }
  return (
    <div className='link_school_code_form'>

        <React.Fragment>
          <Select
            className="dropdown-container"
            isMulti = {true}
            value={section}
            onChange={(selectedSection) =>
              setSection(selectedSection)
            }
            options={sectionsList && sectionsList}
            placeholder="Select Section"
            styles={customStyles}
          />

          <ThemeButton primary onClick={() => handleAssignFreadomTeacher()}
            type="button" className="link_school_code_form_btn">Assign as Freadom Teacher</ThemeButton>
        </React.Fragment>

    </div>
  )
}

export default connectStore({
  user,
  onboard,
  ui
})(AssignFreadomTeacher);
import React, { Component } from "react";
import {
  AlignContentHorizontal,
  EmptyContainer,
  AutoContainer,
  LoadingContainer,
  InfiniteScrollParentContainer,
  InfiniteScrollLoader
} from "static/styled/custom.styled";
import { connectStore } from "redux-box";
import { Link } from "react-router-dom";
import AllExperienceCard from "../all-experience-card/AllExperienceCard";
import { module as experience } from "store/experience";
import InfiniteScroll from "react-infinite-scroller";

class AllExperiencesList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false
    };
  }
 
  fetchMoreExperiences = () => {
    let { experience } = this.props;
    if (
      experience.all_experiences_result !== null &&
      experience.all_experiences_result.has_next
    ) {
      setTimeout(() => {
        if (
          experience.all_experiences_result !== null &&
          experience.all_experiences_result.next_page > 0
        )
          experience.requestGetAllExperiencesList(
            experience.all_experiences_result.next_page
          );
      }, 300);
    }
  };
  //REACT LIFECYCLE METHODS
  componentWillUnmount() {
    let { experience } = this.props;
    experience.unsetAllExperiencesList();
    experience.setAllExperienceNext({ next: false, offset: 0 });
  }
  render() {
    let { experience } = this.props;
    let experienceList = (
      <AlignContentHorizontal>
        {experience.all_experiences_list &&
          experience.all_experiences_list.length > 0 ? (
            experience.all_experiences_list.map((experienceInfo, index) => {
              return (
                <div className="col-sm-6 col-md-4 col-lg-3" key={index}>
                  <Link
                    to={`/experiences/${experience.experience_type}/edit/${
                      experienceInfo.id
                      }`}
                  >
                    <AllExperienceCard
                      experienceInfo={experienceInfo}
                      handlePublishClick={e =>
                        this.handlePublishClick(experienceInfo, e)
                      }
                    />
                  </Link>
                </div>
              );
            })
          ) : (
            <EmptyContainer>
              <h4>NO EXPERIENCES CREATED YET!</h4>
            </EmptyContainer>
          )}
      </AlignContentHorizontal>
    );
    return (
      <AutoContainer>
        {this.props.experience.fetch_all_experience_list ? (
          <LoadingContainer style={{ zIndex: "100" }}>
            <img
              alt="loading"
              width="60"
              height="60"
              src={require("static/img/Theme-loader.gif")}
            />
          </LoadingContainer>
        ) : (
            <InfiniteScrollParentContainer style={{ top: 0, marginBottom: 0 }}>
              <InfiniteScroll
                pageStart={0}
                loadMore={this.fetchMoreExperiences}
                style={{ position: "relative" }}
                hasMore={experience && experience.experience_next}
                loader={
                  <InfiniteScrollLoader className="loader" key={0}>
                    <img
                      alt="loader"
                      width="50"
                      height="50"
                      src={require("static/img/Theme-loader.gif")}
                    />
                  </InfiniteScrollLoader>
                }
                useWindow={false}
              >
                {experienceList}{" "}
              </InfiniteScroll>
            </InfiniteScrollParentContainer>
          )}
      </AutoContainer>
    );
  }
}
export default connectStore({
  experience
})(AllExperiencesList)
import styled from "styled-components";
import { SubHeading, UploadContainer } from "@sc/custom.styled";

export const Container = styled.div``;

export const ChildProfileContainer = styled.div`
  padding: 10px 30px;
`;

export const ChildUploadContainer = UploadContainer.extend`
  background-color: rgba(0, 0, 0, 0.07);
  padding: 10px 14px;
`;

export const ChildLabels = styled.div`
  display: flex;
  justify-content: center;
  flex-flow: row nowrap;
  align-items: center;
`;

export const ChildInputField = ChildLabels.extend`
  dispaly: inline-flex;
  justify-content: flex-start;
  align-items: center;
  input {
    margin-left: 14px;
  }
`;
export const AddButton = styled.button`
  background: ${props => props.color};
  color: white;
  width: 30px;
  height: 30px;
  border: 0;
  border-radius: 100px;
  cursor: pointer;
`;

export const ParentDetailsContainer = styled.div`
  padding: 14px 0px;
`;
export const ParentInfo = styled.div`
  color: ${props => props.color};
  font-size: ${props => props.align};
`;

export const ChildSubHeading = SubHeading.extend`
  margin-bottom: 0px;
  font-weight: 500;
`;

export const ChildFieldLabel = styled.div`
  width: 33%;
  margin: 0 0.5rem;
  font-size: 16px;
  color: rgba(67, 38, 102, 0.6);
`;

export const InterestsContainer = styled.div`
  font-size: 14px;
  color: rgba(67, 38, 102, 0.6);
  span {
    font-size: 16px;
    color: #432666;
  }
`;

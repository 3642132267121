import styled from "styled-components";
import { McqSectionHeader } from "@sc/custom.styled";

export const Container = styled.div`
  overflow: auto;
  height: 100%;
`;

export const AssessmentsHeader = McqSectionHeader.extend`
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  padding-bottom: 14px;
`;

export const GradeSelectionContainer = styled.div`
  padding: 18px 0px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  align-items: center;
`;

export const AssessmentLabels = styled.span`
  font-size: var(--lg-font-size);
  font-weight: bold;
`;
export const BorderBottom = styled.div`
  border: 1px solid rgba(0, 0, 0, 0.2);
`;

export const CreateMcqContainer = styled.div`
  padding-top: 180px;
  padding-bottom: 10px;
`;

export const McqListContainer = styled.div`
  height: 100%;
  overflow-y: auto;
  width: 100%;
`;

export const McqContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  //padding:0px 14px;
  align-items: center;
`;
export const AssessmentName = styled.div``;

import React, {Component} from 'react';
import {EditorMainContainer, Heading, PanelHeader, ThemeButton} from "static/styled/custom.styled";
import {module as activity} from "@st/activity";
import {module as readingChallenge} from "@st/readingChallenge";
import {connectStore} from "redux-box";
import ExperiencesList from "./experiences-list/ExperiencesList";
import ExperienceCard from "./experience-card/ExperienceCard";


 class ExperienceEditor extends Component
{
    componentWillUnmount()
    {
        this.props.readingChallenge.setActiveReadingExperience(null);
    }

    handleDoneBtn=()=>
    {
        this.props.readingChallenge.setActiveReadingExperience(null);
        this.props.activity.setEditorType('')

    }
    render(){
        return(
            <EditorMainContainer style={{overflow:'hidden',padding:'0px'}}>
                <PanelHeader primary>
                    {!this.props.readingChallenge.active_experience? <Heading style={{margin:'0px'}} >  All experiences </Heading>:
                        <div style={{display:"flex",alignItems:"center"}}>
                            <i className="fa fa-chevron-left" style={{fontSize:'16px',marginRight:'8px',cursor:"pointer"}} onClick={()=>this.props.readingChallenge.setActiveReadingExperience(null)}/>
                            <Heading style={{margin:'0px'}} >  Experience details </Heading>
                        </div>}
                    <ThemeButton primary onClick={this.handleDoneBtn}>Done</ThemeButton>
                </PanelHeader>
                {!this.props.readingChallenge.active_experience?
                    <ExperiencesList/>:
                    <ExperienceCard/>}
            </EditorMainContainer>
        )
    }
}
export default connectStore({
    activity,readingChallenge
})(ExperienceEditor)
import styled from "styled-components";

export const CreateTaskButton = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-bottom : 20px;
`
export const TaskListContainer = styled.div`
    display : flex;
    flex-direction : row;
    justify-content: space-between;
`

export const PreLiveClass = styled.div`

`

export const LiveClassTitle = styled.div`
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #000000;
    margin-bottom : 20px;
`
import React, { Component } from 'react';
import { module as payment } from '@st/payment';
import { connectStore } from "redux-box";
import {SectionCard} from "../PaymentsLink.styled"


 class GeneratePaymentLink extends Component {
    constructor(props) {
      super();
      this.state = {
      };
    }

    componentDidMount() {
        
    }

    generatePaymentLink= (e) => {
        e.stopPropagation();
        this.props.payment.generatePaymentLink();
    }

    finalPlanAmount= () => {
        let { payment } = this.props;
        var amount= 0;
        (payment.selectedPlan!=={}) && (amount= payment.selectedPlan.baseAmount);
        (payment.validatedPromoCode!=null) && (amount-= (payment.validatedPromoCode.percentage_value*amount/100))
        return amount.toFixed(2);
    }

    finalPlanDays= () => {
        let { payment } = this.props;
        var days= 0;
        (payment.selectedPlan!=={}) && (days= payment.selectedPlan.baseDays);
        (payment.validatedPromoCode!=null) && (days+= payment.validatedPromoCode.days_value)
        return days;
    }

    render() {
        return (
            <SectionCard className="phone-number-input border rounded p-2 my-2">
                <h6 className="text-muted"><b>STEP 5: Generate Payment Link</b> </h6>
                    {this.props.payment.paymentLink!==""?
                        <div className="text-center pb-2">
                        <div className="alert alert-success" role="alert" 
                        style={{width: '70%',textAlign: 'center', margin: '1rem auto'}}>
                            <h5 className="alert-heading">
                                Payment Link: <a href={this.props.payment.paymentLink}>{this.props.payment.paymentLink}</a>
                            </h5>
                            <hr className="mb-0" />
                            <p>
                                NOTE: Sent to {this.props.payment.phoneNumber} via SMS.
                            </p>
                        </div>

                        </div>
                    :   
                        <div className="text-center pb-2">
                            <div className="alert alert-success" role="alert" 
                            style={{width: '70%',textAlign: 'center', margin: '1rem auto'}}>
                                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                    <h5 className="alert-heading">{this.props.payment.selectedChild.name}</h5>
                                    <p className="mb-0">Plan Name:<b> {this.props.payment.selectedPlan.name}</b></p>
                                </div>
                                <hr className="mb-0" />
                                <div className="row" style={{justifyContent: 'center'}}>
                                    <div className="col-sm-5">
                                        <div className="alert alert-dark m-2 p-2" role="alert">
                                            <b>Final Days: </b> {this.finalPlanDays()}
                                        </div>
                                    </div>
                                    <div className="col-sm-5">
                                        <div className="alert alert-dark m-2 p-2" role="alert">
                                            <b>Final Amount: </b>&#8377; {this.finalPlanAmount()}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <button type="button" className="btn btn-success"
                            onClick={this.generatePaymentLink}>
                                Generate Payment Link<i className="fa fa-refresh ml-2"/>
                            </button>
                        </div>
                    }
                    
            
            </SectionCard>
        )
    }
}

export default connectStore({
    payment
})(GeneratePaymentLink)

import {CountItem} from "static/styled/custom.styled";


export const FreadCountItem=CountItem.extend`
  justify-content:flex-start;
  display:block;
    
    span
    {
        margin-left:4px;
    }
    `;


import React, { Fragment } from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import { ModalBackDrop } from "static/styled/ModalBackDrop.styled";
import SchoolGuidedPathTaskCreator from "@c/school-guided-path-view/school-guided-path-task-creator/SchoolGuidedPathTaskCreator";

export const Routes = function({ location }) {
  return (
    <Switch location={location}>
      <Route
        path="/school-guided-path/edit/:id"
        render={props => (
          <Fragment>
            <SchoolGuidedPathTaskCreator {...props} />
            <ModalBackDrop active second />
          </Fragment>
        )}
      />
      <Route
        path="/school-default-guided-path/edit/:id"
        render={props => (
          <Fragment>
            <SchoolGuidedPathTaskCreator {...props} />
            <ModalBackDrop active second />
          </Fragment>
        )}
      />
    </Switch>
  );
};

export default withRouter(Routes);

import React, { Component } from "react";
import logo from "@i/Logo.png";
import { Link } from "react-router-dom";
import { version } from "../../../../package.json";

import {
  TDList
} from "./Table.styled";

export default class Table extends Component {
  constructor(props) {
    super();
    this.state = {
    };
  }  

  componentDidMount = () => {
    // if(this.props) {
    //   console.log("tableData: ", this.props);
    // }
  }

  formatDate= (dateString) => {
    if(dateString){
      const date= new Date(dateString);
      var monthNames = [
        "Jan", "Feb", "March",
        "April", "May", "June", "July",
        "Aug", "Sep", "Oct",
        "Nov", "Dec"
      ];
      
      var day = date.getDate();
      var monthIndex = date.getMonth();
      var year = date.getFullYear();
    
      return monthNames[monthIndex]+' '+ day +', ' + year;
    }else{
      return "-";
    }
    
  }

  render() {

    // return table head which are not icons
    let tableTextHeads= (
      (this.props.colData) && 
      (this.props.colData
      .filter(col=> col.type!=="ICONS" && col.type!=="LIST" && col.type!=="ACTION",)
      .map((col, index)=> <th key={index+col.name} scope="col">{col.name}</th>))
    )

    let tableListHeads= (
      (this.props.colData) && 
      (this.props.colData
      .filter(col=> col.type==="LIST")
      .map((col, index)=> <th key={index} scope="col">{col.name}</th>))
    )

    let tableActionHeads= (
      (this.props.colData) && 
      (this.props.colData
      .filter(col=> col.type==="ACTION")
      .map((col, index)=> <th key={index} scope="col">{col.name}</th>))
    )

    let tableData= (
      (this.props.tableData) && 
      this.props.tableData
      .map((tr, index)=> {
        return (
        <tr key={index}>
          <th scope="row">{index+1}</th>
          { // for icon stats data
            (this.props.colData
            .some(col=> col.type==="ICONS")
            ) &&
            (<td> 
            {
            (this.props.colData) &&
            (this.props.colData
            .filter(col=> col.type==="ICONS")
            .map((col, idx)=> (
                <i key={idx} className={"mx-1 p-1 bg-light rounded fa fa-"+col.name}><span className="ml-1 badge badge-primary">{tr[col.value]}</span></i>
            ))
            )}
            </td>)
          }
         
          { // only text data
            (this.props.colData) &&
            (this.props.colData
            .filter(col=> col.type!=="ICONS" && col.type!=="LIST" && col.type!=="ACTION")
            .map((col, idx)=> (
              <td key={index+idx} style={{wordBreak: 'break-word'}}> {
                ((col.type==="TEXT") && (tr[col.value].toString().slice(0, 50))) ||
                ((col.type==="DATE") && (this.formatDate(tr[col.value])))
              }</td>)))
          }
          { // only List data
            (this.props.colData) &&
            (this.props.colData
            .filter(col=> col.type==="LIST")
            .map((col, idx)=> (
              <TDList key={tr.id+idx} className="contains-list"> 
                  {
                    (col.key)?
                    (
                      tr[col.value]
                      .map((k, i)=> <span key={tr.id+i+col.key} className="badge badge-primary mx-1">{k[col.key]}</span>)
                    ):
                    (
                      tr[col.value]
                      .map((k, i)=> <span key={tr.id+i} className="badge badge-primary mx-1">{k}</span>)
                    )
                  }  
              </TDList>)))
          }
           { // only action data
            (this.props.colData) &&
            (this.props.colData
            .filter(col=> col.type=="ACTION" )
            .map((col, idx)=> (
              <td key={index+idx} style={{wordBreak: 'break-word'}}> {
                <i key={idx} onClick={()=>{this.props.schoolView(tr)}} className={"mx-1 p-1 bg-light rounded fa fa-"+col.value}><span className="ml-1 badge badge-primary">{tr[col.value]}</span></i>
              }</td>)))
          }
        </tr>)
      })
    )

    return (
      <div className="container-fluid">
        <table className="table table-bordered">
          <thead className="" style={{background: "#432666", color: "white"}}>
            <tr>
              <th scope="col">#</th>
              {
                (this.props.colData
                .some(col=> col.type==="ICONS")
                ) &&
                (<th scope="col">Stats</th>)
              }
              {tableTextHeads}
              {tableListHeads}
              {tableActionHeads}
            </tr>
          </thead>
          <tbody>
            {tableData}
          </tbody>
        </table>
      </div>
    );
  }
}

import { createActions, using } from "redux-box";

// export default {
//   requestquiz2list: (gradeId) => ({ type: "REQUEST_QUIZ2_LIST", gradeId }),
// };
export default createActions({
  //......................Reading CRUD..................
  setActiveQuizGrade: using("grade"),
  requestQuiz: using(),
  requestQuizListByGradeId: using("gradeId"),
  requestCreateQuiz: using("quiz"),
  requestUpdateQuiz: using("quiz"),

  requestQuizById: using("quizId"),
  setQuizList: using("quizList"),
  setFetchQuizList: using("value"),
  setActiveQuiz: using("activeQuiz"),
  unsetActiveQuizQuestion: using(),
  requestDeleteQuiz: using("id"),
  requestActiveGrade: using(),
  requestConcatQuizChallenge: using("offset"),

  //...............Questions.....................
  requestAllQuizQuestions: using("quizid"),
  requestCreateQuizQuestion: using("question"),
  requestUpdateQuizQuestion: using("question"),
  requestDeleteQuizQuestion: using("question"),

  setActiveQuizMcq: using("mcq"),
});

import React, { Component } from "react";
import store from "store";
import action from "@st/activity/actions";
import { module as activity } from "@st/activity";
import { module as level } from "@st/level";
import { connectStore } from "redux-box";
import { uploadFile } from "@s/api/file";
import { Link } from "react-router-dom";
import {
  ThemeButton,
  TagChips,
  TagChipsContainer,
  ImageLoader,
  Chevron,
  DropDown,
  DropDownMenu,
  ToggleSlider
} from "@sc/custom.styled";
import {
  ActivityDescriptionTextArea,
  ActivityDescription,
  FreadomPointsInputField
} from "@sc/ActivityModal.styled";
import {
  SelectFileIn,
  ImageContainer,
  ActivityUploadImageButton,
  ChangeImageButton,
  ActivityInputField
} from "@c/activity-view/activity-creation-form/ActivityCreationForm.styled";
import {
  ActivityPackDescriptionInfoContainer,
  GradeDescriptionLabel,
  ActivitySectionLabelsContainer,
  ActivityPackThemeButton,
  PackThemeButton,
  InputFieldsContainer,
  ActivityUploadImageContainer
} from "./ActivityPackInfo.styled";
import swal from "sweetalert2";
import { module as onboard } from "@st/onboard";
import Permission from "@s/permission";
// export default connectStore({
//   activity,
//   level
// })
class ActivityPackInfo extends Component {
  constructor(props) {
    super();
    this.state = {
      activityPackEdit: false,
      gradeLevelSelected: "",
      gradeSelected: "",
      levelSelected: "",
      levelTypeSelector: false,
      subLevelTypeSelector: false,
      backgroundImageChange: false,
      enableBackgroundImageChange: false,
      imageChange: false,
      enableLoad: false,
      file: "",
      activity_pack_info: {
        name: "",
        grade_level: "",
        freadom_point: "",
        description: "",
        tag: [],
        grade: "",
        background_image: "",
        image: null
      }
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.activity && nextProps.activity.active_activity_pack) {
      this.normalizeForState(nextProps.activity.active_activity_pack);
    } else {
      this.setState({
        gradeSelected: "Select Grade"
      });
    }
  }

  normalizeForState = activeActivityPack => {
    let gradename = "Select";
    let gradeName = this.props.activity.grades.filter(grade => {
      return grade.id === activeActivityPack.grade;
    });
    if (gradeName.length > 0) gradename = gradeName[0].name;
    this.setState({
      gradeSelected: gradename,
      activity: {
        ...this.state.activity_pack_info,
        grade: activeActivityPack.grade
      }
    });
  };

  componentDidMount() {
    const data = this.props.activityPack;
    let gradename = "Select";
    if (data.grade_level.length > 0) {
      console.log("hello");
      let gradeLevels = data.grade_level.split(":");
      this.setState({
        gradeLevelSelected: gradeLevels[0],
        levelSelected: gradeLevels[1]
      });
    }
    let gradeName = this.props.activity.grades.filter(grade => {
      return grade.id === data.grade;
    });
    if (gradeName.length > 0) gradename = gradeName[0].name;
    this.setState({
      gradeSelected: gradename,
      activity_pack_info: {
        ...this.state.activity_pack_info,
        name: data.name,
        grade: data.grade,
        description: data.description,
        tag: data.tag || [],
        grade_level: data.grade_level,
        freadom_point: data.freadom_point,
        image: data.image || null,
        background_image: data.background_image || ""
      },
      levelData: this.props.level.levels.map(level => {
        let levelData = {
          name: level,
          selected: false,
          subLevel: "Easy"
        };
        return levelData;
      })
    });
  }

  handleActivityPackEdit = e => {
    this.setState({
      activityPackEdit: true
    });
  };

  _handleImageChange = e => {
    // e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    let fileSize = e.target.files[0].size / 1024 / 1024;
    if (fileSize > 2) {
      swal("Oops!", "File size greater than 2Mb ", "warning");
    } else {
      reader.onloadend = () => {
        this.setState({
          imageChange: false,
          enableLoad: true,
          activity_pack_info: {
            ...this.state.activity_pack_info,
            image: reader.result
          }
        });
      };
      reader.readAsDataURL(file);
      uploadFile(file).then(res => {
        this.setState({
          activity_pack_info: {
            ...this.state.activity_pack_info,
            image: res.file
          },
          enableLoad: false
        });
      });
    }
  };

  handleBackgroundImageChange = e => {
    let reader = new FileReader();
    let file = e.target.files[0];
    let fileSize = e.target.files[0].size / 1024 / 1024;

    if (fileSize > 2) {
      swal("Oops!", "File size greater than 2MB ", "warning");
    } else {
      reader.onloadend = () => {
        this.setState({
          backgroundImageChange: false,
          enableBackgroundImageChange: true,
          activity_pack_info: {
            ...this.state.activity_pack_info,
            background_image: reader.result
          }
        });
      };
      reader.readAsDataURL(file);
      uploadFile(file).then(res => {
        this.setState({
          activity_pack_info: {
            ...this.state.activity_pack_info,
            background_image: res.file
          },
          enableBackgroundImageChange: false
        });
      });
    }
  };

  handleDoneBtn = () => {
    this.setState({
      activityPackEdit: false
    });
    this.props.activity.updateActivityPack(this.state.activity_pack_info);
  };

  displayGradeMenu = evt => {
    this.setState({ displayMenu: !this.state.displayMenu });
  };

  handleGradeSelected = newGrade => e => {
    this.setState({
      displayMenu: !this.state.displayMenu,
      gradeSelected: newGrade.name,
      activity_pack_info: {
        ...this.state.activity_pack_info,
        grade: newGrade.id
      }
    });
  };

  handleActivityPackNameChange = evt => {
    this.setState({
      activity_pack_info: {
        ...this.state.activity_pack_info,
        name: evt.target.value
      }
    });
  };

  handleActivityDescriptionChange = evt => {
    this.setState({
      activity_pack_info: {
        ...this.state.activity_pack_info,
        description: evt.target.value
      }
    });
  };

  handleUnlockPoints = evt => {
    if (
      evt.target.value.length === 0 &&
      this.state.activity_pack_info.freadom_point !== ""
    ) {
      this.setState({
        activity_pack_info: {
          ...this.state.activity_pack_info,
          freadom_point: ""
        }
      });
    } else
      this.setState({
        activity_pack_info: {
          ...this.state.activity_pack_info,
          freadom_point: evt.target.value
        }
      });
  };

  handleImageFocus = actionType => {
    if (actionType === "over" && this.state.imageChange === false)
      this.setState({
        imageChange: true
      });
    else if (actionType === "out" && this.state.imageChange === true)
      this.setState({
        imageChange: false
      });
  };

  handleBackgroundImageFocus = actionType => {
    if (actionType === "over" && this.state.backgroundImageChange === false)
      this.setState({
        backgroundImageChange: true
      });
    else if (actionType === "out" && this.state.backgroundImageChange === true)
      this.setState({
        backgroundImageChange: false
      });
  };

  handleLevelTypeSelector = val => {
    this.setState({
      levelTypeSelector: val
    });
  };

  handleLevelSelected = level => {
    let levelData = this.state.levelData.map(option => {
      if (option.name === level.name) return { ...option, selected: true };
      return { ...option, selected: false };
    });
    this.setState({
      levelData: levelData,
      activity_pack_info: {
        ...this.state.activity_pack_info,
        grade_level: level.name + ":" + level.subLevel
      }
    });
  };

  handleSubLevelSelected = (evt, level) => {
    let subLevel = level.subLevel;
    let levelData = this.state.levelData.map(option => {
      if (option.name === level.name) {
        subLevel = option.subLevel === "Easy" ? "Hard" : "Easy";
        return { ...option, selected: true, subLevel: subLevel };
      }
      return { ...option, selected: false, subLevel: "Easy" };
    });
    this.setState({
      levelData: levelData,
      activity_pack_info: {
        ...this.state.activity_pack_info,
        grade_level: level.name + ":" + subLevel
      }
    });
    evt.stopPropagation();
  };

  handleTagClick(data) {
    let { activity } = this.props;
    const { permission } = this.props.onboard;
    if(Permission.getPermission(permission, "change_activity")){
      activity.deletePackTag(data);
    }
  }

  render() {
    const state = this.state;
    const { activity } = this.props;
    const { permission } = this.props.onboard;
    // const gradeOptions = activity.grades.map((grade, i) => (
    //   <ActivityGradeSelectOption
    //     onClick={this.handleGradeSelected(grade)}
    //     key={i}
    //   >
    //     {grade.name}
    //   </ActivityGradeSelectOption>
    // ));

    return (
      <ActivityPackDescriptionInfoContainer primary>
        {!this.state.activity_pack_info.image ? (
          <ActivityUploadImageContainer>
            <ActivityUploadImageButton type="button">
              <img
                src={require("static/img/cloud-upload-icon.png")}
                alt="Upload"
              />
              <span>Upload Image </span>
            </ActivityUploadImageButton>

            <SelectFileIn
              type="file"
              onChange={(e) => this._handleImageChange(e)}
            />
          </ActivityUploadImageContainer>
        ) : this.state.enableLoad ? (
          <ActivityUploadImageContainer>
            <ImageLoader>
              {" "}
              <img
                alt="loading"
                width="40"
                height="40"
                src="http://photokonkurs.russia.travel/images/icons/preloader.gif"
              />
            </ImageLoader>
          </ActivityUploadImageContainer>
        ) : (
          <ActivityUploadImageContainer
            onMouseOver={() => this.handleImageFocus("over")}
            onMouseOut={() => this.handleImageFocus("out")}
          >
            <ImageContainer src={this.state.activity_pack_info.image} />
            {state.activityPackEdit ? (
              <ChangeImageButton
                top={this.state.imageChange ? "83%" : "100%"}
                opacity={this.state.imageChange ? 1 : 0}
              >
                Change Image
              </ChangeImageButton>
            ) : null}
            {state.activityPackEdit ? (
              <SelectFileIn
                type="file"
                onChange={(e) => this._handleImageChange(e)}
              />
            ) : null}
          </ActivityUploadImageContainer>
        )}
        {this.state.activity_pack_info.background_image === "" ? (
          <ActivityUploadImageContainer width="200px" marginLeft="14px">
            <ActivityUploadImageButton type="button">
              <img
                src={require("static/img/cloud-upload-icon.png")}
                alt="Upload"
              />
              <span> Upload Fullscreen Image </span>
            </ActivityUploadImageButton>

            {state.activityPackEdit ? (
              <SelectFileIn
                type="file"
                onChange={(e) => this.handleBackgroundImageChange(e)}
              />
            ) : null}
          </ActivityUploadImageContainer>
        ) : this.state.enableBackgroundImageChange ? (
          <ActivityUploadImageContainer width="200px" marginLeft="14px">
            <ImageLoader>
              {" "}
              <img
                alt="loading"
                width="40"
                height="40"
                src="http://photokonkurs.russia.travel/images/icons/preloader.gif"
              />
            </ImageLoader>
          </ActivityUploadImageContainer>
        ) : (
          <ActivityUploadImageContainer
            width="200px"
            marginLeft="14px"
            onMouseOver={() => this.handleBackgroundImageFocus("over")}
            onMouseOut={() => this.handleBackgroundImageFocus("out")}
          >
            <ImageContainer
              src={this.state.activity_pack_info.background_image}
            />
            {state.activityPackEdit ? (
              <ChangeImageButton
                top={this.state.backgroundImageChange ? "83%" : "100%"}
                opacity={this.state.backgroundImageChange ? 1 : 0}
              >
                Change Background
              </ChangeImageButton>
            ) : null}
            {state.activityPackEdit ? (
              <SelectFileIn
                type="file"
                onChange={(e) => this.handleBackgroundImageChange(e)}
              />
            ) : null}
          </ActivityUploadImageContainer>
        )}

        <ActivityDescription height="auto">
          {!state.activityPackEdit ? (
            <ActivitySectionLabelsContainer>
              <GradeDescriptionLabel>
                {" "}
                <span>
                  {" "}
                  {this.state.gradeLevelSelected} ( {this.state.levelSelected})
                  | Freadom Points:
                  {this.state.activity_pack_info.freadom_point}{" "}
                </span>
                {Permission.getPermission(permission, "change_activity") ? (
                  <img
                    src={require("static/img/editicon.png")}
                    alt="edit icon"
                    className="image-fluid"
                    onClick={this.handleActivityPackEdit}
                  />
                ) : null}
                {Permission.getPermission(permission, "change_activity") ? (
                  <img
                    src={require("static/img/trash.png")}
                    alt="delete icon"
                    className="image-fluid"
                  />
                ) : null}
              </GradeDescriptionLabel>
              <GradeDescriptionLabel primary>
                <Link
                  to={
                    "/activities/packs/" +
                    activity.active_grade_level +
                    "/" +
                    activity.active_level
                  }
                >
                  <ActivityPackThemeButton primary>
                    Save Changes
                  </ActivityPackThemeButton>
                </Link>
              </GradeDescriptionLabel>
            </ActivitySectionLabelsContainer>
          ) : (
            <ActivitySectionLabelsContainer>
              <InputFieldsContainer>
                <DropDown
                  minWidth="120px"
                  style={{ width: "150px" }}
                  active={this.state.levelTypeSelector}
                  onBlur={() => this.handleLevelTypeSelector(false)}
                  tabIndex="1"
                >
                  <div
                    onClick={() =>
                      this.handleLevelTypeSelector(
                        !this.state.levelTypeSelector
                      )
                    }
                  >
                    {this.state.activity_pack_info.grade_level.length > 0
                      ? this.state.activity_pack_info.grade_level
                      : "Select Level"}
                    <Chevron>
                      <img
                        src={require("static/img/downarrowicon.png")}
                        alt="down-arrow"
                      />
                    </Chevron>
                  </div>
                  <DropDownMenu
                    maxHeight="100px"
                    active={this.state.levelTypeSelector}
                    color="#fff"
                  >
                    {this.state.levelData.map((level, index) => (
                      <a
                        key={level.name}
                        onMouseDown={(e) => e.preventDefault()}
                        onClick={() => this.handleLevelSelected(level)}
                      >
                        {level.name}
                        {level.selected ? (
                          <ToggleSlider
                            onClick={(evt) => {
                              this.handleSubLevelSelected(evt, level);
                            }}
                            active={level.subLevel === "Hard"}
                          >
                            <div>{level.subLevel}</div>
                          </ToggleSlider>
                        ) : null}
                      </a>
                    ))}
                  </DropDownMenu>
                </DropDown>
                <FreadomPointsInputField>
                  <input
                    type="number"
                    placeholder="Freadom Points"
                    value={state.activity_pack_info.freadom_point}
                    onChange={this.handleUnlockPoints}
                  />
                </FreadomPointsInputField>
              </InputFieldsContainer>
              <ThemeButton primary onClick={this.handleDoneBtn}>
                Done
              </ThemeButton>
            </ActivitySectionLabelsContainer>
          )}

          <ActivityInputField
            primary
            placeholder="New Activity Pack"
            border={
              !state.activityPackEdit ? "none" : "solid 1px rgba(40,50,112,0.1)"
            }
            id="activityPackName"
            value={state.activity_pack_info.name}
            width={state.activityPackEdit ? "75%" : "100%"}
            left={state.activityPackEdit ? "6px" : "0px"}
            onChange={this.handleActivityPackNameChange}
            disabled={!this.state.activityPackEdit}
          />
          <ActivityDescriptionTextArea
            primary={state.activityPackEdit}
            id="activityDescription"
            height={"70px"}
            max={"30px"}
            value={state.activity_pack_info.description}
            onChange={this.handleActivityDescriptionChange}
          />
          <div>
            {!state.activityPackEdit ? (
              <TagChipsContainer>
                {activity.active_activity_pack !== null
                  ? activity.active_activity_pack.tag.map((tag, i) => (
                      <TagChips
                        key={i}
                        onClick={() => this.handleTagClick(tag)}
                      >
                        {tag}
                        <img
                          className="image-fluid"
                          src={require("static/img/tagCloseIcon.png")}
                          alt="Edit icon"
                        />
                      </TagChips>
                    ))
                  : null}
                {Permission.getPermission(permission, "change_activity") ? (
                  <PackThemeButton
                    onClick={() =>
                      store.dispatch(action.setEditorType("pack_tag"))
                    }
                  >
                    + New Tag
                  </PackThemeButton>
                ) : null}
              </TagChipsContainer>
            ) : null}
          </div>
        </ActivityDescription>
      </ActivityPackDescriptionInfoContainer>
    );
  }
}

// export default ActivityPackInfo;

export default connectStore({
  activity,
 level,onboard
 })(ActivityPackInfo)
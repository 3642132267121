import React, { Fragment } from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import UsersContainer from "@c/users-view/users-container/UsersContainer";
import ParentProfile from "@c/users-view/parent-profile/ParentProfile";
import ChildProfile from "@c/users-view/child-profile/ChildProfile";

export const Routes = function({ location }) {
  return (
    <Switch location={location}>
      <Route
        path={"/users/parents/:id"}
        render={props => <ParentProfile {...props} />}
      />
      <Route
        path={"/users/children/:id"}
        render={props => <ChildProfile {...props} />}
      />
      <Route path={"/users"} component={UsersContainer} />
      <Route
        path={"/"}
        render={props => (
          <Fragment>
            <UsersContainer />
          </Fragment>
        )}
      />
    </Switch>
  );
};

export default withRouter(Routes);

import React, { Component } from "react";
import { withRouter } from "react-router";

import {
  SchoolItemRight,
  SchoolItemLeft,
  SchoolItem,
  SchoolDetails,
  SchoolLabels,
} from "./SchoolViewCard.styled";
import { ToolbarItem } from "@sc/Section.styled";
import { module as ui } from "@st/ui";
import { module as inviteCode } from "@st/inviteCode";
import { module as onboard } from "@st/onboard";
import { connectStore } from "redux-box";
import swal from "sweetalert2";
import Permission from "@s/permission";
import { ThemeButton } from "static/styled/custom.styled";
// export default connectStore({
//   ui,
//   caseStudy
// })
class SchoolViewCard extends Component {
  handleEditClick = (evt) => {
    const { permission } = this.props.onboard;
    console.log("this.props.schoolInfo", this.props.schoolInfo);
    this.props.inviteCode.setEditSchool(this.props.schoolInfo);
    if (Permission.getPermission(permission, "change_school")) {
      evt.preventDefault();
      this.props.ui.showModal("ADDSCHOOLFORM");
    } else {
      swal({
        text: "You do not have permission.",
        type: "warning",
      });
    }
  };

  handleCardBtn = (evt) => {
    this.props.inviteCode.setEditSchool(this.props.schoolInfo);
    this.props.ui.showModal("ADDSCHOOLFORM");
    evt.stopPropagation();
  };

  handleAddClass = (evt) => {
    const { permission } = this.props.onboard;
    if (Permission.getPermission(permission, "change_school")) {
      this.props.inviteCode.setEditSchool(this.props.schoolInfo);
      this.props.ui.showModal("ADDCLASSFORM");
    } else {
      swal({
        text: "You do not have permission.",
        type: "warning",
      });
    }
    evt.stopPropagation();
  };

  viewReport = (evt) => {
    const { permission } = this.props.onboard;
    if (Permission.getPermission(permission, "change_school")) {
      console.log("downloadReport====", this.props);
      // this.props.inviteCode.downloadReport(this.props.schoolInfo);
      //  this.props.inviteCode.getReportData(this.props.schoolInfo.school_code);

      this.props.history.push(
        `/school-code?report=${this.props.schoolInfo.school_code}`
      );
    } else {
      swal({
        text: "You do not have permission.",
        type: "warning",
      });
    }
    evt.stopPropagation();
  };

  render() {
    let schoolInfo = this.props.schoolInfo;
    const { permission } = this.props.onboard;
    return (
      <SchoolItem onClick={this.handleCardBtn}>
        <SchoolItemLeft>
          {/* <ImageContainer
            src={
              this.props.schoolInfo && this.props.schoolInfo.author_image
                ? schoolInfo.author_image
                : require("static/img/default-image.png")
            }
            className="image-fluid"
            alt="caseStudy Icon"
          /> */}
          <SchoolDetails>
            <SchoolLabels primary> {schoolInfo.name} </SchoolLabels>
            <SchoolLabels> School Code:- {schoolInfo.school_code}</SchoolLabels>
            <SchoolLabels> School Tag:- {schoolInfo.school_tag} </SchoolLabels>
            <SchoolLabels>
              {" "}
              Expiry Date:- {schoolInfo.expiry_date}{" "}
            </SchoolLabels>
            <SchoolLabels>
              {" "}
              License Count:- {schoolInfo.license_count}{" "}
            </SchoolLabels>
            <SchoolLabels>
              {" "}
              License Used:- {schoolInfo.used_count}{" "}
            </SchoolLabels>
          </SchoolDetails>
        </SchoolItemLeft>
        <SchoolItemRight>
          {Permission.getPermission(permission, "change_school") ? (
            <ToolbarItem primary onClick={this.handleEditClick}>
              <div className="icon-container">
                <i className="fa fa-pencil" />
              </div>
            </ToolbarItem>
          ) : null}
          {Permission.getPermission(permission, "change_school") ? (
            <ToolbarItem onClick={this.handleAddClass}>
              <div className="icon-container">
                <ThemeButton
                  primary
                  type="button"
                  onClick={this.handleAddClass}
                >
                  {" "}
                  Add Class{" "}
                </ThemeButton>
              </div>
            </ToolbarItem>
          ) : null}
          {Permission.getPermission(permission, "change_school") ? (
            <ToolbarItem onClick={this.handleAddClass}>
              <div className="icon-container">
                <ThemeButton primary type="button" onClick={this.viewReport}>
                  {" "}
                  View Report{" "}
                </ThemeButton>
              </div>
            </ToolbarItem>
          ) : null}
        </SchoolItemRight>
      </SchoolItem>
    );
  }
}

export default withRouter(
  connectStore({
    inviteCode,
    ui,
    onboard,
  })(SchoolViewCard)
);

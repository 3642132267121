import React, { useEffect } from "react";
import { withRouter } from "react-router";
import { connectStore } from "redux-box";
import {
  Wrapper,
  Wrapper2,
  Wrapper3,
  Wrapper4,
  Wrapper5,
  Wrapper6,
  Wrapper7,
  Wrapper8,
  Wrapper9,
  Title,
  Title2,
  Title3,
  Title4,
  Title5,
  Title6,
  Title7,
  Title8,
  Title9,
  Title10,
  Title11,
  Title12,
  Title13,
  Title14,
  Title15,
  Title16,
  Title17,
  Title18,
  Title19,
  Title20,
  Box,
  Box2,
  Box4,
  Box5,
  Box6,
  Box7,
  Box8,
  Box9,
  Box10,
  Box11,
  Box12,
  Box13,
  Box14,
  Box15,
  Box16,
  Box17,
  TopImage1,
  Topimage3,
  TopContainer,
  Image1,
  Image2,
  Image3,
  Image4,
  Image5,
  Image6,
  Image7,
  Image8,
  Bottomimage,
  Bar,
  Input1,
  Tabledata,
  TableHeader,
} from "./Report.styled";
import {
  CircularProgressbar,
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import Table from "react-bootstrap/Table";
import { module as inviteCode } from "@st/inviteCode";
import { module as onboard } from "@st/onboard";
import { LoadingContainer } from "@sc/custom.styled";

const Report = (props) => {
  useEffect(() => {
    //       async function fetchMyAPI() {
    //    await props.inviteCode.getReportData();
    // }
    props.inviteCode.reportData = {};
    let paramUrl = new URLSearchParams(props.location.search);
    let School_Code = paramUrl.get("report");

    // For ORF Reports for schools who gave test from freadom app
    props.inviteCode.getReportData(School_Code);

    // For reports for schools who gave test from external link change data in sagas.js file also for GET_EXTERNAL_REPORT_DATA
    // props.inviteCode.getExternalReportData()

    // fetchMyAPI()
  }, []);

  useEffect(() => {
    console.log("Will unmount component");
    return () => {
      props.inviteCode.clearReportData();
    };
  }, []);

  console.log("propsbefore", props);

  return (
    <div>
      {Object.keys(props.inviteCode.reportData).length < 1 ? (
        <LoadingContainer>
          <img
            alt="loading"
            width="60"
            height="60"
            src={require("static/img/Theme-loader.gif")}
          />
        </LoadingContainer>
      ) : Object.keys(props.inviteCode.reportData).length > 1 &&
        props.inviteCode.reportData.Status == "Error" ? (
        <div>
          <Box17>
            <h4>Oops!</h4>
            <h1>{props.inviteCode.reportData.ExceptionMessage}</h1>
          </Box17>
        </div>
      ) : (
        <div>
          {props.inviteCode.reportData &&
          Object.keys(props.inviteCode.reportData).length > 0 ? (
            <div>
              <TopContainer>
                <Wrapper>
                  <Box>
                    {/* <img
                      alt="loading"
                      width="124"
                      height="29"
                      src={require("static/img/Freadomlogo.png")}
                    /> */}
                    <img
                      alt="loading"
                      width="131"
                      height="35"
                      src={require("static/img/freadom_school_logo.png")}
                    />
                  </Box>
                </Wrapper>
                {/* <Title>Carnegie National Speech Assessment </Title> */}
                <Title>Freadom Fluency Assessment Report</Title>
                {/* <Title2>Powered by Carnegie Mellon University </Title2> */}
              </TopContainer>
              <Wrapper2>
                <Box2>
                  <Title3>1 Lakh +</Title3>
                  <Title4>students assessed their fluency</Title4>
                </Box2>
                <Box2>
                  <Title3>1500+</Title3>
                  <Title4>school registered to take the test</Title4>
                </Box2>
                <Box2>
                  <Title3>5 minutes</Title3>
                  <Title4>only to get reliable results</Title4>
                </Box2>
              </Wrapper2>
              <Wrapper3>
                <Box4>
                  <div>
                    <Image1></Image1>
                    <Image2></Image2>
                  </div>
                  <Box5>
                    <Title5>Issued for : </Title5>
                    <Title7>{props.inviteCode.reportData.School_Name}</Title7>
                    <Title5>Date of Assessment : </Title5>
                    <Title6>
                      {props.inviteCode.reportData.Assessment_Date}
                    </Title6>
                  </Box5>
                  <div>
                    <Image3></Image3>
                    <Image4></Image4>
                  </div>
                </Box4>
              </Wrapper3>
              <Wrapper4>
                <Title8>
                  Freadom Fluency Assessment is a reliable english fluency test
                  that provides an accurate method for assessing language
                  proficiency.
                </Title8>
                <Title9>
                  The comprehensive periodic assessment is meticulously designed
                  for students ranging from grades 1 to 8. It assesses their
                  proficiency in key language skills, including words correct
                  per minute, pronunciation accuracy, speech rate and
                  comprehension.
                </Title9>
              </Wrapper4>
              <Wrapper5>
                <Box6>
                  <Title10>Your School Summary</Title10>
                  <Image5></Image5>
                </Box6>
                <Box7>
                  <img
                    alt="loading"
                    width="232.14"
                    height="141.67"
                    src={require("static/img/Artwork.png")}
                  />
                  <Box8>
                    <Title3>
                      {props.inviteCode.reportData.School_Olympiad_Strength}
                    </Title3>
                    <Title4>Total no. of students in grades 1-8</Title4>
                  </Box8>
                  <Box8>
                    <Title3>
                      {
                        props.inviteCode.reportData
                          .Total_No_of_Students_Who_Attempted_CMF
                      }
                    </Title3>
                    <Title4>No. of children who took the assessment</Title4>
                  </Box8>
                  <Box8>
                    <Title3>
                      {
                      parseFloat((
                        props.inviteCode.reportData
                          .Total_No_of_Students_Who_Attempted_CMF /
                        props.inviteCode.reportData.School_Olympiad_Strength
                      ) * 100).toFixed(2)
                      }
                      %
                    </Title3>
                    <Title4>Assessment adoption %</Title4>
                  </Box8>
                </Box7>
              </Wrapper5>
              <Wrapper6>
                <Title11>
                  Encourage your students build stronger reading skills by
                  ensuring they take the fluency test today!
                </Title11>
              </Wrapper6>
              <Wrapper7>
                <Box6>
                  <Title10>Freadom Fluency Assessment</Title10>
                  <Image6></Image6>
                </Box6>
                <Box10>
                  <Box9>
                    <Box10>
                      <Bar label="Square linecaps">
                        <CircularProgressbar
                          value={
                            props.inviteCode.reportData.Words_Correct_Per_Minute
                          }
                          text={`${props.inviteCode.reportData.Words_Correct_Per_Minute}`}
                          styles={buildStyles({
                            pathColor: "lightgreen",
                          })}
                          maxValue={
                            props.inviteCode.reportData
                              .Words_Correct_Per_Minute_National_Avg
                          }
                        />
                      </Bar>
                      <Box16>
                        {
                          props.inviteCode.reportData
                            .Words_Correct_Per_Minute_National_Avg
                        }
                      </Box16>
                    </Box10>

                    <Title4>
                      Median words read correctly per minute score
                    </Title4>
                  </Box9>
                  <Box9>
                    <Box10>
                      <Bar label="Square linecaps">
                        <CircularProgressbar
                          value={props.inviteCode.reportData.Pronunciation}
                          text={`${props.inviteCode.reportData.Pronunciation}`}
                          maxValue={
                            props.inviteCode.reportData
                              .Pronunciation_National_Avg
                          }
                          styles={buildStyles({
                            pathColor: "orange",
                          })}
                        />
                      </Bar>
                      <Box16>
                        {props.inviteCode.reportData.Pronunciation_National_Avg}
                      </Box16>
                    </Box10>
                    <Title4>Median pronunciation score</Title4>
                  </Box9>
                  <Box9>
                    <Box10>
                      <Bar label="Square linecaps">
                        <CircularProgressbar
                          value={props.inviteCode.reportData.Fluency}
                          text={`${props.inviteCode.reportData.Fluency}`}
                          styles={buildStyles({
                            pathColor: "lightred",
                          })}
                          maxValue={
                            props.inviteCode.reportData.Fluency_National_Avg
                          }
                        />
                      </Bar>
                      <Box16>
                        {props.inviteCode.reportData.Fluency_National_Avg}
                      </Box16>
                    </Box10>
                    <Title4>Median speech rate</Title4>
                  </Box9>
                </Box10>

                <Title13>Performance Summary</Title13>
                {props.inviteCode.reportData.Words_Correct_Per_Minute >
                props.inviteCode.reportData.Words_Per_Minute_National_Avg ? (
                  <Box11>
                    <strong> Median words read correctly per minute: </strong>{" "}
                    Your students have scored{" "}
                    <strong>above the national median</strong> in the number of
                    words read correctly per minute criteria. Keep up the
                    performance and set a challenging reading goal for children,
                    like no. of stories to be read aloud each week, and track it
                    on the Freadom app.
                  </Box11>
                ) : props.inviteCode.reportData.Words_Correct_Per_Minute ==
                  props.inviteCode.reportData.Words_Per_Minute_National_Avg ? (
                  <Box11>
                    <strong> Median Words Read correctly per minute: </strong>
                    Your students have scored{" "}
                    <strong>at par with the national median</strong> in the
                    number of words read correctly per minute criteria. Keep up
                    the performance and set a challenging reading goal for
                    children, like no. of stories to be read aloud each week,
                    and track it on the Freadom app.
                  </Box11>
                ) : (
                  <Box11>
                    <strong> Median Words Read correctly per minute: </strong>
                    Your students score <strong> needs improvement</strong> in
                    the number of words read correctly per minute criteria. Set
                    a simple reading goal for children, like no. of stories to
                    be read aloud each week, and track it on the Freadom app.
                    Start simple, with video stories, and then move to
                    text-based stories.
                  </Box11>
                )}
                {props.inviteCode.reportData.Pronunciation >
                props.inviteCode.reportData.Pronunciation_National_Avg ? (
                  <Box12>
                    <strong> Median pronunciation score: </strong> Your students
                    have scored <strong>above the national median</strong> in
                    the pronunciation criteria. Ask your children to share their
                    feedback on what they have read, what they learnt and what
                    they would like to know more orally.
                  </Box12>
                ) : props.inviteCode.reportData.Pronunciation ==
                  props.inviteCode.reportData.Pronunciation_National_Avg ? (
                  <Box12>
                    <strong> Median pronunciation score: </strong>
                    Your students have scored{" "}
                    <strong>at par with the national median </strong>
                    in pronunciation criteria. Ask your children to share their
                    feedback on what they have read, what they learnt and what
                    they would like to know more orally.
                  </Box12>
                ) : (
                  <Box12>
                    <strong> Median pronunciation score: </strong>Your students
                    score <strong> needs improvement</strong> in pronunciation
                    criteria. Encourage children to listen to audio stories on
                    the Freadom app to understand and practise the pronunciation
                    of difficult and unknown words.
                  </Box12>
                )}
                {props.inviteCode.reportData.Fluency >
                props.inviteCode.reportData.Fluency_National_Avg ? (
                  <Box11>
                    <strong> Median speech rate: </strong>
                    Your students have scored{" "}
                    <strong>above the national median</strong> in the Speech
                    rate criteria.Encourage the children to engage in word games
                    and tackle comprehension-based activities on the Freadom
                    app. Making learning playful and interactive fosters a
                    positive and effective educational experience.
                  </Box11>
                ) : props.inviteCode.reportData.Fluency ==
                  props.inviteCode.reportData.Fluency_National_Avg ? (
                  <Box11>
                    <strong>Median speech rate: </strong>Your students have
                    scored <strong>at par with the national median</strong> in
                    fluency criteria. Motivate the children to child play word
                    games and solve comprehension based activities on the
                    Freadom app.
                  </Box11>
                ) : (
                  <Box11>
                    <strong> Median speech rate: </strong>Your students score
                    <strong> needs improvement</strong> in fluency criteria.
                    Ensure that children practice reading aloud via the Freadom
                    app or any book of their choice once a day. More practice
                    will reduce the pauses between reading and improve reading
                    fluency. Also, allow children to express what they have
                    learned at school or in a live class they attend to someone
                    at home regularly to enhance speaking skills and
                    communicative confidence.
                  </Box11>
                )}
                <Box6>
                  <Title10>Grade wise performance</Title10>
                  <Image8></Image8>
                </Box6>
                <Tabledata>
                  <Table hover>
                    <thead>
                      <tr style={{ textAlign: "center" }}>
                        <Title18>Grade</Title18>
                        <TableHeader>Median no. of unique words</TableHeader>
                        <TableHeader>
                          Median time taken to read the passage(seconds)
                        </TableHeader>
                        <TableHeader>
                          Median Words Read correctly per minute
                        </TableHeader>
                        <TableHeader>Median pronunciation score</TableHeader>
                        <TableHeader>Median speech rate</TableHeader>
                        <TableHeader>Gradewise national median*</TableHeader>
                      </tr>
                    </thead>
                    <tbody>
                      {Object.keys(props.inviteCode.reportData.Grade_Wise)
                        .sort((a, b) => {
                          // Extract the number part from "Grade_4", "Grade_2" and compare
                          const numA = parseInt(a.split("_")[1]);
                          const numB = parseInt(b.split("_")[1]);
                          return numA - numB;
                        })
                        .map((grade, index) => {
                          return (
                            <tr key={index} style={{ textAlign: "center" }}>
                              <td>
                                {grade
                                  .split("_")
                                  .map((grade_part) => `${grade_part} `)}
                              </td>
                              <td>
                                {
                                  props.inviteCode.reportData.Grade_Wise[grade]
                                    .Number_of_Unique_Words
                                }
                              </td>
                              <td>
                                {
                                  props.inviteCode.reportData.Grade_Wise[grade]
                                    .Time_Taken_to_Read
                                }
                              </td>
                              <td>
                                {
                                  props.inviteCode.reportData.Grade_Wise[grade]
                                    .Words_Correct_Per_Minute
                                }
                              </td>
                              <td>
                                {
                                  props.inviteCode.reportData.Grade_Wise[grade]
                                    .Pronunciation
                                }
                              </td>
                              <td>
                                {
                                  props.inviteCode.reportData.Grade_Wise[grade]
                                    .Fluency
                                }
                              </td>
                              <td>
                                {
                                  props.inviteCode.reportData.Grade_Wise[grade]
                                    .Words_Correct_Per_Minute_National_Avg
                                }
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </Table>
                </Tabledata>
                <Title19>
                  *National Median: WCPM Median gradewise breakdown from all
                  schools across the country.
                </Title19>

                <Box6>
                  <Image7></Image7>
                </Box6>

                <Box15>
                  The Freadom Fluency Assessment report is a powerful tool to
                  assess where your school and students are placed in a national
                  level language proficiency benchmark. Your students are now on
                  their way to acing their English fluency!
                </Box15>
              </Wrapper7>
              <Wrapper8>
                <Box10>
                  <img
                    alt="loading"
                    width="300"
                    height="244.33"
                    src={require("static/img/Group 635.png")}
                  />
                  <Box13>
                    <Title16>
                      For improving your student learning outcomes, we
                      recommend:
                    </Title16>
                    <Title15>1</Title15>
                    <Title14>
                      {" "}
                      Improving your Adoption Score by encouraging the rest of
                      your students to take the assessment as well.
                    </Title14>
                    <br></br>
                    <Title15>2</Title15>
                    <Title14>
                      {" "}
                      Analysing the grade wise report that will be shared with
                      you soon.
                    </Title14>
                    <br></br>
                    <Title15>3</Title15>
                    <Title14>
                      {" "}
                      Setting up a consultation call with our Academic Experts
                      who will guide you closely to achieve outcomes you desire!
                    </Title14>
                  </Box13>
                </Box10>
              </Wrapper8>
              <Wrapper9>
                <Box10>
                  <Title17>
                    Thank you for joining Freadom and being incredible partners
                    in this journey with us.
                  </Title17>
                  <Bottomimage
                    alt="loading"
                    width="300"
                    height="281"
                    src={require("static/img/Lastbg.png")}
                  />
                </Box10>
              </Wrapper9>
            </div>
          ) : (
            <h1>Hello</h1>
          )}
        </div>
      )}
    </div>
  );
};

export default withRouter(
  connectStore({
    inviteCode,
    onboard,
  })(Report)
);

import styled from "styled-components";
import { NotificationScrollParentContainer } from "@sc/custom.styled";
import { PlanHeading } from "@c/users-view/child-profile/child-subscription/ChildSubscription.styled"
export const PaymentsContainer = styled.div`
width:100%;
position:relative;

width:700px;
border-top:1px solid rgba(0,0,0,0.2);
`;


export const PaymentsHeader = styled.div`
 display:flex;
 position:absolute;
 background-color:white;

 top:0;
 left:0;
 cursor:pointer;
 padding:14px;
 z-index:100000;
 border-bottom:1px solid rgba(0,0,0,0.2);
 
 width:100%;`;


export const PaymentListContainer = NotificationScrollParentContainer.extend`
overflow-y:auto;
overflow-x:hidden;
padding:14px;
padding-top:50px;
width:100%;
min-height:240px;
max-height:calc(100vh - 110px);
transition:all 0.3s ease-in-out;

`;
export const PaymentHeading = PlanHeading.extend`
color:black;
font-size:${props => props.fontSize ? props.fontSize : '14px'}
span {
    color:${props=>props.color?props.color:'#ee8a23'};
    font-size:18px;

}
`;

export const PaymentItem = styled.div`
position:relative;
padding:10px;
border-bottom:1px solid rgba(0,0,0,0.2);
display:flex;
justify-content:space-between;
align-items:center;`;
export const PaymentDataContainer = styled.div`
display:flex;
justify-content:flex-start;
div {
    margin-left:10px;
}
`;

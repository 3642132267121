import { createSagas } from "redux-box";
import swal from "sweetalert2";
import ExperienceApi from "@s/api/experience";
import { call, put } from "redux-saga/effects";
const errorHandler = err => {
  console.log(err);
  swal("Oops!", err.error_message, "error");
};
export default createSagas({
  REQUEST_GET_ALL_EXPERIENCES_LIST: function* ({ offset }) {
    try {
      if (offset === 0)
        yield put({
          type: "SET_FETCH_ALL_EXPERIENCE_LIST",
          val: true
        });
      const gState = require("store").default.getState();
      let date = gState.experience.experience_date;
      let expType = gState.experience.experience_type;
      const res = yield call(
        ExperienceApi.getAllExperiencesList,
        expType,
        offset,
        date
      );
      yield put({
        type: "SET_ALL_EXPERIENCES_RESULT",
        data: res
      });
      yield put({
        type: "SET_ALL_EXPERIENCE_NEXT",
        data: {
          next: res.has_next,
          offset: res.offset
        }
      });
      yield put({
        type: "SET_ALL_EXPERIENCES_LIST",
        data: res && res.result
      });
    } catch (err) {
      errorHandler(err);
    } finally {
      yield put({
        type: "SET_FETCH_ALL_EXPERIENCE_LIST",
        val: false
      });
    }
  },
  REQUEST_DELETE_ALL_EXPERIENCE_BY_ID: function* ({ experienceId }) {
    try {
      const gState = require("store").default.getState();
      let expType = gState.experience.experience_type;
      //const activities =gState.activity.activities;

      const res = yield call(
        ExperienceApi.deleteAllExperienceById,
        expType,
        experienceId
      );
      if (res) {
        yield put({
          type: "SET_ALL_EXPERIENCE_NEXT",
          data: {
            next: false,
            offset: 0
          }
        });
        yield put({
          type: "UNSET_ALL_EXPERIENCES_LIST"
        });
        yield put({
          type: "REQUEST_GET_ALL_EXPERIENCES_LIST",
          offset: 0
        });
      }
    } catch (err) {
      errorHandler(err);
    }
  },
  REQUEST_PUBLISH_ALL_EXPERIENCE_BY_ID: function* ({ experience }) {
    try {
      const gState = require("store").default.getState();
      let expType = gState.experience.experience_type;
      //const activities =gState.activity.activities;

      const res = yield call(
        ExperienceApi.publishAllExperienceById,
        expType,
        experience.id,
        experience.data
      );
      if (res) {
        yield put({
          type: "SET_ALL_EXPERIENCE_NEXT",
          data: {
            next: false,
            offset: 0
          }
        });
        yield put({
          type: "UNSET_ALL_EXPERIENCES_LIST"
        });
        yield put({
          type: "REQUEST_GET_ALL_EXPERIENCES_LIST",
          offset: 0
        });
      }
    } catch (err) {
      errorHandler(err);
    }
  },
  REQUEST_GET_ALL_ACTIVE_EXPERIENCE_BY_ID: function* ({ experienceId }) {
    try {
      const gState = require("store").default.getState();
      let expType = gState.experience.experience_type;
      //const activities =gState.activity.activities;
      yield put({
        type: "SET_FETCH_ALL_ACTIVE_EXPERIENCE",
        value: false
      });
      const res = yield call(
        ExperienceApi.getAllActiveExperienceById,
        expType,
        experienceId
      );
      yield put({
        type: "SET_ACTIVE_ALL_EXPERIENCE",
        experience: res
      });
    } catch (err) {
      errorHandler(err);
    } finally {
      yield put({
        type: "SET_FETCH_ALL_ACTIVE_EXPERIENCE",
        value: false
      });
    }
  }
});

import React, { Component } from "react";
import {
  NotificationDetailContainer,
  PeopleSelectorContainer,
  Heading,
  MessageTypeSelector,
  SchedulingContainer,
  NotificationBasicInfoContainer,
  UploadImageContainer,
  SelectImageButton,
  AddButtonContainer,
  NotificationDescriptionContainer,
  NotificationInputField,
  CounterContainer,
  CriteriaOptionItem,
  CriteriaOptionContainer,
  CriteriaSelectedData,
  SelectBoxContainer
} from "./CreateNotificationContainer.styled";
import NotificationTextEditor from "@c/notifications-view/notification-text-editor/NotificationTextEditor";
import {
  ChangeImageButton,
  ImageContainer,
  SelectFileIn
} from "@c/activity-view/activity-creation-form/ActivityCreationForm.styled";
import { uploadFile } from "@s/api/file";
import TextArea from "react-autosize-textarea";
import {
  ThemeButton,
  ImageLoader,
  SelectorButton,
  SelectBox,
  FlexBetween,
  DateSelectionField,
  PanelHeader
} from "@sc/custom.styled";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import "../../../global.css";
import { connectStore } from "redux-box";
import { module as ui } from "store/ui";
import { module as notification } from "store/notification";
import swal from "sweetalert2";
import { CreateNotificationMainContainer } from "@c/notifications-view/NotificationsView.styled";
import { Link } from "react-router-dom";
import { select } from "redux-saga/effects";
//import {ModalBackDrop} from "static/styled/ModalBackDrop.styled";

const errorHandler = err => {
  //console.log(err);
  swal("Oops!", err, "error");
};


class CreateNotificationContainer extends Component {
  constructor(props) {
    super();
    this.state = {
      gradeSelector: false,
      levelSelector: false,
      SchoolSelector: false,
      criteria_type: "People/Users",
      selectedScheduledDate: "",
      imageChange: false,
      enableLoad: false,
      enableReload: false,
      enableEdit: false,
      // activeDate: moment().add(10, "minutes"),
      activeDate: moment(),
      // date: moment(),
      selectedDate: moment(),
      // time: moment().add(10, "minutes"),
      time: moment(),
      scheduledTime: "Send now",
      scheduledDate: "",
      notification_info: {
        image: "",
        subject: "",
        body: "",
        is_scheduled: false,
        notification_type: "sms"
      },
      showBackdrop: false
    };
  }

  componentWillReceiveProps(nextProps) {
    let currentPath = window.location.pathname;
    let { notification } = this.props;
    if (nextProps.notification && nextProps.notification.active_notification) {
      this.normalizeForState(nextProps.notification.active_notification);
    } else if (currentPath.includes("edit") || notification.unset_data) {
      notification.unsetNotificationData(false);
      this.unsetNotificationData();
    }
  }

  normalizeForState(activeNotification) {
    let date = new Date(activeNotification.scheduled_time);
    let newDate = moment();
    newDate.set({
      hour: date.getHours(),
      minute: date.getMinutes(),
      second: date.getSeconds(),
      millisecond: date.getMilliseconds(),
      date: date.getDate(),
      month: date.getMonth(),
      year: date.getFullYear()
    });
    this.setState({
      notification_info: {
        ...this.state.notification_info,
        subject: activeNotification.subject,
        notification_type: activeNotification.notification_type,
        image: activeNotification.image,
        is_scheduled: activeNotification.is_scheduled,
        body:
          activeNotification.notification_type !== "email"
            ? activeNotification.body
            : ""
      },
      date: newDate,
      time: newDate,
      activeDate: newDate,
      enableDoneBtn: false,
      scheduledTime: "Send Later"
    });
  }

  componentWillMount() {
    let { notification } = this.props;
    let currentPath = window.location.pathname;
    if (currentPath.includes("/edit")) {
      //console.log(this.props.match.params)
      notification.requestNotificationById(this.props.match.params.id);
    }
  }
  componentDidMount() {
    //let history=this.props.history;
    let { notification } = this.props;

    if (notification.unset_data) {
      this.unsetNotificationData();
      notification.unsetNotificationData(false);
    }
    if (window.location.pathname.includes("/edit")) {
      if (this.state.enableEdit)
        this.setState({
          enableEdit: false,
          enableDoneBtn: false
        });
    } else if (!this.state.enableEdit) {
      this.setState({
        enableEdit: true,
        enableDoneBtn: false
      });
    }
  }

  handleErrorCheck = () => {
    let { notification } = this.props;
    let err = "";
    let data = this.state.notification_info;
    //err+=data.image===''?'image':'';
    err += data.subject === "" ? ",subject" : "";
    if (this.state.notification_info.notification_type !== "email")
      err += data.body === "" ? ",body" : "";
    else err += notification.email_description === "" ? ",subject" : "";
    return err;
  };

  handleImageChange = e => {
    let reader = new FileReader();
    let file = e.target.files[0];
    let fileSize = e.target.files[0].size / 1024 / 1024;
    if (fileSize > 2) {
      swal("Oops!", "File size greater than 2MB ", "warning");
    } else {
      //console.log(file);
      reader.onloadend = () => {
        this.setState({
          enableLoad: true,
          imageChange: false,
          notification_info: {
            ...this.state.notification_info,
            image: reader.result
          }
        });
      };
      reader.readAsDataURL(file);
      uploadFile(file).then(res => {
        this.setState({
          notification_info: {
            ...this.state.notification_info,
            image: res.file
          },
          enableLoad: false
        });
      });
    }
  };

  handleImageFocus = actionType => {
    if (actionType === "over" && this.state.imageChange === false)
      this.setState({
        imageChange: true
      });
    else if (actionType === "out" && this.state.imageChange === true)
      this.setState({
        imageChange: false
      });
  };

  handleDoneButton = () => {
    if (this.state.notification_info.is_scheduled) {
      // let date = new Date(this.state.activeDate._d);
      let date = this.state.activeDate.clone().set({
        hour: moment(this.state.time).get('hour'),
        minute: moment(this.state.time).get('minute'),
        second: 0,
        millisecond: 0,
      });
      this.setState({
        selectedScheduledDate: date
      });
    }
    let errCheck = this.handleErrorCheck();
    if (errCheck === "") {
      switch (this.state.criteria_type) {
        case "People/Users": {
          //  for creating people / user notification
          this.handleUsersNotification();
          break;
        }
        case "WOW": {
          // for creating WOW Based filter notification
          this.handleWowNotification();
          break;
        }
        case "Freadom": {
          // for creating Freadom Based filter notification

          this.handleFreadomNotification();
          break;
        }
        default: {
          //  for creating people / user notification
          this.handleUsersNotification();
          break;
        }
      }
    } else {
      swal("Oops!", "Please add " + errCheck, "warning");
    }
  };

  handleUsersNotification = () => {
    let { notification } = this.props;
    let users = notification.selected_users;
    let notification_data = {
      ...this.state.notification_info,
      filter_type: "all",
      filter_type_ids: [],
      custom_user_ids: users
    };
    if (users.length === 0) errorHandler("Please select children");
    else if (users.length > 0 && users[0] === "All") {
      this.handleScheduledNotificationCreation({
        ...notification_data,
        custom_user_ids: []
      });
      this.unsetNotificationData();
    } else {
      this.handleScheduledNotificationCreation(notification_data);
      notification.setCreateSuccess(true);
      this.unsetNotificationData();
    }
  };

  // CASE FOR WOW SCHOOL AND ALL SECTIONS NOTIFICATION
  handleWowSchoolNotification = () => {
    let { notification } = this.props;
    let custom_user_ids = [];
    if (notification.selected_users && notification.selected_users.length > 0)
      custom_user_ids =
        notification.selected_users[0] !== "All"
          ? notification.selected_users
          : [];
    let notification_data = {
      ...this.state.notification_info,
      filter_type: "wow-school",
      filter_type_ids: [notification.selected_schools.id],
      wow_school_ids: [notification.selected_schools.id],
      custom_user_ids: custom_user_ids
    };
    this.handleScheduledNotificationCreation(notification_data);
  };

  handleWowMultipleSchoolNotification = () => {
    let { notification } = this.props;
    let notification_data = {
      ...this.state.notification_info,
      filter_type: "wow-school",
      filter_type_ids: notification.multiple_school_list,
      custom_user_ids: []
    };
    this.handleScheduledNotificationCreation(notification_data);
  };

  // CASE FOR WOW SPECIFIC SELECTED SECTIONS AND SEARCH BAR SELECTION SECTION CASES ALSO
  handleWowSectionNotification = () => {
    let { notification } = this.props;
    let custom_user_ids = [];
    if (notification.selected_users && notification.selected_users.length > 0)
      custom_user_ids =
        notification.selected_users[0] !== "All"
          ? notification.selected_users
          : [];
    let notification_data = {
      ...this.state.notification_info,
      filter_type: "wow-grade",
      filter_type_ids: notification.selected_sections,
      wow_school_ids: [notification.selected_schools.id],
      custom_user_ids: custom_user_ids
    };
    this.handleScheduledNotificationCreation(notification_data);
  };

  // PARENT FUNCTION FOR ALL THE CASES OF THE WOW NOTIFICATION
  handleWowNotification = () => {
    let { notification } = this.props;
    if (notification.multiple_school_list.length > 0) {
      this.handleWowMultipleSchoolNotification();
    } else {
      if (notification.selected_schools === null)
        errorHandler("Please select school!");
      else if (notification.selected_sections.length === 0)
        errorHandler("Please select sections!");
      else if (notification.selected_users.length === 0)
        errorHandler("Please select  children");
      else {
        let selected_sections = notification.selected_sections;
        if (selected_sections.length === 1 && selected_sections[0] === "All") {
          this.handleWowSchoolNotification();
        } else {
          this.handleWowSectionNotification();
        }
        this.unsetNotificationData();
      }
    }
  };

  // PARENT FUNCTION FOR ALL TEH CASES OF FREADOM TYPE NOTIFICATION
  handleFreadomNotification = () => {
    let { notification } = this.props;
    let users = notification.selected_users;
    let selected_filter = notification.selected_filter;
    if (
      notification.selected_grades.length === 0 &&
      notification.selected_levels.length === 0
    )
      errorHandler("Please make some selections");
    else if (selected_filter === "Grade") {
      if (notification.selected_grades.length === 0)
        errorHandler("Please select Grades!");
      else if (users.length > 0) {
        this.handleCreateNotification(
          notification.selected_grades,
          "freadom-grade",
          6
        );
      }
    } else if (selected_filter === "Level") {
      if (notification.selected_levels.length === 0)
        errorHandler("Please select levels!");
      else if (users.length > 0) {
        this.handleCreateNotification(
          notification.selected_levels,
          "freadom-level-sublevel",
          12
        );
      }
    }
  };

  // NOTIFICATION CEATION COVERING FREADOM GRADE AND LEVEL-SUBLEVEL CASES
  handleCreateNotification = (selected_list, selected_filter, len) => {
    let { notification } = this.props;
    let users = notification.selected_users;
    let filter_type_ids = selected_list;
    let filter_type = selected_filter;
    let custom_user_ids = notification.selected_users;
    if (users.length === 0) errorHandler("Please select children!");
    else if (users.length > 0) {
      let notification_data = {
        ...this.state.notification_info,
        filter_type: filter_type,
        filter_type_ids: filter_type_ids,
        custom_user_ids: custom_user_ids
      };

      if (users[0] === "All" && users.length === 1) {
        custom_user_ids = [];
        notification_data = {
          ...notification_data,
          custom_user_ids: []
        };

        this.handleScheduledNotificationCreation(notification_data);
      } else {
        this.handleScheduledNotificationCreation(notification_data);
      }

      this.unsetNotificationData();
    }
  };

  // FINAL FUNCTION TO CHECK THAT IF NOTIFICATION IS SCHEDULED AND CREATE THE NOTIFICATION
  handleScheduledNotificationCreation = notificationData => {
    // debugger;
    let { notification } = this.props;
    if (this.state.notification_info.is_scheduled)
      notificationData = {
        ...notificationData,
        // scheduled_time: moment
        //   .utc(new Date(this.state.activeDate._d))
        //   .format("YYYY-MM-DD HH:mm:ss.SSSSSZ"),
        scheduled_time: this.state.activeDate.clone().set({
          hour: moment(this.state.time).get('hour'),
          minute: moment(this.state.time).get('minute'),
          second: 0,
          millisecond: 0,
        })
      };
    notification.requestCreateNotification(notificationData);
  };

  // TO UNSET THE DATA ON THE COMPLETION OF SUCCESSFUL NOTIFICATION
  unsetNotificationData = () => {
    let { notification } = this.props;
    if (notification.active_notification)
      notification.setActiveNotification(null);
    this.setState({
      activeDate: moment().add(10, "minutes"),
      criteria_type: "People/Users",
      selectedScheduledDate: "",
      imageChange: false,
      enableLoad: false,
      date: moment(),
      time: moment().add(10, "minutes"),
      scheduledTime: "Send now",
      scheduledDate: "",

      notification_info: {
        image: "",
        subject: "",
        body: "",
        is_scheduled: false,
        notification_type: "sms"
      }
    });
    this.props.notification.setCreateSuccess(false);
  };

  // TO HANDLE THE GRADE OPEN/ CLOSE
  handleGradeList = () => {
    this.setState({
      gradeSelector: !this.state.gradeSelector
    });
  };

  // TO HANDLE DATE PICKER SELECTION
  handleDateChange = selectedDate => {
    let date = new Date(selectedDate._d);
    // let date = moment();
    let currentDate = new Date();
    let activeDate = new Date(this.state.activeDate._d);
    let newDate = moment();
    newDate = moment(selectedDate);
    newDate.set({
      hour: activeDate.getHours(),
      minute: activeDate.getMinutes(),
      second: activeDate.getSeconds(),
      millisecond: activeDate.getMilliseconds()
    });
    this.setState({
      selectedDate: moment(selectedDate),
      activeDate: moment(newDate)
    });
    // if (currentDate <= date) {
    //   this.setState({
    //     date: selectedDate,
    //     activeDate: newDate
    //   });
    // } else {
    //   swal(
    //     "Oops!",
    //     "Please select date greater than or equal to current date",
    //     "warning"
    //   );
    // }

  };
  // TO HANDLE TIME CHANGE SELECTION
  // handleDateChange = date => {
  //   this.setState({
  //     selectedDate: moment(date).format("YYYY-MM-DD")
  //   });
  // };
  handleTimeChange = time => {
    // let activeDate = new Date(this.state.activeDate._d);
    // let newDate = moment();

    // newDate = time;
    // console.log(newDate)
    // console.log(time);
    // newDate.set({
    //   year: activeDate.getFullYear(),
    //   month: activeDate.getMonth(),
    //   date: activeDate.getDate()
    // });
    this.setState({
      time: time,
      // activeDate: newDate
    });
    // console.log(newDate);
    // showBackdrop:!this.state.showBackdrop,
  };

  showBackDrop = () => {
    if (!this.state.showBackdrop)
      this.setState({
        showBackdrop: true
      });
  };
  handleSubjectName = evt => {
    this.setState({
      notification_info: {
        ...this.state.notification_info,
        subject: evt.target.value
      }
    });
  };

  handleNotificationDescription = evt => {
    if (evt.target.value.length <= 0) {
      this.setState({
        notification_info: { ...this.state.notification_info, body: "" }
      });
    } else
      this.setState({
        notification_info: {
          ...this.state.notification_info,
          body: evt.target.value
        }
      });
  };
  handleTypeSelection = data => {
    let currentPath = window.location.pathname;
    if (this.props.notification.enableDoneBtn || !currentPath.includes("edit"))
      this.setState({
        notification_info: {
          ...this.state.notification_info,
          notification_type: data.toLowerCase()
        }
      });
    //this.props.notification.setNotificationType(data);
  };

  handleScheduleSelection = data => {
    let scheduled = true;
    let currentPath = window.location.pathname;
    if (
      this.props.notification.enableDoneBtn ||
      !currentPath.includes("edit")
    ) {
      if (data === "Send now") scheduled = false;
      this.setState({
        scheduledTime: data,
        notification_info: {
          ...this.state.notification_info,
          is_scheduled: scheduled
        }
      });
    }
  };
  handleCriteriaSelector = (data, index) => {
    let { notification } = this.props;
    if (data !== notification.criteriaType) {
      this.setState({
        criteria_type: data
      });
      notification.setCriteriaType(data);
    }
    if (notification.active_notification === null) {
      switch (index) {
        case 0: {
          this.unsetWOWData();
          this.unsetFreadomData();
          notification.setSelectedWowData("");
          notification.setSelectedFreadomData("");
          if (notification.selected_filter !== "Grade")
            notification.setSelectedFilter("Grade");
          break;
        }
        case 1: {
          this.unsetWOWData();
          notification.setSelectedAllData("");
          notification.setSelectedFreadomData("");
          notification.setSelectedFilter("Grade");
          break;
        }
        case 2: {
          this.unsetFreadomData();
          notification.setSelectedAllData("");
          notification.setSelectedWowData("");
          break;
        }
        default: {
          break;
        }
      }
    }
  };

  handleUpdateButton = () => {
    let err = "";
    //let {notification}=this.props;
    err = this.handleErrorCheck();
    if (err === "") {
      let { notification } = this.props;
      let notificationData = this.state.notification_info;
      if (this.state.notification_info.is_scheduled) {
        notificationData = {
          ...notificationData,
          // scheduled_time: moment
          //   .utc(new Date(this.state.activeDate._d))
          //   .format("YYYY-MM-DD HH:mm:ss.SSSSSZ")
          // scheduled_time: this.state.time
          scheduled_time: this.state.activeDate.clone().set({
            hour: moment(this.state.time).get('hour'),
            minute: moment(this.state.time).get('minute'),
            second: 0,
            millisecond: 0,
          })
        };
        notification.requestUpdateNotification(notificationData);
      } else {
        notification.requestUpdateNotification(notificationData);
      }

      notification.setEnableDoneBtn(false);
    } else {
      errorHandler("Please select " + err);
    }
  };

  unsetFreadomData = () => {
    let { notification } = this.props;
    if (notification.selected_schools !== null)
      notification.setSelectedSchoolsList(null);
    if (
      notification.selected_sections &&
      notification.selected_sections.length > 0
    )
      notification.setSelectedSectionsList([]);
    if (notification.selected_users && notification.selected_users.length > 0)
      notification.setSelectedUsersList([]);
  };

  unsetWOWData = () => {
    let { notification } = this.props;
    if (notification.selected_grades && notification.selected_grades.length > 0)
      notification.setSelectedGradeList("");
    if (notification.selected_levels && notification.selected_levels.length > 0)
      notification.setSelectedLevelsList([]);
    if (notification.selected_users && notification.selected_users.length > 0)
      notification.setSelectedUsersList([]);
    if (notification.selected_first_child.length > 0)
      notification.setSelectedFirstChild("");
  };

  handleCriteriaEditBtn = (data, index) => {
    let { ui, notification } = this.props;

    notification.setCriteriaType(data);
    switch (index) {
      case 0: {
        ui.showNotificationModal("ALLUSERS");

        break;
      }
      case 1: {
        ui.showNotificationModal("WOWGRADE");
        break;
      }
      case 2: {
        ui.showNotificationModal("FREADOMGRADE");
        break;
      }
      default: {
        ui.showNotificationModal("ALLUSERS");

        break;
      }
    }
  };

  handleEnableEditBtn = () => {
    let { notification } = this.props;

    notification.setEnableDoneBtn(true);
    this.setState({
      enableEdit: true
    });
  };

  handleCloseButton = () => {
    let { notification } = this.props;
    this.setState({
      enableEdit: true
    });
    this.unsetNotificationData();
    notification.unsetDropdownData();
    notification.setActiveNotification(null);
  };

  render() {
    let messageType = ["SMS", "PUSH", "EMAIL"];
    let scheduledType = ["Send now", "Send Later"];
    let { notification } = this.props;
    let currentPath = window.location.pathname;
    let enableDoneBtn = this.props.notification.enableDoneBtn;
    return (
      <CreateNotificationMainContainer>
        {notification.editor_type !== "" && <NotificationTextEditor />}
        {notification.editor_type === "" && (
          <PanelHeader primary style={{ position: "absolute", zIndex: 99 }}>
            <div style={{ dispaly: "flex", alignItems: "center" }}>
              {currentPath.includes("edit") && (
                <Link
                  to="/notifications/scheduled"
                  onClick={this.handleCloseButton}
                >
                  <span>
                    {" "}
                    <img
                      width="14"
                      height="14"
                      alt="arrow-left"
                      src={require("static/img/arrow_left.png")}
                    />
                  </span>{" "}
                </Link>
              )}
              <Heading>
                {" "}
                {currentPath.includes("edit")
                  ? "Edit Notifications"
                  : "Create Notifications"}
              </Heading>
            </div>
            {!currentPath.includes("edit") && (
              <ThemeButton primary onClick={this.handleDoneButton}>
                Done
              </ThemeButton>
            )}
          </PanelHeader>
        )}

        {notification.editor_type === "" &&
          !notification.fetch_active_notification ? (
            <NotificationDetailContainer>
              <MessageTypeSelector>
                <FlexBetween
                  style={
                    currentPath.includes("edit") && !notification.enableDoneBtn
                      ? { justifyContent: "flex-start" }
                      : {}
                  }
                >
                  <Heading>Select Type</Heading>
                  {currentPath.includes("edit") && notification.enableDoneBtn ? (
                    <ThemeButton primary onClick={this.handleUpdateButton}>
                      {" "}
                      Done{" "}
                    </ThemeButton>
                  ) : currentPath.includes("edit") ? (
                    <span
                      onClick={this.handleEnableEditBtn}
                      style={{
                        padding: "5px 10px",
                        marginLeft: "6px",
                        cursor: "pointer"
                      }}
                    >
                      <img
                        src={require("static/img/editicon.png")}
                        alt="edit-icon"
                      />
                    </span>
                  ) : null}
                </FlexBetween>
                <FlexBetween style={{ marginTop: "10px" }}>
                  {messageType.map((data, index) => (
                    <SelectBox
                      key={index}
                      active={
                        this.state.notification_info.notification_type ===
                        data.toLowerCase()
                      }
                      onClick={() => this.handleTypeSelection(data)}
                    >
                      <SelectorButton
                        active={
                          this.state.notification_info.notification_type ===
                          data.toLowerCase()
                        }
                      >
                        <div />
                      </SelectorButton>
                      <span>{data}</span>
                    </SelectBox>
                  ))}
                </FlexBetween>
              </MessageTypeSelector>
              <NotificationBasicInfoContainer>
                {this.state.notification_info.image === "" ? (
                  <UploadImageContainer>
                    <AddButtonContainer> +</AddButtonContainer>
                    {(!currentPath.includes("edit") ||
                      (this.props.notification.enableDoneBtn &&
                        currentPath.includes("edit"))) && (
                        <SelectImageButton
                          type="file"
                          onChange={e => this.handleImageChange(e)}
                        />
                      )}
                  </UploadImageContainer>
                ) : this.state.enableLoad ? (
                  <UploadImageContainer>
                    <ImageLoader>
                      <span style={{ width: "18px", height: "18px" }}>
                        {" "}
                        <img
                          alt="loading"
                          width="14"
                          height="14"
                          src="http://photokonkurs.russia.travel/images/icons/preloader.gif"
                        />
                      </span>
                    </ImageLoader>
                  </UploadImageContainer>
                ) : (
                      <UploadImageContainer
                        style={{ border: "1px solid rgba(0,0,0,0.1)" }}
                        onMouseOver={() => this.handleImageFocus("over")}
                        onMouseOut={() => this.handleImageFocus("out")}
                      >
                        <ImageContainer
                          src={this.state.notification_info.image}
                          style={{ height: "100%" }}
                        />
                        {(!currentPath.includes("edit") ||
                          (this.props.notification.enableDoneBtn &&
                            currentPath.includes("edit"))) && (
                            <ChangeImageButton
                              top={this.state.imageChange ? "84%" : "100%"}
                              opacity={this.state.imageChange ? 1 : 0}
                              style={{ fontSize: "10px", height: "20px" }}
                            >
                              Change
                    </ChangeImageButton>
                          )}
                        {(!currentPath.includes("edit") ||
                          (this.props.notification.enableDoneBtn &&
                            currentPath.includes("edit"))) && (
                            <SelectFileIn
                              type="file"
                              onChange={e => this.handleImageChange(e)}
                            />
                          )}
                      </UploadImageContainer>
                    )}

                <NotificationInputField
                  type="text"
                  placeholder="Enter subject here"
                  value={this.state.notification_info.subject}
                  disabled={
                    !this.props.notification.enableDoneBtn &&
                    currentPath.includes("edit")
                  }
                  onChange={this.handleSubjectName}
                />
                {this.state.notification_info.notification_type !== "email" ? (
                  <NotificationDescriptionContainer>
                    <TextArea
                      onChange={this.handleNotificationDescription}
                      placeholder="Enter description"
                      maxLength="200"
                      value={this.state.notification_info.body}
                      disabled={!enableDoneBtn && currentPath.includes("edit")}
                    />
                    <CounterContainer>
                      {200 - this.state.notification_info.body.length}{" "}
                    </CounterContainer>
                  </NotificationDescriptionContainer>
                ) : (
                    <NotificationDescriptionContainer>
                      <ThemeButton
                        primary
                        onClick={() =>
                          this.props.notification.setNotificationEditorType("email")
                        }
                      >
                        Open Email Editor
                  </ThemeButton>
                    </NotificationDescriptionContainer>
                  )}
              </NotificationBasicInfoContainer>

              <SchedulingContainer>
                <Heading>Scheduling</Heading>
                <FlexBetween
                  style={{ justifyContent: "flex-start", marginTop: "10px" }}
                >
                  {scheduledType.map((data, index) => (
                    <SelectBox
                      key={index}
                      active={this.state.scheduledTime === data}
                      onClick={() => this.handleScheduleSelection(data)}
                    >
                      <SelectorButton active={this.state.scheduledTime === data}>
                        <div />
                      </SelectorButton>
                      <span style={{ marginRight: "10px" }}>{data}</span>
                    </SelectBox>
                  ))}
                </FlexBetween>
                {/*{this.state.showBackdrop?<ModalBackDrop active second/>:null}*/}
                {this.state.scheduledTime === "Send Later" ? (
                  <FlexBetween style={{ margin: "15px 0px" }}>
                    <DateSelectionField
                      style={{ overflow: "hidden", backgroundColor: "white" }}
                    >
                      <img
                        src={require("static/img/date-icon.png")}
                        alt="startDate"
                      />
                      <DatePicker
                        style={{ backgroundColor: "white" }}
                        disabled={
                          !this.props.notification.enableDoneBtn &&
                          currentPath.includes("edit")
                        }
                        minDate={moment().toDate()}
                        // selected={this.state.date}
                        selected={
                          moment.isMoment(this.state.selectedDate)
                            ? this.state.selectedDate.toDate()
                            : this.state.selectedDate
                        }
                        onChange={this.handleDateChange}
                      // onFocus={this.showBackDrop}
                      />
                    </DateSelectionField>
                    <DateSelectionField style={{ overflow: "hidden" }}>
                      <img
                        src={require("static/img/date-icon.png")}
                        alt="startDate"
                      />
                      <DatePicker
                        disabled={
                          !this.props.notification.enableDoneBtn &&
                          currentPath.includes("edit")
                        }
                        minDate={moment().toDate()}
                        selected={

                          moment.isMoment(this.state.time)
                            ? this.state.time.clone().toDate()
                            : this.state.time
                        }

                        onChange={this.handleTimeChange}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={1}
                        dateFormat="hh:mm aa"
                        timeCaption="Time"
                      />
                    </DateSelectionField>{" "}
                  </FlexBetween>
                ) : null}
              </SchedulingContainer>

              <PeopleSelectorContainer>
                <Heading>Select Criteria</Heading>
                <CriteriaOptionContainer>
                  <CriteriaOptionItem>
                    <SelectBoxContainer>
                      <SelectBox
                        active={notification.criteriaType === "People/Users"}
                        onClick={() =>
                          this.handleCriteriaSelector("People/Users", 0)
                        }
                      >
                        <SelectorButton
                          active={notification.criteriaType === "People/Users"}
                        >
                          <div />
                        </SelectorButton>
                        <span> People/Users</span>
                      </SelectBox>
                      {notification.criteriaType === "People/Users" && (
                        <img
                          src={require("static/img/editicon.png")}
                          alt="edit-icon"
                          onClick={() =>
                            this.handleCriteriaEditBtn("People/Users", 0)
                          }
                        />
                      )}
                    </SelectBoxContainer>
                    <CriteriaSelectedData>
                      {notification.selected_all_data !== ""
                        ? notification.selected_all_data
                        : "Nothing selected yet"}
                    </CriteriaSelectedData>
                  </CriteriaOptionItem>
                  <CriteriaOptionItem>
                    <SelectBoxContainer>
                      <SelectBox
                        active={notification.criteriaType === "WOW"}
                        onClick={() => this.handleCriteriaSelector("WOW", 1)}
                      >
                        <SelectorButton
                          active={notification.criteriaType === "WOW"}
                        >
                          <div />
                        </SelectorButton>
                        <span>WOW</span>
                      </SelectBox>
                      {notification.criteriaType === "WOW" && (
                        <img
                          src={require("static/img/editicon.png")}
                          alt="edit-icon"
                          onClick={() => this.handleCriteriaEditBtn("WOW", 1)}
                        />
                      )}
                    </SelectBoxContainer>
                    <CriteriaSelectedData>
                      {notification.selected_wow_data !== ""
                        ? notification.selected_wow_data
                        : notification.multiple_school_list.length > 0
                          ? `${
                          notification.multiple_school_list.length
                          } school selected`
                          : "Nothing selected yet"}
                    </CriteriaSelectedData>
                  </CriteriaOptionItem>
                  <CriteriaOptionItem>
                    <SelectBoxContainer>
                      <SelectBox
                        active={notification.criteriaType === "Freadom"}
                        onClick={() => this.handleCriteriaSelector("Freadom", 2)}
                      >
                        <SelectorButton
                          active={notification.criteriaType === "Freadom"}
                        >
                          <div />
                        </SelectorButton>
                        <span>Freadom</span>
                      </SelectBox>
                      {notification.criteriaType === "Freadom" && (
                        <img
                          src={require("static/img/editicon.png")}
                          alt="edit-icon"
                          onClick={() => this.handleCriteriaEditBtn("Freadom", 2)}
                        />
                      )}
                    </SelectBoxContainer>
                    <CriteriaSelectedData>
                      {" "}
                      {notification.selected_freadom_data !== ""
                        ? notification.selected_freadom_data
                        : "Nothing Selected yet"}
                    </CriteriaSelectedData>
                  </CriteriaOptionItem>
                </CriteriaOptionContainer>
              </PeopleSelectorContainer>
            </NotificationDetailContainer>
          ) : null}
      </CreateNotificationMainContainer>
    );
  }
}
export default connectStore({
  ui,
  notification
})(CreateNotificationContainer)
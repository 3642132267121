import styled from "styled-components";
import { ThemeButton } from "static/styled/custom.styled";


export const ActivitySectionLabelsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  align-items: center;
`;

export const ActivityDropDownListContainer = styled.div`
  display: flex;
  padding-top: 200px;

  flex-flow: column nowrap;
`;

export const ActivityDropDownListItem = styled.div``;

export const ActivityPackThemeButton = ThemeButton.extend`
  padding: 6px 14px;
`;


export const ActivityComponentInfoContainer=styled.div`
 width:100%;
  height: 100%;
  
  overflow: hidden;`;

import React, {Component} from 'react';
import {PanelHeader,Heading, ThemeButton} from "static/styled/custom.styled";
import {CollectionEditorMainContainer} from "./CollectionEditor.styled";
import {module as story} from "@st/story";
import {connectStore} from "redux-box";
import {ActivityModalContainer} from "static/styled/ActivityModal.styled";
import {SelectionLabels, SelectTagsListContainer,TagLabelContainer} from "@c/activity-view/activity-creator/tag-editor/TagEditor.styled";
import swal from "sweetalert2";
import InfiniteScroll from "react-infinite-scroller";

import {
  InfiniteScrollParentContainer,
  InfiniteScrollLoader,
} from "static/styled/custom.styled";

const errorHandler = err => {
    swal({
        text: err,
        type: "warning"});
};


 class CollectionEditor extends Component {
   componentDidMount() {
     this.props.story.requestCollections();
   }
   handleCollectionTag(collection) {
     let { story } = this.props;
     if (story.active_story.collection.length === 3) {
       errorHandler("Cannot Assign More than three collection to Story");
     } else {
       story.requestAddStoryCollection(collection.id);
     }
   }

   fetchMoreCollections = () => {
     if (
       this.props.story.collections_result !== null &&
       this.props.story.collections_result.has_next
     ) {
       setTimeout(() => {
         if (
           this.props.story.collections_result !== null &&
           this.props.story.collections_result.next_page > 0
         )
           this.props.story.requestCollectionsListConcat(
             this.props.story.collections_result.next_page
           );
       }, 100);
     }
   };

   render() {
     let { collectionList } = this.props;
     let { story } = this.props;

     const selectTags =
       collectionList &&
       collectionList.map((collection, i) => (
         <TagLabelContainer
           key={i}
           onClick={() => this.handleCollectionTag(collection)}
         >
           <div>{collection.name}</div>
         </TagLabelContainer>
       ));
     return (
       <CollectionEditorMainContainer>
         <PanelHeader primary>
           <Heading style={{ margin: "0px" }}> Assign Collections </Heading>
           <ThemeButton primary onClick={() => story.setStoryEditorType("")}>
             Done
           </ThemeButton>
         </PanelHeader>
         <ActivityModalContainer>
           <SelectionLabels primary>
             Select Collections from below
           </SelectionLabels>
           <SelectTagsListContainer>
             <InfiniteScrollParentContainer height={"330px"} noTop>
               <InfiniteScroll
                 pageStart={0}
                 loadMore={this.fetchMoreCollections}
                 style={{ position: "relative" }}
                 hasMore={this.props.story.collections_result.has_next}
                 loader={
                   <InfiniteScrollLoader className="loader" key={0}>
                     <img
                       alt="loader"
                       width="50"
                       height="50"
                       src={require("static/img/Theme-loader.gif")}
                     />
                   </InfiniteScrollLoader>
                 }
                 useWindow={false}
               >
                 {/* {collectionList} */}
                 {selectTags}
               </InfiniteScroll>
             </InfiniteScrollParentContainer>
           </SelectTagsListContainer>
         </ActivityModalContainer>
       </CollectionEditorMainContainer>
     );
   }
 }
export default connectStore({
    story
})(CollectionEditor)

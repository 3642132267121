import styled from "styled-components";


export const EnterTagsInputContainer = styled.div`
        border-radius: 30.5px;
    border: solid 1px rgba(28,29,64,0.3);
    position: relative;
    height:40px;
    padding:6px 0px 3px 10px;
    margin-top:20px;
    
    input {
        border:none;
        outline: none;
        font-size:var(--md-font-size);
        width:80%;
        margin-left:10px;
        padding-left: 10px;
        };
    
    button { 
        position: absolute;
        top:3px;
        border: none;
        border-radius: 100%;
        right: 8px;
        width:30px;
        height:30px;
        text-align:center;
        font-size:var(--md-font-size);
        color:white;
        outline: none;
        font-weight: bold;
        background-color: #ff8a23;
        cursor: pointer;
        }`;

export const SelectionLabels=styled.div`
        font-size: var(--lg-font-size);
    font-weight: ${props=> props.primary ? 'normal' : 'bold'};
    letter-spacing: 0.1px;
    text-align: center;
    margin:12px 0px;
    color: ${props => props.primary ? '#263651' : '#000000'};`;

export const SelectTagsListContainer = styled.div`
        display: flex;
        flex-wrap: wrap;`;

export const TagLabelContainer = styled.div`
    margin:4px 8px;
    background-color: rgba(255,138,35,0.1);
    color:#ff8a23;
    outline: none;
    border: none;
    border-radius: 100px;
    
    &:hover
    {
        background-color: #ff8a23;
        color:white;
        cursor: pointer;
    }
    
    div
    {
        padding:6px 14px;
        font-size:var(--sm-font-size);
    }
    `;
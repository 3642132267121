const development = {
  api_url: "https://stage.api.getfreadom.com/api",
  // api_url: "http://127.0.0.1:8000/api",
  // api_url: "https://dev-api.freadom.in/api",
  external_api_url: "https://internals.freadom.in/api/external",
  wow_url: "https://teach-dev-api.wowconnect.in/api/v1",
  cmfluency_url: "https://fluency.getfreadom.com",
  auth_token: "0cbcb492-9718-4263-8978-e5b2b31cd3f4",
};

// eslint-disable-next-line no-unused-vars
const production = {
  api_url: "https://stage.api.getfreadom.com/api",
  external_api_url: "https://internals.freadom.in/api/external",
  wow_url: "https://teach-api.wowconnect.in/api/v1",
  cmfluency_url: "https://fluency.getfreadom.com",
  auth_token: "1cbcb492-9718-4263-8978-e5b2b31cd3f4",
};
export default development;

export default {
  //....................PRODUCT CRUD.........................................
  SET_PODCAST_LIST: (state, { value }) => {
    state.podcast_list = value;
    state.isAddPodcastFormOpened = false;
  },
  SET_EDIT_PODCAST: (state, { podcast }) => {
    state.edit_podcast = podcast;
    state.isAddPodcastFormOpened = true;
    if (podcast == null) {
      state.isAddPodcastFormOpened = false;
    }
  },
  SET_PODCAST_LIST_LOADING: (state, { value }) =>
    (state.isPodcastLoading = value),
  SET_PODCAST: (state, { podcast }) => (state.podcast = podcast),

  SET_FETCH_PODCAST_EPISODES: (state, { value }) =>
    (state.isPodcastEpisodesLoading = value),
  SET_SELECTED_PODCAST: (state, { podcastId }) => (state.podcast = podcastId),
  SET_EPISODES: (state, { episodes }) => (state.episodesList = episodes),
  SET_EPISODES_RESULT: (state, { result }) => (state.episodesResult = result),
  SET_EPISODES_CONCAT_LIST: (state, { List }) =>
    (state.episodesList = state.episodesList.concat(List)),
  SET_EPISODE_EDIT_INFO: (state, { value }) => (state.episodeEditInfo = value),
  SET_EPISODE_EDIT: (state, { value }) => (state.episodeEdit = value),
};

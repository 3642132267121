import React, {Component} from 'react';
import {StorySectionContainer} from "@c/story-view/story-creator/StoryCreator.styled";
import {ThemeButton} from "static/styled/custom.styled";
import {module as story} from "@st/story";

import {connectStore} from "redux-box";
import { module as onboard } from "@st/onboard";
import Permission from "@s/permission";

class HtmlStory extends Component
{
    constructor(props)
    {
        super();
        this.state=({
            showHTML:false
        })
    }
    componentDidMount()
    {
        let {section}=this.props;
        if(section!==null && section.content_type==='html')
        {
              if(section.description && section.description.length>0)
              {
                  this.setState({
                      showHTML:true
                  });
              }
        }
    }
    componentWillReceiveProps(nextProps)
    {
        if(nextProps.story && nextProps.story.active_section)
        {
            let section=nextProps.story.active_section;
            if(section!==null && section.content_type==='html')
            {
                if(section.description && section.description.length>0)
                {
                    this.setState({
                        showHTML:true
                    });
                }
            }
        }
    }
    render(){
        //let {section}=this.props;
        let {permission}=this.props.onboard;
        return (
          <StorySectionContainer>
              {Permission.getPermission(permission, "change_story") ?
                  <ThemeButton primary
                               onClick={()=>{this.props.story.setStoryEditorType('HTML')}}>
                      {!this.state.showHTML ? 'Add HTML' :'Edit HTML'}
                  </ThemeButton>:null}
          </StorySectionContainer>
        )
    }
}
export default connectStore({
    story,onboard
})(HtmlStory)
import React, {Component} from 'react';
import {ThemeButton, McqSectionHeader, McqItemContainer, McqDescription, McqToolbar} from "static/styled/custom.styled";
import {McqSectionContainer} from "./McqSection.styled";
import store from 'store'
import action from '@st/activity/actions'
import {connectStore} from "redux-box";
import {module as activity} from "@st/activity";
import {module as readingChallenge} from "@st/readingChallenge";
import swal from 'sweetalert2'
import { module as onboard } from "@st/onboard";
import Permission from "@s/permission";


 class McqSection extends Component {
    
    handleEditClick=(data)=>{
        let {permission} = this.props.onboard;
        if(Permission.getPermission(permission, "change_reading")){
            this.props.readingChallenge.setActiveReadingMcq(data);
            store.dispatch(action.setEditorType('mcq'))
        }else{
            swal({
                text: "You do not have permission.",
                type: "warning",
              })
        }
        
    };

    handleDeleteClick=(question,evt)=>{
        let {permission} = this.props.onboard;
        if(Permission.getPermission(permission, "change_reading")){
            this.props.activity.closeEditor();
            swal({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
              }).then((result) => {
                if (result.value) {
                    this.props.readingChallenge.requestDeleteReadingQuestion(question);
    
                }
              });
            evt.stopPropagation();
        }else{
            swal({
                text: "You do not have permission.",
                type: "warning",
              })
        }
    };


    
    render() {
        let {readingChallenge} = this.props;
        let {permission} = this.props.onboard;
        return (
            <McqSectionContainer >
                <McqSectionHeader>
                    Assign MCQ
                    { Permission.getPermission(permission, "change_reading")? 
                        <ThemeButton onClick={() =>{
                            this.props.readingChallenge.setActiveReadingMcq(null);
                            store.dispatch(action.setEditorType('mcq'))
                        }}> 
                        + Create MCQ
                        </ThemeButton>
                    :null}
                </McqSectionHeader>
                {readingChallenge.questions && readingChallenge.questions.map((data, idx) => (
                    <McqItemContainer key={idx} style={{cursor:'pointer'}} onClick={() => this.handleEditClick(data)}>
                        <McqDescription

                        >
                            {data.name}
                        </McqDescription>
                        <McqToolbar>
                            <img src={require("static/img/edit-button-blue.png")} onClick={() => this.handleEditClick(data)}
                                className="image-fluid"
                                 alt="Edit icon"/>
                            <img src={require("static/img/trash.png")} onClick={(evt) => this.handleDeleteClick(data,evt)}
                                 className="image-fluid" alt="Delete icon"/>
                        </McqToolbar>
                    </McqItemContainer>
                ))}
            </McqSectionContainer>
        )
    }
}
export default connectStore({
    activity,readingChallenge,onboard
})(McqSection)
import styled from "styled-components";

export const CardContainer = styled.div`
    border-radius:10px;
    overflow:hidden;
    background:white;
    display:flex;
    flex:1 1;
    min-height:180px;
    position:relative;
    margin-bottom:20px;
    border:1px solid #e7e7e7;
        transition:all 0.3s;
      &:hover{
            box-shadow: 0px 3px 10px -2px rgba(0,0,0,0.5);
            }
`;

export const FooterText = styled.div`
     color: var(--primary-color);
    font-size:var(--xxsm);
    opacity:0.7;
    position:absolute;
    padding:10px;
    bottom:0;
    left:0;
    max-height:35px;
    overflow:hidden;
    padding-top:0;
    width:100%;
`;

export const StyledDiv = styled.div`
  background:white;
  background-position:center;
  background-repeat:no-repeat;
  padding: ${props => props.secondary ? '10px' : '0'};
  border-radius: ${props => props.secondary ? '11px 0 0 11px' : '0 11px 11px 0'};
  position:relative;
  border-right: ${props => props.secondary ? '1px solid #e7e7e7' : '0'};
  width:50%;
`;

export const StyledDivImage=StyledDiv.extend`
display:flex;
background:#432666;
justify-content:center;
background-image:url("${props=>props.image ? `${props.image}`: ''}");
background-size:cover;
background-position:center;
max-width:100%;
align-items:center;
`;

export const Title = styled.div`
  font-size:${props => props.primary ? 'var( --sm-font-size)' : '18px'};
  font-weight:${props => props.primary ? '600' : 'normal'};
  padding:${props => props.primary ? '5px 0' : '0'};
   letter-spacing:${props => props.primary ? '0.5px' : ''};
  overflow:hidden;
  height:${props=>props.primary?'80px':'50px'};
  color:${props => props.footer ? 'rgba(var(--primary-color),0.7)' : 'var(--primary-color)'};
  margin: auto;
  /* text-transform: uppercase; */

 @media (max-width:1150px){
        font-size:${props => props.primary ? 'var(--sm-font-size)' : 'var(--xsm-font-size)'};
 }`;

import React, { Component } from "react";
import { module as tags } from "@st/tags";
import { connectStore } from "redux-box";
import {
  ActivityComponentInfoContainer,
  AssessmentsHeader
} from "./ActivityPackCreator.styled";
import TagEditor from "@c/tag-view/activity-pack-creator/tag-editor";
import {
  ComponentContainer,
  CreatorComponent,
  SubContainer
} from "@sc/Creator.styled";
import { CreateMCQQuestionContainer } from "@sc/McqEditor.styled";
import TextArea from "react-autosize-textarea";
import PackActivitiesList from "./pack-activities-list/PackActivitiesList";
import { ThemeButton, LoadingContainer } from "static/styled/custom.styled";
import {
  SectionHeading,
  SectionNameEditable
} from "@c/activity-view/activity-creator/activity-info/ActivityInfo.styled";
import { SubHeading } from "@sc/custom.styled";
import { FixedNav } from "static/styled/NavigationBar.styled";
import Permission from "@s/permission";
import { module as onboard } from "@st/tags";

class ActivityPackCreator extends Component {
  constructor(props) {
    super();
    this.state = {
      displayMenu: false,
      translate: "100%",
      name: "",
      ideal_value: "",
      description: ""
    };
  }

  componentDidMount() {
    this.props.tags.requestTagById(this.props.match.params.id);
    this.props.tags.requestChildTagListByParentId(this.props.match.params.id);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.tags && nextProps.tags.activeTag) {
      this.normalizeForState(nextProps.tags.activeTag);
    } else {
      this.setState({
        name: "",
        ideal_value: "",
        description: ""
      });
    }
  }

  normalizeForState = tags => {
    this.setState({
      name: tags.name,
      ideal_value: tags.ideal_value,
      description: tags.description
    });
  };

  componentWillMount() {
    setTimeout(() => {
      this.setState({
        opacity: 1,
        translate: "0"
      });
    }, 30);
  }

  handleGradeSelected = newgrade => e => {
    this.setState({
      displayMenu: !this.state.displayMenu,
      gradeSelected: newgrade.name
    });
  };
  displayGradeMenu = e => {
    this.setState({
      displayMenu: !this.state.displayMenu
    });
  };

  editEnabled = () => {
    this.setState({
      editEnabled: !this.state.editEnabled
    });
  };

  handleTagNameChange = evt => {
    this.setState({
      name: evt.target.value
    });
  };

  handleValueChange = evt => {
    this.setState({
      ideal_value: evt.target.value
    });
  };
  handleDescriptionChange = e => {
    this.setState({
      description: e.target.value
    });
  };

  handleSaveChangesBtn = () => {
    this.props.tags.updateTagById(
      {
        id: this.props.match.params.id
      },
      {
        name: this.state.name,
        ideal_value: this.state.ideal_value,
        parent_tag: null,
        description: this.state.description
      }
    );
  };

  handleShowSubContainer = (type, title = null) => {
    this.setState({ showSubContainer: type });
  };

  hideSubContainer = () => {
    this.setState({ showSubContainer: false });
  };

  render() {
    let { tags } = this.props;
    const {permission} = this.props.onboard;
    return (
      <CreatorComponent>
        <ComponentContainer
          style={{ transform: `translateX(${this.state.translate})` }}
        >
          {this.props.tags.fetchingActivityPack ? (
            <LoadingContainer primary>
              <div>
                <img
                  alt="loading"
                  width="60"
                  height="60"
                  src={require("static/img/Theme-loader.gif")}
                />
              </div>
            </LoadingContainer>
          ) : (
            <ActivityComponentInfoContainer>
              <FixedNav shadow style={{ padding: "10px 20px" }}>
                <AssessmentsHeader>
                  {this.state.editEnabled ? (
                    <div>
                      <SectionHeading
                        width={"45%"}
                        placeholder="Enter Tag Name"
                        type="text"
                        value={this.state.name}
                        autoFocus
                        onChange={this.handleTagNameChange}
                      />
                      <SectionHeading
                        width={"15%"}
                        placeholder="Enter Ideal Value"
                        type="text"
                        value={this.state.ideal_value}
                        autoFocus
                        onChange={this.handleValueChange}
                      />
                      <CreateMCQQuestionContainer>
                        <TextArea
                          placeholder="Enter description"
                          autoFocus={true}
                          value={this.state.description}
                          onChange={this.handleDescriptionChange}
                        />
                      </CreateMCQQuestionContainer>
                    </div>
                  ) : (
                    <SectionNameEditable>
                      <SubHeading
                        primary
                        style={{ minWidth: "160px" }}
                        width={
                          (this.state.name && this.state.name.length + 1) * 10 +
                          "px"
                        }
                      >
                        {this.state.name}
                      </SubHeading>
                      <SubHeading
                        primary
                        style={{ minWidth: "80px" }}
                        width={
                          (this.state.ideal_value &&
                            this.state.ideal_value.length + 1) *
                            10 +
                          "px"
                        }
                      >
                        {this.state.ideal_value}
                      </SubHeading>
                      <SubHeading
                        primary
                        style={{ minWidth: "80px" }}
                        width={
                          (this.state.description &&
                            this.state.description.length + 1) *
                            10 +
                          "px"
                        }
                      >
                        {this.state.description}
                      </SubHeading>
                      {Permission.getPermission(permission, "change_tag")?
                      <img
                        src={require("static/img/editicon.png")}
                        alt="edit icon"
                        className="image-fluid"
                        onClick={this.editEnabled}
                      />:null}
                    </SectionNameEditable>
                  )}
                  <ThemeButton primary onClick={this.handleSaveChangesBtn}>
                    Save Changes
                  </ThemeButton>
                </AssessmentsHeader>
              </FixedNav>
              <PackActivitiesList />
            </ActivityComponentInfoContainer>
          )}
        </ComponentContainer>
        {tags.currentEditorType && (
          <SubContainer active={tags.currentEditorType}>
            {tags.currentEditorType === "tags" && (
              <TagEditor
                title={this.state.sectionTitle}
                onDone={this.hideSubContainer}
                id={this.props.match.params.id}
              />
            )}
          </SubContainer>
        )}
      </CreatorComponent>
    );
  }
}
export default connectStore({
  tags,onboard
})(ActivityPackCreator)

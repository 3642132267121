import React, { useState, useEffect } from "react";
import FreadomFutureApi from "@s/api/schoolGuidedPath";
import { withRouter } from "react-router";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import { ThemeButton } from "@sc/custom.styled";
import {
  ActivityModalContainer,
  ActivityPopUpContainer,
} from "@sc/ActivityModal.styled";
import { DropDownTitle, DropDownContainer } from "./TaskEditor.styled";
import { connectStore } from "redux-box";
import { module as schoolGuidedPath } from "@st/schoolGuidedPath";
import swal from "sweetalert2";
import { PanelHeader } from "@sc/custom.styled";
import * as constant from "@u/constant";
import "./style_task.css";

const taskOrderOptions = [
  { value: constant.PRE_LIVE_CLASS, label: "Pre-Live class" },
  { value: constant.POST_LIVE_CLASS, label: "Post-Live class" },
];

const taskTypeOptions = [
  { value: constant.NEWS_FREAD, label: "News" },
  { value: constant.ACTIVITY, label: "Activity" },
  { value: constant.STORY, label: "Story" },
  { value: constant.QUIZ, label: "Quiz" },
  { value: constant.WORD_GAME, label: "Word Game" },
];

const taskTagOptions = [
  { value: constant.GRAMMAR, label: "GRAMMAR" },
  { value: constant.READING, label: "READING" },
  { value: constant.LISTENING, label: "LISTENING" },
  { value: constant.SPELLING, label: "SPELLING" },
];

const taskLevelOptions = [
  { value: constant.EASY, label: "EASY" },
  { value: constant.MEDIUM, label: "MEDIUM" },
  { value: constant.HARD, label: "HARD" },
];

const TaskEditor = (props) => {
  const [selectedTaskOrder, setSelectedTaskOrder] = useState(null);
  const [selectedTaskType, setSelectedTaskType] = useState(null);
  const [selectedLevelType, setSelectedLevelType] = useState(null);
  const [selectedTaskTag, setSelectedTaskTag] = useState(null);
  const [selectedTaskContent, setSelectedTaskContent] = useState(null);
  const [selectedSections, setSelectedSections] = useState([]);
  const [sections, setSections] = useState([]);
  const [unit_type,setUnitType] =useState(null)

  const setField = () => {
    let active_task = props.schoolGuidedPath.active_task;
    if (active_task) {
      setSelectedTaskOrder(
        taskOrderOptions.filter(
          (ele) => ele.value === active_task.position_type
        )[0]
      );
      setSelectedTaskType(
        taskTypeOptions.filter(
          (ele) => ele.value === active_task.content_type
        )[0]
      );
      let tagOption = [];
      active_task.tag.forEach((tagValue) => {
        tagOption = [
          ...tagOption,
          ...taskTagOptions.filter((ele) => ele.value === tagValue),
        ];
      });
      setSelectedTaskTag(tagOption);
      if (active_task.content_type === constant.WORD_GAME) {
        setSelectedLevelType(
          taskLevelOptions.filter(
            (ele) => ele.value === active_task.content_id
          )[0]
        );
      } else {
        setSelectedTaskContent({
          _source: {
            name: active_task.name,
            image: active_task.thumbnail_url,
            description: active_task.description,
          },
          _id: active_task.content_id,
        });
      }
    }
  };

  const setDefaultValue = () => {
    setSelectedTaskOrder(null);
    setSelectedTaskType(null);
    setSelectedTaskTag(null);
    setSelectedTaskContent(null);
    setSelectedLevelType(null);
    setSections([])
    setSelectedSections([])
  };

  useEffect(() => {
    if (props.schoolGuidedPath.active_task) {
      setField();
    } else {
      setDefaultValue();
    }
  }, [props.schoolGuidedPath.active_task]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const unit_type = urlParams.get("unit_type");
    const id = urlParams.get("gpId");
    setUnitType(unit_type)

    if (unit_type === "custom") {
      props.schoolGuidedPath.fetchSectionsForCustomGp(id);
    }
  }, []);

  useEffect(() => {
    if (props.schoolGuidedPath.sections) {
      fetchSections();
    }
  }, [props.schoolGuidedPath.sections]);

  const checkData = () => {
    if (selectedTaskType) {
      if (selectedTaskType.value === constant.WORD_GAME) {
        if (
          selectedTaskOrder &&
          selectedTaskType &&
          selectedTaskTag &&
          selectedTaskTag.length > 0 &&
          selectedLevelType
        ) {
          return true;
        }
      } else {
        if (
          selectedTaskOrder &&
          selectedTaskType &&
          selectedTaskTag &&
          selectedTaskTag.length > 0
          &&
          selectedTaskContent
        ) {
          return true;
        }
      }
    }
    return false;
  };

  const createTask = () => {
    let res = checkData();
    if (res) {
      let taskObj = {
        content_type: selectedTaskType.value,
        position_type: selectedTaskOrder.value,
        tag: selectedTaskTag.map((tagEle) => tagEle.value),
        unit: props.match.params.id,
        all_sections:selectedSections && selectedSections.map((section)=>section.value),
      };
      if (selectedTaskType.value === constant.WORD_GAME) {
        taskObj["content_id"] = selectedLevelType.value;
        taskObj["name"] = constant.WORD_GAME_NAME;
        taskObj["description"] = selectedLevelType.value;
        taskObj["thumbnail_url"] = constant.WORD_GAME_THUMBNAIL;
      } else {
        // taskObj["content_id"] = "d5ac3e25-3f25-4a71-8ddf-b4afba8cc072";
        // taskObj["name"] = "Story for Test";
        // taskObj["description"] = "Test Story";
        // taskObj["thumbnail_url"] =
        //   "https://freadom-dev-bucket.s3.amazonaws.com/1586352406.32-sports.jpg";
        taskObj["content_id"] = selectedTaskContent._id;
        taskObj["name"] = selectedTaskContent._source.name;
        taskObj["description"] = selectedTaskContent._source.description;
        taskObj["thumbnail_url"] = selectedTaskContent._source.image;
      }
      props.schoolGuidedPath.createTask(taskObj);
    } else {
      swal(
        "Oops!",
        `You must select 'TASK ORDER', 'TASK TYPE', 'TASK TAG' & '${
          selectedTaskType && selectedTaskType.value === constant.WORD_GAME
            ? "DIFFICULTY LEVEL"
            : "CONTENT"
        }' to create Task `,
        "error"
      );
    }
  };

  const updateTask = () => {
    let res = checkData();
    if (res) {
      let taskObj = {
        content_type: selectedTaskType.value,
        position_type: selectedTaskOrder.value,
        tag: selectedTaskTag.map((tagEle) => tagEle.value),
        unit: props.match.params.id,
        task_id: props.schoolGuidedPath.active_task.id,
      };
      if (selectedTaskType.value === constant.WORD_GAME) {
        taskObj["content_id"] = selectedLevelType.value;
        taskObj["name"] = constant.WORD_GAME_NAME;
        taskObj["description"] = selectedLevelType.value;
        taskObj["thumbnail_url"] = constant.WORD_GAME_THUMBNAIL;
      } else {
        taskObj["content_id"] = selectedTaskContent._id;
        taskObj["name"] = selectedTaskContent._source.name;
        taskObj["description"] = selectedTaskContent._source.description;
        taskObj["thumbnail_url"] = selectedTaskContent._source.image;
      }
      props.schoolGuidedPath.updateTask(taskObj);
    } else {
      swal(
        "Oops!",
        `You must select 'TASK ORDER', 'TASK TYPE', 'TASK TAG' & '${
          selectedTaskType && selectedTaskType.value === constant.WORD_GAME
            ? "DIFFICULTY LEVEL"
            : "CONTENT"
        }' to create Task `,
        "error"
      );
    }
  };

  const handleInputChange = (value) => {
    // console.log("handleInputChange", value);
  };

  const handleChange = (value) => {
    setSelectedTaskContent(value);
  };

  // load options using API call
  const loadOptions = async (searchValue) => {
    if (selectedTaskType.value) {
      if (selectedTaskType.value === constant.NEWS_FREAD) {
        let data = await FreadomFutureApi.getContentSearchList(
          searchValue,
          constant.NEWS_FREAD_GLOBAL_SEARCH
        );
        return data.documents;
      } else {
        let data = await FreadomFutureApi.getContentSearchList(
          searchValue,
          selectedTaskType.value
        );
        return data.documents;
      }
      // return fetch(`https://api.getfreadom.com/api/global-search/v1/?query=${searchValue}&index_type=${selectedTaskType.value}`)
      //   .then((res) => res.json())
      //   .then((data) => data.result.documents);
    }
  };

  const handleTaskTypeChange = (value) => {
    setSelectedTaskContent(null);
    setSelectedLevelType(null);
    setSelectedTaskType(value);
  };

  const fetchSections = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const grade = urlParams.get('grade_id')
    let sectionsList =
      props.schoolGuidedPath.sections[grade];

    let sectionList = sectionsList
      ? sectionsList.map((section) => {
          return {
            value: section.id,
            label: section.name,
          };
        })
      : [];
    setSections(sectionList);
  };

  const handleSection = (section) => {
    setSelectedSections(section);
  };

  return (
    <ActivityPopUpContainer style={{ height: "100%" }}>
      <PanelHeader primary style={{ zIndex: "999" }}>
        <span>Create TASK</span>
        {props.schoolGuidedPath.active_task ? (
          <ThemeButton primary onClick={() => setField()}>
            Reset
          </ThemeButton>
        ) : null}

        {props.schoolGuidedPath.active_task ? (
          <ThemeButton primary onClick={() => updateTask()}>
            Done
          </ThemeButton>
        ) : (
          <ThemeButton primary onClick={() => createTask()}>
            Create
          </ThemeButton>
        )}
      </PanelHeader>
      <ActivityModalContainer className="mcq_body">
        {unit_type === "custom" &&
          !props.schoolGuidedPath.active_task &&(
            <Select
              isMulti={true}
              className="dropdown-container"
              onChange={handleSection}
              value={selectedSections}
              options={sections}
              placeholder="Select Section"
              isDisabled={props.schoolGuidedPath.active_task ? true : false}
            />
          )}

        <DropDownContainer>
          <DropDownTitle>TASK ORDER</DropDownTitle>
          <Select
            placeholder={"Select Task Order"}
            value={selectedTaskOrder}
            onChange={setSelectedTaskOrder}
            options={taskOrderOptions}
          />
        </DropDownContainer>

        <DropDownContainer>
          <DropDownTitle>TASK TYPE</DropDownTitle>
          <Select
            placeholder={"Select Task Type"}
            value={selectedTaskType}
            onChange={handleTaskTypeChange}
            options={taskTypeOptions}
          />
        </DropDownContainer>

        <DropDownContainer>
          <DropDownTitle>TASK TAG</DropDownTitle>
          <Select
            placeholder={"Select Task Tag"}
            value={selectedTaskTag}
            onChange={setSelectedTaskTag}
            options={taskTagOptions}
            isMulti={true}
          />
        </DropDownContainer>
        {(selectedTaskType ? (
          selectedTaskType.value !== constant.WORD_GAME ? (
            true
          ) : (
            false
          )
        ) : (
          true
        )) ? (
          <DropDownContainer>
            <DropDownTitle>CONTENT</DropDownTitle>
            <AsyncSelect
              cacheOptions
              defaultOptions
              isDisabled={
                selectedTaskType && selectedTaskType.value ? false : true
              }
              value={selectedTaskContent}
              key={selectedTaskType && selectedTaskType.value}
              getOptionLabel={(e) => e._source.name}
              getOptionValue={(e) => e._id}
              loadOptions={loadOptions}
              onInputChange={handleInputChange}
              onChange={handleChange}
            />
          </DropDownContainer>
        ) : (
          <DropDownContainer>
            <DropDownTitle>DIFFICULTY LEVEL</DropDownTitle>
            <Select
              placeholder={"Select Level"}
              value={selectedLevelType}
              onChange={setSelectedLevelType}
              options={taskLevelOptions}
            />
          </DropDownContainer>
        )}
      </ActivityModalContainer>
    </ActivityPopUpContainer>
  );
};

// export default McqEditor;
export default withRouter(
  connectStore({
    schoolGuidedPath,
  })(TaskEditor)
);

import React from "react";

import { connectStore } from "redux-box";
import { CardIcon, CardIconContainer } from "static/styled/custom.styled";
import { ToolbarItem } from "@sc/Section.styled";
import { module as ui } from "@st/ui";
import { module as schoolGuidedPath } from "@st/schoolGuidedPath";
import { module as podcast } from "@st/podcast";
import { module as onboard } from "@st/onboard";

import {
  CardContainer,
  StyledDiv,
  StyledDivImage,
  Title,
} from "@c/activity-view/activities-list/ActivityCard/ActivityCard.styled";
import swal from "sweetalert2";

function EpisodeCard(props) {
  const podcastEpisodeInfo = props.podcastEpisodeInfo;
  const { user } = props.onboard;
  const user_group = user.groups[0].name;

  const handleEditClick = (evt) => {
    evt.preventDefault();
    props.podcast.setEpisodeEditInfo(podcastEpisodeInfo);
    props.ui.showModal("PODCASTEPISODEFORM");
  };


  return (
    // <CardContainer>
    //   <CardIconContainer>
    //     {user_group !== "spectator" ? (
    //       <ToolbarItem primary onClick={handleEditClick}>
    //         <div className="icon-container">
    //           <i className="fa fa-pencil" />
    //         </div>
    //       </ToolbarItem>
    //     ) : null}
    //     {/* <CardIcon>
    //         <i className="fa fa-trash" />
    //       </CardIcon> */}
    //   </CardIconContainer>

    //   <Title>{podcastEpisodeInfo.name}</Title>
    // </CardContainer>

    <div>
      <CardContainer>
        <StyledDiv secondary>
          <Title>{podcastEpisodeInfo.name}</Title>
          <Title primary>
            {podcastEpisodeInfo &&
            podcastEpisodeInfo.description &&
            podcastEpisodeInfo.description.length < 36
              ? podcastEpisodeInfo.description
              : podcastEpisodeInfo.description.slice(0, 40) + "..."}
          </Title>
        </StyledDiv>
        <StyledDivImage primary image={podcastEpisodeInfo.thumbnail}>
          <CardIconContainer>
            <CardIcon onClick={handleEditClick}>
              <i className="fa fa-pencil" />
            </CardIcon>
          </CardIconContainer>
        </StyledDivImage>
      </CardContainer>
    </div>
  );
}

export default connectStore({
  ui,
  schoolGuidedPath,
  onboard,
  podcast
})(EpisodeCard);

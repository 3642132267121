import React, { Component } from "react";
import {
  SectionsContainer,
  StoryFooter,
  StorySectionsInfoContainer,
  StoryTypeContainer,
  StoryTypeItem,
  InputBox,
} from "./StoryCreator.styled";
import {
  ComponentContainer,
  CreatorComponent,
  SubContainer,
} from "@sc/Creator.styled.js";
import { module as story } from "@st/story";
import { connectStore } from "redux-box";
import { SelectorButton, CheckBox } from "@sc/custom.styled";
import HtmlEditor from "./html-editor/HtmlEditor";
import HtmlStory from "./html-story/HtmlStory";
import CollectionSection from "./collection-section/CollectionSection";
import McqSection from "./mcq-section/McqSection";
import StoryLinks from "./story-links/StoryLinks";
import CollectionEditor from "./collection-editor/CollectionEditor";
import McqEditor from "./mcq-editor/McqEditor";
import StoryInfo from "./story-info/StoryInfo";
import AudioStory from "./audio-story/AudioStory";
import ImagesStory from "./images-story/ImagesStory";
import VideoStory from "./video-story/VideoStory";
import { Heading, LoadingContainer, PublishContainer } from "@sc/custom.styled";
import YoutubeStory from "./youtube-story/YoutubeStory";
import swal from "sweetalert2";
import Section from "./sectioned-story/section/Section";
import NewSection from "./sectioned-story/new-section/NewSection";
import StoryMediaEditor from "./sectioned-story/story-media-editor/StoryMediaEditor";
import SectionedTextEditor from "./sectioned-story/sectioned-text-editor/SectionedTextEditor";
import PdfStory from "./pdf-story/PdfStory";
import EpubStory from "./epub-story/EpubStory";
import StoryExperienceEditor from "./story-experience-editor/StoryExperienceEditor";
import { module as onboard } from "@st/onboard";
import Permission from "@s/permission";

class StoryCreator extends Component {
  constructor(props) {
    super();
    this.state = {
      opacity: 0,
      storyType: "Read",
      storyTypeSelector: false,
      fileType: "Sectioned",
      translate: "100%",
      freadom_point: "",
      strict: false,
      enableLoader: true,
      point_editable: false,
      is_locked: false,
    };
  }

  componentWillMount() {
    let { story } = this.props;
    story.requestStoryById(this.props.match.params.storyId);
    story.requestStoryQuestionsById(this.props.match.params.storyId);
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({
        opacity: 1,
        translate: "0",
      });
    }, 30);

    let { story } = this.props;
    let history = this.props.history;
    if (
      story.active_story_grade_level !== "" &&
      story.active_story_level !== "" &&
      this.props.history
    ) {
      document.addEventListener("keyup", function (e) {
        if (e.keyCode === 27 && window.location.pathname.includes("/stories")) {
          history.push(
            "/stories/" +
              story.active_story_grade_level +
              "/" +
              story.active_story_level
          );
        }
      });
    }
  }

  componentWillUnmount() {
    this.props.story.setActiveStory(null);
    this.props.story.setStoryEditorType("");
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.story && nextProps.story.active_story) {
      this.normalizeForState(nextProps.story.active_story);
    } else {
      this.setState({
        storyType: "Read",
        fileType: "epub",
        freadom_point: 0,
      });
    }
  }

  normalizeForState = (activeStory) => {
    if (this.state.strict === false && activeStory.section_data.length === 0) {
      let defaultType = this.setFileType(activeStory.story_type);
      this.props.story.setStoryType(defaultType);
      let fileType = defaultType;
      this.setState({
        fileType: fileType,
        strict: true,
      });
    }
    this.setState({
      storyType:
        activeStory.story_type.charAt(0).toUpperCase() +
        activeStory.story_type.slice(1),
      freadom_point: activeStory.freadom_point,
    });
  };

  setFileType = (storyType) => {
    switch (storyType) {
      case "read":
        return "image";
      case "watch":
        return "video";
      case "listen":
        return "audio";
      default:
        return "audio";
    }
  };

  handleStorySelector = (val) => {
    this.setState({
      storyTypeSelector: val,
    });
  };

  handleStoryType = (data) => {
    let { story } = this.props;
    if (story.active_story) {
      if (this.state.storyType === "Sectioned" || data === "Sectioned") {
        swal({
          title: "Are you sure?",
          text:
            "You have already added sections to the story! Changing story type would reset previous sections!",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "red",
          cancelButtonColor: "skyblue",
          confirmButtonText: "Yes, delete it!",
        }).then((result) => {
          if (result.value) {
            this.setState({
              storyType: data,
              storyTypeSelector: false,
            });
            story.requestUpdateStory({
              ...this.props.story.active_story,
              story_type: data.toLowerCase(),
            });
          }
        });
      } else {
        this.setState({
          storyType: data,
          storyTypeSelector: false,
        });
        story.requestUpdateStory({
          ...this.props.story.active_story,
          story_type: data.toLowerCase(),
        });
      }
    }
  };

  renderSections = () => {
    const story = this.props.story && this.props.story.active_story;
    return (
      story &&
      story.section_data &&
      story.section_data.map((section, i) => {
        return (
          <Section
            key={i}
            handleShowSubContainer={this.handleShowSubContainer}
            section={section}
          />
        );
      })
    );
  };
  renderNewSections = () => {
    const { story } = this.props;
    return story.new_sections.map((section, i) => (
      <NewSection key={i} section={section} />
    ));
  };

  addSection = () => {
    let { story } = this.props;
    if (
      this.props.story.active_story &&
      this.props.story.active_story.section_data.length < 10
    )
      this.props.story.addNewSection({
        name: "",
      });
    else {
      swal({
        text:
          "Sorry! you cannot add more than 10 sections to " +
          story.active_story.name,
        type: "warning",
      });
    }
  };

  handleFileTypeSelector(data) {
    let { permission } = this.props.onboard;
    if(Permission.getPermission(permission, "change_story")){
      let { story } = this.props;
      {}
      if (
        story.current_story_type !== data.toLowerCase() &&
        story.active_story &&
        story.active_story.section_data.length > 0 &&
        (story.active_story.section_data[0].media.length > 0 ||
          story.active_story.section_data[0].description !== "")
      ) {
        this.setState({
          strict: true,
        });
        swal({
          title: "Are you sure?",
          text:
            "You have already created the story as " +
            story.current_story_type +
            " type!  Changing the file type would reset your also media uploads!",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "red",
          cancelButtonColor: "skyblue",
          confirmButtonText: "Yes, delete it!",
        }).then((result) => {
          if (result.value) {
            let section = {
              content_type: data.toLowerCase(),
              media: [],
              name: data.toLowerCase(),
              description: "",
            };
            this.setState({
              strict: true,
            });
            //console.log("hello");
            this.props.story.requestSetStoryFileType(data.toLowerCase());
            this.props.story.requestUpdateStorySection(section);
          }
        });
      } else {
        this.setState({
          strict: true,
        });
        this.props.story.requestSetStoryFileType(data.toLowerCase());
      }
    }else{
      swal({
        text:
          "You do not have permission.",
        type: "warning",
      });
    }
    
  }

  handleTopStory = (evt) => {

    let { story } = this.props;
    let {permission} = this.props.onboard
    if(Permission.getPermission(permission, "change_story")){
    this.setState({
      enableLoader: false,
    });
    this.props.story.fetchLoadSetTopStory(true);
    let isTopStory = false;
    let storyLevel = null;
    let topStoryLevel = `${this.props.match.params.gradeLevel}:${this.props.match.params.level}`;
    if (
      story.active_story.top_story_levels.some((data) => data === topStoryLevel)
    ) {
      storyLevel = story.active_story.top_story_levels.filter(
        (data) => data !== topStoryLevel
      );
      isTopStory = true;
    } else {
      isTopStory = false;
    }
    if (isTopStory) {
      story.requestUpdateStory({
        ...story.active_story,
        storyIndex: this.props.match.params.storyIndex,
        top_story_levels: [...storyLevel],
      });
    } else {
      story.requestUpdateStory({
        ...story.active_story,
        top_story_levels: [
          ...story.active_story.top_story_levels,
          topStoryLevel,
        ],
        storyIndex: this.props.match.params.storyIndex,
      });
    }
    evt.stopPropagation();
  }
  };

  handlePointChange = (e) => {
    this.setState({
      freadom_point: e.target.value,
    });
  };

  handleEnterPress = (e) => {
    if (e.key === "Enter" && e.target.value.trim().length) {
      if (e.target.value === "0") {
        this.setState({
          is_locked: false,
        });
      }
      let { story } = this.props;
      this.setState({
        enableLoader: false,
      });
      story.fetchLoadSetTopStory(true);
      story.requestUpdateStory({
        ...story.active_story,
        freadom_point: e.target.value,
      });
      this.setState({
        point_editable: false,
      });
      e.stopPropagation();
    }
  };

  handleAddBtn = () => {
    if (this.state.freadom_point > 0) {
      let { story } = this.props;
      this.setState({
        enableLoader: false,
      });
      story.fetchLoadSetTopStory(true);
      story.requestUpdateStory({
        ...story.active_story,
        freadom_point: this.state.freadom_point,
      });
      this.setState({
        point_editable: false,
      });
    } else {
      swal({
        title: "Are you sure?",
        text: "Freadom point is set 0",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "red",
        cancelButtonColor: "skyblue",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.value) {
          this.handleIsLocked();
        }
      });
    }
  };

  handleIsLocked = () => {
    let {permission} = this.props.onboard
    if(Permission.getPermission(permission, "change_story")){
      let { story } = this.props;
    if (story.active_story.is_locked) {
      this.setState({
        enableLoader: false,
      });
      story.fetchLoadSetTopStory(true);
      story.requestUpdateStory(
        { ...story.active_story, freadom_point: 0 },
        (res) => {
          //console.log('callback')
          this.setState({
            point_editable: false,
            is_locked: false,
          });
        }
      );
    } else {
      this.setState({
        point_editable: true,
        is_locked: true,
      });
    }
    }
    
  };

  handleEditClick = () => {
    this.setState({
      point_editable: true,
    });
  };

  render() {
    let { story } = this.props;
    // console.log("story", this.props);
    let {permission} = this.props.onboard
    let topStoryLevel = `${this.props.match.params.gradeLevel}:${this.props.match.params.level}`;
    const fileTypeData = [
      { name: "Audio", type: "listen" },
      { name: "Video", type: "watch" },
      { name: "Image", type: "read" },
      { name: "Youtube", type: "watch" },
      { name: "HTML", type: "read" },
      { name: "Epub", type: "read" },
      { name: "PDF", type: "read" },
    ];
    let fileTypeList = fileTypeData
      .filter((data) => {
        return data.type === this.state.storyType.toLowerCase();
      })
      .map((data, index) => (
        <StoryTypeItem
          onClick={() => this.handleFileTypeSelector(data.name)}
          key={data.name}
        >
          <SelectorButton
            active={story.current_story_type === data.name.toLowerCase()}
          >
            <div />
          </SelectorButton>
          <span> {data.name}</span>
        </StoryTypeItem>
      ));
    return (
      <CreatorComponent>
        <ComponentContainer
          style={{ transform: `translateX(${this.state.translate})` }}
        >
          {this.props.story.fetch_active_story && this.state.enableLoader ? (
            <LoadingContainer
              style={{ alignItems: "flex-start", top: "150px" }}
            >
              <div>
                <img
                  alt="loading"
                  width="60"
                  height="60"
                  src={require("static/img/Theme-loader.gif")}
                />
              </div>
            </LoadingContainer>
          ) : null}
          {story.active_story && (
            <StoryInfo
              storyInfo={story.active_story}
              history={this.props.history}
              storyIndex={this.props.match.params.storyIndex}
            />
          )}
          {story.active_story && (
            <StorySectionsInfoContainer>
              <SectionsContainer primary>
                <Heading style={{ marginBottom: "0px" }}>
                  Selected Story type : {this.state.storyType}
                </Heading>
              </SectionsContainer>
              {this.state.storyType !== "Sectioned" ? (
                <SectionsContainer>
                  <Heading style={{ marginBottom: "0px" }}>
                    Select File type
                  </Heading>
                  <StoryTypeContainer>{fileTypeList}</StoryTypeContainer>
                </SectionsContainer>
              ) : null}
              {this.props.story.active_story &&
                this.state.storyType === "Sectioned" &&
                this.renderSections()}
              {this.props.story.active_story &&
                this.state.storyType === "Sectioned" &&
                this.renderNewSections()}

              {this.state.storyType === "Read" &&
                story.current_story_type === "html" && (
                  <HtmlStory
                    section={
                      story.active_story &&
                      story.active_story.section_data.length > 0
                        ? story.active_story.section_data[0]
                        : null
                    }
                  />
                )}
              {this.state.storyType === "Listen" &&
                story.current_story_type === "audio" && (
                  <AudioStory
                    section={
                      story.active_story &&
                      story.active_story.section_data.length > 0
                        ? story.active_story.section_data[0]
                        : null
                    }
                  />
                )}
              {this.state.storyType === "Watch" &&
                story.current_story_type === "video" &&
                story.active_story && (
                  <VideoStory
                    section={
                      story.active_story &&
                      story.active_story.section_data.length > 0
                        ? story.active_story.section_data[0]
                        : null
                    }
                  />
                )}
              {this.state.storyType === "Read" &&
                story.current_story_type === "image" && (
                  <ImagesStory
                    section={
                      story.active_story &&
                      story.active_story.section_data.length > 0
                        ? story.active_story.section_data[0]
                        : null
                    }
                  />
                )}
              {this.state.storyType === "Watch" &&
                story.current_story_type === "youtube" && (
                  <YoutubeStory
                    section={
                      story.active_story &&
                      story.active_story.section_data.length > 0
                        ? story.active_story.section_data[0]
                        : null
                    }
                  />
                )}
              {this.state.storyType === "Read" &&
                story.current_story_type === "pdf" && (
                  <PdfStory
                    section={
                      story.active_story &&
                      story.active_story.section_data.length > 0
                        ? story.active_story.section_data[0]
                        : null
                    }
                  />
                )}
              {this.state.storyType === "Read" &&
                story.current_story_type === "epub" && (
                  <EpubStory
                    section={
                      story.active_story &&
                      story.active_story.section_data.length > 0
                        ? story.active_story.section_data[0]
                        : null
                    }
                  />
                )}
              <McqSection questionList={story.questions} />
              {/* <StoryLinks id={this.props.match.params.storyId} /> */}
              {story.active_story && (
                <CollectionSection collection={story.active_story.collection} />
              )}
              {/* //FREAD-1240 */}
              <StoryLinks id={this.props.match.params.storyId} applink={
                story.active_story!=null && story.active_story.deeplink!=null  ?
                story.active_story.deeplink:""
                } />
            </StorySectionsInfoContainer>
          )}
          {story.active_story && (
            <StoryFooter>
              <PublishContainer>
                <CheckBox
                  active={
                    story.active_story &&
                    story.active_story.top_story_levels.some(
                      (data) => data === topStoryLevel
                    )
                  }
                  onClick={this.handleTopStory}
                >
                  <i className="fa fa-check" />
                </CheckBox>
                <span onClick={this.handleTopStory}> Mark as top Story</span>
              </PublishContainer>
              <PublishContainer>
                <CheckBox
                  onClick={this.handleIsLocked}
                  active={
                    (story.active_story && story.active_story.is_locked) ||
                    this.state.is_locked
                  }
                >
                  <i className="fa fa-lock" />
                </CheckBox>
                <span onClick={this.handleIsLocked}> Is Locked</span>
                {story.active_story.is_locked && !this.state.point_editable && (
                  <span>{story.active_story.freadom_point}</span>
                )}
                {story.active_story.is_locked && !this.state.point_editable && (
                  <i
                    className="fa fa-pencil"
                    onClick={this.handleEditClick}
                    style={{
                      marginLeft: "10px",
                      color: "#0acade",
                      fontSize: "15px",
                    }}
                  />
                )}
                {this.state.point_editable && (
                  <InputBox
                    type="text"
                    placeholder="Input freadom points"
                    onChange={this.handlePointChange}
                    onKeyPress={this.handleEnterPress}
                    value={this.state.freadom_point}
                  />
                )}

                {this.state.point_editable && this.state.freadom_point > 0 && (
                  <i
                    className="fa fa-plus-circle"
                    onClick={this.handleAddBtn}
                    style={{
                      marginLeft: "10px",
                      color: "#ff8a23",
                      fontSize: "25px",
                    }}
                  />
                )}
              </PublishContainer>
              {this.props.story.load_set_top_story ? (
                <div style={{ marginLeft: "14px" }}>
                  <img
                    alt="loading"
                    width="30"
                    height="30"
                    src={require("static/img/Theme-loader.gif")}
                  />
                </div>
              ) : null}
            </StoryFooter>
          )}
        </ComponentContainer>
        {/*...........................Side Sub Panel............................*/}
        {story.current_editor_type !== "" && (
          <SubContainer active={story.current_editor_type !== ""}>
            {story.current_editor_type === "HTML" && (
              <HtmlEditor
                section={
                  story.active_story &&
                  story.active_story.section_data.length > 0
                    ? story.active_story.section_data[0]
                    : null
                }
              />
            )}
            {story.current_editor_type === "experience" && (
              <StoryExperienceEditor />
            )}
            {story.current_editor_type === "collection" && (
              <CollectionEditor collectionList={story.collection_list} />
            )}
            {story.current_editor_type === "mcq" && <McqEditor />}
            {story.current_editor_type === "text" && this.state.storyType && (
              <SectionedTextEditor />
            )}
            {[
              "image",
              "audio",
              "video",
              "document",
              "file",
              "youtube",
              "media",
              "epub",
              "pdf",
            ].includes(story.current_editor_type) && <StoryMediaEditor />}
          </SubContainer>
        )}
      </CreatorComponent>
    );
  }
}

export default connectStore({
  story,onboard
})(StoryCreator)

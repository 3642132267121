import styled from "styled-components";
import { HoverAnimation } from "@sc/custom.styled";

export const SchoolItem = HoverAnimation.extend`
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
  margin-bottom: 10px;
  padding: 20px 40px 20px 20px;
  justify-content: space-between;
  min-height: 140px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
`;
export const SchoolItemLeft = styled.div`
  width: 80%;
  flex-flow: row nowrap;
  position: relative;
  display: flex;
`;
export const SchoolItemRight = styled.div`
  width: 250px;
  display: flex;
  padding-top: 10px;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: start;
  outline: line;
`;

export const ImageContainer = styled.img`
  height: 100px;
  width: 100px;
  max-width: 100%;
  object-fit: cover;
  position: absolute;
  object-position: center;
  cursor: pointer;
  border-radius: 10px;
`;

export const SchoolLabels = styled.div`
  font-family: Rubik;
  font-size: ${props =>
    props.primary ? "var(--lg-font-size)" : "var(--md-font-size)"};
  font-weight:${props => (props.primary ? "500" : "normal")};
  line-height: 1.5;
  letter-spacing: 0.1px;
  text-align: left;
  color: ${props =>
    props.primary ? "#000000" : "rgba(0, 0, 0, 0.6)"};rgba(0, 0, 0, 0.6);
`;

export const SchoolDetails = styled.div`
  display: flex;
  flex-flow: column nowrap;
`;

export const SchoolDescription = styled.div`
  color: rgba(0, 0, 0, 0.6);
  font-size: var(--md-font-size);
  margin: 0px;
  padding: 0px;
  word-break: break-all;
`;

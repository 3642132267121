import React, { Component } from "react";
import {
  ToggleButton,
  ToggleHandler,
  ToggleStatus,
  UserDetailValues,
  UserField2,
  UserFieldEmail,
  UserFieldName,
} from "../../../MainContent.styled";
import { module as user } from "@st/user";
import { connectStore } from "redux-box";
// export default connectStore({
//   user,
// })

class ChildCard extends Component {
  handleToggle = (e) => {
    let { user } = this.props;
    if (this.props.child)
      user.requestUpdateChildProfile({
        id: this.props.child.id,
        is_active: !this.props.child.is_active,
      });
    e.preventDefault();
    e.stopPropagation();
  };

  render() {
    // let state=this.state;
    const childInfo = this.props.child;
    return (
      <UserDetailValues>
        <UserFieldName>{childInfo.name} </UserFieldName>
        <UserFieldName>{childInfo.school} </UserFieldName>
        <UserField2> {childInfo.grade.name}</UserField2>
        <UserField2> {childInfo.school_class && childInfo.school_class.map((sec)=>sec.name).join(", ")}</UserField2>
        <UserFieldName> {childInfo.parent.name} </UserFieldName>
        <UserFieldEmail width="160px">
          {" "}
          {childInfo.invite_code
            ? childInfo.invite_code
            : "No Invite Code"}{" "}
        </UserFieldEmail>
        <UserField2> {childInfo.parent.contact_no} </UserField2>
        <UserFieldEmail primary align width="240px">
          {childInfo.is_active ? (
            <ToggleStatus fontSize="12px" primary onClick={this.handleToggle}>
              {" "}
              Disable{" "}
            </ToggleStatus>
          ) : (
            <ToggleStatus fontSize="12px" onClick={this.handleToggle}>
              {" "}
              Disabled
            </ToggleStatus>
          )}
          <ToggleButton
            style={{ margin: "0px 6px" }}
            height="22px"
            color={childInfo.is_active ? "#ff8f26" : "#d5d5d5"}
          >
            {!childInfo.is_active ? (
              <ToggleHandler
                primary
                height="18px"
                width="18px"
                onClick={this.handleToggle}
              />
            ) : (
              <ToggleHandler
                width="18px"
                height="18px"
                left="25px"
                onClick={this.handleToggle}
              />
            )}
          </ToggleButton>
          {childInfo.is_active ? (
            <ToggleStatus fontSize="12px" onClick={this.handleToggle}>
              {" "}
              Enabled{" "}
            </ToggleStatus>
          ) : (
            <ToggleStatus fontSize="12px" primary onClick={this.handleToggle}>
              {" "}
              Enable
            </ToggleStatus>
          )}
        </UserFieldEmail>
      </UserDetailValues>
    );
  }
}

// export default ChildCard;
export default connectStore({
  user
 })(ChildCard)
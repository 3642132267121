export default {
    fetch_reading_challenge_list:false,
    active_reading_grade:{},
    active_reading_challenge:null,
    start_date:'',
    end_date:'',
    reading_offset:0,
    reading_challenges_result:null,
    fetchingReadingChallenge:false,
    readingList:[],
    new_sections: [],
    current_editor_type :'',
    active_experience:null,

    // section CRUD
    current_section_action: "create",
    active_section: {},
    new_section: null,

    //MCQ CRUD
    questions: [],
    active_question: {},
    active_options: [],
    new_question: {
        name: "",
        answer: "",
        level: "easy"
    },
    new_options: [],
    active_mcq: null,
    activity_level:"easy",
    order_no:'',

    //MISC
    search_reading:''
}

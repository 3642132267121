import React, {Component} from "react";
import {
    ActivityComponentInfoContainer,
    TopNav
} from "@c/activity-view/activity-creator/ActivityCreator.styled";
import {
    SaveChangesButton,
    ThemeButton,
    LoadingContainer
} from "static/styled/custom.styled";
import {ComponentContainer, SubContainer,CreatorComponent} from '@sc/Creator.styled';
import {module as activity} from "@st/activity";
import {module as readingChallenge} from "@st/readingChallenge";
import { module as schoolGuidedPath } from "@st/schoolGuidedPath";
import {connectStore} from "redux-box";
import TaskEditor from "./task-editor/TaskEditor";
import TaskList from "./task-list";
import swal from "sweetalert2";
import { module as onboard } from "@st/onboard";
import Permission from "@s/permission";


 class SchoolGuidedPathTaskCreator extends Component
{
    constructor(props) {
        super();
        this.state = {
            showSubContainer: false,
            translate:'100%',
            sectionTitle: null,

        };
    }
    componentWillMount() {
        const {schoolGuidedPath} = this.props;
        schoolGuidedPath.requestTaskList(this.props.match.params.id);
    }
    componentDidMount(){
        setTimeout(()=>{
            this.setState({
                opacity: 1,
                translate: '0'
            })
        },30)

        let history=this.props.history;
        document.addEventListener('keyup', function(e) {

            if (e.keyCode === 27 && window.location.pathname.includes("/school-guided-path")) {
                history.push("/school-guided-path");
            }
        });

    }
    componentWillUnmount(){
        this.props.activity.setEditorType('');
        this.props.readingChallenge.setActiveReadingChallenge(null);
    }

    handleShowSubContainer = (type, title = null) => {
        this.setState({ showSubContainer: type, sectionTitle: title });
    };

    hideSubContainer = () => {
        this.setState({ showSubContainer: false });
    };

    render(){
        let {readingChallenge,activity,schoolGuidedPath}=this.props;
        let {permission}=this.props.onboard;
        return( 
        <CreatorComponent style={{opacity: this.state.opacity}}>
                <ComponentContainer style={readingChallenge.active_reading_challenge ?{}:{ transform: `translateX(${this.state.translate})`}}>
                    <ActivityComponentInfoContainer>
                        <TaskList/>
                    </ActivityComponentInfoContainer>
                </ComponentContainer>


            {activity.current_editor_type &&
             <SubContainer active={activity.current_editor_type}>
                    {activity.current_editor_type === "task" && (
                        <TaskEditor
                            title={this.state.sectionTitle}
                            onDone={this.hideSubContainer}
                        />
                    )}

             </SubContainer>}
        </CreatorComponent>
        )
    }
}
export default connectStore({
    activity,readingChallenge,onboard,schoolGuidedPath
})(SchoolGuidedPathTaskCreator)
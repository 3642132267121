import styled from "styled-components";
import {ActivityInputField} from "../../activity-view/activity-creation-form/ActivityCreationForm.styled";

export const Container=styled.div`

`;

export const StoryCreationMainForm=styled.div`
 display: flex;
  flex-flow: column nowrap;
  margin-left: 14px;
  width: 70%;`;

export const StoryInputContainer=styled.div`
     justify-content:flex-start;
     align-items:center;
     display:flex;
     `;

export const StoryInputField=ActivityInputField.extend`
         border-radius: 24px;
         border: solid 1px #d8d8d8;
         margin:4px 0px;
         maxlength: 10;
         font-size:var(--md-font-size);
         padding:${props=>props.padding?props.padding:'0px'}
         width:92%;
         ::placeholder {
         color:#d8d8d8;
         font-size:var(--sm-font-size);
         }
    `;

export const StoryImagesContainer=styled.div`
        width:140px;
        display:flex;
        flex-flow:column wrap;
        justify-content:space-between;
      
        align-items:center;
        `;


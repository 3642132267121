import React, { Component } from "react";
import { DateSelectionField } from "@sc/custom.styled";
import { SearchBarContainer } from "@c/users-view/MainContent.styled";
import { Link } from "react-router-dom";
import { connectStore } from "redux-box";
import { module as level } from "@st/level";
import { module as newsFreeds } from "store/newsFreeds";
import {
  NavigationContainer,
  NavigationHeaderContainer,
  NavigationToolbar
} from "@sc/NavigationBar.styled";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
//import "../../../global.css";


class NewsFreedsHeader extends Component {
  constructor(props) {
    super();
    this.state = {
      date: '',
      searchBarEnable: false
    };
  }

  componentDidMount() {
    let { newsFreeds } = this.props;
    let date = newsFreeds.active_news_date;
    if (date !== "") {
      let dateData = date.split("-");
      let newDate = moment();
      newDate.set({
        year: dateData[0],
        month: dateData[1] - 1,
        date: dateData[2]
      });
      this.setState({
        date: newDate
      });
    }

    if (this.props.newsFreeds.active_news_date) {
      // let newDate = moment.unix(this.props.experience.experience_date).format('YYYY-MM-DD');
      this.setState({
        date: this.props.newsFreeds.active_news_date
      })
    }

  }



  handleDateChange = date => {
    let { newsFreeds } = this.props;

    this.setState({
      date: date
    });
    if (date) {
      let selectedDate = new Date(date);
      let month =
        selectedDate.getMonth() >= 9
          ? selectedDate.getMonth() + 1
          : "0" + (selectedDate.getMonth() + 1);
      let day =
        selectedDate.getDate() < 10
          ? "0" + selectedDate.getDate()
          : selectedDate.getDate();
      let dateData = selectedDate.getFullYear() + "-" + month + "-" + day;
      newsFreeds.setActiveNewsDate(dateData);
      newsFreeds.requestNewsFreeds();
    }
    //console.log(date.);
  };
  handleLevelSelector = data => {
    let { newsFreeds } = this.props;

    newsFreeds.requestNewsFreedsByLevel({ level: data, subLevel: "Easy" });
  };

  handleClearBtn = () => {
    let { newsFreeds } = this.props;
    this.setState({
      date: ""
    });
    newsFreeds.setActiveNewsDate("");
    newsFreeds.requestNewsFreeds(0);
  };

  handleFreadsSearch = evt => {
    this.props.newsFreeds.setSearchFreed(evt.target.value);
    setTimeout(() => {
      this.props.newsFreeds.requestNewsFreeds();
    }, 1500);
  };

  enableSearchBar = actionType => {
    if (actionType === "focus" && this.state.searchBarEnable === false)
      this.setState({
        searchBarEnable: true
      });
    else if (actionType === "blur" && this.state.searchBarEnable === true)
      this.setState({
        searchBarEnable: false
      });
  };

  render() {
    const data = this.props.level.levels;
    let levelList = data && data.map((level, i) => (
      <Link
        to={"/news-freads/" + level + "/Easy"}
        key={i}
        className={
          level === this.props.newsFreeds.active_news_grade_level
            ? "active"
            : ""
        }
        onClick={() => this.handleLevelSelector(level)}
      >
        {level}
      </Link>
    ));
    return (
      <NavigationHeaderContainer>
        <NavigationContainer>{levelList}</NavigationContainer>

        <NavigationToolbar>
          <SearchBarContainer width={"240px"}>
            <i className="fa fa-search" />

            <input
              type="text"
              onChange={this.handleFreadsSearch}
              placeholder="Search fread"
              value={this.props.newsFreeds.search_freed}
              onFocus={() => this.enableSearchBar("focus")}
              onBlur={() => this.enableSearchBar("blur")}
            />
          </SearchBarContainer>

          <div id="news-freeds-date">
            <DateSelectionField width="220px" style={{ overflow: "hidden" }}>
              <img src={require("static/img/date-icon.png")} alt="startDate" />

              <DatePicker
                selected={this.state.date}
                selectsStart
                startDate={this.state.date}
                onChange={this.handleDateChange}
                placeholderText='Select date'
              />
              {this.state.date ? (
                <i
                  className="fa fa-times"
                  onClick={() => this.handleClearBtn()}
                  style={{ padding: "8px", cursor: "pointer" }}
                />
              ) : null}
            </DateSelectionField>
          </div>
        </NavigationToolbar>
      </NavigationHeaderContainer>
    );
  }
}
export default connectStore({
  newsFreeds,
  level
})(NewsFreedsHeader)
export default {
    // MISC FOR NEWS FREEDS
    fetch_news_freeds_list:false,
    search_freed:'',
    fetch_active_news_freed:false,
    active_news_grade_level:'Level 1',
    current_news_editor_type:'',
    active_news_level:'Easy',
    send_push_notification:false,
    active_news_edit:false,
    //CRUD VARIABLES FOR NEWS FREEDS
    news_freeds_result:null,
    news_freeds_list:[],
    active_news_freed:null,
    active_news_freed_description:'',
    //CRUD FOR MCQ CREATION FOR NEWS FREEDS
    active_question: {},
    questions:[],
    active_options: [],
    new_question: {
        name: "",
        answer: "",
        level: "easy"
    },
    active_news_date:'',
    new_options: [],
    active_mcq: null,


}

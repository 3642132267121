export default {
  //....................PRODUCT CRUD.........................................
  SET_PRODUCT_LIST: (state, { value }) => {
    state.product_list = value;
    state.isAddProductFormOpened = false;
  },
  SET_EDIT_PRODUCT: (state, { product }) => {
    state.edit_product = product;
    state.isAddProductFormOpened = true;
    if (product == null) {
      state.isAddProductFormOpened = false;
    }
  },
  SET_PRODUCT_LIST_LOADING: (state, { value }) =>
    (state.isProductLoading = value),
  SET_SCHOOL_GUIDED_PATH_PRODUCT: (state, { product }) =>
    (state.school_guided_path_product = product),
};

import React, { Component } from "react";
// import Select from "react-select";
// import "./FliveCodeView.scss";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import swal from "sweetalert2";
import "react-tabs/style/react-tabs.css";
import moment from "moment";
import {
  ModalView,
  ModalViewParentContainer,
  ModalViewContainer,
  SelectContainer,
  SlotContainer,
  FormContainer,
  DescriptionContainer,
} from "./FliveCodeView.styled";
import {
  Heading,
  MainBodyContentContainer,
  InputBox,
  ThemeButton,
} from "@/static/styled/custom.styled";
import { connectStore } from "redux-box";
import { module as inviteCode } from "store/inviteCode";
import { module as activity } from "store/activity";
import DatePicker from "react-datepicker";
import { DateSelectionField } from "@sc/custom.styled";
import Scheduled from "./scheduled";
import Upload from "./upload";
import RenewalOldSchool from "./renewalold-school";
import SchoolCode from "./school-code";
import { module as onboard } from "@st/onboard";
import Permission from "@s/permission";


class FliveCodeView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedSchool: null,
      selectedSlot: null,
      selectedSection: null,
      selectedClass: null,
      selectedGrade: null,
      count: 0,
      date: null,
    };
  }
  handleClearBtn = () => {
    let { inviteCode } = this.props;
    this.setState({
      date: "",
    });
  };

  handleDateChange = (date) => {
    let { inviteCode } = this.props;
    // const date = moment().format();
    // console.log("date", date);

    this.setState(
      {
        date: moment(date).format("YYYY-MM-DD"),
      },
      console.log("date", date)
    );
  };

  onDatepickerRef(el) {
    if (el && el.input) {
      el.input.readOnly = true;
    }
  }

  handleCountChange = (e) => {
    this.setState({ count: e.target.value });
  };
  handleButtonClick = () => {
    let { inviteCode } = this.props;
    if (
      this.state.selectedSchool === null ||
      this.state.selectedClass === null ||
      this.state.selectedGrade === null ||
      this.state.selectedGrade === null ||
      this.state.date === null ||
      this.state.selectedSection === null
    ) {
      swal.fire({
        title: "Error!",
        text: "Fields cannot be empty",
        timer: 1500,
      });
    } else {
      let payload = {
        school: this.state.selectedSchool.id,
        class: this.state.selectedClass.id,
        section: this.state.selectedSection.id,
        grade: this.state.selectedGrade.id,
        gradeName: this.state.selectedGrade.name,
        countToGenerate: parseInt(this.state.count, 10),
        expiry_date: this.state.date,
      };
      inviteCode.createRandomInviteCode(payload);
      this.setState({
        selectedSection: null,
      });
    }
  };
  handleSchoolChange = (e) => {
    // this.reInitializeState(["selectedSlot"]);

    let { inviteCode } = this.props;

    const slotId = e.target.value;
    // console.log("Selected project id", projectId);

    this.setState({
      selectedSlot:
        inviteCode.slotList && inviteCode.slotList.find((m) => m.id === slotId),
    });
  };
  componentDidMount() {
    let { inviteCode } = this.props;
    console.log("inviteCode", inviteCode);

    const slotList = inviteCode.requestSlotList();
    console.log("slot", slotList);
  }
  handleUploadClick = (e) => {
    this.inputElement.click();
  };
  render() {
    let { inviteCode } = this.props;
    let { selectedSlot } = this.state;
    const {permission} = this.props.onboard;
    // let slotListOptions =
    //   inviteCode.slotList.length &&
    //   inviteCode.slotList.map((slot, i) => (
    //     <option value={slot.id} key={slot.id}>
    //       {slot.created_at ? slot.created_at : ""}
    //       {/* {console.log("slotList", inviteCode.slotList)} */}
    //     </option>
    //   ));
    let dateFilters = inviteCode.slotList;
    console.log("dateFilter", dateFilters);
    // let slotFilter =
    // console.log("slotfilters", slotFilter);

    return (
      <MainBodyContentContainer primary style={{ position: "relative" }}>
        <Tabs>
          <TabList>
             {/* <Tab>Scheduled</Tab>  */}
             {/* <Tab>Available Slots</Tab> */}
             {/* <Tab>Schedule</Tab>  */}
             <Tab>Upload File</Tab> 
             { Permission.getPermissionForMenu(permission, ["school code"])? <Tab>School Code</Tab> :null}
             <Tab>Renewal Old School</Tab> 
          </TabList>

          {/* <TabPanel>
            <ModalView id="modalDisplay">
              <ModalViewParentContainer>
                <Heading
                  style={{
                    fontSize: "20px",
                    textAlign: "center",
                    padding: "10px",
                    fontWeight: "500",
                  }}
                >
                  Available Slots
                </Heading>
                <ModalViewContainer>
                  <FormContainer>
                    <SelectContainer> DATE</SelectContainer>
                  </FormContainer>

                  <div
                    className="datepicker-wrappper"
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      marginBottom: "20px",
                    }}
                  >
                    <div id="payments-date">
                      <DateSelectionField
                        width="220px"
                        style={{ overflow: "hidden" }}
                      >
                      <img
                          src={require("static/img/date-icon.png")}
                          alt="startDate"
                        />
                      <DatePicker
                          selected={this.state.date}
                          selectsStart
                          startDate={this.state.date}
                          onChange={this.handleDateChange}
                          minDate={moment().toDate()}
                          placeholderText="Select Date"
                          ref={(el) => this.onDatepickerRef(el)}
                        />
                      {this.state.date
                          ? dateFilters.filter(function (dateFilter) {
                              console.log("filters1", dateFilters);

                              return dateFilter.created_at === this.state.date;
                            })
                          :  <i
                               className="fa fa-times"
                              onClick={() => this.handleClearBtn()}
                               style={{ padding: "8px", cursor: "pointer" }}
                            />
                            null}
                      </DateSelectionField>
                    </div>
                  </div>
                  <FormContainer>
                    <select
                      className="custom-select"
                      id="inputGroupSelect01"
                      onChange={(e) => this.handleSchoolChange(e)}
                    >
                      <option defaultValue>Choose...</option>
                      {slotListOptions}
                    </select>
                    <SelectContainer>AVAILABLE SLOTS</SelectContainer>
                    <DescriptionContainer>
                      * each class is of 1 hour duration
                    </DescriptionContainer>
                    <SlotContainer>
                      <img
                        src={require("static/img/group_17.png")}
                        alt="cloud-upload"
                      />
                      <span>Morning </span>
                    </SlotContainer>
                    <div>
                      <p>Each class is of 1 hour duration</p>
                    </div>
                  </FormContainer>
                  <FormContainer>
                    
                  </FormContainer>
                  
                  
                  <FormContainer>
                    <SelectContainer>Freadom Grade</SelectContainer>
                    <Select
                      className="dropdown-container"
                      value={selectedGrade}
                      onChange={this.handleGradeChange}
                      options={this.props.activity.gradeList}
                    />
                  </FormContainer>
                  <FormContainer>
                    <SelectContainer>Invite Count</SelectContainer>
                    <InputBox
                      type="number"
                      placeholder="Input invite count"
                      value={count}
                      onChange={(e) => this.handleCountChange(e)}
                    />
                  </FormContainer>
                  <ThemeButton primary onClick={this.handleButtonClick}>
                    Show Slot Details
                  </ThemeButton>
                </ModalViewContainer>
              </ModalViewParentContainer>
            </ModalView>
          </TabPanel> */}

          {/* <TabPanel>
            <InviteCodeSearch />
          </TabPanel> */}
          {/* <TabPanel>
            <SchoolCode />
          </TabPanel> */}
          {/* <TabPanel>
            <BulkAdoption />
          </TabPanel> */}
          {/* <TabPanel>
            <Scheduled />
          </TabPanel> */}
          <TabPanel>
            <Upload />
          </TabPanel>
          { Permission.getPermissionForMenu(permission, ["school code"])?
          <TabPanel>
            <SchoolCode />
          </TabPanel>:null}
          <TabPanel>
            <RenewalOldSchool />
          </TabPanel>
        </Tabs>
      </MainBodyContentContainer>
    );
  }
}
export default connectStore({
  inviteCode,
  activity,
  onboard
})(FliveCodeView)
import React, { Component } from "react";
import { SubHeading, EmptyDataContainer } from "@sc/custom.styled";
import InfiniteScroll from "react-infinite-scroller";
import {
  ChildSubContainer,
  SubscriptionLabel,
  SubscriptionContainer,
  PlanDateContainer,
  ActivePlanContainer,
  PlanDescription,
  PlanLeftContainer,
  PlanHeading,
  PlanRightContainer,
  SubscriptionSubLabel,
  PlanDetails
} from "./ChildSubscription.styled";
import { connectStore } from "redux-box";
import { module as user } from "@st/user";
import { module as ui } from "@st/ui";
import { module as payment } from "@st/payment";
import {
  NotificationScrollParentContainer,
  LoadingContainer,
  InfiniteScrollLoader
} from "@sc/custom.styled";


class ChildSubscription extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: "",
      endDate: "",
      activeList: false,
      flag:false
    };
  }
  componentDidMount() {
    let { user, payment } = this.props;
    if (user.active_child) {
      payment.requestSubscriptionList({ concat: false, offset: 0 });
      if (user.active_child.trail_start_time !== null) {
        let months = [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec"
        ];
        let date = new Date(user.active_child.trail_start_time);
        let startDate =
          date.getDate() +
          " " +
          months[date.getMonth()] +
          " " +
          date.getFullYear();
        this.setState({
          startDate: startDate
        });
      }
    }
  }

  fetchMoreSubscriptions = () => {
    if (
      this.props.payment.subscription_list_result !== null &&
      this.props.payment.subscription_list_result.has_next
    ) {
      //// console.log("hello");
      setTimeout(() => {
        let { payment } = this.props;
        if (
          payment.subscription_list_result !== null &&
          payment.subscription_list_result.next_page > 0
        )
          this.props.payment.requestSubscriptionList({
            concat: true,
            offset: payment.subscription_list_result.next_page
          });
      }, 300);
    }
  };

  handleActiveList = () => {
    this.setState({
      activeList: !this.state.activeList
    });
  };

  handleSubscription = subscription => {
    let { ui, payment } = this.props;
    payment.setActiveSubscription(subscription);
    payment.requestSubPaymentList({ offset: 0, concat: false });
    ui.showModal("PAYMENTS_LIST");
  };

  convertDateFormat(inputDate) {
    let months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec"
    ];
    let date = new Date(inputDate);
    let newDate =
      date.getDate() + " " + months[date.getMonth()] + " " + date.getFullYear();
    return newDate;
  }

  renderSubscription() {
    let { user } = this.props;
    if (user.active_child) {
      if (
        ((user.active_child.invite_code !== null || (user.active_child.invite_code !== null && user.active_child.invite_code !== '')) &&
        user.active_child.subscription_status === "not_subscribed"
      )) {
        return (
          <SubscriptionContainer>
            <i className="fa fa-newspaper-o" />
            <SubscriptionLabel style={{ marginBottom: "15px" }}>
              {" "}
              WOW Code User
            </SubscriptionLabel>

            <SubHeading>
              {" "}
              WOW Code : {user.active_child.invite_code}{" "}
            </SubHeading>
            <SubHeading> School Name : {user.active_child.school} </SubHeading>
            <SubHeading> Start Date : {this.state.startDate} </SubHeading>
            <SubscriptionSubLabel style={{color:"rgba(0,0,0,0.6)"}}>
              Expires after  {user.active_child.days_left} days 
            </SubscriptionSubLabel>
          </SubscriptionContainer>
        );
      } else if (user.active_child.account_details.account_status == 'school_code_linked' ){
        return ( <SubscriptionContainer>
            <i className="fa fa-newspaper-o" />
            <SubscriptionLabel style={{ marginBottom: "15px" }}>
              {" "}
              School Code 
            </SubscriptionLabel>

            <SubHeading>
              {" "}
              School Code : {user.active_child.school_code}{" "}
            </SubHeading>
            <SubHeading> School Name : {user.active_child.school} </SubHeading>
            <SubHeading> Start Date : {this.state.startDate} </SubHeading>
            <SubscriptionSubLabel style={{color:"rgba(0,0,0,0.6)"}}>
              Expires after  {user.active_child.account_details.days_left} days 
            </SubscriptionSubLabel>
          </SubscriptionContainer>)
      }else if (this.props.user.active_child.trail_expired && user.active_child.subscription_status !== "active")
      { 
        return (
          <SubscriptionContainer>
            <i className="fa fa-exclamation-triangle" />
            <SubscriptionLabel> Trial Period Expired</SubscriptionLabel>
            <SubHeading> Start Date: {this.state.startDate} </SubHeading>
          </SubscriptionContainer>
        );}
      else if (
        user.active_child.trail_expired === false &&
        user.active_child.subscription_status !== "active"
      )
      { 
        return (
          <SubscriptionContainer>
            <i className="fa fa-exclamation-triangle" />
            <SubscriptionLabel color={"rgba(0,0,0,0.6)"}>
              {" "}
              In Trial Period{" "}
            </SubscriptionLabel>
            <SubHeading> Start Date: {this.state.startDate} </SubHeading>
            <SubscriptionSubLabel>
              {" "}
              Only {user.active_child.days_left} left for the trial period
            </SubscriptionSubLabel>
          </SubscriptionContainer>
        );}
    }
  }

  render() {
    // let { active_child } = this.props.user.active_child;
    let { user, payment } = this.props;

    let planList =
      payment.subscription_list && payment.subscription_list.length > 0 ? (
        payment.subscription_list.map((data, index) => (
          <ActivePlanContainer
            onClick={() => this.handleSubscription(data)}
            key={index}
            activeColor={data.status === "active"}
          >
            <PlanLeftContainer>
              <PlanHeading
                fontSize="20x"
                style={{ fontWeight: "600" }}
                color="rgba(0,0,0,0.6)"
              >
                {" "}
                {data.plan.name}{" "}
              </PlanHeading>
              <PlanDescription>
                {data.plan && data.plan.description !== ""
                  ? data.plan.description
                  : "No description added"}{" "}
              </PlanDescription>
              <PlanHeading
                fontSize="14px"
                color="rgba(0,0,0,0.5)"
                style={{ fontWeight: "600" }}
              >
                {" "}
                Mode of Payment :{" "}
                {data.subscription_type
                  .slice(0, 1)
                  .toUpperCase()
                  .concat(data.subscription_type.slice(1))}{" "}
                | Status:{" "}
                {data.status
                  .slice(0, 1)
                  .toUpperCase()
                  .concat(data.status.slice(1))}{" "}
              </PlanHeading>
            </PlanLeftContainer>
            <PlanRightContainer>
              <PlanDetails>
                <PlanHeading style={{ marginRight: "24px" }}>
                  {" "}
                  <span> {user.active_child.days_left} </span> Days Left{" "}
                </PlanHeading>
                <PlanHeading>
                  {" "}
                  <span>
                    {" "}
                    {`${data.plan.currency} ${data.plan.amount / 100}`}
                  </span>{" "}
                  Price{" "}
                </PlanHeading>
              </PlanDetails>
            </PlanRightContainer>
            <PlanDateContainer>
              <PlanHeading>
                {" "}
                Start date : {this.convertDateFormat(data.current_start)}{" "}
              </PlanHeading>
              <PlanHeading>
                {" "}
                End date : {this.convertDateFormat(data.current_end)}{" "}
              </PlanHeading>
            </PlanDateContainer>
          </ActivePlanContainer>
        ))
      ) : (
        <EmptyDataContainer style={{ height: "calc(100vh - 300px)" }}>
          <i className="fa fa-newspaper-o" />
          <h3 style={{ color: "rgba(0,0,0,0.6)" }}>No Subscriptions Found</h3>
        </EmptyDataContainer>
      );

    return (
      <ChildSubContainer>
        {this.renderSubscription()}

        {user.active_child &&
          user.active_child.subscription_status === "active" &&
          (payment.fetch_subscription_list ? (
            <LoadingContainer style={{ zIndex: 100 }}>
              <img
                alt="loading"
                width="60"
                height="60"
                src={require("static/img/Theme-loader.gif")}
              />
            </LoadingContainer>
          ) : (
            <NotificationScrollParentContainer>
              <InfiniteScroll
                pageStart={0}
                style={{ position: "relative" }}
                loadMore={this.fetchMoreSubscriptions}
                hasMore={
                  this.props.payment.subscription_list_result &&
                  this.props.payment.subscription_list_result.has_next
                }
                loader={
                  <InfiniteScrollLoader className="loader" key={0}>
                    {" "}
                    <img
                      alt="loading"
                      width="50"
                      height="50"
                      src={require("static/img/Theme-loader.gif")}
                    />{" "}
                  </InfiniteScrollLoader>
                }
                useWindow={false}
              >
                {planList}
              </InfiniteScroll>
            </NotificationScrollParentContainer>
          ))}
      </ChildSubContainer>
    );
  }
}
export default connectStore({
  user,
  ui,
  payment
})(ChildSubscription)
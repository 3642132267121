export default {
  podcast_list: [],
  isPodcastLoading: false,
  edit_podcast: null,
  isAddPodcastFormOpened: false,
  selected_podcast: null,
  podcast: null,
  episodesList: [],
  episodesResult: {},
  isPodcastEpisodesLoading: false,
  search_reading: "",
  episodeEditInfo: null,
  episodeEdit: false,
};

import styled from "styled-components";

export const UploadImageContainer = styled.div`
  width: 100px;
  height: ${props=>props.height?props.height:'170px'};
  border-radius: 10px;
  border: dashed 1px rgba(151, 151, 151, 0.44);
  background-color: #ffffff;
  cursor: pointer;
  outline: none;
  display: flex;
  justify-content: space-around;
  position: relative;
  margin-bottom:${props=>props.marginBottom?props.marginBottom:'0px'};
`;
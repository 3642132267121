import React, { Component } from "react";
import { YoutubeLink, YoutubeListContainer } from "./YoutubeSection.styled";

export default class YoutubeSection extends Component {
  getThumb = url => {
    const arr = url.split("/");
    const last = arr && arr[arr.length - 1].split("=");
    const id = last && last[last.length - 1];
    return `https://img.youtube.com/vi/${id}/0.jpg`;
  };
  render() {
    return (
      <YoutubeListContainer>
        {this.props.media.map((media, i) => (
          <YoutubeLink key={i}>
            <a href={media.media_url} alt="album icon" target="_blank">
              <img
                src={this.getThumb(media.media_url)}
                alt=""
                style={{ width: "120px" }}

              />
            </a>
          </YoutubeLink>
        ))}
      </YoutubeListContainer>
    );
  }
}

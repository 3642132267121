import { Api } from "./base";

const _headers = () => {
  return {
    Authorization:
      "JWT " + require("store").default.getState().onboard.user.auth_token,
    "Content-Type": "application/json"
  };
};

export default {
  getAllNotifications(offset, type, search, filter_fields) {
    if (search !== "" && filter_fields === "")
      return Api.getCsv(
        `/notification/v1/panel/?offset=${offset}&type=${type}&key=${search}`,
        _headers()
      );
    else if (search === "" && filter_fields !== "")
      return Api.getCsv(
        `/notification/v1/panel/?offset=${offset}&type=${type}&${filter_fields}`,
        _headers()
      );
    else if (search !== "" && filter_fields !== "")
      return Api.getCsv(
        `/notification/v1/panel/?offset=${offset}&type=${type}&key=${search}&${filter_fields}`,
        _headers()
      );

    return Api.getCsv(
      `/notification/v1/panel/?offset=${offset}&type=${type}`,
      _headers()
    );
  },

  getWOWSchools(notification_id) {
    if (notification_id === "")
      return Api.getCsv("/notification/v1/school/", _headers());

    return Api.getCsv(
      `/notification/v1/school/?notification_id=${notification_id}`,
      _headers()
    );
  },

  getSectionsByWowId(schoolId, notification_id) {
    if (notification_id === "")
      return Api.get(`/notification/v1/section/${schoolId}/`, _headers());

    return Api.get(
      `/notification/v1/section/${schoolId}/?notification_id=${notification_id}`,
      _headers()
    );
  },

  getAllGrades() {
    return Api.get("/misc/v1/admin-grade/", _headers());
  },

  getAllChildren(offset, search, id) {
    if (search !== "" && id === "")
      return Api.getCsv(
        `/notification/v1/children/?offset=${offset}&key=${search}`,
        _headers()
      );
    else if (search !== "" && id !== "")
      return Api.getCsv(
        `/notification/v1/children/?offset=${offset}&key=${search}&notification_id=${id}`,
        _headers()
      );
    else if (search === "" && id !== "")
      return Api.getCsv(
        `/notification/v1/children/?offset=${offset}&notification_id=${id}`,
        _headers()
      );

    return Api.getCsv(
      `/notification/v1/children/?offset=${offset}`,
      _headers()
    );
  },

  getAllChildrenByLevel(filterType, levels, offset, search, id) {
    if (search !== "" && id === "")
      return Api.getCsv(
        `/notification/v1/children/?offset=${offset}&filter_type=${filterType}&filter_ids=${levels}&key=${search}`,
        _headers()
      );
    else if (search !== "" && id !== "")
      return Api.getCsv(
        `/notification/v1/children/?offset=${offset}&filter_type=${filterType}&filter_ids=${levels}&key=${search}&notification_type=${id}`,
        _headers()
      );
    else if (search === "" && id !== "")
      return Api.getCsv(
        `/notification/v1/children/?offset=${offset}&filter_type=${filterType}&filter_ids=${levels}&notification_id=${id}`,
        _headers()
      );

    return Api.getCsv(
      `/notification/v1/children/?offset=${offset}&filter_type=${filterType}&filter_ids=${levels}`,
      _headers()
    );
  },

  getChildrenByWowSection(filterIds, offset) {
    return Api.get(
      `/notification/v1/children/?offset=${offset}&filter_type=wow-grade&filter_ids=${filterIds}/`,
      _headers()
    );
  },

  createNotification(data) {
    return Api.post(`/notification/v1/panel/`, data, _headers());
  },

  deleteNotificationById(notificationId) {
    return Api.deleteCall(
      `/notification/v1/panel/${notificationId}/`,
      _headers()
    );
  },
  updateNotification(notificationId, data) {
    return Api.put(
      `/notification/v1/panel/${notificationId}/`,
      data,
      _headers()
    );
  },
  getNotificationById(notificationId) {
    return Api.get(`/notification/v1/panel/${notificationId}/`, _headers());
  }
};

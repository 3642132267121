import React, { Component } from "react";
import {
  UploadContainer,
  SectionToolbar,
  ToolbarItem
} from "@sc/Section.styled";
import {
  SectionHeading,
  SectionNameEditable,
  UploadHeaderContainer
} from "@c/activity-view/activity-creator/activity-info/ActivityInfo.styled";
import { SubHeading } from "static/styled/custom.styled";
import store from "store";
import actions from "store/readingChallenge/actions";
import swal from "sweetalert2";
import VideoSection from "@c/activity-view/activity-creator/video-section/VideoSection";
import TextSection from "@c/activity-view/activity-creator/text-section/TextSection";
import ImageSection from "@c/activity-view/activity-creator/image-section/ImageSection";
import AudioSection from "@c/activity-view/activity-creator/audio-section/AudioSection";
import YoutubeSection from "@c/activity-view/activity-creator/youtube-section/YoutubeSection";
import FileSection from "@c/activity-view/activity-creator/file-section/FileSection";
import { connectStore } from "redux-box";
import { module as activity } from "@st/activity";


class Section extends Component {
  constructor(props) {
    super();
    this.state = {
      enableEdit: false,
      name: ""
    };
  }

  componentDidMount() {
    this.setState({
      name: this.props.section.name
    });
  }

  getSectionContent = () => {
    let dataType = this.props.section.content_type;
    if (dataType === "video")
      return <VideoSection media={this.props.section.media} dataType={dataType}/>;
    else if (dataType === "text")
      return <TextSection description={this.props.section.description} dataType={dataType}/>;
    else if (dataType === "image")
      return <ImageSection media={this.props.section.media} dataType={dataType}/>;
    else if (dataType === "audio")
      return <AudioSection media={this.props.section.media} dataType={dataType}/>;
    else if (dataType === "youtube")
      return <YoutubeSection media={this.props.section.media} dataType={dataType}/>;
    else if (dataType === "pdf" || dataType === "epub")
      return (
        <FileSection media={this.props.section.media} dataType={dataType} />
      );
  };

  handleSectionEditable = evt => {
    const { section } = this.props;
    if (evt.keyCode === 13) {
      this.setState({ enableEdit: false });
      store.dispatch(actions.setActiveReadingSection(section));
      store.dispatch(
        actions.requestUpdateReadingSection({
          name: this.state.name,
          content_type: this.props.section.content_type
        })
      );
    }
  };

  handleSectionNameChange = id => e => {
    this.setState({
      name: e.target.value
    });
  };

  handleSectionRemoval = evt => {
    swal({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!"
    }).then(result => {
      if (result.value) {
        store.dispatch(actions.removeSection());
      }
    });
  };

  showSubContainerWith = type => () => {
    this.props.handleShowSubContainer(type);
  };

  enableEdit = () => {
    this.setState({
      enableEdit: !this.state.enableEdit
    });
  };

  startUpdate = () => {
    let dataType = this.props.section.content_type;
    this.props.activity.setEditorType(dataType);
    store.dispatch(
      actions.startUpdatingReadingSection(dataType, this.props.section)
    );
  };

  showSubContainer = () => {
    store.dispatch(actions.setActiveReadingSection(this.props.section));
    let type = this.props.section.content_type;
    this.props.handleShowSubContainer(type);
  };

  deleteSection = () => {
    swal({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!"
    }).then(result => {
      if (result.value) {
        this.props.activity.setEditorType("");
        store.dispatch(actions.deleteReadingSection(this.props.section));
      }
    });
  };

  render() {
    let section = this.props.section;
    return (
      <UploadContainer>
        <UploadHeaderContainer>
          <UploadHeaderContainer>
            {this.state.enableEdit ||
            (this.props.section && this.props.section.name === "") ? (
              <SectionHeading
                width={"45%"}
                placeholder="Enter Section Name"
                type="text"
                autoFocus
                value={this.state.name}
                onChange={this.handleSectionNameChange(section.id)}
                onKeyDown={this.handleSectionEditable}
              />
            ) : (
              <SectionNameEditable>
                <SubHeading
                  primary
                  style={{ minWidth: "160px" }}
                  width={(section.name.length + 1) * 10 + "px"}
                >
                  {section.name}
                </SubHeading>
                <img
                  src={require("static/img/editicon.png")}
                  alt="edit icon"
                  className="image-fluid"
                  onClick={this.enableEdit}
                />
              </SectionNameEditable>
            )}

            <SectionToolbar>
              <ToolbarItem primary onClick={this.startUpdate}>
                <div className="icon-container">
                  <i className="fa fa-pencil" />
                </div>
              </ToolbarItem>
              <ToolbarItem onClick={this.deleteSection}>
                <div className="icon-container">
                  <i className="fa fa-trash" />
                </div>
              </ToolbarItem>
            </SectionToolbar>
          </UploadHeaderContainer>
        </UploadHeaderContainer>

        {this.getSectionContent()}
      </UploadContainer>
    );
  }
}
export default connectStore({
  activity
})(Section)
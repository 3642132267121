export default {
    SET_ACTIVE_READING_GRADE: (state, {grade}) =>{
    state.active_reading_grade = grade},
    SET_ACTIVE_READING_CHALLENGE:(state,{activeReading}) =>(state.active_reading_challenge = activeReading),
    SET_READING_CHALLENGE_LIST: (state, {readingList}) => (state.readingList = readingList),
    SET_FETCH_READING_CHALLENGE_LIST: (state,{value}) =>(state.fetch_reading_challenge_list=value),
    SET_READING_CHALLENGES_RESULT:(state,{result})=>(state.reading_challenges_result=result),
    SET_CONCAT_READING_CHALLENGE_LIST:(state,{readingList})=>(state.readingList=state.readingList.concat(readingList)),

    SET_ACTIVE_READING_MCQ: (state, {mcq}) => (state.active_mcq = mcq),
    UPDATE_NEW_READING_QUESTION: (state, {question}) => (state.new_question = question),
    ADD_NEW_READING_OPTION:(state,{option}) => (state.new_options.push({name:option,__id:Math.random()}) ),
    REMOVE_NEW_READING_OPTION: (state, {optionId}) => {
        state.new_options = state.new_options.filter(option => option.__id !== optionId)
    },
    SET_READING_QUESTION: (state, { question }) => (state.question_set = question),
    NEW_READING_QUESTION: (state, { question }) => (state.new_question = question),
    SET_ALL_READING_QUESTIONS:(state, {questions}) => (state.questions = questions),
    UNSET_ALL_READING_QUESTIONS:(state, {question}) => (state.questions = question),
    UNSET_ACTIVE_READING_QUESTION:(state) =>{state.questions = []; state.active_mcq = []},

    UNSET_ACTIVE_READING_CHALLENGE:(state, {activeReading}) => (state.active_reading = activeReading),
    SET_SECTIONS: (state, {sections}) => (state.sections = sections),
    SET_ACTIVITY_PACKS: (state, {activityPacks}) =>
        (state.activityPacks = activityPacks),
    FETCH_READING_CHALLENGE: (state, {value}) => (state.fetchingReadingChallenge = value),
    REMOVE_SECTION: (state, action) => state.new_section = null,
    SET_ACTIVE_READING_SECTION: (state, {section}) => state.active_section = section,
    UPDATE_ACTIVE_READING_SECTION: (state, {section}) => state.active_section = {...state.active_section, ...section},
    START_CREATING_READING_SECTION: (state, {sectionType, data}) => {
        state.current_editor_type = sectionType
        state.current_section_action = "create"
        state.active_section = data
    },
    START_UPDATING_READING_SECTION: (state, {sectionType, data}) => {
        state.current_editor_type = sectionType
        state.current_section_action = "update"
        state.active_section = data
    },
    ADD_NEW_READING_SECTION: (state, {section}) => state.new_sections.push({__id: Math.random(), ...section}),
    REMOVE_NEW_READING_SECTION: (state, {section}) => {
        state.new_sections = state.new_sections.filter(sec => sec.__id !== section.__id)
    },
    RESET_NEW_READING_SECTIONS: (state, {action}) => state.new_sections = [],
    UPDATE_NEW_READING_SECTION: (state, {section}) => {
        state.new_sections = state.new_sections.map(sec => {
            if (sec.__id === section.__id)
                return section;
            return sec;
        })
    },
    SET_ACTIVE_READING_EXPERIENCE:(state,{experience})=> state.active_experience=experience,
    SET_SEARCH_READING:(state,{search})=>(state.search_reading=search),
}

import React, { Component } from "react";
import { connectStore } from "redux-box";
import {
  AlignContentHorizontal,
  EmptyContainer,
  AutoContainer,
  LoadingContainer,
  InfiniteScrollParentContainer,
  InfiniteScrollLoader,
} from "static/styled/custom.styled";
import {
  NavigationHeaderContainer,
  NavigationContainer,
} from "static/styled/NavigationBar.styled";
import { module as activity } from "@st/activity";
import { module as quiz2 } from "@st/quiz2";
import { Link } from "react-router-dom";
import swal from "sweetalert2";
import QuizCard from "./quiz2-card";
import InfiniteScroll from "react-infinite-scroller";

class Quiz2List extends Component {
  constructor(props) {
    super();
    this.state = {
      classList: [],
    };
  }

  handleGradeChange = (grade) => {
    this.props.quiz2.setQuizList([]);
    this.props.quiz2.requestQuizListByGradeId(grade.id);
  };
  handleDeleteClick = (e, quiz) => {
    e.preventDefault();
    swal({
      text: "Are you sure that you want to delete this quiz?",
      type: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      quiz_list: [],
    }).then((result) => {
      if (result.value) {
        this.props.quiz2.requestDeleteQuiz(quiz.id);
      }
    });
  };
  fetchMoreQuizzes = () => {
    let { quiz2 } = this.props;

    if (
      this.props.quiz2.quiz_result !== null &&
      this.props.quiz2.quiz_result.has_next
    ) {
      setTimeout(() => {
        if (
          this.props.quiz2.quiz_result !== null &&
          this.props.quiz2.quiz_result.next_page > 0
        )
          this.props.quiz2.requestConcatQuizChallenge(
            this.props.quiz2.quiz_result.next_page
          );
      }, 100);
    }
  };
  render() {
    console.log("props here props", this.props);
    let quizList = this.props.quiz2.quizList;
    let classList =
      this.props.activity.grades &&
      this.props.activity.grades.map((grade, i) => (
        <a
          key={i}
          className={
            this.props.quiz2.active_quiz_grade &&
            grade.id === this.props.quiz2.active_quiz_grade
              ? "active"
              : ""
          }
          onClick={() => this.handleGradeChange(grade)}
        >
          <span> {grade.name}</span>
        </a>
      ));

    let readingChallengeList = (
      <AlignContentHorizontal marginBottom="70px">
        {quizList && quizList.length > 0 ? (
          quizList.map((quizInfo, index) => {
            return (
              <div className="col-sm-6 col-md-4 col-lg-3" key={index}>
                <Link to={"/quiz2/edit/" + quizInfo.id}>
                  <QuizCard
                    quizInfo={quizInfo}
                    challengeId={activity.id}
                    // handlePublishClick={(e) =>
                    //   this.handlePublishClick(quizInfo, e)
                    // }
                    handleDeleteClick={(e) =>
                      this.handleDeleteClick(e, quizInfo)
                    }
                  />
                </Link>
              </div>
            );
          })
        ) : (
          <EmptyContainer>
            <h4>NO QUIZ FOR THIS GRADE</h4>
          </EmptyContainer>
        )}
      </AlignContentHorizontal>
    );
    return (
      <AutoContainer>
        <NavigationHeaderContainer shadow>
          <NavigationContainer>{classList}</NavigationContainer>
        </NavigationHeaderContainer>
        {this.props.quiz2.fetch_quiz_list ? (
          <LoadingContainer style={{ zIndex: "100" }}>
            <img
              alt="loading"
              width="60"
              height="60"
              src={require("static/img/Theme-loader.gif")}
            />
          </LoadingContainer>
        ) : (
          //<div>{readingChallengeList}</div>
          <InfiniteScrollParentContainer>
            <InfiniteScroll
              pageStart={0}
              loadMore={this.fetchMoreQuizzes}
              style={{ position: "relative" }}
              hasMore={this.props.quiz2.quiz_result.has_next}
              loader={
                <InfiniteScrollLoader className="loader" key={0}>
                  <img
                    alt="loader"
                    width="50"
                    height="50"
                    src={require("static/img/Theme-loader.gif")}
                  />
                </InfiniteScrollLoader>
              }
              useWindow={false}
            >
              {readingChallengeList}{" "}
            </InfiniteScroll>
          </InfiniteScrollParentContainer>
        )}
      </AutoContainer>
    );
  }
}

export default connectStore({ activity, quiz2 })(Quiz2List);

import styled from "styled-components";

export const ExperienceListContainer=styled.div`
    width:100%;
   overflow-x:hidden;
   padding:14px;
    margin-top:42px;
    max-height: calc(100vh - 180px);
    overflow-y:auto;
`;

import React, { useEffect } from "react";
import { connectStore } from "redux-box";
import { module as ui } from "@st/ui";
import { module as podcast } from "@st/podcast";
import PodcastCard from "./podcast-card";

import {
  LoadingContainer,
  MainBodyContentContainer,
  AlignContentHorizontal,
  PlusButton,
  EmptyContainer,
  PlusIcon,
} from "@sc/custom.styled";

function PodcastView(props) {
  useEffect(() => {
    props.podcast.fetchPodcastList();
  }, []);

  const handlePodcastSelect = (selectedPodcast) => {
    let path = `podcast/${selectedPodcast.id}/episodes`;
    props.podcast.setSelectedPodcast(selectedPodcast);
    return props.history.push(path);
  };

  const handleEditClick = (e, podcast) => {
    var el = e;
    props.podcast.setEditPodcast(podcast);
    props.ui.showModal("PODCASTUPDATEFORM");
    if (!el) {
      el = window.event;
    }
    el.cancelBubble = true;
    if (el.stopPropagation) el.stopPropagation();
  };

  const handleAddClickButton = () => {
    props.podcast.setEditPodcast({
      thumbnail_url: false,
      description: "",
      name: "",
    });
    props.ui.showModal("PODCASTCREATEFORM");
  };
  return (
    <MainBodyContentContainer>
      <MainBodyContentContainer>
        {props.podcast.isPodcastLoading && (
          <LoadingContainer style={{ zIndex: "100" }}>
            <img
              alt="loading"
              width="60"
              height="60"
              src={require("static/img/Theme-loader.gif")}
            />
          </LoadingContainer>
        )}
        { !props.podcast.isPodcastLoading && (
        <AlignContentHorizontal marginBottom="70px">
          {props.podcast.podcast_list &&
          props.podcast.podcast_list.length > 0 ? (
            props.podcast.podcast_list.map((podcast) => {
              return (
                <div className="col-sm-6 col-md-4 col-lg-3" key={podcast.id}>
                  <PodcastCard
                    handlePodcastSelect={() => handlePodcastSelect(podcast)}
                    podcastDetails = {podcast}
                    handleEditClick={(event) => handleEditClick(event, podcast)}
                  />
                </div>
              );
            })
          ) : (
            <EmptyContainer>
              <h4>NO PODCASTS AVAILABLE</h4>
            </EmptyContainer>
          )}
        </AlignContentHorizontal>
         )}
      </MainBodyContentContainer>
      <PlusButton
        hide={props.podcast.isAddPodcastFormOpened}
        onClick={handleAddClickButton}
      >
        <PlusIcon
          primary
          alt="Plus Icon"
          cross={false}
          src={require("static/img/plusicon.png")}
        />
      </PlusButton>
    </MainBodyContentContainer>
  );
}
export default connectStore({
  ui,
  podcast,
})(PodcastView);

import React, { Component } from "react";
import {
  UploadButton,
  UploadContainer,
} from "./NewSection.styled";
import {
  RemoveSection,
  SectionHeading,
  SectionNameEditable,
  UploadHeaderContainer
} from "../activity-info/ActivityInfo.styled";
import { SubHeading } from "static/styled/custom.styled";
import store from "store";
import actions from "store/activity/actions";

export default class Section extends Component {
  constructor(props) {
    super();
    this.state = {
      editable: true,
      name: "",
      removed: false
    };
  }

  componentDidMount(){
    const props = this.props;
    props.section && props.section.name &&  this.setState({
            editable : (  props.section.name.length===0)
        })
    }



  onNameChange = (e) => {
      // this.setState({ name: e.target.value })
      store.dispatch(actions.updateNewSection({__id: this.props.section.__id, name: e.target.value}))
  }

  disableEdit = (e) =>{
      if(e.key==="Enter"){
        this.setState({ editable: false })
      }
  }

  enableEdit =()=> {
      this.setState({ editable: true })
  }

  removeSection= () =>{
    store.dispatch(actions.removeNewSection(this.props.section))
     store.dispatch(actions.closeEditor())
  }

  startNewTextSection =() =>{
    store.dispatch(actions.startCreatingSection("text", this.props.section ) )
  }
  startNewMediaSection =() =>{
    store.dispatch(actions.startCreatingSection("media", this.props.section ))
  }

  render() {
    const {section} = this.props;

    return (
        <UploadContainer>
        <UploadHeaderContainer>
          <UploadHeaderContainer>
            {this.state.editable ? (
              <SectionHeading
                width={"45%"}
                placeholder="Enter Section Name"
                type="text"
                autoFocus
                value={section.name}
                onChange={this.onNameChange}
                onKeyDown={(e) =>this.disableEdit(e)}
              />
            ) : (
              <SectionNameEditable>
                <SubHeading primary width={(this.state.name.length + 1) * 10 + "px"} style={{minWidth:'160px'}}>
                  {this.props.section.name}
                </SubHeading>
                <img src={require("static/img/editicon.png")} alt="" className="image-fluid" onClick={this.enableEdit} />
              </SectionNameEditable>
            )}

            <RemoveSection onClick={this.removeSection}>
              Remove Section
            </RemoveSection>
          </UploadHeaderContainer>
        </UploadHeaderContainer>
        <div>
          <UploadButton onClick={this.startNewTextSection}>
            <img className="img-fluid" src={require("static/img/edit.png")} alt="add notes"/> Add notes
          </UploadButton>
          <UploadButton onClick={this.startNewMediaSection}>
            <img className="img-fluid" src={require("static/img/link.png")} alt="add media"/> Add Media
          </UploadButton>
        </div>
      </UploadContainer>
    );
  }
}

import styled from "styled-components";


export const HeaderLabel = styled.div `
font-size: 30px;
display: flex;
justify-content: center;
color: white;
`;

export const WaveContainer = styled.div `
display: flex;
justify-content: center;
font-size: 60px;
`



import React, { Component } from "react";
import {
  ActivityContinueButtonContainer,
  ActivityMainFormContainer,
  CreateActivityContainer,
} from "@c/activity-view/activity-creation-form/ActivityCreationForm.styled";
import {
  RCNameInput,
  CreateRCButton,
  DropDownContainer,
  PRTextArea,
} from "./FutureFreadomCreationForm.styled";
import Select from "react-select";
import { module as ui } from "@st/ui";
import { module as activity } from "@st/activity";
import { module as freadomFuture } from "@st/freadomFuture";
import { connectStore } from "redux-box";
import swal from "sweetalert2";

const difficultyLevelOption = [
  { value: "8e41872c-ff47-413c-916d-c52a1731f7c3", label: "easy" },
];

class FutureFreadomCreationForm extends Component {
  constructor(props) {
    super();
    this.state = {
      freadomFutureFormData: {
        name: "",
        description: "",
        selectedGrade: {},
        selectedDifficultyLevel: difficultyLevelOption[0],
      },
    };
  }

  componentDidMount() {
    const { activity } = this.props;

    let selectedGrade = activity.grades.filter(
      (ele) => ele.id === this.props.freadomFuture.active_freadom_future_grade
    );
    this.setState({
      freadomFutureFormData: {
        ...this.state.freadomFutureFormData,
        selectedGrade: selectedGrade[0],
      },
    });
  }

  handleFreadomFutureName = (evt) => {
    this.setState({
      freadomFutureFormData: {
        ...this.state.freadomFutureFormData,
        name: evt.target.value,
      },
    });
  };

  handleFreadomFutureDescription = (evt) => {
    this.setState({
      freadomFutureFormData: {
        ...this.state.freadomFutureFormData,
        description: evt.target.value,
      },
    });
  };

  handleDifficultyChange = (selectedOption) => {
    this.setState({
      freadomFutureFormData: {
        ...this.state.freadomFutureFormData,
        selectedDifficultyLevel: selectedOption,
      },
    });
  };

  handleContinueBtn = () => {
    const { freadomFutureFormData } = this.state;
    if (
      freadomFutureFormData.name &&
      freadomFutureFormData.description &&
      Object.keys(freadomFutureFormData.selectedGrade) &&
      Object.keys(freadomFutureFormData.selectedDifficultyLevel)
    ) {
      let unitObject = {
        name: freadomFutureFormData.name,
        description: freadomFutureFormData.description,
        grade_id: freadomFutureFormData.selectedGrade.id,
        difficulty_level_id:
          freadomFutureFormData.selectedDifficultyLevel.value,
      };
      this.props.freadomFuture.createFreadomFutureUnit(unitObject);
      this.props.ui.hideModal();
    } else {
      swal(
        "Oops!",
        "You must enter title and description to create Unit",
        "error"
      );
    }
  };

  render() {
    const { freadomFutureFormData } = this.state;
    return (
      <CreateActivityContainer>
        <ActivityMainFormContainer>
          <form>
            <RCNameInput
              type="text"
              className="form-control"
              autoFocus
              value={
                Object.keys(freadomFutureFormData.selectedGrade).length > 0 &&
                freadomFutureFormData.selectedGrade.name
              }
              disabled={true}
            />
            <RCNameInput
              type="text"
              className="form-control"
              autoFocus
              placeholder="Add Title"
              value={freadomFutureFormData.name}
              onChange={this.handleFreadomFutureName}
            />
            <DropDownContainer>
              <Select
                value={freadomFutureFormData.selectedDifficultyLevel}
                onChange={this.handleDifficultyChange}
                options={difficultyLevelOption}
              />
            </DropDownContainer>
            <div>
              <PRTextArea
                className="form-control"
                placeholder="Add description"
                value={freadomFutureFormData.description}
                onChange={this.handleFreadomFutureDescription}
              />
            </div>
            <ActivityContinueButtonContainer>
              <CreateRCButton
                primary
                className="btn btn-warning"
                type="button"
                onClick={() => this.handleContinueBtn()}
              >
                Create Unit
              </CreateRCButton>
            </ActivityContinueButtonContainer>
          </form>
        </ActivityMainFormContainer>
      </CreateActivityContainer>
    );
  }
}
export default connectStore({
  ui,
  activity,
  freadomFuture,
})(FutureFreadomCreationForm);

import {createActions,using} from 'redux-box'

export default createActions({
    //MISC FOR NEWS FREEDS
    setActiveNewsGradeLevel:using("gradeLevel"),
    setActiveNewsLevel:using("level"),
    setNewsEditorType:using("editorType"),
    setActiveNewsDate:using("date"),
    setSearchFreed:using("search"),
    setEnableNewsEdit:using("value"),

    //CRUD ACTIONS FOR NEWS FREADS
    setActiveNewsFreed:using("activeFreed"),
    setActiveNewsFreedDescription:using("description"),
    requestNewsFreeds:using(),
    requestNewsFreedsByOffset:using("offset"),
    requestCreateNewsFreed:using("data"),
    setSendPushNotification:using("value"),
    requestNewsFreedById:using("newsFreedId"),
    requestUpdateNewsFreed:using("data"),
    requestNewsFreedsByLevel:using("levelData"),

    //CRUD FOR MCQS
    requestGetAllNewsFreedQuestions:using("newsFreedId"),
    requestUpdateNewsFreedQuestion:using("mcq"),
    requestCreateNewsFreedQuestion:using("question"),
    requestDeleteNewsFreedQuestion:using("question"),
    setAllNewsQuestion:using("question"),
    setActiveNewsFreedQuestion:using("question"),

    unsetAllActiveNewsQuestion:using(),

    //CRUD FOR OPTIONS
    addNewNewsOption: using("option"),
    removeNewsNewOption: using("optionId"),





})
import React from 'react'
import { connectStore } from 'redux-box'
import { ThemeButton } from '../../../../static/styled/custom.styled'
import './unassign.css'
import { module as ui } from "@st/ui";
import { module as user } from "@st/user";
import Select from 'react-select'
import { useState } from 'react';
import { useEffect } from 'react';

const UnAssignFreadomTeacher = (props) => {
  const [sections, setSections] = useState([])
  const [assignedSections, setAssignedSections] = useState([])

  const customStyles = {
    menu: (provided, state) => ({
      ...provided,
      position: "relative"
    }),
  };

  useEffect(() => {
    let school_class_list = []
    if (props.user.active_child) {
      props.user.active_child.school_class.forEach((section) => {
        if(section.teacher == props.user.active_child.teacher){
          school_class_list.push(
            {
              value: section.id,
              label: section.name
            }
          )
        }
      })

      setAssignedSections(school_class_list)
    }
  }, [props.user.active_child])

  const handleUnAssignTeacher = () => {
    const data = {
      is_freadom_teacher: false,
      child: props.user.active_child.id,
      school_class: sections.map((sec) => sec.value)
    }
    props.user.updateFreadomTeacher(data)
    props.ui.hideModal()
  }
  return (
    <div className='unlink_school_code_modal'>
      <p>Are you sure you want to remove the teacher as Freadom Teacher?</p>
      {
        props.user.active_child.is_teacher && assignedSections.length > 0 ?
        <Select
          className="dropdown-container"
          isMulti={true}
          value={sections}
          onChange={(selectedSection) =>
            setSections(selectedSection)
          }
          options={assignedSections && assignedSections}
          placeholder="Select Section"
          styles={customStyles}
        />
        :
        <p>No Sections are assigned to teacher as Freadom Teacher. Please assign.</p>
      }
      <div className='unlink_btn_container'>
        <ThemeButton onClick={() => handleUnAssignTeacher()} primary style={{ width: '50%' }} disabled={assignedSections.length==0} >Remove</ThemeButton>
        <ThemeButton onClick={() => props.ui.hideModal()} style={{ width: '50%' }} >Cancel</ThemeButton>
      </div>

    </div>
  )
}

export default connectStore({
  ui, user
})(UnAssignFreadomTeacher)
import React, { Component } from "react";
import {
  AlignContentHorizontal,
  EmptyContainer,
  AutoContainer,
  LoadingContainer,
  InfiniteScrollParentContainer,
  InfiniteScrollLoader,
} from "static/styled/custom.styled";
import {
  NavigationHeaderContainer,
  NavigationContainer,
} from "static/styled/NavigationBar.styled";
import { connectStore } from "redux-box";
import { module as activity } from "@st/activity";
import { module as podcast } from "@st/podcast";
import { Link } from "react-router-dom";
import PodcastCard from "./episode-card/EpisodeCard";
import InfiniteScroll from "react-infinite-scroller";
import { SearchBarContainer } from "@c/users-view/MainContent.styled";


class PodcastList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchBarEnable: false,
    };
  }

  componentDidMount() {
    if(this.props.id !==undefined)
      this.props.podcast.fetchEpisodesByPodcastId(this.props.id);
  }

  fetchMoreEpisodes = () => {
    if (
      this.props.podcast.episodesResult !== null &&
      this.props.podcast.episodesResult.has_next
    ) {
      setTimeout(() => {
        if (
          this.props.podcast.episodesResult !== null &&
          this.props.podcast.episodesResult.next_page > 0
        )
          this.props.podcast.requestConcatEpisodes(
            this.props.podcast.episodesResult.next_page
          );
      }, 100);
    }
  };

  // handleReadingSearch = (evt) => {
  //   let { podcast } = this.props;
  //   podcast.setSearch(evt.target.value);

  //   setTimeout(() => {
  //     this.props.podcast.requestSchoolGuidedPath({type:this.props.type});
  //   }, 2000);
  // };

  handleClearSearch = () => {
    let { schoolGuidedPath } = this.props;
    schoolGuidedPath.setSearch("");
    schoolGuidedPath.requestSchoolGuidedPath({type:this.props.type});
  };

  enableSearchBar = (actionType) => {
    if (actionType === "focus" && this.state.searchBarEnable === false)
      this.setState({
        searchBarEnable: true,
      });
    else if (actionType === "blur" && this.state.searchBarEnable === true)
      this.setState({
        searchBarEnable: false,
      });
  };

  componentDidUpdate(prevProps){
    if (prevProps.id !== this.props.id) {
      this.props.podcast.fetchEpisodesByPodcastId(this.props.id);
  }
}

  render() {


    let podcastEpisodesList = (
      <AlignContentHorizontal marginBottom="70px">
        {this.props.podcast.episodesList &&
        this.props.podcast.episodesList.length > 0 ? (
          this.props.podcast.episodesList.map(
            (episodeInfo, index) => {
              return (
                <div className="col-sm-6 col-md-4 col-lg-3" key={index}>
                  {/* <Link to={`/podcast/episode/edit/${episodeInfo.id}`}> */}
                  <PodcastCard podcastEpisodeInfo={episodeInfo} />
                  {/* </Link> */}
                </div>
              );
            }
          )
        ) : (
          <EmptyContainer>
            <h4>NO EPISODES FOR THIS PODCAST</h4>
          </EmptyContainer>
        )}
      </AlignContentHorizontal>
    );

    return (
      <AutoContainer>
        {/* <NavigationHeaderContainer shadow>
          {/* <SearchBarContainer
            width={this.state.searchBarEnable ? "300px" : "200px"}
          >
            <i className="fa fa-search" />
            <input
              type="text"
              onChange={this.handleReadingSearch}
              placeholder="Search"
              value={this.props.podcast.search_reading}
              onFocus={() => this.enableSearchBar("focus")}
              onBlur={() => this.enableSearchBar("blur")}
            />
            {this.props.podcast.search_reading.length > 0 && (
              <i
                className="fa fa-times cross"
                onClick={() => this.handleClearSearch()}
              />
            )}
          </SearchBarContainer> */}
        {/* </NavigationHeaderContainer> */}
        {this.props.podcast.isPodcastEpisodesLoading ? (
          <LoadingContainer style={{ zIndex: "100" }}>
            <img
              alt="loading"
              width="60"
              height="60"
              src={require("static/img/Theme-loader.gif")}
            />
          </LoadingContainer>
        ) : (this.props.podcast.episodesList && this.props.podcast.episodesList
            .length > 0) ? (
          <InfiniteScrollParentContainer noTop>
            <InfiniteScroll
              pageStart={0}
              loadMore={this.fetchMoreEpisodes}
              style={{ position: "relative" }}
              hasMore={this.props.podcast.episodesResult.has_next}
              loader={
                <InfiniteScrollLoader className="loader" key={0}>
                  <img
                    alt="loader"
                    width="50"
                    height="50"
                    src={require("static/img/Theme-loader.gif")}
                  />
                </InfiniteScrollLoader>
              }
              useWindow={false}
            >
              {podcastEpisodesList}{" "}
            </InfiniteScroll>
          </InfiniteScrollParentContainer>
        ) : (
          <React.Fragment>{podcastEpisodesList}</React.Fragment>
        )}
      </AutoContainer>
    );
  }
}
export default connectStore({
  activity,
  podcast
})(PodcastList);

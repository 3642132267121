import React from "react";
import { Route, Switch } from "react-router-dom";
import LeaderBoardList from "@c/leader-board-view/leader-board-list";
import FreadomPointLeaderBoardList from "@c/leader-board-view/freadom-point-leader-board-list";

export default function(props) {
  return (
    <Switch>
      <Route exact component={LeaderBoardList} path="/leader-board" />
      <Route
        component={FreadomPointLeaderBoardList}
        path="/leader-board/freadom"
      />
    </Switch>
  );
}

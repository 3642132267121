import { Api } from "./base";

const _headers = () => {
  return {
    Authorization:
      "JWT " + require("store").default.getState().onboard.user.auth_token,
    "Content-Type": "application/json",
  };
};

export default {
  //..................................UNIT CRUD.................

  requestPodcastList() {
    return Api.get(`/podcast/v1/admin-podcast/`, _headers());
  },

  updatePodcast(id, data) {
    return Api.put(`/podcast/v1/admin-podcast/${id}/`, data, _headers());
  },

  createPodcast(data) {
    return Api.post(`/podcast/v1/admin-podcast/`, data, _headers());
  },


  getEpisodes(podcastId, offset, search) {
    console.log(podcastId)
    let path = `/podcast/v1/admin-podcast-episode/?podcast_id=${podcastId}`;

    if (search !== "")
      // add search param
      return Api.getCsv(path, _headers());

    return Api.getCsv(`${path}&offset=${offset}`, _headers());
  },

  createEpisode(episodeData) {
    return Api.post(`/podcast/v1/admin-podcast-episode/`, episodeData, _headers());
  },

  updateEpisode(id,episodeUpdateData) {
    return Api.put(
      `/podcast/v1/admin-podcast-episode/${id}/`,
      episodeUpdateData,
      _headers()
    );
  }
};

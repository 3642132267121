import { Api } from "./base";

const _headers = () => {
  return {
    Authorization:
      "JWT " + require("store").default.getState().onboard.user.auth_token,
    "Content-Type": "application/json"
  };
};

export default {
  //.................end points for collection CRUD..........
  getAllCollections(offset=0) {
    return Api.getCsv(`/misc/v1/admin-collection/?offset=${offset}`, _headers());
  },
  getCollectionById(collectionId) {
    return Api.get(`/misc/v1/admin-collection/${collectionId}/`, _headers());
  },
  updateCollection(collectionId, data) {
    return Api.put(
      `/misc/v1/admin-collection/${collectionId}/`,
      data,
      _headers()

    );
  },
  createCollection(data) {
    return Api.post(`/misc/v1/admin-collection/`, data, _headers());
  },

  //..................end points for story CRUD..........
  getAllStories(gradeLevel, offset, search, collections, sort, filter, date, storyType) {
    let filterArr = filter && filter.length > 0 && filter.map((f) => {
      return `&${f.type}=${f.payload}`;
    });
    window.filterArr = filterArr
    let filterQuery = "";
    if (filterArr && filterArr.length > 0) {
      filterQuery = filterArr.join("");
    }
    filterQuery = filterQuery ? filterQuery : "";
    if (search !== "" && collections === "") {
      return Api.getCsv(
        `/story/v1/admin-story/?grade_level=${gradeLevel}&offset=${offset}&key=${search}&date=${date}${filterQuery}&story_type=${storyType}`,
        _headers()
      );
    }
    else if (search === "" && collections !== "") {
      return Api.getCsv(
        `/story/v1/admin-story/?grade_level=${gradeLevel}&offset=${offset}&collection=${collections}&date=${date}${filterQuery}&story_type=${storyType}`,
        _headers()
      );
    }
    else if (search !== "" && collections !== "") {
      return Api.getCsv(
        `/story/v1/admin-story/?grade_level=${gradeLevel}&offset=${offset}&key=${search}&date=${date}${filterQuery}&story_type=${storyType}`,
        _headers()
      );
    }
    else if (sort !== "") {
      return Api.getCsv(
        `/story/v1/admin-story/?grade_level=${gradeLevel}&offset=${offset}&key=${search}&sort=${sort}&date=${date}${filterQuery}&story_type=${storyType}`,
        _headers()
      );
    }
    else if (date !== "") {
      return Api.getCsv(
        `/story/v1/admin-story/?grade_level=${gradeLevel}&offset=${offset}&date=${date}${filterQuery}&story_type=${storyType}`,
        _headers()
      );
    }
    else if (storyType !== "") {
      return Api.getCsv(
        `/story/v1/admin-story/?grade_level=${gradeLevel}&offset=${offset}&date=${date}${filterQuery}&story_type=${storyType}`,
        _headers()
      );
    }
    else if (filter) {
      return Api.getCsv(
        `/story/v1/admin-story/?grade_level=${gradeLevel}&offset=${offset}&key=${search}&collection=${collections}&${filterQuery}`,
        _headers()
        // filterQuery = ${filter.type}=${filter.payload} , top_story(type) = true(payload)
      );
    }
    return Api.getCsv(
      `/story/v1/admin-story/?grade_level=${gradeLevel}&offset=${offset}`,
      _headers()
    );
  },
  createStory(data) {
    return Api.post(`/story/v1/admin-story/`, data, _headers());
  },
  getStoryById(storyId) {
    return Api.get(`/story/v1/admin-story/${storyId}/`, _headers());
  },
  updateStory(storyId, data) {
    return Api.put(`/story/v1/admin-story/${storyId}/`, data, _headers());
  },
  updateStoryCollection(storyId, data) {
    return Api.put(`/story/v1/admin-story/${storyId}/`, data, _headers());
  },

  //..................end point for Story Section CRUD...........
  getStorySectionById(storyId) {
    return Api.get(
      `/story/v1/admin-story-section-data/?story=${storyId}`,
      _headers()
    );
  },
  createStorySection(data) {
    return Api.post(`/story/v1/admin-story-section-data/`, data, _headers());
  },
  updateStorySection(storyId, data) {
    return Api.put(
      `/story/v1/admin-story-section-data/${storyId}/`,
      data,
      _headers()
    );
  },
  deleteStorySection(storyId) {
    return Api.deleteCall(
      `/story/v1/admin-story-section-data/${storyId}/`,
      _headers()
    );
  },
  deleteStory(storyId) {
    return Api.deleteCall(`/story/v1/admin-story/${storyId}/`, _headers());
  },

  //..................end points for Story question CRUD....................
  createStoryQuestion(data) {
    return Api.post(
      `/story-assessment/v1/admin-story-question/`,
      data,
      _headers()
    );
  },
  updateStoryQuestion(storyId, data) {
    return Api.put(
      `/story-assessment/v1/admin-story-question/${storyId}/`,
      data,
      _headers()
    );
  },
  deleteStoryQuestion(questionId) {
    return Api.deleteCall(
      `/story-assessment/v1/admin-story-question/${questionId}/`,
      _headers()
    );
  },
  getStoryQuestionsById(storyId) {
    return Api.get(
      `/story-assessment/v1/admin-story-question/?story=${storyId}`,
      _headers()
    );
  },

  //...................end points for Story Question Option CRUD...............
  updateStoryQuestionOption(optionId, data) {
    return Api.put(
      `/story-assessment/v1/admin-story-option/${optionId}/`,
      data,
      _headers()
    );
  },
  deleteStoryQuestionOption(optionId) {
    return Api.deleteCall(
      `/story-assessment/v1/admin-story-option/${optionId}/`,
      _headers()
    );
  },
  createStoryQuestionOption(data) {
    return Api.post(
      `/story-assessment/v1/admin-story-option/`,
      data,
      _headers()
    );
  },

  //........................end points for story experiences ............/
  getAllStoryExperiences(storyId) {
    return Api.get(`/experience/v1/admin-story/?story=${storyId}`, _headers());
  },

  deleteStoryExperience(expId) {
    return Api.deleteCall(`/experience/v1/admin-story/${expId}/`, _headers());
  },

  updateStoryExperience(experienceId, data) {
    return Api.put(
      `/experience/v1/admin-story/${experienceId}/`,
      data,
      _headers()
    );
  }
};

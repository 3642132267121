import { Api } from "./base";

const _headers = () => {
  return {
    Authorization:
      "JWT " + require("store").default.getState().onboard.user.auth_token,
    "Content-Type": "application/json"
  };
};

export default {
  //...............................Reading challenge CRUD........................
  getReadingChallenge(gradeId, offset, search) {
    if (search !== "")
      return Api.getCsv(
        `/reading/v1/admin-reading/?offset=${offset}&grade=${gradeId}&key=${search}`,
        _headers()
      );

    return Api.getCsv(
      `/reading/v1/admin-reading/?offset=${offset}&grade=${gradeId}`,
      _headers()
    );
  },
  createReadingChallenge(data) {
    return Api.post(`/reading/v1/admin-reading/`, data, _headers());
  },
  updateReadingChallenge(readingId, data) {
    return Api.put(`/reading/v1/admin-reading/${readingId}/`, data, _headers());
  },
  getReadingChallengeById(readingId) {
    return Api.get(`/reading/v1/admin-reading/${readingId}/`, _headers());
  },
  getReadingChallengeByGradeId(gradeId) {
    return Api.get(`/reading/v1/?grade_id=${gradeId}/`, _headers());
  },

  //...............................Section.........................
  getSectionById(readingId) {
    return Api.get(
      `/reading/v1/admin-reading-section-data/?reading=${readingId}/`,
      _headers()
    );
  },
  createSection(data) {
    return Api.post(
      `/reading/v1/admin-reading-section-data/`,
      data,
      _headers()
    );
  },
  deleteSectionById(sectionId) {
    return Api.deleteCall(
      `/reading/v1/admin-reading-section-data/${sectionId}/`,
      _headers()
    );
  },
  updateSection(sectionId, data) {
    return Api.put(
      `/reading/v1/admin-reading-section-data/${sectionId}/`,
      data,
      _headers()
    );
  },

  //....................................Question..........................
  createQuestion(data) {
    return Api.post(
      `/reading-assessment/v1/admin-reading-question/`,
      data,
      _headers()
    );
  },
  updateQuestion(questionId, data) {
    return Api.put(
      `/reading-assessment/v1/admin-reading-question/${questionId}/`,
      data,
      _headers()
    );
  },
  updateOption(optionId, data) {
    return Api.put(
      `/reading-assessment/v1/admin-reading-option/${optionId}/`,
      data,
      _headers()
    );
  },
  deleteQuestion(questionId) {
    return Api.deleteCall(
      `/reading-assessment/v1/admin-reading-question/${questionId}/`,
      _headers()
    );
  },
  getAllQuestions(readingId) {
    return Api.get(
      `/reading-assessment/v1/admin-reading-question/?reading=${readingId}`,
      _headers()
    );
  },

  //..............................Experience.....................
  updateReadingExperience(experienceId, data) {
    return Api.put(
      `/experience/v1/admin-reading/${experienceId}/`,
      data,
      _headers()
    );
  },
  deleteReadingExperience(experienceId) {
    return Api.deleteCall(
      `/experience/v1/admin-reading/${experienceId}/`,
      _headers()
    );
  }
};

import { createSagas } from "redux-box";
import swal from "sweetalert2";
import TagsApi from "@s/api/tags";
import { call, put } from "redux-saga/effects";
const errorHandler = err => {
  console.error(err);
  swal("Oops!", err.error_message, "error");
};
const successHandler = msg => {
  swal("Success!", msg, "success")
}
export default createSagas({
  REQUEST_TAG_LIST: function*() {
    try {
      yield put({
        type: "SET_FETCH_ACTIVITY_PACKS_BY_LEVEL_TAG",
        value: true
      });
      const res = yield call(TagsApi.getTagList,0);
      yield put({
        type: "SET_TAG_RESULT",
        tagResult: res
      });
      yield put({
        type: "SET_TAG_LIST",
        tagList: res.result
      });
    } catch (err) {
      errorHandler(err);
    }finally {
      yield put({
        type: "SET_FETCH_ACTIVITY_PACKS_BY_LEVEL_TAG",
        value: false
      });
    }
  },
  REQUEST_CONCAT_TAG_LIST: function*({offset}) {
    try {
      yield put({
        type: "SET_FETCH_ACTIVITY_PACKS_BY_LEVEL_TAG",
        value: true
      });
      const res = yield call(TagsApi.getTagList,offset);
      yield put({
        type: "SET_TAG_RESULT",
        tagResult: res
      });
      if (res && res.result && res.result.length > 0){
        yield put({
          type: "SET_CONCAT_TAG_LIST",
          taglist: res.result
        });
      }
    } catch (err) {
      errorHandler(err);
    }finally {
      yield put({
        type: "SET_FETCH_ACTIVITY_PACKS_BY_LEVEL_TAG",
        value: false
      });
    }
  },
  REQUEST_CHILD_TAG_LIST_BY_PARENT_ID: function*({ id }) {
    try {
      const res = yield call(TagsApi.getChildTagListByParentId, id);
      yield put({
        type: "SET_CHILD_TAG_LIST",
        childList: res
      });
    } catch (err) {
      errorHandler(err);
    }
  },
  REQUEST_TAG_BY_ID: function*({ id }) {
    try {
      const res = yield call(TagsApi.getTagById, id);
      yield put({
        type: "SET_ACTIVE_TAG",
        tag: res
      });
    } catch (err) {
      errorHandler(err);
    }
  },
  CREATE_CHILD_TAG: function*({ tag }) {
    try {
      const res = yield call(TagsApi.createChildTag, tag);
      yield put({
        type: "REQUEST_CHILD_TAG_LIST_BY_PARENT_ID",
        id: res.parent_tag
      });
      yield call(successHandler);
    } catch (err) {
      errorHandler(err);
    }
  },
  CREATE_PARENT_TAG: function*({ tag }) {
    try {
      yield call(TagsApi.createParentTag, tag);
      yield put({
        type: "REQUEST_TAG_LIST"
      });
    } catch (err) {
      errorHandler(err);
    }
  },
  UPDATE_TAG_BY_ID: function*({ id, tag }) {
    try {
      const res = yield call(TagsApi.updateTagById, id.id, tag);
      if (res) {
        yield put({
          type: "REQUEST_CHILD_TAG_LIST_BY_PARENT_ID",
          id: res.parent_tag === null ? res.id : res.parent_tag
        });
        if (res.parent_tag === null) {
          yield put({
            type: "REQUEST_TAG_BY_ID",
            id: res.id
          });
        }
        yield put({
          type: "SET_ACTIVE_CHILD_TAG",
          tag: {}
        });
        yield call(successHandler);
      }
    } catch (err) {
      errorHandler(err);
    }
  }
});

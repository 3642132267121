import React from "react";
import { Route, Switch } from "react-router-dom";
import ActivitiesList from "@c/activity-view/activities-list";
import ActivityPackList from "@c/activity-view/activity-pack-list";

export default function(props) {
  return (
    <Switch>
      <Route
        path={"/activities/packs/:gradeLevel/:level"}
        render={props => <ActivityPackList {...props} />}
      />
      <Route component={ActivitiesList} path="/activities" />
    </Switch>
  );
}

import React, { Component } from "react";
// import { Pagination, PaginationButton } from "@c/users-view/MainContent.styled";
import {
  ScrollContainer,
  ScrollParentContainer,
  Container,
  SelectTagsListContainer,
  TagLabelContainer
} from "../TagSelector.styled";
import { TagChips } from "@sc/custom.styled";
import { TagBox } from "@sc/McqEditor.styled";
import { connectStore } from "redux-box";
import { module as tags } from "store/tags";
import { module as activity } from "store/activity";
import { module as story } from "store/story";

// export default connectStore({
//   tags,
//   activity,
//   story
// })

class ChildTagSelector extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tags: [],
      tagPill: []
    };
  }
  // componentDidMount() {
  //   let { activity } = this.props;
  //   let { story } = this.props;
  // }

  handleNext = () => {
    // let { leaderBoard } = this.props;
    // if (leaderBoard.freadomPointLeaderBoardList.has_next) {
    //   leaderBoard.requestFreadomPointLeaderBoardList(
    //     leaderBoard.freadomPointLeaderBoardList.next_page
    //   );
    // }
  };
  handlePrevious = () => {
    // let { leaderBoard } = this.props;
    // if (
    //   leaderBoard.freadomPointLeaderBoardList.prev_page >= 0 &&
    //   leaderBoard.freadomPointLeaderBoardList.offset !== 0
    // ) {
    //   leaderBoard.requestfreadomPointLeaderBoardList(
    //     leaderBoard.freadomPointLeaderBoardList.prev_page
    //   );
    // }
  };

  normalizeForState = tag => {
    const tags = tag.map(data => data.id);
    const tagPill = tag;
    this.setState({
      tags,
      tagPill
    });
  };

  handleTagClick = data => {
    this.props.tags.addTagInSelectedTagList(data);
  };

  handleTagRemoveClick = data => {
    if (data.parent_tag !== null) {
      this.props.tags.removeTagFromSelectedTagList(data);
    } else {
      this.props.tags.removeTagFromSelectedTagList(data);
      this.props.tags.removeChildTagFromSelectedTagList(data);
    }
  };
  render() {
    let { childTagList } = this.props.tags;
    return (
      <Container>
        <ScrollContainer>
          <ScrollParentContainer>
            {childTagList.result &&
              childTagList.result.length > 0 && (
                <SelectTagsListContainer>
                  {childTagList.result.map((tag, i) => (
                    <TagLabelContainer
                      key={i}
                      onClick={() => this.handleTagClick(tag)}
                    >
                      <div>{tag.name}</div>
                    </TagLabelContainer>
                  ))}
                </SelectTagsListContainer>
              )}

            <TagBox>
              {this.props.tags.selectedTagList.length > 0 && (
                <SelectTagsListContainer>
                  {this.props.tags.selectedTagList.map((data, id) => (
                    <TagChips
                      key={id}
                      onClick={() => this.handleTagRemoveClick(data)}
                    >
                      {data.name}
                      <img
                        className="image-fluid"
                        src={require("static/img/tagCloseIcon.png")}
                        alt="Edit icon"
                      />
                    </TagChips>
                  ))}
                </SelectTagsListContainer>
              )}
            </TagBox>
          </ScrollParentContainer>
        </ScrollContainer>
        {/* <Pagination>
          <PaginationButton
            color={childTagList.offset !== 0 ? "#ff8a23" : "#d5d5d5"}
            onClick={this.handlePrevious}
          >
            <i className="fa fa-chevron-left" />
          </PaginationButton>
          <PaginationButton
            color={
              childTagList && childTagList.has_next ? "#ff8a23" : "#d5d5d5"
            }
            onClick={this.handleNext}
          >
            <i className="fa fa-chevron-right" />
          </PaginationButton>
        </Pagination> */}
      </Container>
    );
  }
}
export default connectStore({
  tags,
  activity,
  story
 })(ChildTagSelector)
import React, { Component } from "react";
import {
  ActivityPackCardContainer,
  ActivityPackContainer,
  ActivityPackTitle,
  ActivitySubHeading,
} from "./ActivityPackCard.styled";
import {CardIcon,CardIconContainer,Chips,CountOverlayContainer,CountItem} from "@sc/custom.styled";
import Permission from "@s/permission";

export default class ActivityPackCard extends Component {
  render() {
    let activityPack = this.props.activityPack;
    let permission = this.props.permission;
    return (
      <ActivityPackCardContainer image={activityPack.image}>
        <ActivityPackContainer >
            <CardIconContainer>
                <Chips>{activityPack.activity_count} Activities</Chips>
                {Permission.getPermission(permission, "change_activity")? 
                <CardIcon primary onClick={this.props.handlePublishClick} bg={activityPack.is_published}>
                    <i className="fa fa-check"/>
                </CardIcon>:null}
                {Permission.getPermission(permission, "delete_activity")? 
                  <CardIcon onClick={this.props.handleDeleteClick} >
                      <i className="fa fa-trash"/>
                  </CardIcon>
                :null}
            </CardIconContainer>
          <ActivityPackTitle>{activityPack.name}</ActivityPackTitle>
          <ActivitySubHeading>{activityPack.description}</ActivitySubHeading>
        </ActivityPackContainer>
        <CountOverlayContainer style={{width:'45px',height:'30px'}}>
                        <CountItem >
                            <i className="fa fa-shopping-cart"/>
                            <span>{activityPack.purchase_count}</span>
                        </CountItem>
                    </CountOverlayContainer>
      </ActivityPackCardContainer>
    );
  }
}

import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  NavigationHeaderContainer,
  ToggleButton,
  ToggleHandler,
  ToggleStatus,
  UserLoadingContainer,
  ProfileImageContainer,
  ImageContainer,
} from "components/users-view/MainContent.styled";
import {
  ArrowLeft,
  CameraIconContainer,
  EditButton,
  LastLoginLabel,
  ParentLabelMainContainer,
  ParentNameContainer,
  ParentProfileHeaderLeft,
  ToggleButtonMainContainer,
  UserName,
} from "components/users-view/parent-profile/ParentProfile.styled";
import { module as user } from "@st/user";
import { connectStore } from "redux-box";
import Permission from "@s/permission";
import { module as onboard } from "@st/onboard";
// export default connectStore({
//   user,
// })

// export default 
class ParentProfileHeader extends Component {
  constructor() {
    super();
    this.state = {
      lastLoginDate: "",
      setUsername: true,
      parent_info: {
        name: "",
        id: "",
        image: "",
      },
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.user && nextProps.user.active_parent) {
      this.normalizeForState(nextProps.user.active_parent);
    } else {
      this.setState({
        parent_info: {
          ...this.state.parent_info,
          id: "",
          name: "",
          image: "" || null,
        },
      });
      console.log("parent_info", this.state.parent_info);
    }
  }

  normalizeForState = (parentInfo) => {
    let months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    let date = new Date(parentInfo.modified_at);
    let lastLoginDate =
      date.getDate() + " " + months[date.getMonth()] + " " + date.getFullYear();
    this.setState({
      lastLoginDate: lastLoginDate,
      parent_info: {
        ...this.state.parent_info,

        name: parentInfo.name,
        id: parentInfo.id,
        image: parentInfo.image,
      },
    });
  };

  enableSetUserName = (e) => {
    this.setState({
      setUsername: !this.state.setUsername,
    });
    console.log("setUsername", this.state.setUsername);

    document.getElementById("username").removeAttribute("disabled");
    document.getElementById("username").focus();
  };

  handleNameChange = (evt) => {
    // debugger;
    if (evt.key === "Enter") {
      // debugger;
      this.setState({
        setUsername: !this.state.setUsername,
      });
      if (this.props.user.active_parent) {
        this.props.user.requestUpdateParentProfile(this.state.parent_info);
      }
    }
  };

  handleToggle = (e) => {
    const {permission} = this.props.onboard
    if(Permission.getPermission(permission, "change_user")){
      if (this.props.user && this.props.user.active_parent) {
        this.props.user.requestUpdateParentProfile({
          id: this.props.user.active_parent.id,
          is_active: !this.props.user.active_parent.is_active,
        });
      }
    }
    
  };

  changeUserName = (e) => {
    this.setState({
      parent_info: { ...this.state.parent_info, name: e.target.value },
    });
  };

  render() {
    let state = this.state;
    console.log("state", state);
    const {permission} = this.props.onboard
    let { user } = this.props;
    console.log("user", user);

    return (
      <NavigationHeaderContainer>
        {this.props.user.active_parent ? (
          <ParentProfileHeaderLeft>
            <Link to={"/users/parents"}>
              <ArrowLeft
                src={require("static/img/arrow_left.png")}
                alt="arrow left"
                onClick={() => this.props.user.setActiveParent(null)}
              />
            </Link>
            {!state.parent_info.image || state.parent_info.image === "" ? (
              <ProfileImageContainer>
                <CameraIconContainer>
                  <img
                    src={require("static/img/camera_icon.png")}
                    alt="camera icon"
                  />
                </CameraIconContainer>
                {/*<FileSelectBtn*/}
                {/*type="file"*/}
                {/*onChange={e => this._handleImageChange(e)}/>*/}
              </ProfileImageContainer>
            ) : (
              <ProfileImageContainer>
                <ImageContainer
                  src={
                    state.parent_info.image
                      ? state.parent_info.image
                      : require("static/img/activity-bg.png")
                  }
                ></ImageContainer>
                {/*<FileSelectBtn*/}
                {/*type="file"*/}
                {/*onChange={e => this._handleImageChange(e)}/>*/}
              </ProfileImageContainer>
            )}
            <ParentLabelMainContainer>
              <ParentNameContainer>
                <UserName
                  width={(state.parent_info.name.length + 1) * 12 + "px"}
                  id="username"
                  type="text"
                  placeholder="Enter name"
                  value={state.parent_info.name}
                  disabled={state.setUsername}
                  autoFocus
                  onChange={this.changeUserName}
                  onKeyDown={this.handleNameChange}
                ></UserName>
                {Permission.getPermission(permission, "change_user")? 
                  <EditButton
                    src={require("static/img/editicon.png")}
                    onClick={this.enableSetUserName}
                  />:null}
              </ParentNameContainer>
              <LastLoginLabel>Last login: {state.lastLoginDate}</LastLoginLabel>
            </ParentLabelMainContainer>
          </ParentProfileHeaderLeft>
        ) : null}

        {user.active_parent === null ? (
          <UserLoadingContainer>
            {" "}
            <div>
              <img
                alt="loading"
                width="40"
                height="40"
                src={require("static/img/Theme-loader.gif")}
              />
            </div>
          </UserLoadingContainer>
        ) : null}

        {user.active_parent ? (
          <ToggleButtonMainContainer>
            {user.active_parent.is_active ? (
              <ToggleStatus primary onClick={this.handleToggle}>
                {" "}
                Disable{" "}
              </ToggleStatus>
            ) : (
              <ToggleStatus onClick={this.handleToggle}>
                {" "}
                Disabled{" "}
              </ToggleStatus>
            )}
            <ToggleButton
              color={user.active_parent.is_active ? "#ff8f26" : "#d5d5d5"}
            >
              {!user.active_parent.is_active ? (
                <ToggleHandler primary onClick={this.handleToggle} />
              ) : (
                <ToggleHandler onClick={this.handleToggle} />
              )}
            </ToggleButton>
            {user.active_parent.is_active ? (
              <ToggleStatus onClick={this.handleToggle}> Enabled </ToggleStatus>
            ) : (
              <ToggleStatus primary onClick={this.handleToggle}>
                {" "}
                Enable{" "}
              </ToggleStatus>
            )}
          </ToggleButtonMainContainer>
        ) : null}
      </NavigationHeaderContainer>
    );
  }
}
export default connectStore({
  user,onboard
 })(ParentProfileHeader)
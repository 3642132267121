import { createActions, using } from "redux-box";

export default createActions({
  requestTagList: using(),
  createChildTag: using("tag"),
  createParentTag: using("tag"),
  updateTagById: using("id, tag"),
  setActiveTag: using("tag"),
  setActiveChildTag: using("tag"),
  requestChildTagListByParentId: using("id"),
  requestTagById: using("id"),
  setSelectedTagList: using("data"),
  removeTagFromSelectedTagList: using("data"),
  removeChildTagFromSelectedTagList:using("data"),
  addTagInSelectedTagList:using("data"),
  requestConcatTagList: using("offset")
});

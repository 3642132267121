import React, { Component } from "react";
import PodcastRouter from "@r/podcastRouter";
import { PodcastContainer } from "./PodcastEpisodesView.styled";
import PodcastList from "./podcast-list/PodcastList";
import { PlusButton, PlusIcon } from "@sc/custom.styled";
import { connectStore } from "redux-box";
import { module as ui } from "@st/ui";
import { module as activity } from "@st/activity";
import { module as schoolGuidedPath } from "@st/schoolGuidedPath";
import { module as onboard } from "@st/onboard";
import Permission from "@s/permission";
import swal from "sweetalert2";

class PodcastEpisodesView extends Component {
  constructor(props) {
    super(props);
    const { id } = this.props.match.params;
    this.state = { id: id };
  }

  componentWillMount() {
    // this.props.podcast.setFetchSchoolGuidedPathList(true);
  }

  // componentWillUnmount() {
  //   this.props.schoolGuidedPath.setSchoolGuidedPathList([]);
  // }

  handleAddClickButton = (e) => {
    
    const { permission } = this.props.onboard;

    if (Permission.getPermission(permission, "add_reading")) {
      this.props.ui.showModal("PODCASTEPISODEFORM");
    } else {
      swal({
        text: "You do not have permission.",
        type: "warning",
      });
    }
  };

  render() {
    const isCreatorOpen =
      this.props.location.pathname.includes("/podcast/edit") ||
      this.props.location.pathname.includes("/podcast/edit");

    return (
      <PodcastContainer primary>
        <PodcastRouter />
        <PodcastList
          id={this.props.match.params.id}
          location={this.props.location}
        />

        <PlusButton
          // hide={this.props.ui.show_modal}
          onClick={this.handleAddClickButton}
        >
          <PlusIcon
            primary
            alt="Plus Icon"
            // cross={isCreatorOpen}
            src={require("static/img/plusicon.png")}
          />
        </PlusButton>
      </PodcastContainer>
    );
  }
}
export default connectStore({
  ui,
  activity,
  schoolGuidedPath,
  onboard,
})(PodcastEpisodesView);

import React, { Component } from "react";
import {
  ActivityContinueButtonContainer,
  ActivityMainFormContainer,
  CreateActivityContainer,
  ActivityUploadImageButton,
  SelectFileIn,
  ImageContainer,
  ChangeImageButton,
  UploadActivityImageContainer,
} from "@c/activity-view/activity-creation-form/ActivityCreationForm.styled";
import {
  RCNameInput,
  PRTextArea,
} from "../../school-guided-path-view/school-guided-path-creation-form/SchoolGuidedPathCreationForm.styled";
import {
  ThemeButton,
  UploadImagesParentContainer,
  ImageLoader,
} from "@sc/custom.styled";
import { uploadFile } from "@s/api/file";
import { module as ui } from "@st/ui";
import { module as podcast } from "@st/podcast";
import { connectStore } from "redux-box";
import swal from "sweetalert2";

class PodcastEditForm extends Component {
  constructor(props) {
    super();
    this.state = {
      updatedPodcast: {
        name: "",
        description: "",
        thumbnail: "",
        author_name:"",
        feed_url:"",
        is_published:true
      },
      imageChange: false,
    };
  }

  componentDidMount() {
    const { podcast } = this.props;

    if (!this.props.isCreateForm) {
      this.setState({
        updatedPodcast: podcast.edit_podcast,
      });
    }
  }

  componentWillUnmount() {
    this.props.podcast.setEditPodcast(null);
  }

  handleName = (evt) => {
    this.setState({
      updatedPodcast: {
        ...this.state.updatedPodcast,
        name: evt.target.value,
      },
    });
  };

  handleDescription = (evt) => {
    this.setState({
      updatedPodcast: {
        ...this.state.updatedPodcast,
        description: evt.target.value,
      },
    });
  };

  handleAuthorName = (evt) => {
    this.setState({
      updatedPodcast: {
        ...this.state.updatedPodcast,
        author_name: evt.target.value,
      },
    });
  };

  handleFeedURL = (evt) => {
    this.setState({
      updatedPodcast: {
        ...this.state.updatedPodcast,
        feed_url: evt.target.value,
      },
    });
  };



  handleImageFocus = (actionType) => {
    if (actionType === "over" && this.state.imageChange === false)
      this.setState({
        imageChange: true,
      });
    else if (actionType === "out" && this.state.imageChange === true)
      this.setState({
        imageChange: false,
      });
  };

  _handleImageChange = (e) => {
    // e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];

    let fileSize = e.target.files[0].size / 1024 / 1024;

    if (fileSize > 2) {
      swal("Oops!", "File size greater than 2Mb ", "warning");
    } else {
      reader.onloadend = () => {
        this.setState({
          imageChange: false,
          enableLoad: true,
          updatedPodcast: {
            ...this.state.updatedPodcast,
            thumbnail: reader.result,
          },
        });
      };
      reader.readAsDataURL(file);
      uploadFile(file).then((res) => {
        this.setState({
          updatedPodcast: {
            ...this.state.updatedPodcast,
            thumbnail: res.file,
          },
          enableLoad: false,
        });
      });
    }
  };

  handleSubmit = async () => {
    const { updatedPodcast } = this.state;

    if (updatedPodcast.name === "") {
      return swal("Oops", "Name cannot be Blank", "error");
    }
    if (updatedPodcast.description === "") {
      return swal("Oops", "Description cannot be Blank", "error");
    }
    if (updatedPodcast.author_name === "") {
      return swal("Oops", "Author Name cannot be Blank", "error");
    }
    // if (updatedPodcast.feed_url === "") {
    //   return swal("Oops", "Feed URL cannot be Blank", "error");
    // }

    if (
      this.state.updatedPodcast.thumbnail === "undefined" ||
      this.state.updatedPodcast.thumbnail === "" ||
      this.state.updatedPodcast.thumbnail === null
    ) {
      return swal("Oops", "Thumbnail is required.", "error");
    }
    if (this.props.isCreateForm) {
      await this.props.podcast.createPodcast({ ...updatedPodcast });
      this.props.ui.hideModal()
    } else {
      await this.props.podcast.updatePodcastList(updatedPodcast);
      this.props.ui.hideModal()
    }

  };

  render() {
    const { updatedPodcast } = this.state;
    return (
      <CreateActivityContainer>
        <UploadImagesParentContainer>
          {!this.state.updatedPodcast.thumbnail ? (
            <UploadActivityImageContainer height="120px" marginBottom="14px">
              <ActivityUploadImageButton type="button">
                <div>
                  <img
                    src={require("static/img/cloud-upload-icon.png")}
                    alt="cloud-upload"
                  />
                </div>
                <span>Upload Image </span>
              </ActivityUploadImageButton>

              <SelectFileIn
                type="file"
                onChange={(e) => this._handleImageChange(e)}

              />
            </UploadActivityImageContainer>
          ) : this.state.enableLoad ? (
            <UploadActivityImageContainer height="120px" marginBottom="14px">
              <ImageLoader>
                {" "}
                <img
                  alt="loading"
                  width="30"
                  height="30"
                  src="http://photokonkurs.russia.travel/images/icons/preloader.gif"
                />
              </ImageLoader>
            </UploadActivityImageContainer>
          ) : (
            <UploadActivityImageContainer
              height="120px"
              marginBottom="14px"
              onMouseOver={() => this.handleImageFocus("over")}
              onMouseOut={() => this.handleImageFocus("out")}
            >
              <ImageContainer src={this.state.updatedPodcast.thumbnail} />
              <ChangeImageButton
                top={this.state.imageChange ? "83%" : "100%"}
                opacity={this.state.imageChange ? 1 : 0}
              >
                Change Image
              </ChangeImageButton>
              <SelectFileIn
                type="file"
                onChange={(e) => this._handleImageChange(e)}
              />
            </UploadActivityImageContainer>
          )}
        </UploadImagesParentContainer>
        <ActivityMainFormContainer>
          <form>
            <RCNameInput
              type="text"
              className="form-control"
              autoFocus
              placeholder="Edit Podcast Title"
              value={updatedPodcast.name}
              onChange={this.handleName}
            />
            <div>
              <PRTextArea
                className="form-control"
                placeholder="Edit Podcast Description"
                value={updatedPodcast.description}
                onChange={this.handleDescription}
              />
            </div>

            <RCNameInput
              type="text"
              className="form-control"
              autoFocus
              placeholder="Edit Podcast Author Name"
              value={updatedPodcast.author_name}
              onChange={this.handleAuthorName}
            />
            <RCNameInput
              type="text"
              className="form-control"
              autoFocus
              placeholder="Edit Podcast Feed URL"
              value={updatedPodcast.feed_url}
              onChange={this.handleFeedURL}
            />
            <ActivityContinueButtonContainer>
              {this.props.isCreateForm ? (
                <ThemeButton onClick={this.handleSubmit} primary type="button">
                  {" "}
                  Add Podcast{" "}
                </ThemeButton>
              ) : (
                <ThemeButton onClick={this.handleSubmit} primary type="button">
                  {" "}
                  Update Podcast{" "}
                </ThemeButton>
              )}
            </ActivityContinueButtonContainer>
          </form>
        </ActivityMainFormContainer>
      </CreateActivityContainer>
    );
  }
}
export default connectStore({
  ui,
  podcast,
})(PodcastEditForm);

import React from "react";
import { Route, Switch } from "react-router-dom";
import ParentsList from "@c/users-view/users-container/parents-list/ParentsList";
import ChildrenList from "@c/users-view/users-container/children-list/ChildrenList";
import RegisterUserForm from "@c/users-view/users-container/user_creater_form/UserCreaterForm";

export default function(props) {
  return (
    <Switch>
      <Route component={ChildrenList} path="/users/children" />
      <Route component={RegisterUserForm} path={"/users/register"} />
      <Route exact component={ParentsList} path={"/users/parents" | "/users"} />
    </Switch>
  );
}

import React, { Component } from 'react';
import { module as payment } from '@st/payment';
import { connectStore } from "redux-box";
import {SectionCard} from "../PaymentsLink.styled"



 class FetchChild extends Component {
    constructor(props) {
      super();
      this.state = {
        phoneNumber: ""
      };
    }

    componentDidMount() {
        let { payment } = this.props;
        (payment.phoneNumber!=="") && this.setState({phoneNumber: payment.phoneNumber})
    }

    handleInputChange =(e) => this.setState({phoneNumber: e.target.value}) 

    fetchChild= () => {
        const phoneNumber= this.state.phoneNumber;
        if(phoneNumber.length===0 || phoneNumber.length>10) return this.props.hasErr("Invalid Phone Number");
        
        this.props.fetchChild(phoneNumber);
    }

    render() {
        return (
            <SectionCard>
                <h6 className="text-muted"><b>STEP 1: Fetch Users</b> </h6>
                <div className="input-group" style={{textAlign: 'center',justifyContent: 'center'}}>
                    <div className="input-group-prepend">
                        <span className="input-group-text bg-light" id="basic-addon1">
                            <i className="fa fa-phone" aria-hidden="true"></i>
                        </span>
                    </div>
                    <input type="number" className="form-control" 
                    placeholder="Enter Contact Number Here" style={{width: '300px', flex: 'inherit'}}
                    value={this.state.phoneNumber}
                    onChange={this.handleInputChange} />
                </div>
                <div className="text-center mt-4">
                    <button type="button" className="btn btn-success btn-sm" onClick={this.fetchChild}>
                        Fetch Child
                        <i className="fa fa-angle-down ml-2"/>
                    </button>
                </div>
            </SectionCard>
        )
    }
}
export default connectStore({
    payment
})(FetchChild)
import { Api } from "./base";

const _headers = () => {
  return {
    Authorization:
      "JWT " + require("store").default.getState().onboard.user.auth_token,
    "Content-Type": "application/json"
  };
};

export default {
  requestNewsFreeds(offset, date, activeLevel, search) {
    if (search === "")
      return Api.getCsv(
        `/news-fread/v1/admin-news-fread/?offset=${offset}&date=${date}&grade_level=${activeLevel}`,
        _headers()
      );

    return Api.getCsv(
      `/news-fread/v1/admin-news-fread/?offset=${offset}&date=${date}&grade_level=${activeLevel}&key=${search}`,
      _headers()
    );
  },
  requestNewsFreedById(newsFreedId) {
    return Api.get(
      `/news-fread/v1/admin-news-fread/${newsFreedId}/`,
      _headers()
    );
  },
  updateNewsFreedById(newsFreedId, data) {
    return Api.put(
      `/news-fread/v1/admin-news-fread/${newsFreedId}/`,
      data,
      _headers()
    );
  },
  createNewsFreed(data) {
    return Api.post(`/news-fread/v1/admin-news-fread/`, data, _headers());
  },

  //........................................MCQ..................................

  createQuestion(data) {
    return Api.post(
      `/news-fread-assessment/v1/admin-news-fread-question/`,
      data,
      _headers()
    );
  },
  updateQuestion(questionId, data) {
    return Api.put(
      `/news-fread-assessment/v1/admin-news-fread-question/${questionId}/`,
      data,
      _headers()
    );
  },
  updateOption(optionId, data) {
    return Api.put(
      `/news-fread-assessment/v1/admin-news-fread-option/${optionId}/`,
      data,
      _headers()
    );
  },
  deleteQuestion(questionId) {
    return Api.deleteCall(
      `/news-fread-assessment/v1/admin-news-fread-question/${questionId}`,
      _headers()
    );
  },
  getAllQuestion(newsFreedId) {
    return Api.get(
      `/news-fread-assessment/v1/admin-news-fread-question/?news_fread=${newsFreedId}`,
      _headers()
    );
  }
};

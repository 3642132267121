import React, {Component} from 'react';
import {FlatCard,FlexEnd,BottomDetail,IconContainer,FlexCenter,FlatBackdrop} from "static/styled/custom.styled";
import {module as story} from "@st/story";
import {connectStore} from "redux-box";
import swal from "sweetalert2";
import {ExperienceListContainer} from "@c/activity-view/activity-creator/experiences-editor/experiences-list/ExperiencesList.styled";



 class StoryExperienceList extends Component
{
    constructor(props){
        super();
        this.state = {
            is_published:false,
            is_deleted:false,
            fileName:'',
        }
    }

    handleDownload=(evt)=>{
        evt.stopPropagation();
        //evt.preventDefault();
        // this.setState({
        //     fileName:this.getFileName(experience)
        // })
        //evt.preventDefault();
    };
    handleDelete(e,experienceId){
        swal({
            text: "Are you sure that you want to delete this experience?",
            type: "warning",
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No'

        }).then((result) => {
            if (result.value) {
            this.props.story.requestDeleteStoryExperience(experienceId);
            }
        });
        e.stopPropagation();
    };

    handlePublish(e,experience){
        let value=null;
        if(experience.is_published)
            value="Unpublish";
        else
            value="Publish";

        swal({
            text: "Are you sure that you want to "+ value + "  this experience?",
            type: "warning",
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No'

        }).then((result) => {
            if (result.value) {
            this.props.story.requestPublishStoryExperience({...experience,show:false});
            }
        });
        e.stopPropagation();
    };


    getFileName=(experience)=> {
        let fileName=experience.media[0].media_url.toString();
        let fileExt=fileName.split(".");
        console.log(fileExt[fileExt.length-1]);
        return ("exp."+ fileExt[fileExt.length-1]).toString();
    };

    handleDownLoadError=(evt)=> {
        evt.stopPropagation();
        swal("Sorry!", "No media attached to this experience", "warning");
    };

    render(){
        let {active_story} = this.props.story;
            // let experience=[{
            //
            // }];
            let experienceList=active_story && active_story.experience.map((experience,index)=>

            <FlatCard
                    key={index}
                    bg={experience.content_type === 'image' && experience.media.length && experience.media[0].thumbnail_url}
                    onClick={()=>this.props.story.setActiveStoryExperience(experience)}>
                {experience.content_type!=='image' ?
                <FlatBackdrop bg={experience.media.length && experience.media[0].thumbnail_url} />:
                <FlatBackdrop bg={experience.media.length && experience.media[0].media_url} /> }
                <FlexEnd>
                    <IconContainer size="20px" onClick={(e)=>this.handlePublish(e,experience)}>
                        <i className="fa fa-check-circle" style={{color:experience.is_published ? '#61bd4f': '#fff'}}/>
                    </IconContainer>
                    {experience.media.length>0?
                        <IconContainer size="20px">
                            <a href={experience.media[0].media_url} target="_blank" onClick={(evt)=>this.handleDownload(evt,experience)} download> <i className="fa fa-cloud-download" style={{color:'#fff'}}/>
                            </a>
                        </IconContainer>:<IconContainer size="20px">
                            <i className="fa fa-cloud-download" style={{color:'#fff'}} onClick={this.handleDownLoadError}/> </IconContainer>}
                    <IconContainer size="20px" onClick={(e)=>this.handleDelete(e,experience.id)}>
                        <i className="fa fa-trash" style={{color:'#fff'}}/>
                    </IconContainer>
                </FlexEnd>
                {experience.content_type === 'video' &&
                    <FlexCenter><i className="fa fa-play-circle" style={{color:'#fff',fontSize:'28px'}} />
                    </FlexCenter> }
                <BottomDetail active={experience.text === ''}>
                    {experience.child_image ? <img src={experience.child_image} alt='user icon'/>:
                        <img src={require('static/img/user.png')} alt='user icon'/>}
                    <div className='description'>
                        <p>{experience.child_name}</p>
                        <div>{experience.text}</div>
                    </div>
                </BottomDetail>
            </FlatCard>);

        return(

            <ExperienceListContainer>
                {experienceList && experienceList.length>0?experienceList:<div style={{height:"calc(100vh - 300px)",
                    display:"flex",justifyContent:"center",
                    alignItems:"center",
                    padding:"10px 0px",
                    fontSize:'18px',
                    color:"rgba(0,0,0,0.4)"}}>
                    No experiences added to this reading challenge
                </div>}
            </ExperienceListContainer>
        )
    }
    
}
export default connectStore({
    story
  })(StoryExperienceList)
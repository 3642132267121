import { Api } from "./base";

const _headers = () => {
  return {
    Authorization:
      "JWT " + require("store").default.getState().onboard.user.auth_token,
    "Content-Type": "application/json"
  };
};

export default {
  requestPlanInfo() {
    return Api.get("/payment/v1/admin-plan/", _headers());
  },
  requestPayments(search, offset=0, status, date) { 
    if (search === "")
      return Api.getCsv(
        `/payment/v1/admin-payment/?status=${status}&offset=${offset}&date=${date}`,
        _headers()
      );

    return Api.getCsv(
      `/payment/v1/admin-payment/?status=${status}&offset=${offset}&key=${search}&date=${date}`,
      _headers()
    );
  },
  getAllSubscriptions(childId, offset) {
    return Api.getCsv(
      `/payment/v1/admin-subscription/?child_id=${childId}&offset=${offset}`,
      _headers()
    );
  },

  getAllChildPayments(subId, offset) {
    return Api.getCsv(
      `/payment/v1/admin-payment/?subscription_id=${subId}&offset=${offset}`,
      _headers()
    );
  },

  // FOR GENERATING PAYMENT LINKS
  getChildListForPaymentLink(phoneNumber) {
    return Api.get(`/misc/v1/children-by-contact/?contact_no=${phoneNumber}`, _headers());
  },

  // API CALL TO INTERNAL DASHBOARD FREADOM: different from admin panel
  getAllWebPlans() { // For getting all plans
    return Api.externalGet(`/active-pricing-plans`);
  },

  validatePromoCode(data) {
    return Api.post("/misc/v1/promo-code/",data, _headers());
  },

  generatePaymentLink(data) { 
    return Api.post("/payment/v1/admin-paylink/",data, _headers());
  }
};

import styled from "styled-components";

export const UploadActivityImageContainer = styled.div`
  width: 140px;
  height: ${props=>props.height?props.height:'170px'};
  border-radius: 10px;
  border: dashed 1px rgba(151, 151, 151, 0.44);
  background-color: #ffffff;
  cursor: pointer;
  outline: none;
  display: flex;
  justify-content: space-around;
  position: relative;
  margin-bottom:${props=>props.marginBottom?props.marginBottom:'0px'};
`;

export const CreateActivityContainer = styled.div`
  width: 550px;
  padding: 30px;
  border-radius: 8px;
  display: -ms-flexbox;
  height:${props=>props.primary?'240px':'auto'};
  display: flex;
  flex-flow: ${props=>props.primary?'column nowrap':'row nowrap'};
  overflow: ${props=>props.primary?'hidden':'auto'};
`;

export const ActivityUploadImageButton = styled.button`
  border: none;
  background: rgba(67, 38, 102,0.1);
  position:relative;
  width:100%;
  height:100%;
  padding:0px;
  border-radius:10px;
  img {
    width: 40px;
    max-width: 100%;
    background-repeat: no-repeat;
    height: 32px;
    
  }
  
  

  span {
  position:absolute;
  bottom:10px;
  width:100%;
  display:flex;
  align-items:center;
  justify-content:center;
    font-size: var(--sm-font-size);
    letter-spacing: 0.1px;
    text-align: center;
    color: #432666;
  }
`;

export const ActivityMainFormContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  margin-left: 14px;
  width: 70%;
`;

export const SelectFileIn = styled.input`
  position: absolute;
  top: ${props => (props.primary ? "83%" : "0")};
  width: 140px;
  height: 100%;
  overflow:hidden;
  left: 0;
  outline: none;
  opacity: 0;
  cursor: pointer  !important;
`;

export const ActivityInputField = styled.input`
        font-size:${props =>
          props.primary ? "var(--lg-font-size)" : "var(--lg-font-size)"};
    
    font-weight: 500;
    padding:${props => (props.primary ? "6px" : "0px")};
    padding-left:${props=>props.left?props.left:'6px'};
    letter-spacing: 0.1px;
    text-align: left;
    background-color:white;
    width:${props => (props.width ? props.width : "100%")};
    height:${props => (props.primary ? "34px" : "30px")};
    color:rgb(0,0,0);
    border:${props => (props.border ? props.border : "none")};
    border-radius:${props => (props.width ? "10px" : "0px")};
    outline: none;
    margin-top:6px;
    ::placeholder {
    color:${props =>
      props.primary ? "rgba(38,54,81,0.8)" : "rgba(0,0,0,0.2)"};
    font-size:${props => (props.primary ? "14px" : "18px")};
    };
    }
`;

export const ActivityContinueButtonContainer = styled.div`
  margin-top: 10px;
`;

export const ActivityDescription = styled.textarea`
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.1px;
  text-align: left;
  width: 100%;
  color: rgb(0, 0, 0);
  border: none;
  outline: none;
  resize: none;
  margin-top: 4px;
  ::placeholder {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.4);
  }
`;

export const ImageContainer = styled.img`
      height: auto;
    width: 100%;
    max-width: 100%;
    object-fit: cover;
    object-position: center;
  cursor: pointer;
    border-radius:10px;

`;

export const ChangeImageButton = styled.button`
  width: 100%;
  position: absolute;
  top: 82%;
  padding: 5px 0px;
  border: none;
  font-size: var(--xsm-font-size);
  left: 0;
  cursor: pointer;
  transition: all 0.3s;
  opacity: ${props => props.opacity};
  outline: none;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  text-align: center;
`;

export const HoverImageButton = styled.a`
  width: 100%;
  position: absolute;
  top: 82%;
  padding: 5px 0px;
  border: none;
  font-size: var(--xsm-font-size);
  left: 0;
  cursor: pointer;
  transition: all 0.3s;
  opacity: ${props => props.opacity};
  outline: none;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  text-align: center;
`;

export const ActivityGradePackLabel=styled.div`
    
    padding:4px 0px;
    padding-left:6px;`;

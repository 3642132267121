import styled from "styled-components";

export const InputField = styled.div`
  position: relative;
  width: 100%;
  border-radius: 100px;
  border: solid 1px rgba(40, 50, 112, 0.1);
  margin: var(--sm-font-size) 0px;
`;

export const InputBox = styled.input`
  border: 1px solid rgba(0, 0, 0, 0.15);
  font-size: var(--sm-font-size);
  text-align: left;
  padding: 10px;
  margin-bottom: 10px;
  color: #000;
  border-radius: 100px;
  width: 100%;
  background-color: #fff;
  outline: none;
`;

export const InputArea = styled.textarea`
  border: 1px solid rgba(0, 0, 0, 0.15);
  font-size: var(--sm-font-size);
  height: 80px;
  text-align: left;
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 10px;
  width: 100%;
  color: #000;
  border-radius: 10px;
  background-color: #fff;
  outline: none;
`;

import { consolidateStreamedStyles } from "styled-components";

export default {
  //MUTAIONS COMMON FOR ALL TABS
  SET_EXPERIENCE_TYPE: (state, { expType }) =>
    (state.experience_type = expType),
  SET_ALL_EXPERIENCE_NEXT: (state, { data }) => {
    state.experience_offset = data.offset;
    state.experience_next = data.next;
  },
  SET_FETCH_ALL_EXPERIENCE_LIST: (state, { val }) =>
    (state.fetch_all_experience_list = val),
  SET_ALL_EXPERIENCES_LIST: (state, { data }) => {
    state.all_experiences_list.push(...data);
  },
  UNSET_ALL_EXPERIENCES_LIST: (state, { data }) => {
    state.all_experiences_list = [];
  },
  SET_ALL_EXPERIENCES_RESULT: (state, { data }) => {
    state.all_experiences_result = data;
  },
  SET_FETCH_ALL_ACTIVE_EXPERIENCE: (state, { value }) => {
    state.fetch_all_active_experience = value;
  },
  SET_ACTIVE_ALL_EXPERIENCE: (state, { experience }) => {
    state.active_all_experience = experience;
  },
  SET_ACTIVE_EXPERIENCE_DATE: (state, { date }) =>
    (state.experience_date = date)
  ,
};

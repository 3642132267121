import styled from "styled-components";
import {
  AutoContainer,
  InfiniteScrollParentContainer
} from "@/static/styled/custom.styled";
export const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 1px solid #fff;
`;

export const ScrollContainer = AutoContainer.extend`
  top: 10px;
  padding-bottom: 10px;
`;

export const ScrollParentContainer = InfiniteScrollParentContainer.extend`
  z-index: 0;
  padding-top: 0;
  padding-bottom: 0;
  top: 0;
`;
export const SelectTagsListContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const TagLabelContainer = styled.div`
  margin: 4px 8px;
  background-color: rgba(255, 138, 35, 0.1);
  color: #ff8a23;
  outline: none;
  border: none;
  border-radius: 100px;
  transition: all 0.3s;
  &:hover {
    background-color: #ff8a23;
    color: white;
    cursor: pointer;
  }
  div {
    padding: 6px 14px;
    font-size: var(--sm-font-size);
  }
`;
export const ContentBox = styled.div`
  position: relative;
  height: 700px;
  overflow-y: hidden;
`;
export const FlexBox = styled.div`
  width: 100%;
  display: flex;
`;

export const ChildBox = styled.div`
  height: 700px;
  position: relative;
  width: 350px;
  &:nth-child(1) {
    border-right: 1px solid #f3f3f3;
  }
`;

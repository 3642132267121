import React, { Component } from "react";
import {
  ActivityContinueButtonContainer,
  ActivityMainFormContainer,
  CreateActivityContainer,
} from "@c/activity-view/activity-creation-form/ActivityCreationForm.styled";
import {
  RCNameInput,
  CreateRCButton,
  PRTextArea,
  PRShortTextArea,
  Dropdown,
} from "./SchoolGuidedPathCreationForm.styled";
import { ThemeButton } from "@sc/custom.styled";

import { module as ui } from "@st/ui";
import { module as activity } from "@st/activity";
import { module as schoolGuidedPath } from "@st/schoolGuidedPath";
import { connectStore } from "redux-box";
import swal from "sweetalert2";
import Select from "react-select";

class SchoolGuidedPathCreationForm extends Component {
  constructor(props) {
    super();
    this.state = {
      schoolGuidedPathFormData: {
        name: "",
        description: "",
        selectedGrade: {},
        short_description: "",
        sections: [],
        selectedSection: [],
        id: "",
        school_id: null,
      },
    };
  }

  componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search);

    const unit_type = urlParams.get("type");
    const { activity } = this.props;

    if (unit_type === "custom") {
      this.fetchSections();
    }

    let selectedGrade = activity.grades.filter(
      (ele) => ele.id === this.props.schoolGuidedPath.active_grade
    );
    this.setState({
      schoolGuidedPathFormData: {
        ...this.state.schoolGuidedPathFormData,
        selectedGrade: selectedGrade[0],
      },
    });

    let { unitEditInfo } = this.props.schoolGuidedPath;
    if (unitEditInfo && unitEditInfo.id) {
      this.setUnitData();
    } else {
      this.props.schoolGuidedPath.setUnitEdit(true);
    }
  }

  componentWillUnmount() {
    this.props.schoolGuidedPath.setUnitEditInfo(null);
    this.props.schoolGuidedPath.setUnitEdit(false);
  }

  setUnitData = () => {
    let { unitEditInfo } = this.props.schoolGuidedPath;
    const { activity } = this.props;
    let selectedGrade = activity.grades.filter(
      (ele) => ele.id === this.props.schoolGuidedPath.active_grade
    );
    let selectedSections = this.props.schoolGuidedPath.sections[
      this.props.schoolGuidedPath.active_grade
    ];
    let filteredSection =
      selectedSections &&
      selectedSections.filter(
        (section) => section.id == unitEditInfo.school_class
      );
    let section = {};
    if (filteredSection && filteredSection.length > 0) {
      section = {
        value: filteredSection[0].id,
        label: filteredSection[0].name,
      };
    }

    // console.log(section,selectedSections)
    // console.log("unitEditInfo====", unitEditInfo);
    this.setState({
      schoolGuidedPathFormData: {
        ...this.state.schoolGuidedPathFormData,
        name: unitEditInfo.name,
        description: unitEditInfo.description,
        short_description: unitEditInfo.short_description,
        selectedGrade: selectedGrade[0],
        selectedSection: section,
        id: unitEditInfo.id,
      },
    });
  };

  handleName = (evt) => {
    this.setState({
      schoolGuidedPathFormData: {
        ...this.state.schoolGuidedPathFormData,
        name: evt.target.value,
      },
    });
  };

  handleShortDescription = (evt) => {
    this.setState({
      schoolGuidedPathFormData: {
        ...this.state.schoolGuidedPathFormData,
        short_description: evt.target.value,
      },
    });
  };

  handleDescription = (evt) => {
    this.setState({
      schoolGuidedPathFormData: {
        ...this.state.schoolGuidedPathFormData,
        description: evt.target.value,
      },
    });
  };

  handleSection = (evt) => {
    this.setState({
      schoolGuidedPathFormData: {
        ...this.state.schoolGuidedPathFormData,
        selectedSection: evt,
      },
    });
  };

  handleEditBtn = () => {
    this.props.schoolGuidedPath.setUnitEdit(true);
  };

  handleUpdateBtn = () => {
    const { schoolGuidedPathFormData } = this.state;
    const urlParams = new URLSearchParams(window.location.search);

    const unit_type = urlParams.get("type");
    if (
      schoolGuidedPathFormData.name &&
      schoolGuidedPathFormData.description &&
      schoolGuidedPathFormData.short_description &&
      Object.keys(schoolGuidedPathFormData.selectedGrade)
    ) {
      let updateUnitObject = {
        name: schoolGuidedPathFormData.name,
        description: schoolGuidedPathFormData.description,
        short_description: schoolGuidedPathFormData.short_description,
        // grade: schoolGuidedPathFormData.selectedGrade.id,
        unitId: this.props.schoolGuidedPath.unitEditInfo.id,
      };
      {
        unit_type === "custom"
          ? (updateUnitObject["section"] =
              schoolGuidedPathFormData.selectedSection &&
              schoolGuidedPathFormData.selectedSection.value)
          : (updateUnitObject["grade"] =
              schoolGuidedPathFormData.selectedGrade.id);
      }
      this.props.schoolGuidedPath.updateUnit(updateUnitObject);
      this.props.ui.hideModal();
      this.props.schoolGuidedPath.setUnitEdit(false);
    } else {
      swal(
        "Oops!",
        "You must enter title, description and long description",
        "error"
      );
    }
  };

  handleContinueBtn = () => {
    const { schoolGuidedPathFormData } = this.state;
    const urlParams = new URLSearchParams(window.location.search);

    const unit_type = urlParams.get("type");
    const guided_path_id = urlParams.get("id");
    if (
      schoolGuidedPathFormData.name &&
      schoolGuidedPathFormData.description &&
      schoolGuidedPathFormData.short_description &&
      Object.keys(schoolGuidedPathFormData.selectedGrade)
    ) {
      let unitObject = {
        name: schoolGuidedPathFormData.name,
        description: schoolGuidedPathFormData.description,
        short_description: schoolGuidedPathFormData.short_description,
      };
      unitObject["guided_path"] = guided_path_id;

      {
        if (unit_type === "custom") {
          unitObject["sections"] =
            schoolGuidedPathFormData.selectedSection &&
            schoolGuidedPathFormData.selectedSection.map(
              (section) => section.value
              );
            } else {
          unitObject["grade"] = schoolGuidedPathFormData.selectedGrade.id;
        }
      }
      {
        unit_type === "custom"
          ? this.props.schoolGuidedPath.createMultiUnit(unitObject)
          : this.props.schoolGuidedPath.createUnit(unitObject);
      }
      this.props.ui.hideModal();
    } else {
      swal(
        "Oops!",
        "You must enter title, description and long description",
        "error"
      );
    }
  };

  fetchSections = () => {
    let sectionsList = this.props.schoolGuidedPath.sections[
      this.props.schoolGuidedPath.active_grade
    ];
    let sectionList = sectionsList
      ? sectionsList.map((section) => {
          return {
            value: section.id,
            label: section.name,
          };
        })
      : [];
    this.setState({ sections: sectionList });
  };

  render() {
    const { schoolGuidedPathFormData } = this.state;
    const urlParams = new URLSearchParams(window.location.search);

    const unit_type = urlParams.get("type");
    return (
      <CreateActivityContainer>
        {console.log("schoolGuidedPath======", this.props.schoolGuidedPath)}
        <ActivityMainFormContainer>
          <form>
            <RCNameInput
              type="text"
              className="form-control"
              autoFocus
              value={
                Object.keys(schoolGuidedPathFormData.selectedGrade).length >
                  0 && schoolGuidedPathFormData.selectedGrade.name
              }
              disabled={true}
            />
            {unit_type === "custom" && (
              <Select
                isMulti={
                  this.props.schoolGuidedPath.unitEditInfo
                    ? this.props.schoolGuidedPath.unitEditInfo.id
                      ? false
                      : true
                    : true
                }
                className="dropdown-container"
                onChange={this.handleSection}
                value={schoolGuidedPathFormData.selectedSection}
                options={this.state.sections}
                placeholder="Select Section"
                isDisabled={
                  this.props.schoolGuidedPath.unitEditInfo ? true : false
                }
              />
            )}
            <RCNameInput
              type="text"
              className="form-control"
              autoFocus
              maxLength="50"
              placeholder="Add Unit Title"
              value={schoolGuidedPathFormData.name}
              disabled={
                this.props.schoolGuidedPath.unitEdit
                  ? !this.props.schoolGuidedPath.unitEdit
                  : true
              }
              onChange={this.handleName}
            />
            <div>
              <PRShortTextArea
                className="form-control"
                placeholder="Add Short description"
                maxLength="50"
                value={schoolGuidedPathFormData.short_description}
                disabled={
                  this.props.schoolGuidedPath.unitEdit
                    ? !this.props.schoolGuidedPath.unitEdit
                    : true
                }
                onChange={this.handleShortDescription}
              />
            </div>
            <div>
              <PRTextArea
                className="form-control"
                placeholder="Add Long description"
                maxLength="200"
                value={schoolGuidedPathFormData.description}
                disabled={
                  this.props.schoolGuidedPath.unitEdit
                    ? !this.props.schoolGuidedPath.unitEdit
                    : true
                }
                onChange={this.handleDescription}
              />
            </div>
            <ActivityContinueButtonContainer>
              {this.props.schoolGuidedPath.unitEditInfo &&
              this.props.schoolGuidedPath.unitEditInfo.id ? (
                this.props.schoolGuidedPath.unitEdit ? (
                  <ThemeButton
                    primary
                    type="button"
                    onClick={this.handleUpdateBtn}
                  >
                    {" "}
                    Update Unit{" "}
                  </ThemeButton>
                ) : (
                  <ThemeButton
                    primary
                    type="button"
                    onClick={this.handleEditBtn}
                  >
                    {" "}
                    Edit Unit{" "}
                  </ThemeButton>
                )
              ) : (
                <CreateRCButton
                  primary
                  className="btn btn-warning"
                  type="button"
                  onClick={() => this.handleContinueBtn()}
                >
                  {" "}
                  Create Unit{" "}
                </CreateRCButton>
              )}
            </ActivityContinueButtonContainer>
          </form>
        </ActivityMainFormContainer>
      </CreateActivityContainer>
    );
  }
}
export default connectStore({
  ui,
  activity,
  schoolGuidedPath,
})(SchoolGuidedPathCreationForm);

import React, { Component } from 'react';
import { TextSectionContainer } from './TextSection.styled';

export default class TextSection extends Component {
    render() {
        let data = this.props.description;
        return (
            <TextSectionContainer >
                <div dangerouslySetInnerHTML={{__html:data}}>
                </div>
            </TextSectionContainer>
        )
    }
}

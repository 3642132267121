import React, { Component } from "react";
import {
  NavigationContainer,
  NavigationHeaderContainer
} from "@sc/NavigationBar.styled";
import { NavLink } from "react-router-dom";
import { SearchBarContainer } from "@c/users-view/MainContent.styled";


export default class ViewHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchBarEnable: true,
      navigationSelector: false,
      active: ""
    };
  }
  componentDidMount() {
    let currentPath = window.location.pathname;
    if (currentPath === '/leader-board' || currentPath === '/') {
      this.setState({
        active: 'Leader Board',
      })
    }
    else {
      this.setState({
        active: 'Freadom Leader Board',
      })
    }
  }
  enableSearchBar = actionType => {
    if (actionType === "focus" && this.state.searchBarEnable === false)
      this.setState({
        searchBarEnable: true
      });
    else if (actionType === "blur" && this.state.searchBarEnable === true)
      this.setState({
        searchBarEnable: false
      });
  };
  handleClearSearch = () => {
    let { leaderBoard } = this.props;
    leaderBoard.setSearchLeaderBoard("");
    leaderBoard.requestLeaderBoardList(0);
    leaderBoard.setSearchFreadomLeaderBoard("");
    leaderBoard.requestFreadomPointLeaderBoardList(0);
  };
  handleLeaderBoardSearch = evt => {

    if (this.state.active === 'Leader Board') {
      let { leaderBoard } = this.props;
      leaderBoard.setSearchLeaderBoard(evt.target.value);
      setTimeout(() => {
        let { leaderBoard } = this.props;
        leaderBoard.requestLeaderBoardList(0);
      }, 2500);
    }
    if (this.state.active === 'Freadom Leader Board') {
      let { leaderBoard } = this.props;
      leaderBoard.setSearchFreadomLeaderBoard(evt.target.value);
      setTimeout(() => {
        let { leaderBoard } = this.props;
        leaderBoard.requestFreadomPointLeaderBoardList(0);
      }, 2500);
    }
  };
  handleNavigationChange = data => {
    this.setState({
      active: data.title
    })
  };
  render() {
    let { leaderBoard } = this.props;
    const data = [
      { title: "Leader Board", link: "/leader-board", active: "/leader-board" },
      {
        title: "Freadom Leader Board",
        link: "/leader-board/freadom",
        active: "/leader-board/freadom"
      }
    ];
    let tabList = data.map((type, i) => (
      <NavLink
        exact={true}
        to={type.link}
        key={i}
        className={this.state.active === type.active ? "active" : ""}
        onClick={() => this.handleNavigationChange(type)}
      >
        {type.title}
      </NavLink>
    ));
    return (
      <NavigationHeaderContainer>
        <NavigationContainer>{tabList}</NavigationContainer>
        <SearchBarContainer
          width={this.state.searchBarEnable ? "300px" : "200px"}>
          <i className="fa fa-search" />
          <input
            type="text"
            onChange={this.handleLeaderBoardSearch}
            placeholder="Search"
            value={leaderBoard.search_leaderBoard}
            onFocus={() => this.enableSearchBar("focus")}
            onBlur={() => this.enableSearchBar("blur")}
          />
          {this.props.leaderBoard.search_leaderBoard.length > 0 && (
            <i
              className="fa fa-times cross"
              onClick={() => this.handleClearSearch()}
            />
          )}

        </SearchBarContainer>
      </NavigationHeaderContainer>
    );
  }
}

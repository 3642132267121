import React, { Component } from "react";
import {
  AlignContentHorizontal,
  EmptyContainer,
  AutoContainer,
  LoadingContainer,
  InfiniteScrollParentContainer,
  InfiniteScrollLoader,
} from "static/styled/custom.styled";
import {
  NavigationHeaderContainer,
  NavigationContainer,
} from "static/styled/NavigationBar.styled";
import { connectStore } from "redux-box";
import { module as activity } from "@st/activity";
import { module as schoolGuidedPath } from "@st/schoolGuidedPath";
import { Link } from "react-router-dom";
import SchoolGuidedPathCard from "./school-guided-path-card/SchoolGuidedPathCard";
import InfiniteScroll from "react-infinite-scroller";
import { SearchBarContainer } from "@c/users-view/MainContent.styled";
import {
  GradeButton,
} from "./SchoolGuidedPathList.styled";
import Select from "react-select";

class SchoolGuidedPathList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      classList: [],
      searchBarEnable: false,
      activeGrade: null,
      units: [],
      sectionList: [],
      selectedSection: null,
      refreshComponent: false,
      grades: [],
      gradeChanged: false,
      allGradeSections: {},
    };
    this.handleSections = this.handleSections.bind(this);
  }

  componentDidMount() {
    let urlParams = new URLSearchParams(window.location.search);
    let id = urlParams.get("id");
    let type = urlParams.get("type");

    this.setState({id:id})

    if (type === "custom") {
      this.props.schoolGuidedPath.fetchSectionsForGpProduct(id);
    }
  }

  fetchMoreReadingChallenges = () => {
    if (
      this.props.schoolGuidedPath.result !== null &&
      this.props.schoolGuidedPath.result.has_next
    ) {
      setTimeout(() => {
        if (
          this.props.schoolGuidedPath.result !== null &&
          this.props.schoolGuidedPath.result.next_page > 0
        )
          this.props.schoolGuidedPath.requestConcat(
            this.props.schoolGuidedPath.result.next_page
          );
      }, 100);
    }
  };
  handleGradeChange = (grade) => {
    this.props.schoolGuidedPath.setSchoolGuidedPathList([]);
    if (this.props.type !== "custom") {
      this.props.schoolGuidedPath.requestByGradeId(grade.id);
    } else {
      // console.log("inside else");
      this.props.schoolGuidedPath.setActiveGradeId(grade.id);
      let sectionsList = this.state.allGradeSections[grade.id];
      let sectionList = sectionsList
        ? sectionsList.map((section) => {
            return {
              value: section.id,
              label: section.name,
            };
          })
        : [];
      // console.log(sectionList);
      if (sectionList.length > 0) {
        this.setState({ selectedSection: sectionList[0] });
        this.props.schoolGuidedPath.requestBySectionId(
          sectionList[0].value
        );
      }
    }
    // this.setState({selectedSection:{value:'all',label:"All"}})
  };

  filterReadingChallenge = () => {
    return this.props.schoolGuidedPath.List &&
      this.props.schoolGuidedPath.active_grade
      ? this.props.schoolGuidedPath.List.filter((schoolGuidedPath) => {
          return (
            schoolGuidedPath.grade ===
              this.props.schoolGuidedPath.active_grade &&
            this.props.schoolGuidedPath.active_grade
          );
        })
      : [];
  };

  handleReadingSearch = (evt) => {
    let { schoolGuidedPath } = this.props;
    schoolGuidedPath.setSearch(evt.target.value);
    // console.log('inside fucntion calling action')
    setTimeout(() => {
      this.props.schoolGuidedPath.requestSchoolGuidedPath({type:this.props.type});
    }, 2000);
  };

  handleClearSearch = () => {
    let { schoolGuidedPath } = this.props;
    schoolGuidedPath.setSearch("");
    schoolGuidedPath.requestSchoolGuidedPath({type:this.props.type});
  };

  enableSearchBar = (actionType) => {
    if (actionType === "focus" && this.state.searchBarEnable === false)
      this.setState({
        searchBarEnable: true,
      });
    else if (actionType === "blur" && this.state.searchBarEnable === true)
      this.setState({
        searchBarEnable: false,
      });
  };

  componentDidUpdate(prevProps, prevState) {
    let prevSchoolGuidedPath = prevProps.schoolGuidedPath;
    if (prevSchoolGuidedPath.grades !== this.props.schoolGuidedPath.grades) {
      this.setState({ grades: this.props.schoolGuidedPath.grades });
    }
    if (
      prevSchoolGuidedPath.sections !== this.props.schoolGuidedPath.sections
    ) {
      this.setState({ allGradeSections: this.props.schoolGuidedPath.sections });
    }
    if (
      prevSchoolGuidedPath.active_grade !==
      this.props.schoolGuidedPath.active_grade
    ) {
      let sectionsList = this.state.allGradeSections[
        this.props.schoolGuidedPath.active_grade
      ];
      let sectionList = sectionsList
        ? sectionsList.map((section) => {
            return {
              value: section.id,
              label: section.name,
            };
          })
        : [];

      // sectionList.push({
      //   value:"all",
      //   label:"All"
      // })

      this.setState({
        sectionList: sectionList,
        activeGrade: this.props.schoolGuidedPath.active_grade,
      });
      if (sectionList.length > 0) {
        this.setState({ selectedSection: sectionList[0] });
        this.props.schoolGuidedPath.requestBySectionId(sectionList[0].value);
      }
    }
    if (
      prevSchoolGuidedPath.active_section !==
      this.props.schoolGuidedPath.active_section
    ) {
      let sectionsList = this.state.allGradeSections[
        this.props.schoolGuidedPath.active_grade
      ];
      let sectionList = sectionsList
        ? sectionsList.map((section) => {
            return {
              value: section.id,
              label: section.name,
            };
          })
        : [];
      // console.log(sectionList, this.props.schoolGuidedPath.active_section);
      if (sectionList.length > 0) {
        let filteredSection = sectionList.filter(
          (section) =>
            section.value === this.props.schoolGuidedPath.active_section
        );
        // console.log(filteredSection);
        this.setState({
          selectedSection: filteredSection[0],
        });
      }
    }
  }

  handleSections = (event) => {
    this.setState({ selectedSection: event });
    if (event.value !== "all") {
      this.props.schoolGuidedPath.requestBySectionId(event.value);
    }
    // else{
    // this.props.schoolGuidedPath.requestByGradeId(this.props.schoolGuidedPath.active_grade);
    // }
  };

  render() {
    const { location } = this.props;
    let classList =
      this.props.activity.grades &&
      this.props.activity.grades.map((grade, i) => (
        <GradeButton
          key={i}
          className={
            this.props.schoolGuidedPath.active_grade &&
            grade.id === this.props.schoolGuidedPath.active_grade
              ? "active"
              : ""
          }
          disabled={
            this.props.type === "custom"
              ? !this.state.grades.includes(grade.id)
              : false
          }
          onClick={() => this.handleGradeChange(grade)}
        >
          <span> {grade.name}</span>
        </GradeButton>
      ));

    let editRoute = location.pathname.includes("/school-guided-path")
      ? "/school-guided-path/edit/"
      : "/school-default-guided-path/edit/";

    let readingChallengeList = (
      <AlignContentHorizontal marginBottom="70px">
        {this.props.schoolGuidedPath.List &&
        this.props.schoolGuidedPath.List.length > 0 ? (
          this.props.schoolGuidedPath.List.map(
            (schoolGuidedPathInfo, index) => {
              return (
                <div className="col-sm-6 col-md-4 col-lg-3" key={index}>
                  <Link
                    to={
                      editRoute +
                      schoolGuidedPathInfo.id +
                      "?unit_type=" +
                      this.props.type +
                      "&gpId=" +
                      this.state.id +
                      "&grade_id=" +
                      this.props.schoolGuidedPath.active_grade
                    }
                  >
                    <SchoolGuidedPathCard
                      schoolGuidedPathInfo={schoolGuidedPathInfo}
                    />
                  </Link>
                </div>
              );
            }
          )
        ) : (
          <EmptyContainer>
            <h4>NO UNIT FOR THIS GRADE</h4>
          </EmptyContainer>
        )}
      </AlignContentHorizontal>
    );

    return (
      <AutoContainer>
        <NavigationHeaderContainer shadow>
          <NavigationContainer>{classList}</NavigationContainer>
          <SearchBarContainer
            width={this.state.searchBarEnable ? "300px" : "200px"}
          >
            <i className="fa fa-search" />
            <input
              type="text"
              onChange={this.handleReadingSearch}
              placeholder="Search"
              value={this.props.schoolGuidedPath.search_reading}
              onFocus={() => this.enableSearchBar("focus")}
              onBlur={() => this.enableSearchBar("blur")}
            />
            {this.props.schoolGuidedPath.search_reading.length > 0 && (
              <i
                className="fa fa-times cross"
                onClick={() => this.handleClearSearch()}
              />
            )}
          </SearchBarContainer>
          {this.props.type === "custom" && (
            <Select
              className="dropdown-container"
              onChange={this.handleSections}
              value={this.state.selectedSection}
              options={this.state.sectionList && this.state.sectionList}
              placeholder="Select Section"
            />
          )}
        </NavigationHeaderContainer>
        {this.props.schoolGuidedPath.fetch_list ? (
          <LoadingContainer style={{ zIndex: "100" }}>
            <img
              alt="loading"
              width="60"
              height="60"
              src={require("static/img/Theme-loader.gif")}
            />
          </LoadingContainer>
        ) : this.props.schoolGuidedPath.List.length > 0 ? (
          <InfiniteScrollParentContainer noTop>
            <InfiniteScroll
              pageStart={0}
              loadMore={this.fetchMoreReadingChallenges}
              style={{ position: "relative" }}
              hasMore={this.props.schoolGuidedPath.result.has_next}
              loader={
                <InfiniteScrollLoader className="loader" key={0}>
                  <img
                    alt="loader"
                    width="50"
                    height="50"
                    src={require("static/img/Theme-loader.gif")}
                  />
                </InfiniteScrollLoader>
              }
              useWindow={false}
            >
              {readingChallengeList}{" "}
            </InfiniteScroll>
          </InfiniteScrollParentContainer>
        ) : (
          <React.Fragment>{readingChallengeList}</React.Fragment>
        )}
      </AutoContainer>
    );
  }
}
export default connectStore({
  activity,
  schoolGuidedPath,
})(SchoolGuidedPathList);

export default {
  SET_TAG_LIST: (state, { tagList }) => (state.tagList = tagList),
  SET_TAG_RESULT: (state, { tagResult }) => (state.tagResult = tagResult),
  SET_CONCAT_TAG_LIST: (state, { taglist }) =>
  (state.tagList = state.tagList.concat(taglist)),
  SET_ACTIVE_TAG: (state, { tag }) => (state.activeTag = tag),
  SET_ACTIVE_CHILD_TAG: (state, { tag }) => (state.activeChildTag = tag),
  SET_CHILD_TAG_LIST: (state, { childList }) =>
    (state.childTagList = childList),
  SET_SELECTED_TAG_LIST: (state, { data }) => {
    state.selectedTagList = data;
    state.selectedTagIdList = data.map(tag => tag.id);
  },
  REMOVE_TAG_FROM_SELECTED_TAG_LIST: (state, { data }) => {
    state.selectedTagIdList = state.selectedTagIdList.filter(
      tag => tag !== data.id
    );
    state.selectedTagList = state.selectedTagList.filter(
      tag => tag.id !== data.id
    );
  },
  REMOVE_CHILD_TAG_FROM_SELECTED_TAG_LIST: (state, { data }) => {
    state.selectedTagList = state.selectedTagList.filter(
      tag => tag.parent_tag !== data.id
    );
    state.selectedTagIdList = state.selectedTagList.map(tag => tag.id);
  },
  ADD_TAG_IN_SELECTED_TAG_LIST: (state, { data }) => {
    let list = state.selectedTagIdList;
    if (list.length > 0 && !list.some(tag => tag === data.id)) {
      state.selectedTagIdList = [...state.selectedTagIdList, data.id];
      state.selectedTagList = [...state.selectedTagList, data];
    } else if (list.length === 0) {
      state.selectedTagIdList = [...state.selectedTagIdList, data.id];
      state.selectedTagList = [...state.selectedTagList, data];
    }
  },
  SET_FETCH_ACTIVITY_PACKS_BY_LEVEL_TAG: (state, { value }) =>
  (state.fetch_activity_packs_by_level = value),
};

import styled from "styled-components"

export const UserDetailsTableField=styled.div`

   font-size: var(--sm-font-size);
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    padding:10px 0px;
    color:${props=>props.primary? '#ff8a23': 'black'}
    

`;